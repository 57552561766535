import { getCurrentUser, getUserToken } from "./user-helper";

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = getCurrentUser();
  const token = getUserToken();

  if (currentUser && token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
