export const organizationConstants = {
  GET_SINGLE_REQUEST: "ORGANIZATION_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "ORGANIZATION_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "ORGANIZATION_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "ORGANIZATION_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "ORGANIZATION_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "ORGANIZATION_GET_ALL_FAILURE",

  ADD_REQUEST: "ORGANIZATION_ADD_REQUEST",
  ADD_SUCCESS: "ORGANIZATION_ADD_SUCCESS",
  ADD_FAILURE: "ORGANIZATION_ADD_FAILURE",

  UPDATE_REQUEST: "ORGANIZATION_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ORGANIZATION_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ORGANIZATION_UPDATE_FAILURE",

  DELETE_REQUEST: "ORGANIZATION_DELETE_REQUEST",
  DELETE_SUCCESS: "ORGANIZATION_DELETE_SUCCESS",
  DELETE_FAILURE: "ORGANIZATION_DELETE_FAILURE",

  CLEAR: "ORGANIZATION_CLEAR",
};
