export const roleConstants = {
  GETALL_INTERNAL_REQUEST: "ROLES_GETALL_INTERNAL_REQUEST",
  GETALL_INTERNAL_SUCCESS: "ROLES_GETALL_INTERNAL_SUCCESS",
  GETALL_INTERNAL_FAILURE: "ROLES_GETALL_INTERNAL_FAILURE",

  GETALL_EXTERNAl_REQUEST: "ROLES_GETALL_EXTERNAl_REQUEST",
  GETALL_EXTERNAl_SUCCESS: "ROLES_GETALL_EXTERNAl_SUCCESS",
  GETALL_EXTERNAl_FAILURE: "ROLES_GETALL_EXTERNAl_FAILURE",

  ADD_REQUEST: "ROLES_ADD_REQUEST",
  ADD_SUCCESS: "ROLES_ADD_SUCCESS",
  ADD_FAILURE: "ROLES_ADD_FAILURE",

  UPDATE_REQUEST: "ROLES_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ROLES_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ROLES_UPDATE_FAILURE",

  GET_SINGLE_REQUEST: "ROLE_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "ROLE_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "ROLE_GET_SINGLE_FAILURE",


}