import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { addActiveAll, removeActiveAll } from "../store/Store/orderSlice";
import { useState } from "react";
import EditCalculatedRows from "../components/EditCalculatedRows";

const StyledWrapper = styled("div")({
  overflowX: "auto",
  overflowY: "auto",
});

const EditTable = ({ searchItem }) => {
  const dispatch = useDispatch();
  const { activeSport, listedItems, allActiveSport, order } = useSelector(
    (state) => state.orders
  );
  const [data, setData] = useState([]);
  // const hasBeenActivated = allActiveSport.includes(activeSport);
  const hasBeenActivated = listedItems?.length==0? false : listedItems.every(element => {
    return order.includes(element);
  });
  useEffect(() => {
    setData(
      listedItems.filter(
        (l) =>
          l?.sport_id === activeSport &&
          l?.name.toLowerCase().includes(searchItem.toLowerCase())
      )
    );
  }, [activeSport, listedItems, searchItem]);

  const addAllItems = (e) => {
    let value = e.target.checked;
    if (value) {
      dispatch(removeActiveAll({ activeSport }))
      dispatch(addActiveAll({ activeSport }));
    } else dispatch(removeActiveAll({ activeSport }));
  };

  return (
    <StyledWrapper>
      <TableContainer>
        <Table
          sx={{ overflow: "true", width: "1000px" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "250px" }}>
                <Checkbox
                  onChange={addAllItems}
                  id={activeSport}
                  checked={hasBeenActivated}
                />
                Item
              </TableCell>
              <TableCell align="right" style={{ minWidth: "150px" }}>
                Fabric
              </TableCell>
              <TableCell align="right" style={{ minWidth: "150px" }}>
                Lining Item
              </TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Extra Charge</TableCell>
              <TableCell align="right">Discount</TableCell>
              <TableCell align="right" style={{ minWidth: "130px" }}>
                Discounted Price
              </TableCell>
              <TableCell align="center" style={{ minWidth: "130px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <EditCalculatedRows row={row} index={index} key={row.index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledWrapper>
  );
};

EditTable.defaultProps = {
  searchItem: "",
};

EditTable.propTypes = {
  searchItem: PropTypes.string,
};

export default EditTable;
