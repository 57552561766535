import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import ParameterForm from "../../../components/admin/parameterManagement/ParameterCard/ParameterForm/ParameterForm.component";

import { parameterTypes } from "../../../constants";

const ParameterEdit = () => {
  const { parameter } = useParams();
  const location = useLocation();
  const currentParameter = parameterTypes.filter((pt) => pt.slug === parameter);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/parameter-management"
    >
      Parameter Management
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/parameter-management/${currentParameter[0]?.slug}`}
    >
      {currentParameter[0]?.title}
    </Link>,
    <Typography key="3">{`Add ${currentParameter[0]?.title}`}</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Add ${currentParameter[0]?.titleSingular}`}
        pageTitleShort={`Add ${currentParameter[0]?.title}`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent
            title={`Enter ${currentParameter[0]?.titleSingular} Parameters`}
            subtitle=""
            description=""
          >
            <Grid container spacing="2">
              <Grid item xs={12}>
                <ParameterForm
                  parameter={currentParameter[0]}
                  mode="edit"
                  paramdata={location?.state?.param}
                />
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default ParameterEdit;
