export const patternConstants = {
  GET_SINGLE_REQUEST: "PATTERN_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "PATTERN_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "PATTERN_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "PATTERN_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "PATTERN_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "PATTERN_GET_ALL_FAILURE",

  ADD_REQUEST: "PATTERN_ADD_REQUEST",
  ADD_SUCCESS: "PATTERN_ADD_SUCCESS",
  ADD_FAILURE: "PATTERN_ADD_FAILURE",

  UPDATE_REQUEST: "PATTERN_UPDATE_REQUEST",
  UPDATE_SUCCESS: "PATTERN_UPDATE_SUCCESS",
  UPDATE_FAILURE: "PATTERN_UPDATE_FAILURE",

  DELETE_REQUEST: "PATTERN_DELETE_REQUEST",
  DELETE_SUCCESS: "PATTERN_DELETE_SUCCESS",
  DELETE_FAILURE: "PATTERN_DELETE_FAILURE",

  CLEAR: "PATTERN_CLEAR",
};
