export const couriersConstants = {
    GET_SINGLE_REQUEST: "COURIERS_GET_SINGLE_REQUEST",
    GET_SINGLE_SUCCESS: "COURIERS_GET_SINGLE_SUCCESS",
    GET_SINGLE_FAILURE: "COURIERS_GET_SINGLE_FAILURE",
  
    GET_ALL_REQUEST: "COURIERS_GET_ALL_REQUEST",
    GET_ALL_SUCCESS: "COURIERS_GET_ALL_SUCCESS",
    GET_ALL_FAILURE: "COURIERS_GET_ALL_FAILURE",
  
    ADD_REQUEST: "COURIERS_ADD_REQUEST",
    ADD_SUCCESS: "COURIERS_ADD_SUCCESS",
    ADD_FAILURE: "COURIERS_ADD_FAILURE",
  
    UPDATE_REQUEST: "COURIERS_UPDATE_REQUEST",
    UPDATE_SUCCESS: "COURIERS_UPDATE_SUCCESS",
    UPDATE_FAILURE: "COURIERS_UPDATE_FAILURE",
  
    DELETE_REQUEST: "COURIERS_DELETE_REQUEST",
    DELETE_SUCCESS: "COURIERS_DELETE_SUCCESS",
    DELETE_FAILURE: "COURIERS_DELETE_FAILURE",
  
    CLEAR: "COURIERS_CLEAR",
  };
  