import { sizeConstants } from "../../constants";

export function sizes(state = {}, action) {
  switch (action.type) {
    // get all
    case sizeConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case sizeConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case sizeConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case sizeConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case sizeConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case sizeConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case sizeConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case sizeConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case sizeConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case sizeConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case sizeConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case sizeConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case sizeConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
