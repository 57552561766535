export const countryConstants = {
  GET_SINGLE_REQUEST: "COUNTRY_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "COUNTRY_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "COUNTRY_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "COUNTRY_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "COUNTRY_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "COUNTRY_GET_ALL_FAILURE",

  ADD_REQUEST: "COUNTRY_ADD_REQUEST",
  ADD_SUCCESS: "COUNTRY_ADD_SUCCESS",
  ADD_FAILURE: "COUNTRY_ADD_FAILURE",

  UPDATE_REQUEST: "COUNTRY_UPDATE_REQUEST",
  UPDATE_SUCCESS: "COUNTRY_UPDATE_SUCCESS",
  UPDATE_FAILURE: "COUNTRY_UPDATE_FAILURE",

  DELETE_REQUEST: "COUNTRY_DELETE_REQUEST",
  DELETE_SUCCESS: "COUNTRY_DELETE_SUCCESS",
  DELETE_FAILURE: "COUNTRY_DELETE_FAILURE",

  CLEAR: "COUNTRY_CLEAR",
};
