import styled from "@emotion/styled";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Inputs from "../Inputs";
import Selects from "../Selects";
import { useFormik } from "formik";
import validationSchema from "../../Schemas/customerSchema";
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "798px",
  height: "502px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};

const AcceptQuotationModal = ({ ...props }) => {
  const { open, handleClose, countries, request, createAccount } = props;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      address1: "",
      address2: "",
      postalCode: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      createAccount(e);
      navigate("/login");
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction={"column"} spacing={3} sx={{ padding: "0 5px 0 5px" }}>
          <Typography fontSize={"24px"}>Customer Registration</Typography>
          <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
            <Inputs
              variant="standard"
              label="Organization Name"
              id="name"
              sx={{ width: "45%" }}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
            />
          </Stack>
          <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
            <Inputs
              variant="standard"
              label="Organization Telephone No"
              sx={{ width: "100%" }}
              value={formik.values.phone}
              onChange={formik.handleChange}
              id="phone"
              error={formik.errors.phone}
            />
            <Inputs
              variant="standard"
              label="Organization Email"
              type={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              id="email"
              error={formik.errors.email}
            />
          </Stack>
          <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
            <Inputs
              variant="standard"
              label="Address Line 1"
              sx={{ width: "100%" }}
              value={formik.values.address1}
              onChange={formik.handleChange}
              id="address1"
              error={formik.errors.address1}
            />
            <Inputs
              variant="standard"
              label="Address Line 2"
              sx={{ width: "100%" }}
              value={formik.values.address2}
              onChange={formik.handleChange}
              id="address2"
              error={formik.errors.address2}
            />
          </Stack>
          <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
            <Inputs
              variant="standard"
              label="Postal Code"
              sx={{ width: "100%" }}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              id="postalCode"
              error={formik.errors.postalCode}
            />
            <Selects
              label={"Country"}
              value={formik.values.country}
              error={formik.errors.country}
              onChange={formik.setFieldValue}
              id="country"
              name="country"
              data={countries}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between">
          <Button color="success" onClick={() => request("accept", "success")}>
            SKIP ACCOUNT CREATION
          </Button>
          <StyledButton
            color="success"
            variant="contained"
            onClick={() => formik.submitForm()}
          >
            CREATION ACCOUNT AND ACCEPT THE QUOTATION
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};

AcceptQuotationModal.propTypes = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,

  countries: PropTypes.array,
};
AcceptQuotationModal.defaultProps = {
  open: false,
  countries: [],
};

export default AcceptQuotationModal;
