import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../../../../layouts/PageLayout/PageLayout.layout";
import { useDispatch } from "react-redux";
import { userActions } from "../../../../actions";
import { getCurrentUser } from "../../../../helpers";

const ProtectedCustomerRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(getCurrentUser() ? true : null);
  const userId = getCurrentUser()?.id;

  useEffect(() => {
    const loginCheck = () => {
      if (!user) {
        navigate("/login", { state: { redirect: location.pathname } });
      }
    };
    loginCheck();
    dispatch(userActions.getUserDetails(userId));
  }, [dispatch, navigate, user, userId]);

  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
};

export default ProtectedCustomerRoute;
