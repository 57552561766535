import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { convertToPlaces, getDiscountedPrice } from "../../helpers/helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateList, updateOrder } from "../../store/Store/orderSlice";
import { authHeader } from "../../helpers";
import { apiConstants } from "../../constants";

const StyledButton = styled(Button)({
  color: "white",
});

const StyledPriceButton = styled(Button)({
  color: "white",
  height: "40px",
  minWidth: "160px !important",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "798px",
  minHeight: "174px",
  padding: "20px",
};

const EditItemModal = ({ ...props }) => {
  const { open, handleClose, data, index } = props;
  const { activeSport, listedItems, order } = useSelector(
    (state) => state.orders
  );
  const [updatedItem, setUpdated] = useState({});
  const [fabrics, setFabrics] = useState([]);
  const [linings, setLinings] = useState([]);
  const [edit, setEdit] = useState(false);
  const [extra, setExtra] = useState([]);
  const [extraCharges, setExtraCharges] = useState([
    ...(data.extra_charges_list || []),
  ]);
  const [selected, setSelected] = useState({});

  const [enableAdd, setEnableAdd] = useState(false);

  const [fabricPriceId, setFabricPriceId] = useState("");
  const [priceId, setPriceId] = useState("");
  const [originalUnitPrice, setOriginalUnitPrice] = useState("");
  const [unitPriceError, setUnitPriceError] = useState(false);
  const [quantity, setQty] = useState(updatedItem?.qty);

  const isCustomItem = updatedItem.price_id ?? false;
  const dispatch = useDispatch();

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const clearAll = () => {
    setLinings([]);
    handleClose();
    setEdit(false);
  };

  const handleSave = () => {
    const checked =
      order.filter((f) => f.id === listedItems[index]?.id).length !== 0;
    let newItem = {
      ...updatedItem,
      selected: checked,
      original_unit_price: updatedItem.original_unit_price * 100,
    };
    dispatch(updateList({ index: index, newItem: newItem }));
    checked && dispatch(updateOrder({ index: index, newItem }));
    clearAll();
  };

  const onChangeText = (event) => {
    const { id, value } = event.target;
    if (id === "unit_price") {
      setUpdated((state) => ({
        ...state,
        original_unit_price: value,
      }));
    }
    setUpdated((state) => ({
      ...state,
      [id]: value,
    }));
  };

  const getExtra = () => {
    fetch(`${apiConstants.API_URL}/extra-charges`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const res = text && JSON.parse(text);
          setExtra(res?.data);
        });
      }
    );
  };

  const getLinings = (id) => {
    fetch(
      `${apiConstants.API_URL}/price/items/sport/${activeSport}/item/${updatedItem.item_id}/fabric/${id}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const res = text && JSON.parse(text);
        const linings = res?.data;
        linings.push({
          lining_ids: [],
          combined_name: "No Lining",
          price_id: "",
        });
        setLinings(res?.data);
      });
    });
  };

  useEffect(() => {
    if (isCustomItem) {
      fetch(
        `${apiConstants.API_URL}/price/items/sport/${data?.sport_id}/item/${data?.item_id}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const res = text && JSON.parse(text);
          setFabrics(res?.data);
          getLinings(data?.fabric_id);
        });
      });
    }
    getExtra();
    setEnableAdd(false);
    setUpdated({
      ...data,
      original_unit_price: data.original_unit_price / 100,
    });
    setOriginalUnitPrice(data.originalUnitPrice);
  }, [data, isCustomItem]);

  const onChangeFabric = (event) => {
    const { id, name, price_id } = event.target.value;
    setFabricPriceId(price_id);
    setPriceId(price_id);
    setUpdated({ ...data, fabric_id: id, fabric_name: name, lining_name: [] });
    getLinings(id);
  };

  const onChangeStyle = (event) => {
    const style = event.target.value;
    setUpdated({ ...data, style });
  };

  const onChangeLinings = (e) => {
    const value = e.target.value;
    let lining_names;
    if (value?.lining_ids !== 0) {
      lining_names = value?.combined_name?.split(",");
    } else {
      lining_names = "";
    }
    setPriceId(value?.price_id);
    setUpdated({
      ...data,
      lining_ids: value?.lining_ids,
      lining_name: lining_names,
    });
  };

  const getPrice = () => {
    let price_id;
    if (priceId || fabricPriceId) {
      price_id = updatedItem?.lining_ids.length !== 0 ? priceId : fabricPriceId;
    } else {
      price_id = updatedItem?.price_id;
    }
    fetch(
      `${apiConstants.API_URL}/price/${price_id}/qty/${updatedItem?.qty}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const res = text && JSON.parse(text);
        setOriginalUnitPrice(res?.data.unit_price);
        setUpdated({
          ...updatedItem,
          unit_price: getDiscountedPrice(
            res?.data.unit_price,
            updatedItem.discount_percentage
          ),
          price_id: res?.data.price_id,
          original_unit_price: res?.data.unit_price / 100,
        });
        setEnableAdd(res?.data?.unit_price > 0);
      });
    });
  };

  const onChangeQty = (e) => {
    setUpdated({
      ...updatedItem,
      qty: parseInt(e.target.value),
    });
    setQty(parseInt(e.target.value));
    // onChangeQtyExtra()
  };

  const onChangeDiscount = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "") * 100;

    let newUnitPrice = getDiscountedPrice(
      updatedItem.original_unit_price * 100,
      value
    );

    setUpdated((state) => ({
      ...state,
      unit_discount: value,
      unit_price: newUnitPrice,

      // unit_discount: value,
      // discount_percentage: value,
      // unit_price: newUnitPrice,
      total_price: state.qty * newUnitPrice,
    }));
    setEnableAdd(value > 0);
  };

  const onChangeUnitPrice = (e) => {
    let newUnitPrice = getDiscountedPrice(
      e.target.value * 100,
      updatedItem.discount_percentage
    );

    setUpdated({
      ...updatedItem,
      original_unit_price: e.target.value,
      unit_price: newUnitPrice,
    });
    setEnableAdd(e.target.value * 100 >= originalUnitPrice);
    if (e.target.value * 100 >= originalUnitPrice) {
      setUnitPriceError(false);
    } else {
      setUnitPriceError(true);
    }
  };

  const removeExtra = (index) => {
    let qty = quantity ? quantity : parseInt(updatedItem?.qty);
    let newArray = [];
    if (index) {
      newArray = extraCharges.slice(index);
    }
    setExtraCharges([...newArray]);
    let totalExtra = 0;
    newArray.map((e) => {
      totalExtra = totalExtra + e.cost * qty;
      return "";
    });
    setUpdated({
      ...updatedItem,
      extra_charges: totalExtra,
      extra_charges_list: [...newArray],
    });
  };

  const onChangeExtra = (e) => {
    const { value } = e.target;
    setSelected(() => value);
  };

  useEffect(() => {
    if (extraCharges.length !== 0) {
      let qty = quantity ? quantity : parseInt(updatedItem?.qty);
      let newArra = [...extraCharges];
      let totalExtra = 0;
      newArra.map((e) => {
        totalExtra = totalExtra + e.cost * qty;
        console.log(totalExtra);
        return "";
      });
      setUpdated((state) => ({
        ...state,
        extra_charges: totalExtra,
        // extra_charges_list: [...newArra],
      }));
    }
  }, [quantity]);

  const addExtraTotal = () => {
    let qty = quantity ? quantity : parseInt(updatedItem?.qty);
    // console.log(quantity)
    let newselected = { ...selected, qty };

    // console.log(newselected)
    if (selected.code) {
      let newArra = [...extraCharges];
      newArra.push(newselected);
      setExtraCharges(() => [...newArra]);

      setSelected({});
      let totalExtra = 0;
      newArra.map((e) => {
        totalExtra = totalExtra + e.cost * qty;
        // console.log(totalExtra)
        return "";
      });
      setUpdated((state) => ({
        ...state,
        extra_charges: totalExtra,
        extra_charges_list: [...newArra],
      }));
      setEnableAdd(true);
    }
  };

  const enableRequest = updatedItem.qty > 0;
  //const enableAdd = updatedItem.unit_price > 0;
  const showExtra = extraCharges && extraCharges?.length > 0;

  return (
    <Modal
      open={open}
      onClose={clearAll}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={1}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              Edit Item - {data?.name}
            </Typography>
          </Stack>
          {isCustomItem ? (
            <>
              {/* Fabric */}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={4}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label">
                    Select Fabric
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="fabric"
                    size="medium"
                    onChange={onChangeFabric}
                    value={updatedItem?.fabric_name}
                    renderValue={(selected) => selected}
                    label="Select Fabric"
                  >
                    {fabrics.map((d) => (
                      <MenuItem value={d}>{d?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {
                  <FormControl fullWidth variant="standard">
                    {/* <InputLabel id="demo-simple-select-label">
                      Style Name
                    </InputLabel> */}
                    <TextField
                      style={{ width: "70%" }}
                      id="style_name"
                      value={updatedItem?.style}
                      onClick={() => setEdit(true)}
                      label="Style Name"
                      variant="standard"
                      onChange={onChangeStyle}
                    />
                  </FormControl>
                }
              </Stack>
              {/* Linings Qty price */}
              <Stack direction={"row"} spacing={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label">
                    Select Linings
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="fabric"
                    size="medium"
                    onChange={onChangeLinings}
                    value={updatedItem?.lining_name?.join(" + ")}
                    renderValue={(selected) => selected}
                    label="Select Linings"
                  >
                    {linings.map((d, index) => (
                      <MenuItem key={index} value={d}>
                        {(d?.combined_name).split(",").join(" + ")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {edit ? (
                  <FormControl variant="standard" style={{ width: "70%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Quantity
                    </InputLabel>
                    <Input
                      id="qty"
                      onChange={onChangeQty}
                      defaultValue={updatedItem?.qty}
                      variant="standard"
                    />
                  </FormControl>
                ) : (
                  <TextField
                    style={{ width: "70%" }}
                    id="qty"
                    value={updatedItem?.qty}
                    onClick={() => setEdit(true)}
                    label="Quantity"
                    variant="standard"
                  />
                )}
                <StyledPriceButton
                  variant="contained"
                  onClick={() => getPrice()}
                  disabled={!enableRequest}
                >
                  GET PRICE
                </StyledPriceButton>
              </Stack>
              {/* price discount */}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={4}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Unit Price
                  </InputLabel>
                  <Input
                    style={{ width: "100%" }}
                    variant="standard"
                    value={updatedItem.original_unit_price}
                    onChange={onChangeUnitPrice}
                    disabled
                  />
                  {unitPriceError && (
                    <FormHelperText
                      id="component-error-text"
                      sx={{ color: "red" }}
                    >
                      Unit price cannot be less than{" "}
                      {convertToPlaces(originalUnitPrice)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Discount
                  </InputLabel>
                  <Input
                    style={{ width: "100%" }}
                    variant="standard"
                    onChange={onChangeDiscount}
                    defaultValue={convertToPlaces(updatedItem.unit_discount)}
                    onClick={() => setEdit(true)}
                  />
                </FormControl>

                {/* {edit ? (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Discount
                    </InputLabel>
                    <Input
                      style={{ width: "100%" }}
                      onChange={onChangeDiscount}
                      defaultValue={updatedItem.discount_percentage}
                    />
                  </FormControl>
                ) : (
                  <TextField
                    style={{ width: "100%" }}
                    value={updatedItem.discount_percentage}
                    label="Discount"
                    variant="standard"
                    onClick={() => setEdit(true)}
                  />
                )} */}
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Discounted Price
                  </InputLabel>
                  <Input
                    style={{ width: "100%" }}
                    disabled
                    value={convertToPlaces(updatedItem.unit_price)}
                  />
                </FormControl>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={4}
              >
                {edit ? (
                  <>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Item Name
                      </InputLabel>
                      <Input
                        id="name"
                        onChange={onChangeText}
                        defaultValue={updatedItem.name}
                      />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Quantity
                      </InputLabel>
                      <Input
                        id="qty"
                        onChange={onChangeText}
                        type="number"
                        defaultValue={updatedItem.qty}
                      />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Price
                      </InputLabel>
                      <Input
                        id="unit_price"
                        onChange={onChangeText}
                        type="number"
                        defaultValue={updatedItem.unit_price}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <TextField
                      label="Line Item Name"
                      variant="standard"
                      fullWidth
                      id="name"
                      onClick={() => setEdit(true)}
                      value={updatedItem.name}
                    />
                    <TextField
                      label="Quantity"
                      variant="standard"
                      fullWidth
                      id="qty"
                      onClick={() => setEdit(true)}
                      type="number"
                      value={updatedItem.qty}
                    />
                    <TextField
                      label="Price"
                      variant="standard"
                      fullWidth
                      id="unit_price"
                      onClick={() => setEdit(true)}
                      type="number"
                      value={updatedItem.unit_price}
                    />
                  </>
                )}
              </Stack>
            </>
          )}
          {/* extra */}
          <Stack direction={"row"} spacing={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Select Extra Charges
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="item"
                size="small"
                onChange={onChangeExtra}
              >
                {extra.map((d) => (
                  <MenuItem value={d}>{d?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={() => addExtraTotal()}
              sx={{ minWidth: "150px" }}
            >
              ADD CHARGES
            </Button>
          </Stack>
          {showExtra && (
            <Stack sx={{ mt: "20px" }}>
              <Divider>
                <Typography fontWeight={500} fontSize={"20px"}>
                  EXTRA CHARGES
                </Typography>
              </Divider>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraCharges?.map((e, index) => (
                    <TableRow>
                      <TableCell>{e?.code}</TableCell>
                      <TableCell>{e?.name}</TableCell>
                      <TableCell>
                        {convertToPlaces(
                          quantity
                            ? e.cost * quantity
                            : e.cost * updatedItem.qty
                        )}
                      </TableCell>
                      <TableCell>
                        <DeleteIcon
                          color="warning"
                          onClick={() => removeExtra(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      Add-ons
                      {convertToPlaces(updatedItem.extra_charges)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          )}

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button color="success" onClick={() => clearAll()}>
              CLOSE
            </Button>
            <StyledButton
              color="success"
              onClick={() => handleSave()}
              variant="contained"
              disabled={!enableAdd}
            >
              UPDATE
            </StyledButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

EditItemModal.propTypes = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,

  data: PropTypes.object,
};
EditItemModal.defaultProps = {
  open: false,
  data: {
    extra_charges_list: [],
  },
};

export default EditItemModal;
