import * as React from "react";
import { useStyles } from "../../../Theme/Style";
import { styled } from "@mui/material/styles";
import { Box, Paper, CircularProgress, Typography } from "@mui/material";
import Intro from "../../atoms/Intro/Intro.component";
import PaymentManagementDetails from "./PaymentManagementDetails";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const PaymentManagementContainer = () => {
  const classes = useStyles();

  const breadcrumbs = [<Typography key="3">All Transactions</Typography>];

  const [allTransactions, setAllTransactions] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({});
  const [loadingComponent, setLoadingComponent] = React.useState(false);

  React.useEffect(() => {
    setLoadingComponent(true);
    getTransactionsByPage("1");
  }, []);

  const getTransactionsByPage = (number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/account-transactions?page=${number}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setPaginationData(data.meta);
        setAllTransactions(data?.data);
        setLoadingComponent(false);
      });
    });
  };

  const handlePageChange = (newPage) => {
    getTransactionsByPage(newPage + 1);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, marginBottom: "40px" }}>
        <Box className={classes.headerContainer}>
          <Intro
            pageTitle={`All Transactions`}
            pageTitleShort="All Transactions"
            breadcrumbs={breadcrumbs}
            additionalWidgets=""
          />
        </Box>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
          {/* <Box gridColumn="span 3">
                    <PaymentManagementSideBar/>
                </Box> */}
          <Box gridColumn="span 12">
            <Content>
              {loadingComponent ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress size={75} />
                </Box>
              ) : (
                <PaymentManagementDetails
                  transactions={allTransactions}
                  paginationData={paginationData}
                  handlePageChange={handlePageChange}
                />
              )}
            </Content>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PaymentManagementContainer;
