import React from "react";
import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const CartButton = () => {
  const navigate = useNavigate();

  return (
    <Fab
      color="primary"
      aria-label="shopping cart"
      variant="extended"
      sx={{ position: "fixed", right: "1.5rem", bottom: "1.5rem" }}
      onClick={() => navigate("/store/cart")}
    >
      <ShoppingCartOutlinedIcon />
      View Cart
    </Fab>
  );
};

export default CartButton;
