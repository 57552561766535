import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Button, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import ActionsButton from './ActionsButton';
import StatusChange from './StatusChange';
import moment from 'moment';
import { authHeader } from '../../helpers';
import { apiConstants } from '../../constants';
import { FilterAlt } from '@mui/icons-material';

const useStyles = makeStyles({
    salesOrdersFilterBar:{
        margin: '14px 0px 16px',
        backgroundColor:'#EFEFEF',
        padding:'16px'
    },
    filterButton:{
        fontSize:15, 
        color: '#fff !important', 
        height: '42px',
        "&:hover":{
            backgroundColor:'#fff !important',
            color:'#2AB5BF !important',
            border:'1px solid #2AB5BF'
        }
    },
    dataGrid:{
        "& .MuiTablePagination-displayedRows":{
            fontSize: 12,
        },
        "& .MuiTablePagination-select":{
            fontSize: 12,
        },
        "& .MuiTablePagination-selectLabel":{
            fontSize: 12,
        },
        "& .MuiDataGrid-cell:focus":{
            outline:'none !important'
        }
    },
    salesOrderFilterTitle:{
        color:'rgba(0, 0, 0, 0.87) !important', 
        fontSize:'14 !important'
    },
    salesOrderFilterContainer:{
        display:'flex', 
        justifyContent:'flex-end', 
        alignItems: 'center',
    }

})

const columns  = [
    { field: 'id', headerName: 'ID', resizable: false },
    { field: 'created_at', headerName: 'Date', width:150, renderCell:params=>moment(params.row.created_at).format('DD/MM/YYYY')},
    {
      field: 'customer',
      headerName: 'Customer',
      sortable: true,
      width:300,
      minWidth: 100,
      valueGetter: (params) =>
      `${params.row.organization?.name || ''}`,
    },
    { field: 'status', headerName: 'Status', renderCell:(params)=>(<StatusChange {...params} component='salesOrder'/>), width:200},
    { field: 'actions', headerName: 'Actions', width:200, type:'actions', renderCell:(params)=>(<ActionsButton {...params} component='salesOrder'/>), resizable: false}, 
];
  
const StyledInputBase = styled(InputBase)(() => ({
    borderRadius:'4px',
    backgroundColor:'rgba(0, 0, 0, 0.06)',
    '& .MuiInputBase-input': {
    paddingLeft: `calc(1em)`,
    height:'38px',
    width:'100%'
    },
}));

const AllSalesOrders = ({...props}) => {
    const { 
        salesOrders, pendingSalesOrders, approvedSalesOrders, paidSalesOrders, 
        status, paginationData, handlePageChange 
    } = props;
   
    const classes = useStyles();
  
    const [rows, setRows] = React.useState([]);

    const [filteredResults, setFilteredResults] = React.useState([]);

    const [searchCustomer, setSearchCustomer] = React.useState('');
    const [filterBySalesOrderId, setFilterBySalesOrderId] = React.useState('');
    console.log(paginationData);
    React.useEffect(() => {
        if(status===0){
            setRows(salesOrders);
            setFilteredResults(salesOrders);
        }
        else if(status===1){
            setRows(pendingSalesOrders);
        }
        else if(status===2){
            setRows(approvedSalesOrders);
        }
        if(searchCustomer === ""){
            setFilteredResults(rows);
        }
        if(filterBySalesOrderId === ""){
            setFilteredResults(rows);
        }
    }, [status, salesOrders, pendingSalesOrders, approvedSalesOrders, paidSalesOrders, searchCustomer, rows, filterBySalesOrderId])

    const getSalesOrderByOrganization = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };

        fetch(`${apiConstants.API_URL}/sales-orders?organization.name=${searchCustomer}`, requestOptions).then((response)=>{
            response.text().then((text) => {
                const data = text && JSON.parse(text);
                console.log(data?.data);
                setFilteredResults(data?.data);
            });
        });
    }

    const getSalesOrderBySalesOrderId = () => {

        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };

        fetch(`${apiConstants.API_URL}/sales-orders?id=${filterBySalesOrderId}`, requestOptions).then((response)=>{
            response.text().then((text) => {
                const data = text && JSON.parse(text);
                setFilteredResults(data?.data);
            });
        });
    }

    const handleCustomerFilter = () => {
        setFilterBySalesOrderId('');
        if(searchCustomer !== ''){
            getSalesOrderByOrganization();
        }
        else{
            setFilteredResults(rows);
        }
    }

    const handleSalesOrderIdFilter = () => {
        setSearchCustomer('');
        if(filterBySalesOrderId !== ''){
            getSalesOrderBySalesOrderId();
        }
        else{
            setFilteredResults(rows);
        }
    }

    return(
        <>
        <Box className={classes.salesOrdersFilterBar}>
            <Typography component={'span'} className={classes.salesOrderFilterTitle}>
                Filter Sales Orders
            </Typography>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                <Box className={classes.salesOrderFilterContainer}>
                    <Box>
                        <StyledInputBase
                        placeholder="Customer Name"
                        inputProps={{ 'aria-label': 'search' , type:'text'}}
                        value={searchCustomer}
                        onChange={(e)=>{
                            setSearchCustomer(e.target.value);
                        }}
                        onClick={() => setFilterBySalesOrderId('')}
                        />
                    </Box>
                    <Button variant="contained" className={classes.filterButton} onClick={handleCustomerFilter}>
                        <FilterAlt fontSize='12'/>
                    </Button>
                </Box>
                <Box className={classes.salesOrderFilterContainer}>
                    <Box>
                        <StyledInputBase
                        placeholder="Sales Order ID"
                        inputProps={{ 'aria-label': 'search' , type:'text'}}
                        value={filterBySalesOrderId}
                        onChange={(e)=>{
                            setFilterBySalesOrderId(e.target.value);
                        }}
                        onClick={() => setSearchCustomer('')}
                        />
                    </Box>
                    <Button variant="contained" className={classes.filterButton} onClick={handleSalesOrderIdFilter}>
                        <FilterAlt fontSize='12'/>
                    </Button>
                </Box>
            </Box>
        </Box>
        <Box style={{ width: '100%' }}>
            <DataGrid
            autoHeight={true}
            className={classes.dataGrid}
            rows={filteredResults}
            pageSize={paginationData?.per_page}
            pagination
            columns={columns}
            rowCount={paginationData?.total}
            getRowId={(row)=>row.id}
            onPageChange={handlePageChange}
            checkboxSelection
            paginationMode="server"
            />
        </Box>
        </>
    );
}

export default AllSalesOrders;