// save cart
const saveCart = (newItem) => {
  const existingItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const updatedItems = [...existingItems, newItem?.item];

  localStorage.setItem("cartItems", JSON.stringify(updatedItems));
};

// update cart
const updateCart = (updatedItem) => {
  const existingItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const updatedItemIndex = existingItems.findIndex(
    (item) => item.id === updatedItem?.id
  );

  const updatedItems =
    updatedItemIndex !== -1
      ? [
          ...existingItems.slice(0, updatedItemIndex),
          updatedItem,
          ...existingItems.slice(updatedItemIndex + 1),
        ]
      : [...existingItems, updatedItem];

  localStorage.setItem("cartItems", JSON.stringify(updatedItems));
};

// check the item is already in cart
const checkCart = (currentItem) => {
  const existingItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const isPresent = existingItems.some((item) => item.id === currentItem.id);

  return isPresent;
};

// get item from cart
const getCartItem = (id) => {
  const existingItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const currentItem = existingItems.find((item) => item?.id === id);

  return currentItem;
};

// get all cart items
const getCart = () => {
  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  return cartItems;
};

// delete item from cart
const deleteCartItem = (id) => {
  let existingItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  existingItems = existingItems.filter((item) => item?.id !== id);

  localStorage.setItem("cartItems", JSON.stringify(existingItems));
};

// delete and empty cart
const deleteEntireCart = () => {
  localStorage.setItem("cartItems", JSON.stringify([]));
};

// Example usage:

export {
  getCart,
  saveCart,
  updateCart,
  checkCart,
  getCartItem,
  deleteCartItem,
  deleteEntireCart,
};
