import React, { useState, useEffect } from "react";
import { QuotationContainer } from "../../molecules/Quotations/QuotationContainer";
import { useParams } from "react-router-dom";
import { QuotationsService } from "../../../services/api/quotations/quotations.service";
import { CircularProgress, Backdrop } from "@mui/material";

const quotationsService = new QuotationsService();

export const CustomerViewQuotation = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [quotationData, setQuotationData] = useState({});
  const [totals, setTotals] = useState({
    gst: 0,
    totalPrice: 0,
    extraCharges: 0,
    grandTotal: 0,
    discount: 0,
    artWorkCharges: 0,
    originalTotal: 0,
  });
  const [responded, setResponded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const results = await quotationsService.viewQuotationAsCustomer(id);
      setQuotationData(results.data);
      calculateTotals(results.data);
      setIsLoading(false);
    };
    fetchData();
  }, [id, responded]);

  const calculateTotals = (data) => {
    const totals = [...data?.items, ...data?.custom_items].reduce(
      (acc, item) => {
        acc.totalPrice += item?.total_price || 0;
        acc.originalTotal += item?.original_total_price || 0;
        acc.gst += item?.gst_amount || 0;
        acc.extraCharges += item?.extra_charges || 0;
        acc.discount += (item?.unit_discount || 0) * (item?.qty || 1);
        acc.artWorkCharges += item?.artwork_charges || 0;
        acc.grandTotal += item?.grand_total || 0;
        return acc;
      },
      {
        totalPrice: 0,
        originalTotal: 0,
        gst: 0,
        extraCharges: 0,
        discount: 0,
        artWorkCharges: 0,
        grandTotal: 0,
      }
    );

    setTotals(totals);
  };

  if (isLoading) {
    return (
      <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
        <CircularProgress size={50} />
      </Backdrop>
    );
  }

  return (
    <QuotationContainer
      organizationName={quotationData.organization_name}
      items={[...quotationData.items, ...quotationData.custom_items]}
      file={quotationData.quotation_files.map((f) => {
        f.name = f.file.split("/")[2].split("_")[1];
        return f;
      })}
      extraCharges={totals.extraCharges}
      originalTotal={totals.originalTotal}
      totalDiscount={totals.discount}
      totalPrice={totals.totalPrice}
      totalDelivery={quotationData.estimated_shipping_charges}
      grandTotal={totals.grandTotal}
      gst={totals.gst}
      quotationStatus={quotationData?.quotation_status?.keyword}
      organization={quotationData?.organization}
      responded={setResponded}
      artWorkCharges={totals.artWorkCharges}
    />
  );
};
