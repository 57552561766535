export const storeItemConstants = {
  GET_SINGLE_REQUEST: "STORE_ITEM_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "STORE_ITEM_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "STORE_ITEM_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "STORE_ITEM_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "STORE_ITEM_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "STORE_ITEM_GET_ALL_FAILURE",

  ADD_REQUEST: "STORE_ITEM_ADD_REQUEST",
  ADD_SUCCESS: "STORE_ITEM_ADD_SUCCESS",
  ADD_FAILURE: "STORE_ITEM_ADD_FAILURE",

  UPDATE_REQUEST: "STORE_ITEM_UPDATE_REQUEST",
  UPDATE_SUCCESS: "STORE_ITEM_UPDATE_SUCCESS",
  UPDATE_FAILURE: "STORE_ITEM_UPDATE_FAILURE",

  DELETE_REQUEST: "STORE_ITEM_DELETE_REQUEST",
  DELETE_SUCCESS: "STORE_ITEM_DELETE_SUCCESS",
  DELETE_FAILURE: "STORE_ITEM_DELETE_FAILURE",

  CLEAR: "ARTWORK_CHARGE_CLEAR",
};
