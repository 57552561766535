import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { PropTypes } from "prop-types";

const AutoCompleted = ({
  options,
  onChange,
  label,
  variant,
  placeholder,
  disabled,
  value,
  defaultValues,
}) => {
  const defaultOptions =
    defaultValues && defaultValues.length > 0 ? defaultValues : [];

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(event, value) => onChange(value)}
      defaultValue={defaultOptions}
      shrink
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

AutoCompleted.defaultProps = {
  defaultValues: [],
};

AutoCompleted.propTypes = {
  defaultValues: PropTypes.array,
};
export default AutoCompleted;
