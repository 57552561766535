import styled from "@emotion/styled";
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { REJECT_REQUEST, ADD_REQUEST } from "../../constants/Constants";
import ReactQuill from "react-quill";
import'react-quill/dist/quill.snow.css'
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const AdditionalEmailBody = ({ ...props }) => {
  const {
    open,
    handleClose,
    type,
    label,
    setAdditionalBody,
    note,
    saveRequest,
    buttonText,
    disabledSubmitButton,
    additionalBody,
    setSubject,
    subject
  } = props;

  const buttonType = type === ADD_REQUEST ? "success" : "error";
  const headerText = label;
  const bodyText = note;
  const reason = "";

  const [value, setValue] =useState('');
  const onChange = (e) => {
    console.log(e)
    setAdditionalBody(() => e);
  };
  const onChange2 = (e) => {
    console.log(e)
    setSubject(() => e.target.value);

  };

  // const [value, setValue] =useState('');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction={"column"} spacing={2} sx={{ padding: "0 5px 0 5px" }}>
          <Typography fontSize={"24px"}>{headerText}</Typography>
          <Typography fontSize={"14px"}>{bodyText}</Typography>
          <Typography fontSize={"16px"} style={{ color: "#00000099" }}>
            {reason}
          </Typography>
          {/*<ReactQuill style={{height:'100px'}} value={subject} onChange={onChange2 } />*/}
          <TextField id="standard-basic" label="Email Subject" variant="standard" defaultValue={subject} onChange={onChange2} />
          <br/>
          <br/>
          <Typography fontSize={"16px"} >
           Dear (<i>customer_name</i>),
          </Typography>
          <ReactQuill style={{height:'230px'}} value={additionalBody} onChange={onChange } />

      <Typography style={{paddingTop:'50px'}}>
        The Quatation can be accessed by the URL (<i>quotation_url</i>) and Accept Option is available. If not you can reject it by mentioning the reason for alteration.<br/><br/>
        Have any questions? Please contact customerservice@s-trend.com.au or simply reply to this email and we will be in touch as soon as possible.<br/><br/>
        Kind regards,<br/>

        S-Trend Sales Team

      </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between">
          <Button color="success" onClick={() => handleClose(!open)}>
            CLOSE
          </Button>
          <StyledButton
            color={buttonType}
            variant="contained"
            onClick={() => saveRequest()}
            disabled={disabledSubmitButton}
          >
            {buttonText}
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};

AdditionalEmailBody.propTypes = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,

  type: PropTypes.oneOf([REJECT_REQUEST, ADD_REQUEST]),

  request: PropTypes.func.isRequired,
};
AdditionalEmailBody.defaultProps = {
  open: false,
  type: REJECT_REQUEST,
};

export default AdditionalEmailBody;
