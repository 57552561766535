import { roleConstants } from "../constants/role.constants";
import { roleService } from "../services/role.service";
import { alertActions } from "./alert.actions";

export const roleActions = {
    getAllInternalRole,
    getAllExternalRole,
    getSingle,
    addRole,
    updateRole,
};

function getAllInternalRole() {
    return (dispatch) => {
      dispatch(request());
  
      roleService.getAllInternalRole().then(
        (roles) => dispatch(success(roles)),
        (error) => dispatch(failure(error))
      );
    };
  
    function request() {
      return { type: roleConstants.GETALL_INTERNAL_REQUEST };
    }
    function success(interRoles) {
      return { type: roleConstants.GETALL_INTERNAL_SUCCESS, interRoles };
    }
    function failure(error) {
      return { type: roleConstants.GETALL_INTERNAL_FAILURE, error };
    }
}

function getAllExternalRole() {
    return (dispatch) => {
      dispatch(request());
  
      roleService.getAllExternalRole().then(
        (roles) => dispatch(success(roles)),
        (error) => dispatch(failure(error))
      );
    };
  
    function request() {
      return { type: roleConstants.GETALL_EXTERNAl_REQUEST };
    }
    function success(exterRoles) {
      return { type: roleConstants.GETALL_EXTERNAl_SUCCESS, exterRoles };
    }
    function failure(error) {
      return { type: roleConstants.GETALL_INTERNAL_FAILURE, error };
    }
}

// get single
function getSingle(id) {
  return (dispatch) => {
    dispatch(request(id));
    roleService.getSingle(id).then(
      (parameter) => {
        dispatch(success(parameter));
        // dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  }

  function request(parameter) {
    return { type: roleConstants.GET_SINGLE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: roleConstants.GET_SINGLE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: roleConstants.GET_SINGLE_FAILURE, error };
  }
}

// add role
function addRole(
  role,
  description,
  roleType,
  permissionsItem
) {
  return (dispatch) => {
    dispatch(
      request(
        role,
        description,
        roleType,
        permissionsItem,
      )
    );

    roleService
      .addRole(
        role,
        description,
        roleType,
      )
      .then(
        (parameter) => {
          if(permissionsItem.length !== 0){
            roleService.updatePermissions(parameter?.data?.id, permissionsItem) 
            .then(
              (param) => {
                dispatch(success(param));
                dispatch(alertActions.success(param));
              },
              (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
          }
          else{
            dispatch(success(parameter));
            dispatch(alertActions.success(parameter));
          }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(parameter) {
    return { type: roleConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: roleConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: roleConstants.ADD_FAILURE, error };
  }
}

// update role
function updateRole(
  id,
  role,
  description,
  roleType,
  permissionsItem
) {
  return (dispatch) => {
    dispatch(
      request(
        id,
        role,
        description,
        roleType,
        permissionsItem,
      )
    );
    if(permissionsItem.length !== 0){
    roleService
      .updatePermissions(
        id,
        permissionsItem
      )
      .then(
        (parameter) => {
          dispatch(success(parameter));
          dispatch(alertActions.success(parameter));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    }
  };
  function request(parameter) {
    return { type: roleConstants.UPDATE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: roleConstants.UPDATE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: roleConstants.UPDATE_FAILURE, error };
  }
}
