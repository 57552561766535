import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter Organization Email")
    .required("Organization Email is Required"),
  name: yup.string().required("Organization's Name is Required"),
  phone: yup
    .string()
    .required("Organization'n Telephone Number is Required")
    .min(10)
    .max(12),
  address_line_1: yup.string().required("Organization Address 1 is Required"),
  address_line_2: yup.string().optional(),
  postal_code: yup.string().required("Organization Postal Code is Required"),
  country: yup.string().required("Country is Required"),
  contact_person_name: yup.string().required("Contact Person Name is Required"),
  suburb: yup.string().required("Suburb is Required"),
  state: yup.string().required("State is Required"),
  country_id: yup.number(),
  sales_rep_id: yup.string().required("Sales rep is required"),
  gst_enabled: yup.boolean(),
});

export default validationSchema;
