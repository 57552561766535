import React from 'react'
import { Box, Typography, Stack, Skeleton } from '@mui/material'
import BannerBGLatarelEl from "../../../../assets/imges/backgrounds/banner-bg-latarel-el.png"


function InnerTopBanner(props) {

const { mainText, subText } = props;

  return (
    <Stack>
        <Box
          sx={{
            background: `#000 url(${BannerBGLatarelEl})`,
            backgroundPosition: 'right',
            backgroundSize: "30%",
            backgroundRepeat: 'no-repeat',
            marginBottom: "20px",
            color: "#fff",
            borderRadius: "8px",
            padding: "30px"
          }}
        >
          <Stack>
            {subText && 
                <Typography sx={{ color: "#fff", textTransform: "Uppercase", fontSize: "14px", fontWeight: "600" }}>{subText ? subText : <Skeleton variant="text" sx={{ fontSize: '14px', background: '#86e780', maxWidth: "200px" }} />}</Typography>
            }
            <Typography sx={{ color: "#69c464", fontWeight: "medium", fontSize: "32px" }}>
              {mainText ? mainText : <Skeleton variant="text" sx={{ fontSize: '32px', background: '#86e780', maxWidth: "200px" }} />}
            </Typography>

          </Stack>
        </Box>
      </Stack>
  )
}

export default InnerTopBanner