import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import Customer from "../components/Customer";
import OrderDetails from "../components/OrderDetails";
import ConceptDesign from "../components/ConceptDesign";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  CUSTOMER_DETAIL,
  ORDER_DETAIL,
  CONCEPT_DETAIL,
  STEPS,
  NEXT,
  FINISH,
} from "../constants/Constants";
import QuotationSummery from "../components/QuotationSummery";
import Stepper from "../Common/Stepper";
import { useSelector, useDispatch } from "react-redux";
import { create, removeCustomerData } from "../store/Store/Slice";
import { useFormik } from "formik";
import validationSchema from "../Schemas/customerSchema";
import FailedModal from "../Common/Modals/FailedModal";
import Intro from "../components/atoms/Intro/Intro.component";
import { removeAll } from "../store/Store/orderSlice";
import { authHeader } from "../helpers";
import { apiConstants } from "../constants";
import ProgressCircularWithBackdrop from "../Common/ProgressCircular/ProgressCircularWithBackdrop";

const StyledButton = styled(Button)({
  color: "white",
});

const QuotationPage = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/quotations">
      All Quotations
    </Link>,
    <Typography key="3">{"New Quotation"}</Typography>,
  ];

  const [stage, updateStage] = useState(CUSTOMER_DETAIL);
  const [finished, updateFinish] = useState(false);
  const { customer } = useSelector((state) => state.customer);
  const { order, listedItems } = useSelector((state) => state.orders);
  const [sports, updateSports] = useState([]);
  const [selectedSports, updateSelectedSports] = useState([]);
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const formikRef = React.createRef();
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const [newCustomer, setNewCustomer] = useState(true);

  useEffect(() => {
    dispatch(removeAll({}));
    dispatch(removeCustomerData({}));
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(`${apiConstants.API_URL}/sports`, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        updateSports(data?.data);
      });
    });

    formik.resetForm();
  }, [dispatch]);

  const formik = useFormik({
    initialValues: customer,
    validationSchema: validationSchema,
    enableReinitialize: true,
    innerRef: formikRef,
    onSubmit: (e) => {
      dispatch(create(e));
      switch (stage) {
        case CUSTOMER_DETAIL:
          if (formik?.isValid) {
            updateStage(ORDER_DETAIL);
            //break;
          }
          break;
        case ORDER_DETAIL:
          if (order.length > 0) {
            updateStage(CONCEPT_DETAIL);
            break;
          }
          break;
        default:
          break;
      }
      //dispatch(validated());
    },
  });

  /**
   *
   */
  const preAction = () => {
    switch (stage) {
      case ORDER_DETAIL:
        updateStage(CUSTOMER_DETAIL);
        break;
      case CONCEPT_DETAIL:
        updateStage(ORDER_DETAIL);
        break;
      default:
        break;
    }
  };

  /**
   *
   */
  const nextAction = () => {
    formik.submitForm();
  };

  const finishAction = () => {
    updateFinish(true);
  };

  const nextBtnText = stage === CONCEPT_DETAIL ? FINISH : NEXT;
  const btnAction = nextBtnText === FINISH ? finishAction : nextAction;
  const activeStep =
    stage === CUSTOMER_DETAIL
      ? 0
      : stage === ORDER_DETAIL
      ? 1
      : stage === CONCEPT_DETAIL && finished
      ? 3
      : 2;

  const enableSubmitForm = order.length > 0;

  /**
   *
   * @param {*} screen
   * @returns
   */
  const renderPage = (screen) => {
    switch (screen) {
      case CUSTOMER_DETAIL:
        return (
          <Customer
            formik={formik}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
          />
        );
      case ORDER_DETAIL:
        return (
          <OrderDetails
            sports={sports}
            selectedSports={selectedSports}
            updateSelectedSports={updateSelectedSports}
            order={order}
            listedItems={listedItems}
          />
        );
      case CONCEPT_DETAIL:
        return <ConceptDesign file={file} setFile={setFile} />;
      default:
        return (
          <Customer newCustomer={newCustomer} setNewCustomer={setNewCustomer} />
        );
    }
  };

  return (
    <Box>
      <Intro
        pageTitle={"New Quotation"}
        pageTitleShort={"New Quotation"}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />
      <Box sx={{ width: "50%", margin: "10px 0" }}>
        <Stepper steps={STEPS} activeStep={activeStep} />
      </Box>
      <Stack direction={"row"} sx={{ direction: "column" }}>
        <Card
          sx={{
            width: "934px",
            display: "flex",
            flexDirection: "column",
            paddingRight: "10px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            {renderPage(stage)}

            {/* footer */}
            <Container>
              <Stack
                direction={"row"}
                sx={{ justifyContent: "space-between", marginTop: "30px" }}
              >
                {stage !== "customerDetailsScreen" ? (
                  <Button variant="text" onClick={() => preAction()}>
                    BACK
                  </Button>
                ) : (
                  <Box></Box>
                )}
                {nextBtnText !== "FINISH" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "10px",
                    }}
                  >
                    <StyledButton
                      variant="contained"
                      onClick={() => {
                        btnAction();
                      }}
                    >
                      {nextBtnText}
                    </StyledButton>
                  </Box>
                )}
              </Stack>
            </Container>
          </CardContent>
        </Card>
        {stage !== "customerDetailsScreen" && (
          <Card
            title="Quotation Summery"
            sx={{ width: "350px", backgroundColor: "#EFEFEF" }}
          >
            <CardContent sx={{ textAlign: "left" }}>
              <QuotationSummery
                enableSubmit={enableSubmitForm}
                file={file}
                loading={setOpenProgressCircular}
                setProgress={setProgress}
              />
            </CardContent>
          </Card>
        )}
      </Stack>
      <FailedModal />
      <ProgressCircularWithBackdrop
        open={openProgressCircular}
        progress={progress}
      />
    </Box>
  );
};

export default QuotationPage;
