import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import Dividers from "../../Common/Dividers";
import PropTypes from "prop-types";
import { convertToPlaces } from "../../helpers/helpers";

const StyledSalesOrder = styled(Stack)(({ theme }) => ({
  width: "100%",
  minWidth: "250px",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  borderRadius: "4px",
  padding: "10px",
}));

const StyledText = styled(Typography)({
  fontSize: "14px",
});

const StyledDivider = styled(Dividers)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.neutral.main}`,
}));

const StyledTotalText = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
});

const SalesSummeryCustomer = ({
  data,
  displayData,
  activeStep,
  totalDelivery,
}) => {
  let totalGst = 0;
  let totalShipping = totalDelivery;
  let excludingTotal = 0;
  let extraCharges = 0;

  data?.items?.map((item, index) => {
    if (index <= activeStep) {
      totalGst = totalGst + item?.gst_amount;
      //totalShipping = totalShipping + item?.estimated_shipping_charges;
      excludingTotal = excludingTotal + item?.total_price;
      extraCharges = extraCharges + item?.extra_charges || 0;
    }
    return "";
  });

  let total = totalGst + totalShipping + excludingTotal + extraCharges;

  return (
    <StyledSalesOrder spacing={1}>
      <Typography fontSize={"24px"}>Sales Order Summary</Typography>
      <Stack spacing={2}>
        <Stack>
          {data?.items?.map((item, index) =>
            index <= activeStep ? (
              <Stack
                direction={"row"}
                justifyContent="space-between"
                key={index}
              >
                <StyledText>{item?.price?.item?.name}</StyledText>
                <StyledText> {convertToPlaces(item?.total_price)}</StyledText>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                justifyContent="space-between"
                key={index}
              >
                <StyledText>{item?.item?.name}</StyledText>
                <StyledText> - </StyledText>
              </Stack>
            )
          )}
        </Stack>

        {/* Totals */}
        <Stack spacing={1}>
          <Stack direction={"row"} justifyContent="space-between">
            <StyledText>Add-ons</StyledText>
            <StyledText>{convertToPlaces(extraCharges)}</StyledText>
          </Stack>
          <Stack direction={"row"} justifyContent="space-between">
            <StyledText>Total Excluding Tax</StyledText>
            <StyledText>{convertToPlaces(excludingTotal)}</StyledText>
          </Stack>
          <Stack direction={"row"} justifyContent="space-between">
            <StyledText>Estimated Shipping</StyledText>
            <StyledText> {convertToPlaces(totalShipping)}</StyledText>
          </Stack>
          {totalGst !== 0 && (
            <>
              <StyledDivider />
              <Stack direction={"row"} justifyContent="space-between">
                <StyledText>GST</StyledText>
                <StyledText>{convertToPlaces(totalGst)}</StyledText>
              </Stack>
            </>
          )}
          <StyledDivider />
          <Stack direction={"row"} justifyContent="space-between">
            <StyledTotalText>Grand Total</StyledTotalText>
            <StyledTotalText>{convertToPlaces(total)}</StyledTotalText>
          </Stack>
        </Stack>
      </Stack>
    </StyledSalesOrder>
  );
};

SalesSummeryCustomer.propTypes = {
  data: PropTypes.object,
};
SalesSummeryCustomer.defaultProps = {
  data: {
    items: [],
  },
};
export default SalesSummeryCustomer;
