import * as React from "react";
import { Typography, Box, Paper, Divider, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";

const StyledContents = styled(Typography)({
  fontSize: "14px",
  color: "#fff",
});

const useStyles = makeStyles({
  invoiceSummaryContainer: {
    backgroundColor: "#000 !important",
    borderRadius: "4px",
    padding: "16px 16px",
    textAlign: "left",
    marginBottom: "20px",
    "& .MuiDivider-root": {
      borderColor: "rgba(255, 255, 255, 0.4)",
    },
  },
  invoiceSummaryTitle: {
    color: "#fff",
    fontSize: "24px",
    marginBottom: "32px",
  },
  invoiceSummaryTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    marginBottom: "4px",

    "& .MuiTypography-root:first-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
    "& .MuiTypography-root:last-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
  },
  invoiceSummaryTextContainerSub: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    margin: "8px 0 8px",

    "& .MuiTypography-root:first-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
    "& .MuiTypography-root:last-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
  },
  invoiceSummaryTextContainerTotal: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    margin: "12px 0 12px",

    "& .MuiTypography-root:first-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
    "& .MuiTypography-root:last-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
  },
  notificationOfInvoiceSummary: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-start",
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: 14,
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginRight: "9px",
    },
  },
});

const PaymentSummaryDetails = ({ ...props }) => {
  const classes = useStyles();
  const { invoiceDetails, amountFormat, amountDecimalFormat } = props;

  const customer = invoiceDetails?.address?.split(",");
  const customerName = customer ? customer[0] : "";
  const line1 = customer ? customer[2] : "";
  const line2 = customer ? customer[3] : "";
  const line3 = customer ? customer[4] : "";
  const country = customer ? customer[5] : "";

  const subCharge =
    invoiceDetails?.extra_charge + invoiceDetails?.artwork_charge;

  return (
    <Paper className={classes.invoiceSummaryContainer}>
      <Box className={classes.invoiceSummaryTitle}>Payment Summary</Box>
      <div>
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Grand Total</Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.final_grand_total)}
          </Typography>
        </Box>
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Amount Paid </Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.total_payed_amount)}
          </Typography>
        </Box>
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Balance To Be Paid</Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.balance_amount)}
          </Typography>
        </Box>
      </div>
      <Divider sx={{ margin: "20px 0" }} />
      <Box className={classes.invoiceSummaryTitle}>Invoice Summary</Box>
      <div>
        <Stack style={{ color: "#fff", margin: "12px 0 12px" }} spacing>
          <Typography>Sent To:</Typography>
          <Box>
            <StyledContents>{customerName}</StyledContents>
            <StyledContents>
              {line1},{line2}{" "}
            </StyledContents>
            <StyledContents>{line3} </StyledContents>
            <StyledContents>{country}</StyledContents>
          </Box>
        </Stack>
        {invoiceDetails?.purchase_order && (
          <Box sx={{ margin: "12px 0 12px" }}>
            <StyledContents sx={{ color: "#fff" }}>PO Number:</StyledContents>
            <StyledContents sx={{ color: "#fff" }}>
              {invoiceDetails?.purchase_order}
            </StyledContents>
          </Box>
        )}
        <Typography sx={{ color: "rgba(255, 255, 255, 0.6)" }}>
          Order Details:
        </Typography>
        <Divider sx={{ margin: "8px 0" }} />
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Subtotal (excluding tax)</Typography>
          <Typography>
            A$
            {amountFormat(
              invoiceDetails?.sub_total -
                (subCharge + invoiceDetails?.shipping_charge)
            )}
          </Typography>
        </Box>
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Sub Charges (excluding tax) </Typography>
          <Typography>A$ {amountFormat(subCharge)}</Typography>
        </Box>
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Delivery (excluding tax)</Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.shipping_charge)}
          </Typography>
        </Box>
        {invoiceDetails?.gst_amount !== 0 && (
          <Box className={classes.invoiceSummaryTextContainer}>
            <Typography>GST</Typography>
            <Typography>
              A$ {amountFormat(invoiceDetails?.gst_amount)}
            </Typography>
          </Box>
        )}
        <Divider />
        <Box className={classes.invoiceSummaryTextContainerSub}>
          <Typography>Sponsored</Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.sponsered_amount)}
          </Typography>
        </Box>
        <Box className={classes.invoiceSummaryTextContainerSub}>
          <Typography>Discount</Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.discount_amount)}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.invoiceSummaryTextContainerTotal}>
          <Typography>Grand Total</Typography>
          <Typography>
            A$ {amountFormat(invoiceDetails?.grand_total)}
          </Typography>
        </Box>
      </div>
    </Paper>
  );
};
export default PaymentSummaryDetails;
