import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Typography,
  Link,
  Alert,
  Stack,
  CircularProgress,
  Pagination,
  Box,
} from "@mui/material";
import {
  webstoreActions,
  sportCategoryActions,
  organizationActions,
} from "../../../actions";

import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import VerticalTabs from "../../../components/common/ui/tabs/VerticalTabs/VerticalTabs.component";
import WebstoreCategoryTab from "../../../components/admin/WebStoreManagement/WebStoreCategoryTabs/WebStoreCategoryTab.component";
import { apiConstants } from "../../../constants";
import { authHeader } from "../../../helpers";

const WebStoreSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const organization = useSelector(
    (state) => state.organizations?.parameter?.data
  );
  const webstore = useSelector((state) => state.webstores?.store);
  const sportCategories = useSelector(
    (state) => state.sportCategories?.items?.data
  );

  const [numPages, setNumPages] = useState(0);
  const [numItems, setNumItems] = useState(0);
  const [storeItems, setStoreItems] = useState([]);
  const [storeItemCategories, setStoreItemCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(organizationActions.getSingleParameter(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(webstoreActions.getSingleParameter(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(sportCategoryActions.getAllParameters());
  }, [dispatch, id]);

  // calculate number of pages
  useEffect(() => {
    const calculateNumPages = () => {
      const np = parseInt(webstore?.meta?.total / webstore?.meta?.per_page);

      setNumPages(np > 1 ? np : 1);
      setNumItems(webstore?.meta?.total);
    };

    calculateNumPages();
  }, [webstore?.meta?.per_page, webstore?.meta?.total]);

  // get store items
  useEffect(() => {
    const getStoreItems = () => {
      setIsLoading(true);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      fetch(
        // `${apiConstants.API_URL}/store-items?organization_id=${id}&page=${webstore?.meta?.current_page}`,
        `${apiConstants.API_URL}/store-production-orders?organization.id=${id}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setStoreItems(data?.data);
          setIsLoading(false);
        });
      });
    };

    getStoreItems();
  }, [dispatch, id, webstore?.meta?.current_page]);

  // filter by category
  useEffect(() => {
    const filterProductData = () => {
      const tabData = [];

      sportCategories &&
        storeItems &&
        sportCategories.map((item) => {
          const tab = {
            id: item?.id,
            label: item?.name,
            // slug: createSlug(item?.name),
            data: <WebstoreCategoryTab />,
          };

          const garments = storeItems.filter(
            (garment) =>
              garment?.price?.sport?.id === item?.id ||
              garment?.sport?.id === item?.id
          );

          tab.data = (
            <WebstoreCategoryTab
              categoryName={item?.name}
              orderedItems={garments}
            />
          );

          if (garments?.length > 0) {
            tabData.push(tab);
          }
        });

      const allItems = {
        id: 0,
        label: "All",
        data: (
          <WebstoreCategoryTab categoryName="All" orderedItems={storeItems} />
        ),
      };

      storeItems.length > 0 && tabData.unshift(allItems);

      setStoreItemCategories(tabData);
    };

    filterProductData();
  }, [sportCategories, storeItems]);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/admin/webstore-management/"
    >
      Webstore Management
    </Link>,
    <Typography key="2">Store - {organization?.name}</Typography>,
  ];
  return (
    <>
      <Intro
        pageTitle={`Store - ${organization?.name ? organization?.name : ""}`}
        pageTitleShort="Store"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        {isLoading && (
          <Stack>
            <CircularProgress />
          </Stack>
        )}

        {storeItemCategories?.length > 0 && !isLoading && (
          <VerticalTabs categories={storeItemCategories} />
        )}
      </Content>

      {storeItems.length > 0 && !isLoading ? (
        <Box>
          <Pagination count={numPages} color="primary" sx={{ mt: 4 }} />
        </Box>
      ) : (
        !isLoading && (
          <Alert
            severity="info"
            sx={{ mt: -3, position: "relative", zIndex: 3 }}
          >
            There are no items in the <strong>{organization?.name} </strong>{" "}
            store
          </Alert>
        )
      )}

      {/* {numItems > 0 && !isLoading
        ? numPages > 0 && (
            <Pagination count={numPages} color="primary" sx={{ mt: 4 }} />
          )
        : !isLoading && (
            <Alert
              severity="info"
              sx={{ mt: -3, position: "relative", zIndex: 3 }}
            >
              There are no items in the <strong>{organization?.name} </strong>{" "}
              store
            </Alert>
          )} */}
    </>
  );
};

export default WebStoreSingle;
