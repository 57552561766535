import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import ParameterForm from "../../../components/admin/parameterManagement/ParameterCard/ParameterForm/ParameterForm.component";

import { parameterTypes } from "../../../constants";
import { ProductionOrderTemplateForm } from "../../../components/admin/parameterManagement/ParameterCard/ParameterForm/ProductionOrderTemplate";

const ProductionOrderTemplateView = () => {
  const { id } = useParams();
  const location = useLocation();

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/parameter-management"
    >
      Parameter Management
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/parameter-management/production-order-templates`}
    >
      Production Order Templates
    </Link>,
    <Typography key="3">{`view Production Order Template`}</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`view Production Order Template`}
        pageTitleShort={`view Production Order Template`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent
            title={`view Production Order Template`}
            subtitle=""
            description=""
          >
            <Grid container spacing="2">
              <Grid item xs={12}>
                <ProductionOrderTemplateForm mode={"view"} paramdata={{ id }} />
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default ProductionOrderTemplateView;
