import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Stack, Stepper, Step, StepLabel } from "@mui/material";
import { HORIZONTAL, VERTICAL } from "../../constants/Constants";

const QontoConnector = styled(StepLabel)(({ theme }) => ({
  ".Mui-completed .MuiStepIcon-root": {
    color: theme.palette?.success.main,
  },
  ".MuiStepConnector-root .MuiStepConnector-vertical": {
    marginRight: "49px !important",
  },
}));

const QontoConnector1 = styled(StepLabel)(({ theme }) => ({
  ".Mui-completed .MuiStepIcon-root": {
    color: theme.palette?.success.main,
  },
}));

const ProductionStatusStepper = ({
  steps,
  activeStep,
  orientation,
  alternativeLabel,
}) => {
  return (
    <Stack spacing={4}>
      {orientation !== "horizontal" && (
        <Stepper activeStep={1} orientation={orientation}>
          <Step sx={{ paddingLeft: 0 }}>
            <QontoConnector>{"Start"}</QontoConnector>
          </Step>
          <Step></Step>
        </Stepper>
      )}
      <Stepper
        sx={{ marginTop: "0 !important" }}
        activeStep={activeStep}
        orientation={orientation}
        alternativeLabel={alternativeLabel}
      >
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <QontoConnector>{label?.stage?.name}</QontoConnector>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

ProductionStatusStepper.propTypes = {
  /**
   * Stpes to be displayed
   */
  steps: PropTypes.array.isRequired,

  /**
   *  Active stage
   */
  activeStep: PropTypes.number,

  /**
   *
   */
  orientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),

  /**
   *
   */
  alternativeLabel: PropTypes.bool,
};

ProductionStatusStepper.defaultProps = {
  activeStep: 1,
  orientation: HORIZONTAL,
  steps: [],
  alternativeLabel: false,
};

export default ProductionStatusStepper;
