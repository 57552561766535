import React, { createContext, useContext } from "react";

const GlobalLoadingContext = createContext();

const useLoading = () => {
  const context = useContext(GlobalLoadingContext);
  if (!context) {
    throw new Error(
      "Components are not properly wrapped by the GlobalContextProvider."
    );
  }
  return context;
};

export { useLoading, GlobalLoadingContext as default };
