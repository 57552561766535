import React from "react";
import { Box, Typography } from "@mui/material";
import Intro from "../../atoms/Intro/Intro.component";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { capitalizeFirstLetter } from "../../../helpers/helpers";
import { useGetAllPreProdApprovalsQuery } from "../../../services/api/prod-approval";
import { ApprovalActionButton } from "../../atoms/ActionButton/ApprovalActionButton";
import { BarLoader } from "../../atoms/Loaders";
import { PRE_PRODUCTION_APPROVAL } from "../../../constants/Constants";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 5px",
  },

  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
});

export const approvalsColumnsConfig = [
  {
    field: "id",
    headerName: "Approval ID",
    resizable: false,
    width: 100,
  },
  {
    field: "created_at",
    headerName: "Date",
    resizable: false,
    width: 150,
  },
  {
    field: "production_order",
    headerName: "Production Order Number",
    resizable: false,
    width: 200,
  },
  {
    field: "org_name",
    headerName: "Organization Name",
    resizable: false,
    width: 200,
  },
  {
    field: "item_description",
    headerName: "Item Description",
    resizable: false,
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params) => {
      return (
        <Typography
          style={{ color: params.row.status === "approved" ? "green" : "grey" }}
        >
          {capitalizeFirstLetter(params.row.status)}
        </Typography>
      );
    },
  },
  {
    field: "images",
    headerName: "Images Count",
    width: 100,
    renderCell: (params) => params?.row?.images?.length,
  },

  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    type: "actions",
    renderCell: (params) => <ApprovalActionButton {...params.row} />,
    resizable: false,
  },
];

const PreProductionApprovals = ({ type }) => {
  const classes = useStyles();

  const {
    data: preProdApprovals,
    isLoading,
    isFetching,
  } = useGetAllPreProdApprovalsQuery(
    { type },
    {
      skip: false,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const breadcrumbs = [
    <Typography key="3">
      {type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN
        ? "Pattern Approval"
        : "Prototype Approval"}
    </Typography>,
  ];

  return (
    <>
      <Box className={classes.headerContainer}>
        <Intro
          pageTitle={
            type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN
              ? "Pattern Approval"
              : "Prototype Approval"
          }
          pageTitleShort=""
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
        />
      </Box>

      <Box style={{ width: "100%" }}>
        <BarLoader loading={isLoading || isFetching} />
        <DataGrid
          autoHeight={true}
          className={classes.dataGrid}
          rows={preProdApprovals || []}
          pageSize={20}
          pagination
          getRowId={(row) => row.id}
          // checkboxSelection
          rowCount={preProdApprovals?.length || 0}
          // onPageChange={}
          columns={approvalsColumnsConfig}
          paginationMode="server"
        />
      </Box>
    </>
  );
};

export default PreProductionApprovals;
