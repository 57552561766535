import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiConstants } from "../../constants";
import { getUserToken } from "../../helpers";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiConstants.API_URL,
    prepareHeaders: (headers) => {
      const token = getUserToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
