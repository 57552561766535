import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";

export const extraChargeService = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter() {}

// get all parameter
function getAllParameters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/extra-charges`, requestOptions).then(
    handleResponse
  );
}

// add parameter
function addParameter(name, code, type, cost, status) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      name: name,
      code: code,
      type: type,
      cost: cost * 100,
      status: status,
    }),
  };

  return fetch(`${apiConstants.API_URL}/extra-charges`, requestOptions).then(
    handleResponse
  );
}

// update parameter
function updateParameter(id, name, code, type, cost, status) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      name: name,
      code: code,
      type: type,
      cost: cost * 100,
      status: status,
    }),
  };

  return fetch(
    `${apiConstants.API_URL}/extra-charges/${id}`,
    requestOptions
  ).then(handleResponse);
}

// delete parameter
function deleteParameter(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/extra-charges/${id}`,
    requestOptions
  ).then(handleResponse);
}

// clear parameter
function clear() {}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
