import * as React from "react";
import { Typography, Box, Paper, Divider } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  invoiceSummaryContainer: {
    backgroundColor: "#000 !important",
    borderRadius: "4px",
    padding: "16px 16px",
    textAlign: "left",
    marginBottom: "20px",
    "& .MuiDivider-root": {
      borderColor: "rgba(255, 255, 255, 0.4)",
    },
  },
  invoiceSummaryTitle: {
    color: "#fff",
    fontSize: "24px",
    marginBottom: "32px",
  },
  invoiceSummaryTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    marginBottom: "4px",

    "& .MuiTypography-root:first-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
    "& .MuiTypography-root:last-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
  },
  invoiceSummaryTextContainerSub: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    margin: "8px 0 8px",

    "& .MuiTypography-root:first-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
    "& .MuiTypography-root:last-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
  },
  invoiceSummaryTextContainerTotal: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    margin: "12px 0 12px",

    "& .MuiTypography-root:first-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
    "& .MuiTypography-root:last-child": {
      lineHeight: "20px",
      fontSize: 14,
    },
  },
  notificationOfInvoiceSummary: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-start",
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: 14,
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginRight: "9px",
    },
  },
});

const InvoiceSummaryDetails = ({ ...props }) => {
  const classes = useStyles();
  const {
    salesOrderById,
    subCharge,
    grandTotal,
    discountAmount,
    sponsorAmount,
    amountFormat,
    amountDecimalFormat,
    shippingCharge,
  } = props;
  return (
    <Paper className={classes.invoiceSummaryContainer}>
      <Box className={classes.invoiceSummaryTitle}>Invoice Summary</Box>
      <div>
        <Box className={classes.invoiceSummaryTextContainer}>
          <Typography>Subtotal (excluding tax)</Typography>
          <Typography>
            A$
            {amountFormat(
              salesOrderById.sub_total - (subCharge + shippingCharge)
            )}
          </Typography>
        </Box>
        {!!subCharge && (
          <Box className={classes.invoiceSummaryTextContainer}>
            <Typography>Sub Charges (excluding tax) </Typography>
            <Typography>A$ {amountFormat(subCharge)}</Typography>
          </Box>
        )}

        {!!shippingCharge && (
          <Box className={classes.invoiceSummaryTextContainer}>
            <Typography>Delivery (excluding tax)</Typography>
            <Typography>A$ {amountFormat(shippingCharge)}</Typography>
          </Box>
        )}

        {salesOrderById.gst_amount !== 0 && (
          <Box className={classes.invoiceSummaryTextContainer}>
            <Typography>GST</Typography>
            <Typography>
              A$ {amountFormat(salesOrderById.gst_amount)}
            </Typography>
          </Box>
        )}
        <Divider />
        {!!sponsorAmount && (
          <>
            <Box className={classes.invoiceSummaryTextContainerSub}>
              <Typography>Sponsored</Typography>
              <Typography>A$ {amountFormat(sponsorAmount)}</Typography>
            </Box>
            {parseFloat(discountAmount) !== 0 && (
              <Box className={classes.invoiceSummaryTextContainerSub}>
                <Typography>Discount</Typography>
                <Typography>
                  A$ {amountDecimalFormat(discountAmount)}
                </Typography>
              </Box>
            )}
            <Divider />
          </>
        )}
        <Box className={classes.invoiceSummaryTextContainerTotal}>
          <Typography>Grand Total</Typography>
          <Typography>A$ {amountFormat(grandTotal)}</Typography>
        </Box>
      </div>
      <Box className={classes.notificationOfInvoiceSummary}>
        <InfoOutlined />
        <Typography>
          Please quote our invoice number when payment is made, thus enabling us
          to identify your payment and release bulk production and shipment
        </Typography>
      </Box>
    </Paper>
  );
};
export default InvoiceSummaryDetails;
