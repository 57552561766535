import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "bankname", headerName: "Bank Name", width: 150 },
  { field: "accountname", headerName: "Account Name", width: 150 },
  { field: "bsb", headerName: "BSB", width: 150 },
  { field: "accountnumber", headerName: "Account Number", width: 150 },
  { field: "status", headerName: "Status", width: 150 },
];

const rows = [
  {
    id: 1,
    bankname: "Bank Name 1",
    accountname: "Account Name 1",
    bsb: "BSB 1",
    accountnumber: "Account Number 1",
    status: "Active",
  },
  {
    id: 2,
    bankname: "Bank Name 2",
    accountname: "Account Name 2",
    bsb: "BSB 2",
    accountnumber: "Account Number 2",
    status: "Active",
  },
  {
    id: 3,
    bankname: "Bank Name 3",
    accountname: "Account Name 3",
    bsb: "BSB 3",
    accountnumber: "Account Number 3",
    status: "Active",
  },
  {
    id: 4,
    bankname: "Bank Name 4",
    accountname: "Account Name 4",
    bsb: "BSB 4",
    accountnumber: "Account Number 4",
    status: "Active",
  },
  {
    id: 5,
    bankname: "Bank Name 5",
    accountname: "Account Name 5",
    bsb: "BSB 5",
    accountnumber: "Account Number 5",
    status: "Active",
  },
];

const BankDetails = () => {
  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        rowsPerPageOptions={[5]}
        // checkboxSelection
      />
    </Box>
  );
};

export default BankDetails;
