import React from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  // AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Stack,
  Alert,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GarmentItem from "../GarmentItem/GarmentItem.component";

const GarmentCollection = ({ items, category }) => {
  return (
    <Accordion sx={{ mb: 2, boxShadow: "none" }} defaultExpanded={true}>
      <AccordionDetails>
        <Grid container spacing={1}>
          {items.map(
            (item, i) =>
              item?.is_active === 1 && (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  xl={2}
                  key={`gc-${category}-${i}`}
                >
                  <GarmentItem
                    id={item?.id}
                    poId={item?.production_order?.id}
                    name={item?.production_order?.price?.item?.name}
                    unitPrice={item?.production_order?.store_item?.unit_price}
                    style={item?.production_order?.pattern_id}
                    thumbnail={
                      item?.production_order?.image_url !== null
                        ? item?.production_order?.image_url
                        : item?.production_order?.thumbnail_url !== null
                        ? item?.production_order?.image_url
                        : ""
                    }
                    organization_id={
                      item?.production_order?.store_item?.organization_id
                    }
                  />
                </Grid>
              )
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const GarmentsByCategory = (props) => {
  const { loading, category, items } = props;

  const activeProducts = items.filter((item) => item?.is_active === 1);

  return (
    <Box component="section">
      <Grid container>
        <Grid item xs={12}>
          {category && (
            <Typography variant="h3" sx={{ fontSize: "1.4rem", mb: 2, pl: 3 }}>
              {category} Items
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {loading ? (
            <Stack justifyContent="center" alignItems="center" sx={{ p: 3 }}>
              <CircularProgress />
            </Stack>
          ) : items.length ? (
            <GarmentCollection items={items} category={category} />
          ) : (
            <Alert severity="info" sx={{ ml: 3 }}>
              No items to display in <strong>{category}</strong> category
            </Alert>
          )}
        </Grid>
      </Grid>

      {activeProducts.length === 0 && (
        <Alert
          severity="info"
          sx={{ mt: -3, ml: 3, position: "relative", zIndex: 3 }}
        >
          There are no active items in the <strong>{category}</strong> category.
          Please contact your sales agent and request to enable{" "}
          <strong>{category}</strong> products in your organization store.
        </Alert>
      )}
    </Box>
  );
};

export default GarmentsByCategory;
