import { useTheme } from "@emotion/react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import * as React from "react";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { from, map, take } from "rxjs";
import { useNotification } from "../../../Contexts/GlobalNotificationContext";
import { useLoading } from "../../../Contexts/GlobalLoadingContext";
import { useStyles } from "../../../Theme/Style";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";
import { QuotationsService } from "../../../services/api/quotations/quotations.service";
import { Breadcrumbs } from "../../atoms/Breadcrumbs/Breadcrumbs";
import TabsComponent from "../../atoms/CustomTabs/CustomTabs";
import { TabPanel } from "../../atoms/TabPanel/TabPanel";
import { GeneralPageLayout } from "../../molecules/PageLayout/GeneralPageLayout";
import { QuotationsTableWithFilter } from "../../molecules/Quotations/QuotationsTableWithFilters";

const quotationsService = new QuotationsService();

const getStatusCode = (status) => {
  switch (status) {
    case 0:
      return null;
    case 1:
      return "submitted";
    case 2:
      return "accepted";
    case 3:
      return "rejected";
    default:
      return null;
  }
};

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const QuotationsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const showCreateButton =
    checkPermission(PERMISSIONS.QUOTATIONS) ||
    checkPermission(PERMISSIONS.SALES_AGENT_QUOTATIONS);
  const [status, setStatus] = React.useState("all");
  const [value, setValue] = React.useState(0);
  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [quotations, setQuotations] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({});
  const [filterByQuotationId, setFilterByQuotationId] = React.useState("");
  const { showLoader, hideLoader } = useLoading();
  const { addNotification } = useNotification();

  const handleNewQuotation = () => {
    navigate("/admin/quotation");
  };

  const handleTabChange = React.useCallback((event, newValue) => {
    setValue(newValue);
    setStatus(getStatusCode(newValue));
  }, []);

  const getQuotationByOrganization = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        quotationsService.getQuotationByOrganizationName(
          searchCustomer,
          nextPage
        )
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setQuotations(response.data);
            setPaginationData(response.meta);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, hideLoader, searchCustomer, showLoader]
  );

  const getQuotationByQuotationId = React.useCallback(async () => {
    showLoader();
    const subscription = from(
      quotationsService.getQuotationByQuotationId(filterByQuotationId)
    )
      .pipe(
        map((response) => ({
          data: response.data,
          meta: {
            current_page: 1,
            last_page: 1,
            per_page: 1,
            total: 1,
            from: 1,
            to: 1,
          },
        })),
        take(1)
      )
      .subscribe({
        next: (response) => {
          setQuotations(response.data);
          setPaginationData(response.meta);
          hideLoader();
        },
        error: (error) => {
          addNotification({ message: error.message, type: "error" });
          hideLoader();
        },
      });

    return () => subscription.unsubscribe();
  }, [filterByQuotationId, addNotification, hideLoader, showLoader]);

  const getQuotationsByPage = React.useCallback(
    async (page) => {
      const nextPage = page + 1;
      showLoader();
      const subscription = from(
        quotationsService.getPaginatedQuotationByStatus(status, nextPage)
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setQuotations(response.data);
            setPaginationData(response.meta);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });
      return () => subscription.unsubscribe();
    },
    [addNotification, hideLoader, showLoader, status]
  );

  const handlePageChange = React.useCallback(
    (page) => {
      if (searchCustomer !== "") {
        getQuotationByOrganization(page);
        return;
      }

      if (filterByQuotationId !== "") {
        getQuotationByQuotationId();
        return;
      }

      getQuotationsByPage(page);
    },
    [
      filterByQuotationId,
      getQuotationByOrganization,
      getQuotationByQuotationId,
      getQuotationsByPage,
      searchCustomer,
    ]
  );

  React.useEffect(() => {
    if (searchCustomer !== "" || filterByQuotationId !== "") {
      return;
    }

    (async () => {
      await getQuotationsByPage(0);
    })();
  }, [
    status,
    searchCustomer,
    filterByQuotationId,
    setQuotations,
    setPaginationData,
    // getQuotationsByPage,
  ]);

  return (
    <GeneralPageLayout
      pageTitle="All Quotations"
      breadcrumbs={<Breadcrumbs lastStep="All Quotations" />}
      headerWidgets={
        showCreateButton && (
          <Box>
            <Grid container gap={"16px"} alignItems={"center"} textAlign="left">
              <Button
                variant="contained"
                className={classes.headerButton}
                onClick={handleNewQuotation}
              >
                <AddCircleOutline sx={{ mr: 1 }} />
                New quotation
              </Button>
            </Grid>
          </Box>
        )
      }
    >
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <Content>
            <TabsComponent
              value={value}
              handleChange={handleTabChange}
              tabLabels={[
                "All Quotations",
                "Quotations - submitted",
                "Quotations - accepted",
                "Quotations - rejected",
              ]}
            />

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
            >
              {[0, 1, 2, 3].map((item, index) => (
                <TabPanel
                  key={index}
                  value={value}
                  index={item}
                  dir={theme.direction}
                >
                  <QuotationsTableWithFilter
                    enableFilters={item === 0 ? true : false}
                    quotationsViewLink="/admin/quotations/$id$"
                    getQuotationByOrganization={getQuotationByOrganization}
                    handlePageChange={handlePageChange}
                    getQuotationByQuotationId={getQuotationByQuotationId}
                    searchCustomer={searchCustomer}
                    setSearchCustomer={setSearchCustomer}
                    quotations={quotations}
                    paginationData={paginationData}
                    setFilterByQuotationId={setFilterByQuotationId}
                    filterByQuotationId={filterByQuotationId}
                  />
                </TabPanel>
              ))}
            </SwipeableViews>
          </Content>
        </Box>
      </Box>
    </GeneralPageLayout>
  );
};

export default QuotationsPage;
