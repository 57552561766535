import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkPermission } from "../../../helpers/helpers";
import WebStoreTable from "../../../components/admin/WebStoreManagement/WebStoreTable/WebStoreTable.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import Intro from "../../../components/atoms/Intro/Intro.component";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";

const PageWidgets = ({
  buttonLabel,
  searchLabel,
  parameter,
  setSearchValue,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={`Search ${searchLabel}`}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Search>
        {/* {showAddNewUser &&  */}
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          // onClick={() => navigate("add")}
          onClick={handleClickOpen}
          size="large"
        >
          Create a New Web Store
        </Button>
        {/* } */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Creating a new websore"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Please navigate to Organisation Management and activate 'Enable Store' option in organisation properties to set up a web store for this client. Only web stores that have already been created are listed on this page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                navigate("/admin/organization-management");
              }}
              autoFocus
            >
              Go to Organization Management
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
};

const WebStoreList = () => {
  const { parameter } = useParams();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const hasPermission = checkPermission(PERMISSIONS.WEB_STORE_MANAGEMENT);

    if (!hasPermission) {
      navigate("/");
    }
  }, [navigate]);

  const breadcrumbs = [
    <Typography key="2">Web Stores</Typography>,
    <Typography key="2">All Web Stores</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle="All Web Stores"
        pageTitleShort="All Web Stores"
        breadcrumbs={breadcrumbs}
        additionalWidgets={
          <PageWidgets
            buttonLabel="Web Stores"
            searchLabel="All Web Stores"
            parameter={parameter}
            setSearchValue={setSearchValue}
          />
        }
      />

      <Content>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <BoxedContent title="All Web Stores" subtitle="" description="">
              {/* <Alert severity="info" sx={{ mb: 2 }}>
                Please navigate to the Organizations menu and activate Enable
                Store option in organization properties to create a web store
                for the organization. Only the web stores that are already
                created is listed here.
              </Alert> */}
              <WebStoreTable search={searchValue} />
            </BoxedContent>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default WebStoreList;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s ease-in-out",
  color: "#232323",
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    fontSize: "0.85rem",
    width: "100%",
    height: "1.85rem",
    [theme.breakpoints.up("md")]: {
      minWidth: "10rem",
    },
  },
}));
