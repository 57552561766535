import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStyles } from "../../../Theme/Style";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #2AB5BF",
    borderRadius: "5px 0px 5px 5px",
    minWidth: 180,
    color: "#2AB5BF",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& a": {
      textDecoration: "none !important",
    },
    "& .MuiMenu-list": {
      padding: "4px 0",
      textTransform: "uppercase",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      fontWeight: 500,
      color: "#2cb5c0 !important",

      "& .MuiSvgIcon-root": {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const SalesOrdersActionButton = ({
  isViewOnly,
  pathToView,
  salesOrderId,
  status,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const generateInvoiceOrderLink = `/admin/invoices/${salesOrderId}`;

  if (isViewOnly || status === "pending" || status === "in_production") {
    return (
      <Button
        variant="outlined"
        onClick={() => navigate(pathToView.replace("$id$", salesOrderId))}
      >
        View
      </Button>
    );
  }

  return (
    <>
    <ButtonGroup
      aria-label="outlined primary button group"
      className={classes.outlinedGroupButton}
    >
      <Button
        variant="outlined"
        onClick={() => navigate(pathToView.replace("$id$", salesOrderId))}
      >
        View
      </Button>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          backgroundColor:
            Boolean(anchorEl) && status !== "pending"
              ? "#2AB5BF !important"
              : undefined,
          color:
            Boolean(anchorEl) && status !== "pending"
              ? "#fff !important"
              : undefined,
        }}
        disableElevation
        onClick={handleClick}
      >
        <ArrowDropDown />
      </Button>
    </ButtonGroup>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {status === "approved" && (
          <Link to={generateInvoiceOrderLink}>
            <MenuItem onClick={handleClose} disableRipple>
              Generate Invoice
            </MenuItem>
          </Link>
        )}
        {status === "paid" && (
          <MenuItem onClick={handleClose} disableRipple>
            Send Production Order
          </MenuItem>
        )}
      </StyledMenu>
      </>
  );
};
