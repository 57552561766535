import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentTermActions } from "../../../../../../actions";
import { Divider, Button, TextField, Grid, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";

const PaymentTerms = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;

  const dispatch = useDispatch();
  const paramState = useSelector((state) => state.paymentTerms);
  const alertState = useSelector((state) => state.alert);
  const formikRef = React.createRef();

  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  // const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    code: Yup.string().required("Internal Code is required"),
    name: Yup.string().required("Displayed Name is required"),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    code: "",
    name: "",
    // status: true,
  };

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata) {
        const fields = ["code", "name"];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, paramdata[field], false)
        );
      }
    };

    prefillData();
  }, []);

  useEffect(() => {
    const handleSuccess = () => {
      if (paramState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (paramState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [paramState, handleSuccessDialog, alertState, handleErrorAlert]);

  const handleSubmit = (values) => {
    if (values && mode === "add") {
      dispatch(paymentTermActions.addParameter(values.name, values.code));
    }
    if (values && mode === "edit") {
      dispatch(
        paymentTermActions.updateParameter(
          paramdata.id,
          values.name,
          values.code
        )
      );
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label="Internal Code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Displayed Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              {/* <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid> */}

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={paramState?.loading}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentTerms;
