import { Add, ModeEditOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { useStyles } from "../../../Theme/Style";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";

const PriceBookDetails = ({
  handlePriceBookEntry,
  selectedSportDetails,
  loadingComponent,
  filteredResults,
  showEditRecord,
}) => {
  const classes = useStyles();

  // const column = [
  //   "Fabric",
  //   "Lining",
  //   "1-14",
  //   "15-29",
  //   "30-99",
  //   "100-149",
  //   "150+",
  //   "Factory Cost",
  // ];

  // columns
  const [column, setColumns] = React.useState([]);

  const [editPermission, setEditPermission] = React.useState(false);

  // check permissions and add columns
  React.useEffect(() => {
    const updateColumns = () => {
      const cols = [
        "Fabric",
        "Lining",
        "1-14",
        "15-29",
        "30-99",
        "100-149",
        "150+",
      ];
      const hasPermission = checkPermission(
        PERMISSIONS.CREATE_AND_EDIT_PRICEBOOK_RECORDS
      );

      setEditPermission(hasPermission);
      if (hasPermission) {
        setColumns([...cols, "Factory Cost"]);
      } else {
        setColumns(cols);
      }
    };

    updateColumns();
  }, []);

  return (
    <>
      {loadingComponent ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box>
          {Object.keys(filteredResults).map((item, index) => (
            <>
              <Box className={classes.priceBookEntrySubTitleContainer}>
                <Box sx={{ fontSize: 16 }}>{item}</Box>
                {showEditRecord && (
                  <Box>
                    <Button
                      sx={{ fontSize: 13 }}
                      onClick={() =>
                        handlePriceBookEntry("edit", filteredResults[`${item}`])
                      }
                    >
                      <Add />
                      add new item
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>
                <div style={{ width: "100%", marginBottom: "30px" }}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead sx={{ backgroundColor: "#D5F9FC" }}>
                        <TableRow>
                          {column.map((i) => (
                            <TableCell className={classes.tableHeaderCell}>
                              {i}
                            </TableCell>
                          ))}
                          {showEditRecord && (
                            <TableCell className={classes.tableHeaderCell}>
                              Edit
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {filteredResults[`${item}`].map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.fabric.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.liningCell}
                            >
                              {row.linings.map((l) => (
                                <>
                                  <div>{l.name}</div>
                                  <div className="icon">+</div>
                                </>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              ${(row.up_1_to_14 / 100).toFixed(2)}
                            </TableCell>
                            <TableCell align="left">
                              ${(row.up_15_to_29 / 100).toFixed(2)}
                            </TableCell>
                            <TableCell align="left">
                              ${(row.up_30_to_99 / 100).toFixed(2)}
                            </TableCell>
                            <TableCell align="left">
                              ${(row.up_100_to_149 / 100).toFixed(2)}
                            </TableCell>
                            <TableCell align="left">
                              ${(row.up_150_plus / 100).toFixed(2)}
                            </TableCell>
                            {editPermission && (
                              <TableCell align="left">
                                ${(row.production_cost / 100).toFixed(2)}
                              </TableCell>
                            )}
                            {showEditRecord && (
                              <TableCell
                                align="left"
                                onClick={() =>
                                  handlePriceBookEntry(
                                    "edit price",
                                    filteredResults[`${item}`],
                                    index
                                  )
                                }
                              >
                                <ModeEditOutline
                                  sx={{ width: "20px", height: "20px" }}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Box>
            </>
          ))}
        </Box>
      )}
    </>
  );
};

export default PriceBookDetails;
