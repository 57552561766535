import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import {
  organizationActions,
  paymentTermActions,
  countryActions,
} from "../../../../actions";
import DataTable from "../../../common/ui/table/DataTable/DataTable.component";
import { checkPermission } from "../../../../helpers/helpers";
import { PERMISSIONS } from "../../../../constants/data/rolesPermissions.constants";
import { UtilService } from "../../../../services/api/util/util.service";

const utilService = new UtilService();

function filterData(data, filter) {
  return data.filter((item) => {
    if (filter === "" || filter === null || filter === undefined) {
      return true;
    }

    const lowerCaseFilter = filter.toLowerCase();
    const lowerCaseName = (item.name || "").toLowerCase();
    const lowerCaseEmail = (item.delivery_email || "").toLowerCase();
    const loverCasdDeliveryName = (item.contact_person_name || "").toLowerCase();
    const lowerCaseContactNo = (item.contact_no || "").toLowerCase();

    if (
      lowerCaseName.includes(lowerCaseFilter) ||
      lowerCaseEmail.includes(lowerCaseFilter) ||
      loverCasdDeliveryName.includes(lowerCaseFilter) ||
      lowerCaseContactNo.includes(lowerCaseFilter)
    ) {
      return true;
    }

    return false;
  });
}

function formatData(paymentTerms, organizations, countries) {
  const formattedData = organizations?.items?.data.map((item) => {
    const { payment_term_id, country_id, ...rest } = item;
    const paymentTerm = paymentTerms?.items?.data.filter((term) => {
      return term.id === payment_term_id;
    });
    const country = countries?.items?.data.filter((term) => {
      return term.id === country_id;
    });
    rest.payment_term_id = paymentTerm[0].name;
    rest.country_id = country[0].name;

    return rest;
  });
  return formattedData;
}

const OrganizationTable = ({ filter }) => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations);
  const paymentTerms = useSelector((state) => state.paymentTerms);
  const countries = useSelector((state) => state.countries);
  const [dataFotmatted, setDataFormatted] = useState();
  const isEnabledEdit = checkPermission(PERMISSIONS.ORGANIZATION_MANAGEMENT);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Organization Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "contact_person_name",
      headerName: "Contact Person Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "sales_rep_id",
      headerName: "Sales Agent",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "address_line_1",
      headerName: "Address Line 1",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "address_line_2",
      headerName: "Address Line 2",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "postal_code",
      headerName: "Postal Code",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "country_id",
      headerName: "Country",
      width: 150,
      hide: true,
      showDetail: true,
    },

    {
      field: "use_same_billing_address",
      headerName: "Use Same Billing Address",
      width: 150,
      hide: true,
      showDetail: true,
      type: "boolean",
    },
    {
      field: "purchase_order_required",
      headerName: "Purchase Order Required",
      width: 150,
      hide: true,
      showDetail: true,
      type: "boolean",
    },

    {
      field: "payment_term_id",
      headerName: "Payment Term",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_name",
      headerName: "Delivery Name",
      minWidth: 200,
      flex: 1,
      hide: true,
      showDetail: true,
    },
    // {
    //   field: "contact_person_name",
    //   headerName: "Contact Person Name",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "delivery_email",
      headerName: "Delivery Email",
      minWidth: 200,
      flex: 1,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_contact_no",
      headerName: "Delivery Contact No",
      minWidth: 200,
      flex: 1,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_address_line_1",
      headerName: "Delivery Address Line 1",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_address_line_2",
      headerName: "Delivery Address Line 2",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_city",
      headerName: "Delivery City",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_state",
      headerName: "Delivery State",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_postal_code",
      headerName: "Delivery Postal Code",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "delivery_country_id",
      headerName: "Delivery Country",
      width: 150,
      hide: true,
      showDetail: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(organizationActions.getAllParameters());
    dispatch(paymentTermActions.getAllParameters());
    dispatch(countryActions.getAllParameters());

    const countries = utilService.getCountries();
    console.log("countries", countries);

  }, [dispatch]);

  useEffect(() => {
    if (
      paymentTerms?.items?.data &&
      organizations?.items?.data &&
      countries?.items?.data
    ) {
      const formattedData = formatData(paymentTerms, organizations, countries);
      const filteredData = filterData(formattedData, filter);
      setDataFormatted(filteredData);
    }
  }, [
    filter,
    paymentTerms,
    organizations,
    countries,
  ]);

  return (
    <Box>
      {organizations && (
        <DataTable
          loading={organizations?.loading || paymentTerms?.loading}
          rows={dataFotmatted}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="organizationActions"
          isEnabledEdit={isEnabledEdit}
          isEnabledDelete={false}
        />
      )}
    </Box>
  );
};

export default OrganizationTable;
