import * as React from 'react';
import { Accordion,AccordionSummary, AccordionDetails, Box, Grid, Button, FormControl, TextField, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from '../../../Theme/Style';

const SpecialInstructionComponent = (props) => {
    const {setSpecialComment, specialComment, handleSubmit} = props;
    const classes = useStyles();
    const [error, setError] = React.useState({
        status:false,
        message:'',
    });

    const handleCommentSubmit = (e) =>{
        if(specialComment !== ''){
            handleSubmit(e.target.value, 'comment');
        }
        else{
            setError({status:true, message:'Please enter comment'});
        }
    }

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{color:'#fff'}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{backgroundColor:'#2AB5BF'}}
            >  
                <Grid container className={classes.accordionSummaryTextContainer}>
                    <Grid item xs={4}>Special Instructions</Grid>
                    <Grid item xs={4}>特别说明</Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Box>Please specify if you have any additional requests, special instructions, change etc.</Box>
                <Box>请说明您是否有任何其他要求、特殊说明、更改等。</Box>

                <FormControl sx={{minWidth: 120, width:'100%' }}>
                    <TextField
                    className={classes.textField}
                    id="standard-multiline-static"
                    label="Your Comments | 你的评论"
                    multiline
                    rows={4}
                    variant="standard"
                    value={specialComment}
                    onChange={(e)=>{
                        setSpecialComment(e.target.value);
                        setError({status:false});
                    }}
                    />
                </FormControl>

                {error?.status &&
                <Box sx={{mt:1}}>
                    <Typography sx={{color:'red', fontSize:12}}>{error?.message}</Typography>
                </Box>
                }
                <Box className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.submitBtn} onClick={handleCommentSubmit}> Save </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default SpecialInstructionComponent;
