import React, { useState } from "react";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  Autocomplete,
  IconButton,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styles from "./../ParameterForm.module.scss";

const properties = [
  {
    name: "backlength",
    label: "Back Length",
  },
  {
    name: "chest",
    label: "Chest",
  },
  {
    name: "sleevelengthfromcervical",
    label: "Sleeve Length From Cervical",
  },
  {
    name: "sleevegirth",
    label: "Sleevegirth",
  },
  {
    name: "hemcircumference",
    label: "Hemcircumference",
  },
  {
    name: "neckwidth",
    label: "Neckwidth",
  },
  {
    name: "neckdrop",
    label: "Neckdrop",
  },
  {
    name: "shoulderbreadth",
    label: "Shoulder Breadth",
  },
  {
    name: "ribcuffwidth",
    label: "Rib Cuff Width",
  },
  {
    name: "ribcufflength",
    label: "Rib Cuff Length",
  },
  {
    name: "hoodheight",
    label: "Hood Height",
  },
  {
    name: "armcircumference",
    label: "Arm Circumference",
  },
  {
    name: "hoodshoelacelength",
    label: "Hood Shoelace Length",
  },
];

const Pattern = (props) => {
  const { title, titleSingle, mode, handleSuccessDialog } = props;
  const debug = false;
  const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    patternname: Yup.string().required("Pattern Name is required"),
    sizingchart: Yup.string().required("Sizing Chart required"),
    kidssizes: Yup.array().of(
      Yup.object().shape({
        property: Yup.string().required("Sizing property is required"),
        fourk: Yup.number(),
        sixk: Yup.number(),
        eightk: Yup.number(),
        tenk: Yup.number(),
        twelvek: Yup.number(),
        fourteenk: Yup.number(),
      })
    ),
    adultsizes: Yup.array().of(
      Yup.object().shape({
        property: Yup.string().required("Sizing property is required"),
        extrasmall: Yup.number(),
        small: Yup.number(),
        medium: Yup.number(),
        large: Yup.number(),
        xlarge: Yup.number(),
        twoxlarge: Yup.number(),
        threexlarge: Yup.number(),
        fourxlarge: Yup.number(),
      })
    ),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    patternname: "",
    sizingchart: "",
    kidssizes: [
      {
        id: Math.random(),
        property: "",
        fourk: "",
        sixk: "",
        eightk: "",
        tenk: "",
        twelvek: "",
        fourteenk: "",
      },
    ],
    adultsizes: [
      {
        id: Math.random(),
        extrasmall: "",
        small: "",
        medium: "",
        large: "",
        xlarge: "",
        twoxlarge: "",
        threexlarge: "",
        fourxlarge: "",
      },
    ],
    status: true,
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  const handleSubmit = (values) => {
    console.log(values);
    handleSuccessDialog(successMsg);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="patternname"
                  name="patternname"
                  label="Pattern Name"
                  value={values.patternname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.patternname && Boolean(errors.patternname)}
                  helperText={touched.patternname && errors.patternname}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              {/* <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.sizingchart && Boolean(errors.sizingchart)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Sizing Chart
                  </InputLabel>
                  <Select
                    id="sizingchart"
                    name="sizingchart"
                    value={values.sizingchart}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"Sizing Chart 1"}>Sizing Chart 1</MenuItem>
                    <MenuItem value={"Sizing Chart 2"}>Sizing Chart 2</MenuItem>
                  </Select>
                  {touched.sizingchart && Boolean(errors.sizingchart) && (
                    <FormHelperText>{errors.sizingchart}</FormHelperText>
                  )}
                </FormControl>
              </Grid> */}

              <Grid item xs={12}>
                <div>
                  <Grid
                    container
                    spacing={2}
                    sx={{ py: 1 }}
                    className={styles.sizingChartGrid}
                  >
                    <Grid item xs={3}>
                      <Typography>Sizing Property Kids</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>4K</Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>6K</Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>8K</Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>10K</Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>12K</Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>14K</Typography>
                    </Grid>
                  </Grid>
                </div>

                <FieldArray name="kidssizes">
                  {({ push, remove }) => (
                    <>
                      {values.kidssizes.map((p, index) => {
                        const property = `kidssizes[${index}].property`;
                        const touchedProperty = getIn(touched, property);
                        const errorProperty = getIn(errors, property);

                        const fourk = `kidssizes[${index}].fourk`;
                        const touchedFourK = getIn(touched, fourk);
                        const errorFourK = getIn(errors, fourk);

                        const sixk = `kidssizes[${index}].sixk`;
                        const touchedSixK = getIn(touched, sixk);
                        const errorSixK = getIn(errors, sixk);

                        const eightk = `kidssizes[${index}].eightk`;
                        const touchedEightK = getIn(touched, eightk);
                        const errorEightK = getIn(errors, eightk);

                        const tenk = `kidssizes[${index}].tenk`;
                        const touchedTenK = getIn(touched, tenk);
                        const errorTenK = getIn(errors, tenk);

                        const twelvek = `kidssizes[${index}].twelvek`;
                        const touchedTwelveK = getIn(touched, twelvek);
                        const errorTwelveK = getIn(errors, twelvek);

                        const fourteenk = `kidssizes[${index}].fourteenk`;
                        const touchedFourteenK = getIn(touched, fourteenk);
                        const errorFourteenK = getIn(errors, fourteenk);

                        return (
                          <div key={p.id} translate="no">
                            <Grid
                              container
                              spacing={2}
                              sx={{ py: 1 }}
                              className={styles.sizingChartGrid}
                            >
                              <Grid item xs={3}>
                                <Autocomplete
                                  fullWidth
                                  id={property}
                                  name={property}
                                  options={properties}
                                  onChange={(event, value) => {
                                    setFieldValue(property, value.name);
                                  }}
                                  classes={{
                                    root: styles.autocompleteRoot,
                                    inputRoot: styles.autocompleteInputRoot,
                                    option: styles.autoCompleteOption,
                                    groupLabel: styles.autoCompleteGroupLabel,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      margin="normal"
                                      label="Sizing Property"
                                      fullWidth
                                      // required
                                      variant="outlined"
                                      className={styles.textField}
                                      size="small"
                                      value={p.property}
                                      helperText={
                                        touchedProperty && errorProperty
                                          ? errorProperty
                                          : ""
                                      }
                                      error={Boolean(
                                        touchedProperty && errorProperty
                                      )}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={fourk}
                                  label="4K"
                                  value={p.fourk}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedFourK && errorFourK ? errorFourK : ""
                                  }
                                  error={Boolean(touchedFourK && errorFourK)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={sixk}
                                  label="6K"
                                  value={p.sixk}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedSixK && errorSixK ? errorSixK : ""
                                  }
                                  error={Boolean(touchedSixK && errorSixK)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={eightk}
                                  label="8K"
                                  value={p.eightk}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedEightK && errorEightK
                                      ? errorEightK
                                      : ""
                                  }
                                  error={Boolean(touchedEightK && errorEightK)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={tenk}
                                  label="10K"
                                  value={p.tenk}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedTenK && errorTenK ? errorTenK : ""
                                  }
                                  error={Boolean(touchedTenK && errorTenK)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={twelvek}
                                  label="12K"
                                  value={p.twelvek}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedTwelveK && errorTwelveK
                                      ? errorTwelveK
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedTwelveK && errorTwelveK
                                  )}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={fourteenk}
                                  label="14K"
                                  value={p.fourteenk}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedFourteenK && errorFourteenK
                                      ? errorFourteenK
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedFourteenK && errorFourteenK
                                  )}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              {index > 0 && (
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="clear"
                                    onClick={() => remove(index)}
                                    sx={{ pt: 0.5 }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>

                            <Divider
                              style={{ marginTop: 1, marginBottom: 3 }}
                            />
                          </div>
                        );
                      })}

                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() =>
                            push({
                              id: Math.random(),
                              property: "",
                              fourk: "",
                              sixk: "",
                              eightk: "",
                              tenk: "",
                              twelvek: "",
                              fourteenk: "",
                            })
                          }
                          sx={{
                            borderRadius: "2rem",
                            mt: 0.75,
                            mb: 1,
                          }}
                          className={styles.addButton}
                        >
                          Add
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <Grid
                    container
                    spacing={2}
                    sx={{ py: 1 }}
                    className={styles.sizingChartGrid}
                  >
                    <Grid item xs={3}>
                      <Typography>Sizing Property Adults</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>XS</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>S</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>M</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>L</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>XL</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>2XL</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>3XL</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>4XL</Typography>
                    </Grid>
                  </Grid>
                </div>

                <FieldArray name="kidssizes">
                  {({ push, remove }) => (
                    <>
                      {values.kidssizes.map((p, index) => {
                        const property = `kidssizes[${index}].property`;
                        const touchedProperty = getIn(touched, property);
                        const errorProperty = getIn(errors, property);

                        const extrasmall = `kidssizes[${index}].extrasmall`;
                        const touchedXSmall = getIn(touched, extrasmall);
                        const errorXSmall = getIn(errors, extrasmall);

                        const small = `kidssizes[${index}].small`;
                        const touchedSmall = getIn(touched, small);
                        const errorSmall = getIn(errors, small);

                        const medium = `kidssizes[${index}].medium`;
                        const touchedMedium = getIn(touched, medium);
                        const errorMedium = getIn(errors, medium);

                        const large = `kidssizes[${index}].large`;
                        const touchedLarge = getIn(touched, large);
                        const errorLarge = getIn(errors, large);

                        const xlarge = `kidssizes[${index}].xlarge`;
                        const touchedXLarge = getIn(touched, xlarge);
                        const errorXLarge = getIn(errors, xlarge);

                        const twoxlarge = `kidssizes[${index}].twoxlarge`;
                        const touchedTwoXLarge = getIn(touched, twoxlarge);
                        const errorTwoXLarge = getIn(errors, twoxlarge);

                        const threexlarge = `kidssizes[${index}].threexlarge`;
                        const touchedThreeXLarge = getIn(touched, threexlarge);
                        const errorThreeXLarge = getIn(errors, threexlarge);

                        const fourxlarge = `kidssizes[${index}].fourxlarge`;
                        const touchedFourXLarge = getIn(touched, fourxlarge);
                        const errorFourXLarge = getIn(errors, fourxlarge);

                        return (
                          <div key={p.id} translate="no">
                            <Grid
                              container
                              spacing={2}
                              sx={{ py: 1 }}
                              className={styles.sizingChartGrid}
                            >
                              <Grid item xs={3}>
                                <Autocomplete
                                  fullWidth
                                  id={property}
                                  name={property}
                                  options={properties}
                                  onChange={(event, value) => {
                                    setFieldValue(property, value.name);
                                  }}
                                  classes={{
                                    root: styles.autocompleteRoot,
                                    inputRoot: styles.autocompleteInputRoot,
                                    option: styles.autoCompleteOption,
                                    groupLabel: styles.autoCompleteGroupLabel,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      margin="normal"
                                      label="Sizing Property"
                                      fullWidth
                                      // required
                                      variant="outlined"
                                      className={styles.textField}
                                      size="small"
                                      value={p.property}
                                      helperText={
                                        touchedProperty && errorProperty
                                          ? errorProperty
                                          : ""
                                      }
                                      error={Boolean(
                                        touchedProperty && errorProperty
                                      )}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={extrasmall}
                                  label="XS"
                                  value={p.extrasmall}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedXSmall && errorXSmall
                                      ? errorXSmall
                                      : ""
                                  }
                                  error={Boolean(touchedXSmall && errorXSmall)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={small}
                                  label="S"
                                  value={p.small}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedSmall && errorSmall ? errorSmall : ""
                                  }
                                  error={Boolean(touchedSmall && errorSmall)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={medium}
                                  label="M"
                                  value={p.medium}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedMedium && errorMedium
                                      ? errorMedium
                                      : ""
                                  }
                                  error={Boolean(touchedMedium && errorMedium)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={large}
                                  label="L"
                                  value={p.large}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedLarge && errorLarge ? errorLarge : ""
                                  }
                                  error={Boolean(touchedLarge && errorLarge)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={xlarge}
                                  label="XL"
                                  value={p.xlarge}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedXLarge && errorXLarge
                                      ? errorXLarge
                                      : ""
                                  }
                                  error={Boolean(touchedXLarge && errorXLarge)}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={twoxlarge}
                                  label="2XL"
                                  value={p.twoxlarge}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedTwoXLarge && errorTwoXLarge
                                      ? errorTwoXLarge
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedTwoXLarge && errorTwoXLarge
                                  )}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={threexlarge}
                                  label="3XL"
                                  value={p.threexlarge}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedThreeXLarge && errorThreeXLarge
                                      ? errorThreeXLarge
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedThreeXLarge && errorThreeXLarge
                                  )}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  name={fourxlarge}
                                  label="4XL"
                                  value={p.fourxlarge}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touchedFourXLarge && errorFourXLarge
                                      ? errorFourXLarge
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedFourXLarge && errorFourXLarge
                                  )}
                                  variant="outlined"
                                  className={styles.textField}
                                  size="small"
                                />
                              </Grid>

                              {index > 0 && (
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="clear"
                                    onClick={() => remove(index)}
                                    sx={{ pt: 0.5 }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>

                            <Divider
                              style={{ marginTop: 1, marginBottom: 3 }}
                            />
                          </div>
                        );
                      })}

                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() =>
                            push({
                              id: Math.random(),
                              property: "",
                              fourk: "",
                              sixk: "",
                              eightk: "",
                              tenk: "",
                              twelvek: "",
                              fourteenk: "",
                            })
                          }
                          sx={{
                            borderRadius: "2rem",
                            mt: 0.75,
                            mb: 1,
                          }}
                          className={styles.addButton}
                        >
                          Add
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={submitting}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Pattern;
