import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { HORIZONTAL, VERTICAL } from "../constants/Constants";

const QontoConnector = styled(StepLabel)(({ theme }) => ({
  ".Mui-completed .MuiStepIcon-root": {
    color: theme.palette?.success.main,
  },
}));

const CustomizedSteppers = ({
  steps,
  activeStep,
  orientation,
  alternativeLabel,
}) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      {orientation !== 'horizontal' &&
      <Stepper
        activeStep={1}
        orientation={orientation}
      >
            <Step sx={{paddingLeft:0}}>
              <QontoConnector>{'Start'}</QontoConnector>
            </Step>
            <Step>
            </Step>
      </Stepper>
      }
      <Stepper
        sx={{ marginTop:'0 !important' }} 
        activeStep={activeStep}
        orientation={orientation}
        alternativeLabel={alternativeLabel}
      >
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <QontoConnector>{label}</QontoConnector>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

CustomizedSteppers.propTypes = {
  /**
   * Stpes to be displayed
   */
  steps: PropTypes.array.isRequired,

  /**
   *  Active stage
   */
  activeStep: PropTypes.number,

  /**
   *
   */
  orientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),

  /**
   *
   */
  alternativeLabel: PropTypes.bool,
};

CustomizedSteppers.defaultProps = {
  activeStep: 1,
  orientation: HORIZONTAL,
  steps: [],
  alternativeLabel: false,
};

export default CustomizedSteppers;
