import React from "react";
import {
  Typography,
  Link,
  Grid,
  Box,
  Button,
  Stack,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { deleteEntireCart } from "../../../helpers";

const CustomerStoreCheckoutSucces = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Store
    </Link>,
    <Typography key="3">Cart</Typography>,
    <Typography key="3">Success</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Success`}
        pageTitleShort="Store"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Grid container>
          <Grid item xs={6} sx={{ mx: "auto", textAlign: "center" }}>
            <Card>
              <CardContent sx={{ p: 5 }}>
                <Box
                  sx={{
                    background: "#69c464",
                    width: 75,
                    height: 75,
                    borderRadius: "50%",
                    mx: "auto",
                    display: "block",
                  }}
                >
                  <DoneIcon
                    sx={{
                      color: "#fff",
                      fontSize: 40,
                      mt: 2,
                    }}
                  />
                </Box>
                <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
                  Your Order has been placed successfully!
                </Typography>

                <Typography color="text.secondary" gutterBottom>
                  We received your order and you will receive an invoice to
                  complete your payment.
                </Typography>

                <Box sx={{ px: 10, mt: 5 }}>
                  {/* <Typography
                    color="text.secondary"
                    gutterBottom
                    variant="body2"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <span>Payment Type</span>
                      <span style={{ flex: 1 }}></span>
                      <strong>Online</strong>
                    </Stack>
                  </Typography> */}
                  <Typography
                    color="text.secondary"
                    gutterBottom
                    variant="body2"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <span>Payment Status</span>
                      <span style={{ flex: 1 }}></span>
                      <strong>Pending</strong>
                    </Stack>
                  </Typography>
                  <Typography
                    color="text.secondary"
                    gutterBottom
                    variant="body2"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <span>Order ID</span>
                      <span style={{ flex: 1 }}></span>
                      <strong>#5543232</strong>
                    </Stack>
                  </Typography>
                  {/* <Typography
                    color="text.secondary"
                    gutterBottom
                    variant="body2"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <span>Amount to be paid</span>
                      <span style={{ flex: 1 }}></span>
                      <strong>$500.00</strong>
                    </Stack>
                  </Typography> */}
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  sx={{ mx: "auto" }}
                  onClick={() => {
                    deleteEntireCart();
                    navigate("/store");
                  }}
                >
                  Back to Store
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default CustomerStoreCheckoutSucces;
