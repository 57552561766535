import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import PropTypes from "prop-types";

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "444px",
  padding: "10px",
};

const SuccessModal = ({ ...props }) => {
  const { title, open, handleClose, body, extra } = props;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"}>
            <CheckCircleOutlineTwoToneIcon
              color="success"
              fontSize="small"
              style={{ margin: "5px 5px 0 0" }}
            />
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              {title}
            </Typography>
          </Stack>
          {body && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }} s>
              {body}
            </Typography>
          )}

          {extra && <Typography>{extra}</Typography>}

          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Button onClick={() => handleClose()}>CLOSE</Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

SuccessModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  extra: PropTypes.string,
};

SuccessModal.defaultProps = {
  open: "",
  title: "",
  body: "",
  extra: "",
};

export default SuccessModal;
