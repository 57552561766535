import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "798px",
  minHeight: "174px",
  padding: "20px",
};

const RecordPaymentModal = ({ ...props }) => {
  const {
    open,
    handleClose,
    invoiceDetails,
    submitRecordPayment,
    disabledSubmit,
  } = props;
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [recordPayment, setRecordPayment] = useState({});
  const [transactionTypes, setTransactionType] = useState([]);
  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const clearAll = () => {
    handleClose();
  };

  useEffect(() => {
    const types = ["Partial payment", "Fully Payment"];
    setPaymentTypes(types);
    setPaymentMethods(["Bank Transfer", "Online Payment", "Cash"]);
    setMinDate(moment(invoiceDetails?.issued_date));
    const formatDate = moment(selectedDate).format("DD/MM/YYYY HH:mm:ss");
    setRecordPayment((state) => ({
      ...state,
      date: formatDate,
    }));

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/transaction-types/invoice`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
        } else {
          setTransactionType(data?.data);
        }
      });
    });
  }, [selectedDate, invoiceDetails?.issued_date]);

  const onChange = (e) => {
    const { id, value } = e.target;
    const balance =
      (invoiceDetails?.final_grand_total - invoiceDetails?.total_payed_amount) /
      100;
    if (id === "amount") {
      if (value > balance) {
        setError({
          status: true,
          message: "Amount cannot be greater than amount of to be paid",
        });
      } else {
        setError({ status: false, message: "" });
      }
    }
    setRecordPayment((state) => ({
      ...state,
      [id]: value,
    }));
  };

  const onDateChange = (value) => {
    const formatDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    setRecordPayment((state) => ({
      ...state,
      payment_date: formatDate,
    }));
  };

  const onPaymentMethodChange = (e) => {
    setRecordPayment((state) => ({
      ...state,
      payment_method: e.target.value,
    }));
  };

  const handlePayment = () => {
    submitRecordPayment(recordPayment);
  };

  return (
    <Modal
      open={open}
      onClose={clearAll}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={1}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              Record Payment
            </Typography>
          </Stack>
          <Typography>
            Use this option to record payments received via Bank Transfer, Cash
            or any other offline payment methods.
          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            {/* <FormControl fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="item"
                                size="small"
                                onChange={onChange}
                            >
                                {paymentTypes?.map((d, index) => (
                                <MenuItem key={index} value={d}>{d}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
            <FormControl fullWidth variant="standard">
              <DatePicker
                label="Date"
                id="date"
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                value={selectedDate}
                minDate={minDate}
                onChange={(newValue) => {
                  onDateChange(newValue);
                  setSelectedDate(newValue);
                }}
              />
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <FormControl fullWidth variant="standard">
              <TextField
                variant="standard"
                placeholder="Amount"
                label="Amount"
                id="amount"
                onChange={onChange}
              />
              {error.status && (
                <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Payment Method
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                size="small"
                onChange={onPaymentMethodChange}
              >
                {transactionTypes?.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <FormControl fullWidth variant="standard">
              <TextField
                variant="standard"
                id="reference_number"
                placeholder="Reference number"
                label="Reference number"
                onChange={onChange}
              />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <TextField
                variant="standard"
                placeholder="Comments"
                label="Comments"
                id="note"
                onChange={onChange}
              />
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ fontStyle: "italic" }}>
                Receipt / Attachments:
              </Typography>
              <Typography sx={{ fontStyle: "italic" }}>
                Please attach customer provided recipt or attachment
              </Typography>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                backgroundColor: "rgba(239, 239, 239, 0.65)",
                border: "1px dash #9E9E9E",
                height: "59px",
                width: "100%",
                padding: "0 10px",
              }}
            >
              <Stack
                sx={{ marginTop: "12px" }}
                direction={"row"}
                justifyContent="space-between"
              >
                <Box>
                  <Typography>File Name</Typography>
                </Box>
                <Box>
                  <Button variant="outlined" sx={{ width: "60px" }}>
                    Upload
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button color="success" onClick={() => clearAll()}>
              CLOSE
            </Button>
            <StyledButton
              color="success"
              variant="contained"
              disabled={disabledSubmit}
              onClick={handlePayment}
            >
              Record payment
            </StyledButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
export default RecordPaymentModal;
