import React, {useEffect, useState} from "react";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography,
  CardActions,
  Box,
  SvgIcon,
  InputLabel,
  CircularProgress,
  FormHelperText,
  Autocomplete, FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {fileUploaderActions, labelActions} from "../../../../../../actions";
import {authHeader} from "../../../../../../helpers";
import {apiConstants} from "../../../../../../constants/api/api.constants";
import {useStyles} from "../../../../../../Theme/Style";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Labels = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { title, titleSingle, mode, handleSuccessDialog } = props;
  const debug = false;
  const [submitting, setSubmitting] = useState(false);
  const [filename, setFilename] = useState();
  const [file, setFile] = useState();
  const [toggle, setToggle] = useState(true);
  const [labelTypes, setLabelTypes] = useState();
  const [labelTypeValue, setLabelTypeValue] = useState([]);
  const fileUploadState = useSelector((state) => state.fileUploads);
  const successMsg = "";

  const formikRef = React.createRef();
  console.log(mode)
  const validationSchema = Yup.object({
    labelname: Yup.string().required("Label Name is required"),
    image: Yup.mixed().required("Label Image is required"),
    labelType: Yup.string().required("Label Type is required"),
  });

  const initialValues = {
    labelname: "",
    image: "",
    labelType: "",
  };

  // const handleToggle = (e) => {
  //   setToggle(e.target.checked);
  // };

  useEffect(()=>{
    const requestOptions = {
      method: "GET",
      headers: authHeader(),

    };

    fetch(
        `${apiConstants.API_URL}/label-types`,
        requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setLabelTypes(data?.data)
        console.log(data)
      });
    });


  },[])

  useEffect(() => {
    const showImgPreview = () => {
      if (fileUploadState?.parameter?.data) {
        setFilename(fileUploadState?.parameter?.data?.path);
        formikRef.current.setFieldValue(
            "image",
            fileUploadState?.parameter?.data?.path
        );
      }
    };

    showImgPreview();
  }, [fileUploadState?.parameter?.data, formikRef]);

  const handleSubmit = (values) => {

    dispatch(
      labelActions.addParameter(
          values.labelname,
          filename,
          values.labelType,
          handleSuccessDialog(successMsg),
          successMsg
      ))


  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFile(URL.createObjectURL(file));

    dispatch(fileUploaderActions.addParameter(file));
    // await showImgPreview(file);
  };
  const defaultProps1 = {
    options: labelTypes?.data,
    getOptionLabel: (option) => option?.name || "" ,
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>

              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-label">Label Type</InputLabel>
                <Select
                    fullWidth
                    label="Label Type"
                    id="labelType"
                    name="labelType"
                    value={values.labelType}
                    // label="Age"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    className={styles.textField}
                    size="small"
                    error={touched.labelType && Boolean(errors.labelType)}
                    helperText={touched.labelType && errors.labelType}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {labelTypes?.map((name) => (
                      <MenuItem
                          key={name?.id}
                          value={name?.id}
                          // style={getStyles(name, personName, theme)}
                      >
                        {name?.name}
                      </MenuItem>
                  ))}
                </Select>

              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel id="demo-simple-select-label">Label Name</InputLabel>
                <TextField
                  fullWidth
                  id="labelname"
                  name="labelname"
                  // label="Label Name"
                  value={values.labelname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.labelname && Boolean(errors.labelname)}
                  helperText={touched.labelname && errors.labelname}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>


            </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                    container
                    spacing={{ xs: 2, sm: 2 }}
                    sx={{ pt: 0, pb: 0, mt: 2 }}
                >
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="image">
                      <br/>
                      <Card
                          elevation={0}
                          sx={{
                            border: "1px dashed #bbb",
                            cursor: "pointer",
                            mt: 2,
                            minHeight: "15rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                      >
                        <CardContent>
                          <Box>
                            <SvgIcon
                                {...props}
                                sx={{
                                  width: "3rem",
                                  height: "3rem",
                                  margin: "1rem auto 1.5rem auto",
                                  opacity: "0.8",
                                  display: "block",
                                }}
                            >
                              <svg
                                  xmlSpace="preserve"
                                  viewBox="0 0 409.794 409.794"
                              >
                                <path d="M387.367 176.731c3.861-2.284 5.154-7.267 2.869-11.128L329.955 63.46a8.133 8.133 0 0 0-6.999-3.999H96.876a8.117 8.117 0 0 0-7.096 4.17L32.848 165.774a8.111 8.111 0 0 0 3.138 11.055 8.08 8.08 0 0 0 3.95 1.032 8.106 8.106 0 0 0 7.104-4.17l54.616-97.982h216.651l57.924 98.144a8.138 8.138 0 0 0 11.136 2.878zM401.665 196.768H301.196c-4.495 0-8.129 3.633-8.129 8.129s3.633 8.129 8.129 8.129h92.341v121.059H16.257v-114.37h83.139c4.495 0 8.129-3.633 8.129-8.129s-3.633-8.129-8.129-8.129H8.129A8.121 8.121 0 0 0 0 211.586v130.618a8.121 8.121 0 0 0 8.129 8.129h393.537a8.121 8.121 0 0 0 8.129-8.129V204.897a8.129 8.129 0 0 0-8.13-8.129z" />
                                <path d="m258.277 202.41-12.42 38.741h-76.254l-21.151-40.049c-2.089-3.967-7.015-5.511-10.982-3.39-3.967 2.097-5.495 7.007-3.39 10.982l23.443 44.382a8.12 8.12 0 0 0 7.186 4.333h87.081a8.12 8.12 0 0 0 7.738-5.641l14.233-44.382c1.374-4.276-.984-8.852-5.251-10.226-4.299-1.367-8.867.99-10.233 5.25zM333.011 168.652a8.107 8.107 0 0 0 10.949 3.503 8.128 8.128 0 0 0 3.503-10.949l-11.51-22.354a8.126 8.126 0 0 0-7.226-4.406H98.421a8.14 8.14 0 0 0-7.486 4.95L81.44 161.75c-1.756 4.129.171 8.901 4.308 10.665a8.102 8.102 0 0 0 3.178.642 8.141 8.141 0 0 0 7.486-4.95l7.389-17.403H323.76l9.251 17.948zM107.216 126.79a8.141 8.141 0 0 0 7.486-4.95l6.706-15.834h178l8.568 16.428a8.125 8.125 0 0 0 10.974 3.447 8.144 8.144 0 0 0 3.447-10.974l-10.852-20.801a8.138 8.138 0 0 0-7.21-4.365H116.027a8.14 8.14 0 0 0-7.486 4.95l-8.811 20.793c-1.756 4.137.187 8.909 4.308 10.665a8.313 8.313 0 0 0 3.178.641z" />
                              </svg>
                            </SvgIcon>
                          </Box>
                          <Typography
                              gutterBottom
                              variant="body"
                              component="div"
                              sx={{
                                textAlign: "center",
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                              }}
                          >
                            Click or drag files to upload Label
                          </Typography>
                          <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ textAlign: "center", fontSize: "0.65rem" }}
                          >
                            (Accepted file types: JPEG, PNG, GIF, BMP)
                          </Typography>
                        </CardContent>
                        <CardActions sx={{ pointerEvents: "none", mb: 1 }}>
                          <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              fullWidth
                          >
                            Upload
                          </Button>
                        </CardActions>
                      </Card>
                    </label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={(e) => {

                          handleImageUpload(e);
                        }}
                        onBlur={handleBlur}

                        style={{
                          position: "absolute",
                          pointerEvents: "none",
                          visibility: "hidden",
                        }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ mt: { sm: 5 } }}>
                    <div className="upload-preview" style={{ height: "100%" }}>
                      <Card
                          elevation={0}
                          sx={{
                            border: "1px dashed #bbb",
                            height: {
                              sm: "100%",
                            },
                          }}
                      >
                        <Box sx={{ p: 1 }}>
                          {fileUploadState?.loading ? (
                              <Box
                                  sx={{
                                    display: "flex",
                                    p: 5,
                                    justifyContent: "center",
                                  }}
                              >
                                <CircularProgress />
                              </Box>
                          ) : fileUploadState?.parameter?.data && file ? (
                              <img
                                  alt="uploaded img"
                                  src={file}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "11.5rem",
                                    objectFit: "cover",
                                  }}
                              />
                          ) : null}
                        </Box>
                        <CardContent
                            sx={{ px: 0, py: 0, pb: "1rem !important" }}
                        >
                          <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                textAlign: "center",
                                fontSize: "0.75rem",
                                m: 0,
                              }}
                          >
                            {fileUploadState?.parameter?.data?.path
                                ? fileUploadState?.parameter?.data?.path
                                : "Please upload a label image "}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                </Grid>

                {touched.image && Boolean(errors.image) && (
                    <FormHelperText sx={{ color: "red" }}>
                      {touched.image && errors.image}
                    </FormHelperText>
                )}
              </Grid>

              {/*<Grid item xs={12} md={6}>*/}
              {/*  <TextField*/}
              {/*    fullWidth*/}
              {/*    id="image"*/}
              {/*    name="image"*/}
              {/*    label="Label Image"*/}
              {/*    value={values.image}*/}
              {/*    onChange={handleChange}*/}
              {/*    onBlur={handleBlur}*/}
              {/*    error={touched.image && Boolean(errors.image)}*/}
              {/*    helperText={touched.image && errors.image}*/}
              {/*    variant="outlined"*/}
              {/*    className={styles.textField}*/}
              {/*    size="small"*/}
              {/*  />*/}
              {/*</Grid>*/}


              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={submitting}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            {/*</Grid>*/}

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Labels;
