import { extraChargeConstants } from "../../constants";

export function extraCharges(state = {}, action) {
  switch (action.type) {
    // get all
    case extraChargeConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case extraChargeConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case extraChargeConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case extraChargeConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case extraChargeConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case extraChargeConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case extraChargeConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case extraChargeConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case extraChargeConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case extraChargeConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case extraChargeConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case extraChargeConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case extraChargeConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
