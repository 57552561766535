import * as React from 'react';
import {Grid, Typography} from '@mui/material';
import { useStyles } from '../Theme/Style';
import { InfoOutlined } from '@mui/icons-material';

const Notification = ({ ...props }) => {
    const { description } = props
    const classes = useStyles();
    return(
        <Grid container className={classes.notificationContainer}>
            <Grid item xs={0}><InfoOutlined sx={{color:'#0288D1'}}/></Grid>
            <Grid item xs={11}>
                <Typography>
                    {description}
                </Typography>
            </Grid>
        </Grid>
    );

}
export default Notification;