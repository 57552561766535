export const productionOrderConstants = {
  GET_SINGLE_REQUEST: "PRODUCTION_ORDER_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "PRODUCTION_ORDER_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "PRODUCTION_ORDER_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "PRODUCTION_ORDER_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "PRODUCTION_ORDER_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "PRODUCTION_ORDER_GET_ALL_FAILURE",

  ADD_REQUEST: "PRODUCTION_ORDER_ADD_REQUEST",
  ADD_SUCCESS: "PRODUCTION_ORDER_ADD_SUCCESS",
  ADD_FAILURE: "PRODUCTION_ORDER_ADD_FAILURE",

  UPDATE_REQUEST: "PRODUCTION_ORDER_UPDATE_REQUEST",
  UPDATE_SUCCESS: "PRODUCTION_ORDER_UPDATE_SUCCESS",
  UPDATE_FAILURE: "PRODUCTION_ORDER_UPDATE_FAILURE",

  DELETE_REQUEST: "PRODUCTION_ORDER_DELETE_REQUEST",
  DELETE_SUCCESS: "PRODUCTION_ORDER_DELETE_SUCCESS",
  DELETE_FAILURE: "PRODUCTION_ORDER_DELETE_FAILURE",

  CLEAR: "PRODUCTION_ORDER_CLEAR",
};
