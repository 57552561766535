import { AddCircleOutline } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { forkJoin, from } from "rxjs";
import { useStyles } from "../../../Theme/Style";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";
import { PriceBookService } from "../../../services/api/price-book/price-book.service";
import { Breadcrumbs } from "../../atoms/Breadcrumbs/Breadcrumbs";
import { GeneralPageLayout } from "../../molecules/PageLayout/GeneralPageLayout";
import SuccessModal from "../../../Common/Modals/SuccessModal";
import AddPriceBookEntryComponent from "./AddPriceBookEntryComponent";
import PriceBookDetails from "./PriceBookDetails";
import PriceBookSideBar from "./PriceBookSidebar";
import { UtilService } from "../../../services/api/util/util.service";

const priceBookService = new PriceBookService();
const utilService = new UtilService();

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(171, 171, 171, 0.15)",
  "&:hover": {
    backgroundColor: "rgba(171, 171, 171, 0.25)",
  },
  marginLeft: 0,
  width: "100%",
  height: "46.67px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "12px 8px 12px 0px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const PriceBookContainer = () => {
  const classes = useStyles();

  const [priceBookStep, setPriceBookStep] = React.useState("");
  const [getSportCategories, setGetSportCategories] = React.useState([]);
  const [getItems, setItems] = React.useState([]);
  const [fabrics, setFabrics] = React.useState([]);
  const [linings, setLinings] = React.useState([]);
  const [sportDetails, SetSportDetails] = React.useState({});
  const [successMessage, setSuccessMessage] = React.useState({
    open: false,
    title: "",
    body: "",
  });
  const [sportItems, setSportItems] = React.useState([]);
  const [itemsRowParams, setItemsRow] = React.useState({});
  const [loadingComponent, setLoadingComponent] = React.useState(false);
  const [loadingSidebar, setLoadingSidebar] = React.useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = React.useState(false);
  const [params, setParams] = React.useState({
    Fabric: false,
    Item: false,
  });
  const [searchInput, setSearchInput] = React.useState("");
  const [filteredResults, setFilteredResults] = React.useState([]);
  const navigate = useNavigate();

  const handleSportItemClick = React.useCallback(
    async (id) => {
      setSearchInput("");
      setLoadingComponent(true);

      const sportsCategoryData = await priceBookService.getSpartsItemById(id);
      SetSportDetails(sportsCategoryData.data);

      const sportItemsData = await priceBookService.getSportById(id);
      setSportItems(sportItemsData.data);

      setLoadingComponent(false);
    },
    [disabledSubmitButton]
  );

  React.useEffect(() => {
    setLoadingComponent(true);
    setLoadingSidebar(true);
    const hasPermission = checkPermission(
      PERMISSIONS.CREATE_AND_EDIT_PRICEBOOK_RECORDS
    );

    let subscription = null;

    if (hasPermission) {
      const $sportsCategories = from(priceBookService.getSportsCategories());
      const $items = from(priceBookService.getItems());
      const $fabrics = from(utilService.getFabrics());
      const $linings = from(priceBookService.getLinings());

      subscription = forkJoin({
        sportscategories: $sportsCategories,
        items: $items,
        fabrics: $fabrics,
        linings: $linings,
      }).subscribe(({ sportscategories, items, fabrics, linings }) => {
        if (sportscategories.data && sportscategories.data.length > 0) {
          setGetSportCategories(sportscategories.data);
          handleSportItemClick(sportscategories.data[0].id);
          SetSportDetails(sportscategories.data[0]);
          setLoadingSidebar(false);
        }

        setItems(items.data);
        setFabrics(fabrics.data);
        setLinings(linings.data);

        setLoadingComponent(false);
      });
    } else {
      navigate("/");
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [handleSportItemClick, navigate]);

  // search functionality
  React.useEffect(() => {
    const types = [];

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        types.push(key.toLowerCase());
      }
    });

    if (types.length === 0) {
      Object.keys(params).forEach((key) => types.push(key.toLowerCase()));
    }

    if (!searchInput || searchInput === "") {
      setFilteredResults(sportItems);
    } else {
      const filteredObjects = {};

      Object.keys(sportItems).forEach((key) => {
        const filteredItems = [];
        const items = sportItems[key];

        items.forEach((item) => {
          let canInclued = false;
          types.forEach((type) => {
            if (
              item[type] &&
              item[type].name.toLowerCase().includes(searchInput)
            ) {
              canInclued = true;
            }
          });

          if (canInclued) {
            filteredItems.push(item);
          }
        });

        if (filteredItems.length > 0) {
          filteredObjects[key] = filteredItems;
        }
      });

      setFilteredResults(filteredObjects);
    }
  }, [params, sportItems, searchInput]);

  const handlePriceBookEntry = (type, itemData = [], index = 0) => {
    setPriceBookStep("add-price-book-entry");
    const itemDetails = {
      type: type,
      data: itemData,
      index: index,
    };
    setItemsRow(itemDetails);
  };

  const backToSportCategory = () => {
    setPriceBookStep("price-book");
  };

  const handleClose = () => {
    setSuccessMessage({ ...successMessage, open: false });
    setPriceBookStep("price-book");
  };

  const submitEntry = React.useCallback(
    async (type, successMessage, data, id = 0) => {
      setDisabledSubmitButton(true);
      if (type === "new") {
        const response = await priceBookService.createPricebookEntry(data);

        if (response.status === 200) {
          setSuccessMessage(successMessage);
          Object.keys(sportItems).forEach(function (key) {
            if (key === response?.data?.data.item.name) {
              sportItems[key].push(response?.data?.data);
            }
          });
        }
        setDisabledSubmitButton(false);
      } else {
        const response = await priceBookService.updatePricebookEntry(id, data);
        setDisabledSubmitButton(false);
        setSuccessMessage(successMessage);
        Object.keys(sportItems).forEach(function (key) {
          if (key === response?.data?.data?.item?.name) {
            sportItems[key].forEach(function (obj, index) {
              if (obj.id === response.data?.data.id) {
                sportItems[key][index] = response.data?.data;
              }
            });
          }
        });
      }
    },
    [sportItems]
  );

  const handleFilterParam = React.useCallback(
    (key) => {
      if (key) {
        const paramsObj = { ...params };
        paramsObj[key] = !params[key];
        setParams(paramsObj);
      }
    },
    [params]
  );

  return (
    <GeneralPageLayout
      breadcrumbs={
        priceBookStep === "price-book" || priceBookStep === "" ? (
          <Breadcrumbs lastStep="Price Book" />
        ) : (
          <Breadcrumbs setStep={backToSportCategory} steps={["Price Book"]} />
        )
      }
      pageTitle={"Price Book"}
      headerWidgets={
        <Box>
          {priceBookStep !== "add-price-book-entry" && (
            <Grid container gap={"16px"} alignItems={"center"} textAlign="left">
              <Box>
                <Box sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Filter results by
                </Box>
                <Box className={classes.checkBoxContainer}>
                  {Object.keys(params).map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          checked={params[key].checked}
                          onChange={(e) => handleFilterParam(key)}
                        />
                      }
                      label={key.replace(/_/g, " ")}
                    />
                  ))}
                </Box>
              </Box>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Entry"
                  inputProps={{ "aria-label": "search", type: "text" }}
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                />
              </Search>
              <Button
                variant="contained"
                className={classes.headerButton}
                onClick={() => handlePriceBookEntry("new")}
              >
                <AddCircleOutline />
                add new Price book entry
              </Button>
            </Grid>
          )}
        </Box>
      }
    >
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        {(() => {
          switch (priceBookStep) {
            case "add-price-book-entry":
              return (
                <Box gridColumn="span 12">
                  <Content>
                    <AddPriceBookEntryComponent
                      backToSportCategory={backToSportCategory}
                      itemsRowParams={itemsRowParams}
                      sportCategories={getSportCategories}
                      items={getItems}
                      fabrics={fabrics}
                      linings={linings}
                      sportDetails={sportDetails}
                      submitEntry={submitEntry}
                      disabledSubmitButton={disabledSubmitButton}
                    />
                  </Content>
                </Box>
              );
            default:
              return (
                <>
                  <Box gridColumn="span 3">
                    <PriceBookSideBar
                      sportCategories={getSportCategories}
                      handleSportItemClick={handleSportItemClick}
                      selectedSportDetails={sportDetails}
                      loadingSidebar={loadingSidebar}
                    />
                  </Box>
                  <Box gridColumn="span 9">
                    <Content>
                      <PriceBookDetails
                        selectedSportDetails={sportDetails}
                        handlePriceBookEntry={handlePriceBookEntry}
                        loadingComponent={loadingComponent}
                        filteredResults={filteredResults}
                        showEditRecord={true}
                      />
                    </Content>
                  </Box>
                </>
              );
          }
        })()}
      </Box>
      <SuccessModal
        open={successMessage.open}
        handleClose={handleClose}
        title={successMessage.title}
        body={successMessage.body}
      />
    </GeneralPageLayout>
  );
};

export default PriceBookContainer;
