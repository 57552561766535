import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import RoleForm from "../../../components/admin/RoleManagement/RoleForm/RoleForm.component";

const RoleEdit = () => {
  const location = useLocation();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/user-management/roles">
      Role Management
    </Link>,

    <Typography key="3">Edit Role</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Edit Role`}
        pageTitleShort={`Edit Role`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent title={`Enter New Role`} subtitle="" description="">
            <Grid container spacing="2">
              <Grid item xs={12}>
                <RoleForm 
                mode="edit"
                paramdata={location?.state?.param}
                />
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default RoleEdit;
