import styled from "@emotion/styled";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Notification from "../../Common/Notification";
import drawer from "../../images/drawer.png";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";

const StyledContainer = styled(Box)({
  width: "100%",
  border: "1px dashed #C4C4C4",
  height: "379px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
});
const StyledFileContainer = styled(Box)({
  width: "100%",
  border: "1px dashed #C4C4C4",
  height: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
});
const StyledButton = styled(Button)({
  width: "300px",
  height: "40px",
  pointerEvents: "none",
});
const StyledClearButton = styled(Button)({
  width: "100px",
  height: "40px",
});
const SalesPurchaseOrder = ({ ...props }) => {
  const { file, setFile, setDisabledSubmitBtn, readOnly } = props;

  React.useEffect(() => {
    setDisabledSubmitBtn(false);
  });

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log(file, "file");
    const formData = new FormData();
    formData.append("file", file);
    setFile({
      image: formData,
      image_url: URL.createObjectURL(file),
      name: file?.name,
    });
  };

  const clearSelectedFile = () => {
    if (readOnly) return;
    setFile({});
  };

  return (
    <Stack spacing={2}>
      {!readOnly && (
        <>
          <Typography fontSize={"16px"} fontWeight={400}>
            Please upload the Purchase Order
          </Typography>
          <Notification
            title="Note"
            body="If your organization is defined as PO required in the organization definition, then the PO is mandatory"
          />
        </>
      )}
      <StyledContainer>
        <img src={readOnly ? file.image_url : drawer} alt="" height={"150px"} />
        {!readOnly && (
          <Stack style={{ textAlign: "center" }} spacing={1}>
            <Typography>
              Click or drag files to this area to upload your purchase order
            </Typography>
            <Typography fontSize={"12px"}>
              Click or drag files to this area to upload your purchase order
            </Typography>
            <Typography fontSize={"11px"}>
              Click or drag files to this area to upload your purchase order
            </Typography>
          </Stack>
        )}
        {!readOnly && (
          <Stack direction={"row"} spacing={2}>
            <StyledClearButton
              variant="outlined"
              color="error"
              onClick={clearSelectedFile}
            >
              CLEAR
            </StyledClearButton>
            {!file.image_url && (
              <label htmlFor={"image"}>
                <StyledButton variant="outlined">UPLOAD</StyledButton>
              </label>
            )}
          </Stack>
        )}
        <input
          type="file"
          id={`image`}
          name={`image`}
          accept="*"
          onChange={(e) => {
            handleImageUpload(e);
          }}
          disabled={!!readOnly}
          style={{
            position: "absolute",
            pointerEvents: "none",
            visibility: "hidden",
          }}
        />
      </StyledContainer>
      {file.image_url && (
        <StyledFileContainer>
          <Box
            sx={{
              display: "flex",
              padding: "5px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <InsertDriveFileIcon
                color="primary"
                sx={{ marginRight: "5px" }}
              />
              <Typography variant="span">{file?.name}</Typography>
            </Box>
            <Button>
              <CloseIcon
                color="red"
                fontSize="15"
                sx={{ marginTop: "5px", fontWeight: "800" }}
                onClick={clearSelectedFile}
              />
            </Button>
          </Box>
        </StyledFileContainer>
      )}
    </Stack>
  );
};

export default SalesPurchaseOrder;
