import React, { useEffect } from "react";
import { Box } from "@mui/material";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const PaymentMethodsList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },
  ];

  const recordIdentifier = "name";

  const { data, isLoading } = {
    isLoading: false,
    data: [
      {
        id: 1,
        name: "Cash",
      },
      {
        id: 2,
        name: "Credit Card",
      },
    ],
  };

  return (
    <Box>
      <DataTable
        loading={isLoading}
        rows={data || []}
        columns={columns}
        recordIdentifier={recordIdentifier}
        actionIdentifier="payment_methods"
        isEnabledEdit={true}
      />
    </Box>
  );
};

export default PaymentMethodsList;
