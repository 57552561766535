export const artworkChargeConstants = {
  GET_SINGLE_REQUEST: "ARTWORK_CHARGE_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "ARTWORK_CHARGE_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "ARTWORK_CHARGE_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "ARTWORK_CHARGE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "ARTWORK_CHARGE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "ARTWORK_CHARGE_GET_ALL_FAILURE",

  ADD_REQUEST: "ARTWORK_CHARGE_ADD_REQUEST",
  ADD_SUCCESS: "ARTWORK_CHARGE_ADD_SUCCESS",
  ADD_FAILURE: "ARTWORK_CHARGE_ADD_FAILURE",

  UPDATE_REQUEST: "ARTWORK_CHARGE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ARTWORK_CHARGE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ARTWORK_CHARGE_UPDATE_FAILURE",

  DELETE_REQUEST: "ARTWORK_CHARGE_DELETE_REQUEST",
  DELETE_SUCCESS: "ARTWORK_CHARGE_DELETE_SUCCESS",
  DELETE_FAILURE: "ARTWORK_CHARGE_DELETE_FAILURE",

  CLEAR: "ARTWORK_CHARGE_CLEAR",
};
