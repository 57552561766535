import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {coloryActions, fabricActions} from "../../../../../../actions";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
const Fabrics = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;

  const dispatch = useDispatch();
  const paramState = useSelector((state) => state.fabrics);
  const alertState = useSelector((state) => state.alert);
  const colors = useSelector((state) => state.colors);
  const formikRef = React.createRef();

  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    name: Yup.string().required("Fabric Name is required"),
    chinese_name: Yup.string().required("Chinese Name is required"),
    technical_name: Yup.string().required("Technical Name is required"),
    // fabricColor: Yup.string().required("Fabric Color is required"),
    fabricColor: Yup.array().of(
            Yup.object().shape({
              id: Yup.string(),
              english_name: Yup.string(),
              chinese_name:Yup.string()
            })
        ).min(1,"Fabric Color  is required"),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    name: "",
    chinese_name: "",
    technical_name: "",
    fabricColor: [],
    status: true,
  };

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata) {
        const fields = ["name", "status", "chinese_name", "technical_name"];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, paramdata[field], false)
        );
      }
    };

    prefillData();
  }, []);

  useEffect(() => {
    const handleSuccess = () => {
      if (paramState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (paramState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [paramState, handleSuccessDialog, alertState, handleErrorAlert]);

  const handleSubmit = (values) => {

    let fabricColorIds=[]
    values?.fabricColor?.map((value => {
      fabricColorIds=[...fabricColorIds,value.id]
    }))

    if (values && mode === "add") {

      dispatch(
        fabricActions.addParameter(
          values.name,
          values.chinese_name,
          values.technical_name,
          values.status,
          fabricColorIds
        )
      );
    }
    if (values && mode === "edit") {
      dispatch(
        fabricActions.updateParameter(
          paramdata.id,
          values.name,
          values.chinese_name,
          values.technical_name,
          values.status,
          fabricColorIds
        )
      );
    }
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  useEffect(() => {
    dispatch(coloryActions.getAllParameters());
  }, [dispatch]);


  const colorsProps = {
    options: colors?.items?.data,
    getOptionLabel: (option) => option?.english_name || '',
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Fabric Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="chinese_name"
                  name="chinese_name"
                  label="Chinese Name"
                  value={values.chinese_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.chinese_name && Boolean(errors.chinese_name)}
                  helperText={touched.chinese_name && errors.chinese_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="technical_name"
                  name="technical_name"
                  label="Technical Name"
                  value={values.technical_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.technical_name && Boolean(errors.technical_name)
                  }
                  helperText={touched.technical_name && errors.technical_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>

                <Autocomplete
                    fullWidth
                    multiple
                    className={styles.textField}
                    name="fabricColor"
                    variant="standard"
                    size="small"
                    options={colors?.items?.data|| []}
                    value={values.fabricColor}
                    onChange={(e, v) => {
                      console.log(v)
                      setFieldValue("fabricColor", v);
                    }}


                    getOptionLabel={(option) => option.english_name}
                    renderTags={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                  <Chip variant="standard" key={value.id}   label={value.english_name} />
                              ))}
                            </Box>
                        )}
                    renderInput={(params) => (
                        <TextField    onBlur={handleBlur}
                                      error={
                                        touched.fabricColor && Boolean(errors.fabricColor)
                                      }  helperText={touched.fabricColor && errors.fabricColor} variant="standard" {...params} label="Fabric Color" />
                    )}
                 />
                {/*<InputLabel id="demo-multiple-chip-label" style={{height:'16.5px'}}>Fabric Color</InputLabel>*/}
                {/*<Select*/}

                {/*    fullWidth*/}
                {/*    variant="outlined"*/}
                {/*    label='fabric Color'*/}
                {/*    // className={styles.textField}*/}
                {/*    size="small"*/}
                {/*    name="fabricColor"*/}
                {/*    // id="fabricColor"*/}
                {/*    multiple*/}
                {/*    value={values.fabricColor}*/}
                {/*    onChange={handleChange}*/}
                {/*    onBlur={handleBlur}*/}
                {/*    error={*/}
                {/*      touched.technical_name && Boolean(errors.technical_name)*/}
                {/*    }*/}
                {/*    helperText={touched.technical_name && errors.technical_name}*/}
                {/*    input={<Input id="select-multiple-chip" label="Chip" />}*/}
                {/*    renderValue={(selected) => (*/}
                {/*        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>*/}
                {/*          {selected.map((value) => (*/}
                {/*              <Chip key={value.id} label={value.english_name} />*/}
                {/*          ))}*/}
                {/*        </Box>*/}
                {/*    )}*/}
                {/*    // MenuProps={MenuProps}*/}
                {/*>*/}
                {/*  {colors?.items?.data.map((color) => (*/}
                {/*      <MenuItem*/}
                {/*          key={color.id}*/}
                {/*          value={color}*/}
                {/*          // style={getStyles(name, personName, theme)}*/}
                {/*      >*/}
                {/*        {color.english_name}*/}
                {/*      </MenuItem>*/}
                {/*  ))}*/}
                {/*</Select>*/}

              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={paramState?.loading}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Fabrics;
