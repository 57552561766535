import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { artworkChargeActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const ArtworkCharges = () => {
  const dispatch = useDispatch();
  const artworkCharges = useSelector((state) => state.artworkCharges);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "count",
      headerName: "Count",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "max_limit",
      headerName: "Max Limit",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "id";

  useEffect(() => {
    dispatch(artworkChargeActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {artworkCharges && (
        <DataTable
          loading={artworkCharges?.loading}
          rows={artworkCharges?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="artworkChargeActions"
        />
      )}
    </Box>
  );
};

export default ArtworkCharges;
