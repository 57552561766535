import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { extraChargeActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const ExtraCharges = () => {
  const dispatch = useDispatch();
  const extraCharges = useSelector((state) => state.extraCharges);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      minWidth: 100,
      flex: 1,
      renderCell:params=>params.row.cost/100
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      hide: true,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(extraChargeActions.getAllParameters());
  }, [dispatch]);

  console.log(extraCharges?.items?.data,'extra charge');

  return (
    <Box>
      {extraCharges && (
        <DataTable
          loading={extraCharges?.loading}
          rows={extraCharges?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="extraChargeActions"
        />
      )}
    </Box>
  );
};

export default ExtraCharges;
