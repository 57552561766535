import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useStyles } from "../../../Theme/Style";
import { useEffect } from "react";

const FabricDetailsComponent = (props) => {
  const classes = useStyles();
  const {
    activeItem,
    colors,
    fabrics,
    poFields,
    handleSubmit,
    positions,
    template,
  } = props;

  // const poFieldItems = poFields.filter((p=> p?.is_trim === 0)) || [];

  const defaultProps = {
    options: poFields,
    getOptionLabel: (option) => {
      console.log("option:", option);
      return option?.name ? option?.name + "-" + option?.chinese_name : "";
    },
  };
  const defaultProps1 = {
    options: fabrics,
    getOptionLabel: (option) =>
      option?.name ? option?.name + "-" + option?.chinese_name : "",
  };
  const defaultProps2 = {
    options: colors,
    getOptionLabel: (options) =>
      options?.english_name
        ? options?.english_name + "-" + options?.chinese_name
        : "",
  };
  const defaultProps3 = {
    options: positions,
    getOptionLabel: (options) => (options?.name ? options?.name : ""),
  };

  console.log("prop3", defaultProps3);
  const [fabricsItems, setFabricItems] = React.useState([]);

  const [error, setError] = React.useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    if (template?.productionOderCategories) {
      const fabricDetails = template?.productionOderCategories?.map((f) => {
        return {
          item: {
            name: f.name,
            id: f.id,
            chinese_name: f.chinese_name,
          },
          fabricType: {},
          color: {},
        };
      }) || {
        item: {},
        fabricType: {},
        color: {},
      };
      setFabricItems(fabricDetails);
    }
  }, [template]);

  React.useEffect(() => {
    if (activeItem?.production_order?.po_fields.length !== 0) {
      const nonTrims = activeItem?.production_order?.po_fields?.filter(
        (f) => f?.is_trim === 0
      );
      const items = nonTrims.map((n) => {
        return {
          item: { id: n?.id, name: n?.combinedName },
          // combinedName: fabricsItem[0]?.combinedName},
          fabricType: { name: n?.po_field_values?.value },
          color: n?.po_field_values?.color,
        };
      });
      setFabricItems(items);
    } else {
      setFabricItems([
        {
          // item:{id:poFields[0]?.id,
          // name:poFields[0]?.name},
          // combinedName: fabricsItem[0]?.combinedName},
          fabricType: activeItem?.price?.fabric,
          color: activeItem?.color,
        },
      ]);
      console.log("valuessssssssss", poFields[0]);
    }
  }, [activeItem]);

  const addNewRow = () => {
    const fabricDetails = {
      item: {},
      fabricType: {},
      color: {},
    };
    setFabricItems([...fabricsItems, fabricDetails]);
  };

  const handleChange = (event, name, index, val) => {
    setError({ status: false });
    const { value } = event.target;
    const rowsInput = [...fabricsItems];
    rowsInput[index][name] = val;
    setFabricItems(rowsInput);
  };

  const deleteRows = (index) => {
    const rows = [...fabricsItems];
    rows.splice(index, 1);
    setFabricItems(rows);
  };

  const handleFabricSubmit = () => {
    if (
      fabricsItems?.every(
        (el) => el?.item?.id && el.fabricType?.name && el.color?.id
      )
    ) {
      handleSubmit(fabricsItems, "fabric");
    } else {
      setError({ status: true, message: "Please fill the values" });
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Fabric Details
          </Grid>
          <Grid item xs={4}>
            面料细节
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Box className={classes.tableHeader}>
          <Grid container>
            <Grid item xs={3} className={classes.cell}>
              <Box>Item</Box>
              <Box>物品</Box>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Box>Fabric Type</Box>
              <Box>面料类型</Box>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Box>Color</Box>
              <Box>颜色</Box>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Box>Position</Box>
              <Box>颜色</Box>
            </Grid>
          </Grid>
        </Box>
        {fabricsItems?.map((subItems, index) => {
          return (
            <Box className={classes.row} key={index}>
              <Grid container>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps}
                      value={subItems?.item || {}}
                      // defaultValue={subItems.item || ''}
                      id="controlled-demo"
                      autoComplete
                      //   includeInputInList
                      freeSolo={true}
                      // onSelect={(e)=>handleChange(e, 'item', index)}
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "item", index, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps1}
                      value={subItems?.fabricType}
                      // defaultValue={subItems.fabricType}
                      id="controlled-demo"
                      autoComplete
                      includeInputInList
                      // freeSolo={true}
                      // disabled={index === 0}
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "fabricType", index, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps2}
                      value={subItems?.color}
                      // defaultValue={subItems.color}
                      id="controlled-demo"
                      autoComplete
                      includeInputInList
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "color", index, value);
                      }}
                      // onSelect={(e)=>handleChange(e, 'color', index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps3}
                      value={subItems?.position}
                      // defaultValue={subItems.color}
                      id="controlled-demo"
                      autoComplete
                      includeInputInList
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "color", index, value);
                      }}
                      // onSelect={(e)=>handleChange(e, 'color', index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {index !== 0 && (
                  <Button className={classes.itemRemoveIcon}>
                    <RemoveCircleOutline onClick={() => deleteRows(index)} />
                  </Button>
                )}
              </Grid>
            </Box>
          );
        })}

        {error?.status && (
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ color: "red", fontSize: 12 }}>
              {error?.message}
            </Typography>
          </Box>
        )}
        <Box className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            className={classes.addNewRowBtn}
            onClick={addNewRow}
          >
            {" "}
            <AddCircleOutline sx={{ paddingRight: "10px" }} /> Add New Row{" "}
          </Button>
          <Button
            variant="contained"
            className={classes.submitBtn}
            onClick={handleFabricSubmit}
          >
            {" "}
            Save{" "}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FabricDetailsComponent;
