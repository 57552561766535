import styled from "@emotion/styled";
import { Box, Button, Modal, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import InvoiceContainer from "../../components/Invoice/InvoiceContainer";

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "95%",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  overflow: 'auto',
};

const ViewInvoiceModal = ({ ...props }) => {
  const { open, handleClose } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <InvoiceContainer view={true} />
        <Stack direction={"row"} justifyContent="right">
          <Button color="success" onClick={() => handleClose(!open)}>
            CLOSE
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

ViewInvoiceModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};
ViewInvoiceModal.defaultProps = {
  open: false,
};

export default ViewInvoiceModal;
