import * as React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    section:{
        marginBottom:'60px'
    },
    termsAndConditionsHeader:{
        fontSize:'24px !important', 
        fontWeight:'500 !important', 
        marginBottom:'30px !important'
    },
    termsAndConditionRow:{
        display:'flex',
        "& .MuiTypography-root:first-child":{
            textAlign: 'right',
            width: '20px',
            marginRight:'9px'
        },
        "& .MuiTypography-root:last-child":{
            textAlign: 'left',
            width: '100%'
        }
    }
});

const TermsAndConditionComponent = () => {
    const classes = useStyles();

    return(
        <Box sx={{textAlign:'left'}}>
            <Box className={classes.section}>
                <Typography className={classes.termsAndConditionsHeader}>HOW TO PAY?</Typography>
                <Box>
                    <Typography sx={{marginBottom:'20px'}}>For direct deposits or Internet banking, please use the following:</Typography>

                    <Typography>Bank Name: ANZ Banking Group</Typography>
                    <Typography>BSB Number: 013-366 </Typography>
                    <Typography>Account Name: S-Trend Pty Ltd </Typography>
                    <Typography>Account Number: 4382 70697 </Typography>
                
                    <Typography sx={{marginTop:'20px'}}>Payment can also be made by cheque payable to S-TREND PTY Ltd</Typography>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography className={classes.termsAndConditionsHeader}>PAYMENT TERMS</Typography>
                <Box>
                    <Typography>50% deposit before bulk production, balance paid before delivery.</Typography>
                    <Typography>S-Trend Sportswear remains the owner of all goods until payment is received in full</Typography>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography className={classes.termsAndConditionsHeader}>TERMS AND CONDITIONS</Typography>
                <Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>1.</Typography>
                        <Typography>
                        Our delivery timeframe is 4 - 6 weeks, from artwork approval, with the exception of some accessories. Please communicate any delivery deadlines in advance, if applicable.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>2.</Typography>
                        <Typography>
                        All unit prices are exclusive of GST;
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>3.</Typography>
                        <Typography>
                        Payments made by credit card will incur a 2% surcharge on Grand Total;
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>4.</Typography>
                        <Typography>
                        50% deposit is required prior to commencement of order and the balance is due prior to delivery;
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>5.</Typography>
                        <Typography>
                        Repeat orders of existing styles in quantities of 1 to 4 are subject to a small quantity surcharge of $2.00 per garment;
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>6.</Typography>
                        <Typography>
                        Prices are inclusive of artwork with 3 revisions on orders with 16 or more units per style. Artwork for orders of under 16 units per style is subject to a designer's fee of $50.00 per garment style. 50% of the designer's fee is deducted from order invoice if an order of more than 4 units is placed.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>7.</Typography>
                        <Typography>
                        Logos not provided in correct vector formats (.ai .pdf .eps) are subject to a redraw fee of $20.00 per logo.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>8.</Typography>
                        <Typography>
                        Names and numbers are subject to an additional $1.00 per unit.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>9.</Typography>
                        <Typography>
                        Our standard lead time is 4 weeks for production. Express service is available from time to time.  Please check with your S-Trend representative for further information.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>10.</Typography>
                        <Typography>
                        Pre-production samples on new orders are provided free for confirmed orders of 150 units or above. Additional charges apply for pre-production samples for orders of under 150 units. Please check with your representative for further information. 
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TermsAndConditionComponent;