import { storeItemConstants } from "../../constants";
import { storeItemService } from "../../services";
import { alertActions } from "../alert.actions";

export const storeItemActions = {
  getSingleParameter,
  getAllParameters,
  // addParameter,
  // updateParameter,
  // deleteParameter,
  // clear,
};

// get single parameter
function getSingleParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    storeItemService.getSingleParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(parameter) {
    return { type: storeItemConstants.GET_SINGLE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: storeItemConstants.GET_SINGLE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: storeItemConstants.GET_SINGLE_FAILURE, error };
  }
}

// get all parameter
function getAllParameters() {
  return (dispatch) => {
    dispatch(request());

    storeItemService.getAllParameters().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error))
    );
  };

  function request(params) {
    return { type: storeItemConstants.GET_ALL_REQUEST, params };
  }
  function success(items) {
    return { type: storeItemConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: storeItemConstants.GET_ALL_FAILURE, error };
  }
}

// add parameter
// function addParameter(count, max_limit, cost) {
//   return (dispatch) => {
//     dispatch(request(count, max_limit, cost));

//     storeItemService.addParameter(count, max_limit, cost).then(
//       (parameter) => {
//         dispatch(success(parameter));
//         dispatch(alertActions.success(parameter));
//       },
//       (error) => {
//         dispatch(failure(error));
//         dispatch(alertActions.error(error));
//       }
//     );
//   };

//   function request(parameter) {
//     return { type: storeItemConstants.ADD_REQUEST, parameter };
//   }
//   function success(parameter) {
//     return { type: storeItemConstants.ADD_SUCCESS, parameter };
//   }
//   function failure(error) {
//     return { type: storeItemConstants.ADD_FAILURE, error };
//   }
// }

// update parameter
// function updateParameter(id, count, max_limit, cost) {
//   return (dispatch) => {
//     dispatch(request(id, count, max_limit, cost));

//     storeItemService.updateParameter(id, count, max_limit, cost).then(
//       (parameter) => {
//         dispatch(success(parameter));
//         dispatch(alertActions.success(parameter));
//       },
//       (error) => {
//         dispatch(failure(error));
//         dispatch(alertActions.error(error));
//       }
//     );
//   };

//   function request(parameter) {
//     return { type: storeItemConstants.UPDATE_REQUEST, parameter };
//   }
//   function success(parameter) {
//     return { type: storeItemConstants.UPDATE_SUCCESS, parameter };
//   }
//   function failure(error) {
//     return { type: storeItemConstants.UPDATE_FAILURE, error };
//   }
// }

// delete parameter
// function deleteParameter(id) {
//   return (dispatch) => {
//     dispatch(request(id));

//     storeItemService.deleteParameter(id).then(
//       (parameter) => {
//         dispatch(success(parameter));
//         dispatch(alertActions.success(parameter));
//       },
//       (error) => {
//         dispatch(failure(error));
//         dispatch(alertActions.error(error));
//       }
//     );
//   };

//   function request(parameter) {
//     return { type: storeItemConstants.DELETE_REQUEST, parameter };
//   }
//   function success(parameter) {
//     return { type: storeItemConstants.DELETE_SUCCESS, parameter };
//   }
//   function failure(error) {
//     return { type: storeItemConstants.DELETE_FAILURE, error };
//   }
// }

// clear parameter
// function clear() {
//   return { type: storeItemConstants.CLEAR };
// }
