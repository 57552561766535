import { userConstants } from "../constants";
import { userService } from "../services/user.service";
import { alertActions } from "./alert.actions";
import {
  getCurrentUser,
  updatePermissionsLocally,
  updateUserLocally,
} from "../helpers";

export const userActions = {
  login,
  logout,
  getAll,
  getSingle,
  addUser,
  updateUser,
  getUserDetails,
  customerLogin,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        // history.push("/dashboard");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function customerLogin(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.customerLogin(username, password).then(
      (user) => {
        dispatch(success(user));
        // history.push("/dashboard");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function getAll(page) {
  return (dispatch) => {
    dispatch(request(page));

    userService.getAll(page).then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// get single
function getSingle(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getSingle(id).then(
      (parameter) => {
        dispatch(success(parameter));
        // dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: userConstants.GET_SINGLE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: userConstants.GET_SINGLE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: userConstants.GET_SINGLE_FAILURE, error };
  }
}

// add user
function addUser(
  first_name,
  last_name,
  code,
  contact_no,
  address_line_1,
  address_line_2,
  postal_code,
  country_id,
  manager_id,
  is_admin,
  status,
  email,
  organization_id,
  sport_id,
  roleName
) {
  return (dispatch) => {
    dispatch(
      request(
        first_name,
        last_name,
        code,
        contact_no,
        address_line_1,
        address_line_2,
        postal_code,
        country_id,
        manager_id,
        is_admin,
        status,
        email,
        organization_id,
        sport_id,
        roleName
      )
    );

    userService
      .addUser(
        first_name,
        last_name,
        code,
        contact_no,
        address_line_1,
        address_line_2,
        postal_code,
        country_id,
        manager_id,
        is_admin,
        status,
        email,
        organization_id,
        sport_id
      )
      .then(
        (parameter) => {
          // dispatch(success(parameter));
          // dispatch(alertActions.success(parameter));
          userService.assignRolesToUser(parameter?.data?.id, roleName).then(
            (parameter) => {
              dispatch(success(parameter));
              dispatch(alertActions.success(parameter));
            },
            (error) => {
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          );
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(parameter) {
    return { type: userConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: userConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: userConstants.ADD_FAILURE, error };
  }
}

// update user
function updateUser(
  id,
  first_name,
  last_name,
  code,
  contact_no,
  address_line_1,
  address_line_2,
  postal_code,
  country_id,
  manager_id,
  is_admin,
  status,
  email,
  organization_id,
  sport_id,
  roleName,
  image
) {
  return (dispatch) => {
    dispatch(
      request(
        id,
        first_name,
        last_name,
        code,
        contact_no,
        address_line_1,
        address_line_2,
        postal_code,
        country_id,
        manager_id,
        is_admin,
        status,
        email,
        organization_id,
        sport_id,
        roleName,
        image
      )
    );

    userService
      .updateUser(
        id,
        first_name,
        last_name,
        code,
        contact_no,
        address_line_1,
        address_line_2,
        postal_code,
        country_id,
        manager_id,
        is_admin,
        status,
        email,
        organization_id,
        sport_id,
        image
      )
      .then(
        (param) => {
          // dispatch(success(parameter));
          // dispatch(alertActions.success(parameter));
          userService.assignRolesToUser(id, roleName).then(
            (parameter) => {
              dispatch(success(parameter));
              dispatch(alertActions.success(parameter));
            },
            (error) => {
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          );
          const user = getCurrentUser();

          if (user?.id === id) {
            updateUserLocally(param?.data);
          }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(parameter) {
    return { type: userConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: userConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: userConstants.ADD_FAILURE, error };
  }
}

// get user details
function getUserDetails(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getUserDetails(id).then(
      (parameter) => {
        dispatch(success(parameter));
        updatePermissionsLocally(parameter?.data?.roles[0]?.permissions);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: userConstants.GET_USER_DETAILS_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: userConstants.GET_USER_DETAILS_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_DETAILS_FAILURE, error };
  }
}
