export const deliveryFeeConstants = {
  GET_SINGLE_REQUEST: "DELIVERY_FEE_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "DELIVERY_FEE_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "DELIVERY_FEE_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "DELIVERY_FEE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "DELIVERY_FEE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "DELIVERY_FEE_GET_ALL_FAILURE",

  ADD_REQUEST: "DELIVERY_FEE_ADD_REQUEST",
  ADD_SUCCESS: "DELIVERY_FEE_ADD_SUCCESS",
  ADD_FAILURE: "DELIVERY_FEE_ADD_FAILURE",

  UPDATE_REQUEST: "DELIVERY_FEE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "DELIVERY_FEE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "DELIVERY_FEE_UPDATE_FAILURE",

  DELETE_REQUEST: "DELIVERY_FEE_DELETE_REQUEST",
  DELETE_SUCCESS: "DELIVERY_FEE_DELETE_SUCCESS",
  DELETE_FAILURE: "DELIVERY_FEE_DELETE_FAILURE",

  CLEAR: "DELIVERY_FEE_CLEAR",
};
