import React from "react";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./S[ecImageDialog.module.scss";

const SpecImageDialog = (props) => {
  const { img } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen()} className={styles.button}>
        <img src={`${img}`} srcSet={`${img}`} alt="" loading="lazy" />
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={`${img}`}
            srcSet={`${img}`}
            alt=""
            loading="lazy"
            style={{ display: "block", maxWidth: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SpecImageDialog;
