import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../Theme/Style";
import { useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { Delete } from "@mui/icons-material";
import * as Yup from "yup";

const validationSchema = Yup.object({
  logoPlacementImage: Yup.array().min(1, "At least one image is required"),
  sublimatedColorCodeImages: Yup.array(),
  printerNumber: Yup.string().required("Printer Number is required"),
});

const SublimationComponent = () => {
  const classes = useStyles();

  const [previewImages, setPreviewImages] = useState({
    logoPlacementImage: [],
    sublimatedColorCodeImages: [],
  });

  const handleImageChange = (event, setFieldValue, fieldName, index) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue(`${fieldName}[${index}]`, file);
        setPreviewImages((prev) => ({
          ...prev,
          [fieldName]: [
            ...prev[fieldName].slice(0, index),
            reader.result,
            ...prev[fieldName].slice(index + 1),
          ],
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Sublimation
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Formik
          initialValues={{
            logoPlacementImage: [""],
            sublimatedColorCodeImages: [],
            printerNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              {/* Logo Placement Section */}
              <Typography variant="subtitle1" marginBottom={2}>
                Logo Placement Section
              </Typography>

              <FieldArray name="logoPlacementImage">
                {({ push, remove }) => (
                  <>
                    <Grid container spacing={2}>
                      {values.logoPlacementImage.map((_, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`logo-placement-image-${index}`}
                            type="file"
                            onChange={(event) =>
                              handleImageChange(
                                event,
                                setFieldValue,
                                "logoPlacementImage",
                                index
                              )
                            }
                          />
                          <label htmlFor={`logo-placement-image-${index}`}>
                            <Button variant="outlined" component="span">
                              Upload Logo Placement Image {index + 1}
                            </Button>
                          </label>
                          {previewImages.logoPlacementImage[index] && (
                            <Box mt={2}>
                              <img
                                src={previewImages.logoPlacementImage[index]}
                                alt={`Logo Placement Preview ${index + 1}`}
                                style={{ width: "100%", maxHeight: "200px" }}
                              />
                              <IconButton onClick={() => remove(index)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={() => push("")}>
                          Add More Images
                        </Button>
                      </Grid>
                    </Grid>
                    {errors.logoPlacementImage &&
                      touched.logoPlacementImage && (
                        <div style={{ color: "red" }}>
                          {errors.logoPlacementImage}
                        </div>
                      )}
                  </>
                )}
              </FieldArray>
              <Divider sx={{ margin: "20px 0" }} />

              {/* Sublimated Color Code Section */}
              <Typography variant="subtitle1" marginBottom={2}>
                Sublimated Color Code
              </Typography>
              <FieldArray name="sublimatedColorCodeImages">
                {({ push, remove }) => (
                  <>
                    <Grid container spacing={2} mt={2}>
                      {values.sublimatedColorCodeImages.map((_, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`sublimated-color-code-image-${index}`}
                            type="file"
                            onChange={(event) =>
                              handleImageChange(
                                event,
                                setFieldValue,
                                "sublimatedColorCodeImages",
                                index
                              )
                            }
                          />
                          <label
                            htmlFor={`sublimated-color-code-image-${index}`}
                          >
                            <Button variant="outlined" component="span">
                              Upload Sublimated Color Code Image {index + 1}
                            </Button>
                          </label>
                          {previewImages.sublimatedColorCodeImages[index] && (
                            <Box mt={2}>
                              <img
                                src={
                                  previewImages.sublimatedColorCodeImages[index]
                                }
                                alt={`Sublimated Color Code Preview ${
                                  index + 1
                                }`}
                                style={{ width: "100%", maxHeight: "200px" }}
                              />
                              <IconButton onClick={() => remove(index)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={() => push("")}>
                          Add More Images
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </FieldArray>
              <Divider sx={{ margin: "20px 0" }} />

              {/* Printer Number Section */}
              <Typography variant="subtitle1" marginBottom={2}>
                Printer Number
              </Typography>
              <Grid item xs={12} mt={2}>
                <TextField
                  label="Printer Number"
                  name="printerNumber"
                  fullWidth
                  onChange={(e) =>
                    setFieldValue("printerNumber", e.target.value)
                  }
                  value={values.printerNumber}
                  error={touched.printerNumber && Boolean(errors.printerNumber)}
                  helperText={touched.printerNumber && errors.printerNumber}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} mt={4}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

export default SublimationComponent;
