export const sportsCategoryConstants = {
  GET_SINGLE_REQUEST: "SPORTS_CATEGORY_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "SPORTS_CATEGORY_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "SPORTS_CATEGORY_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "SPORTS_CATEGORY_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "SPORTS_CATEGORY_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "SPORTS_CATEGORY_GET_ALL_FAILURE",

  ADD_REQUEST: "SPORTS_CATEGORY_ADD_REQUEST",
  ADD_SUCCESS: "SPORTS_CATEGORY_ADD_SUCCESS",
  ADD_FAILURE: "SPORTS_CATEGORY_ADD_FAILURE",

  UPDATE_REQUEST: "SPORTS_CATEGORY_UPDATE_REQUEST",
  UPDATE_SUCCESS: "SPORTS_CATEGORY_UPDATE_SUCCESS",
  UPDATE_FAILURE: "SPORTS_CATEGORY_UPDATE_FAILURE",

  DELETE_REQUEST: "SPORTS_CATEGORY_DELETE_REQUEST",
  DELETE_SUCCESS: "SPORTS_CATEGORY_DELETE_SUCCESS",
  DELETE_FAILURE: "SPORTS_CATEGORY_DELETE_FAILURE",

  CLEAR: "SPORTS_CATEGORY_CLEAR",
};
