import React from "react";
import Notification from "../../../Common/Notification";
import { Button, CardContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import { apiConstants } from "../../../constants";
import { getUserToken } from "../../../helpers";

const StyledBoxContent = styled(Stack)({
  height: "auto",
  minHeight: "142px",
  padding: "19px 30px",
  borderRadius: "8px",
  justifyContent: "space-around",
  textAlign: "center",
  border: "1px dashed #9E9E9E",
});

const StyledBox = styled(StyledBoxContent)(({ theme }) => ({
  backgroundColor: "#efefef !important",
  border: "1px dashed #9E9E9E",
  height: "auto",
  // width: "501px",
}));

const SalesOrderConceptDetails = ({
  items,
  updateItems,
  setOpenProgressCircular,
}) => {
  const handleImageUpload = (e, item, index) => {
    setOpenProgressCircular(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    const token = getUserToken();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    fetch(`${apiConstants.API_URL}/images/sales-order`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          let newData = {
            ...item,
            image: data?.data?.path,
            image_url: URL.createObjectURL(file),
          };
          updateItems(index, newData);
          setOpenProgressCircular(false);
        });
      }
    );
  };

  const clearSelectedFile = (e, item, index) => {
    let newData = {
      ...item,
      image: "",
      image_url: "",
    };
    updateItems(index, newData);
  };

  return (
    <CardContent
      sx={{ textAlign: "left", minWidth: "500px", minHeight: "576px" }}
    >
      <Stack spacing={3}>
        <Typography fontSize={"24px"}>Concept Details</Typography>
        <Notification
          body="Please upload and assign concept designs for each item. If you have already uploaded the designs while creating the quotation, you can use existing designs as well."
          extra="Please Note"
        />
        <Stack spacing={2}>
          {items?.map((item, index) => (
            <div key={item?.id}>
              <Stack direction={"row"} justifyContent="space-between">
                <Typography>{item?.price?.item?.name || item?.name}</Typography>
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2 }}
              >
                <StyledBox sx={{ cursor: "pointer" }}>
                  <Typography fontSize={"14px"}>
                    Click files to this area to upload your concept designs
                  </Typography>
                  <Typography fontSize={"11px"}>
                    (Accepted file types: SVG, EPS, PDF, JPG, PNG)
                  </Typography>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent="space-evenly"
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={(e) => clearSelectedFile(e, item, index)}
                    >
                      CLEAR
                    </Button>
                    <label htmlFor={`image${item?.id}`}>
                      <Button
                        variant="outlined"
                        style={{ width: "246px", pointerEvents: "none" }}
                      >
                        UPLOAD {`image`}
                      </Button>
                    </label>
                  </Stack>
                </StyledBox>
                <input
                  type="file"
                  id={`image${item?.id}`}
                  name={`image${item?.id}`}
                  accept="image/*"
                  onChange={(e) => {
                    // setFieldValue("image", e.currentTarget.files[0]);
                    // showImgPreview(e);
                    handleImageUpload(e, item, index);
                  }}
                  //onBlur={handleBlur}
                  // error={touched.image && Boolean(errors.image)}
                  // helperText={touched.image && errors.image}
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    visibility: "hidden",
                  }}
                />
                <Stack>
                  <StyledBox>
                    {!item?.image_url || !item?.image ? (
                      <Typography fontSize={"14px"}>
                        Click or drag files to this area to upload your concept
                        designs
                      </Typography>
                    ) : (
                      <img
                        id={`image${item?.id}`}
                        alt="uploaded img"
                        src={item?.image_url || item?.image}
                        style={{
                          height: "auto",
                          objectFit: "cover",
                          width: "100%",
                        }}
                      />
                    )}
                  </StyledBox>
                </Stack>
              </Stack>
            </div>
          ))}
        </Stack>
      </Stack>
    </CardContent>
  );
};

SalesOrderConceptDetails.propTypes = {
  items: PropTypes.object,
};
SalesOrderConceptDetails.defaultProps = {
  items: {
    items: [],
  },
};
export default SalesOrderConceptDetails;
