import React, { useState } from "react";
import {
  Box,
  Paper,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Chip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./WebStoreItem.module.scss";

const StoreItem = ({
  itemId,
  active,
  name,
  image,
  styleId,
  newItem,
  store,
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <Box>
      <Card
        elevation={0}
        sx={{ p: 1, textAlign: "center", backgroundColor: "transparent" }}
        className={`${active ? styles.active : styles.deactive}`}
      >
        <Link
          // to={`items/${store?.id}`}
          // to={`items/${itemId}`}
          to={`items/${itemId}`}
          style={{ position: "relative", display: "block" }}
        >
          <CardMedia
            component="img"
            height=""
            image={image}
            alt="green iguana"
            sx={{
              height: "230px",
              boxShadow: "0 0 7px 0px rgba(0,0,0,0.1)",
              borderRadius: 2,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
          {newItem && (
            <Chip
              label="Newly added"
              color="warning"
              size="small"
              className={styles.newBadge}
            />
          )}
          {!active && <div className={styles.hiddenBadge}>Hidden Item</div>}
        </Link>

        <CardContent sx={{ pl: 0, pr: 0 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "0.875rem",
              fontWeight: 500,
              mb: 0,
              textTransform: "uppercase",
            }}
          >
            {name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {styleId && <>Pattern #{styleId}</>}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const WebstoreItem = (props) => {
  const { itemId, name, image, styleId, active, newItem, store } = props;

  return (
    <div>
      <StoreItem
        itemId={itemId}
        active={active}
        name={name}
        image={image}
        styleId={styleId}
        newItem={newItem}
        store={store}
      />
    </div>
  );
};

export default WebstoreItem;
