import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./Slice";
import orderSlice from "./orderSlice";
import messagesSlice from "./messagesSlice";

export default configureStore({
  reducer: {
    customer: cartReducer,
    orders: orderSlice,
    message: messagesSlice,
  },
});
