import React from "react";
import { Grid, Typography } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import ParameterCard from "../../../components/admin/parameterManagement/ParameterCard/ParameterCard.component";

import { parameterTypes } from "../../../constants";

const ParamaeterManagementHome = () => {
  const breadcrumbs = [<Typography key="3">Parameter Management</Typography>];

  return (
    <>
      <Intro
        pageTitle={`Parameter Management`}
        pageTitleShort="Parameter Management"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <BoxedContent title="Select an option" subtitle="" description="">
          <Grid container spacing={2} sx={{ mt: -3 }}>
            {parameterTypes.map((parameter) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                key={parameter.id}
                sx={{
                  flexBasis: { lg: "20%", xl: "16.6666%" },
                  maxWidth: { lg: "20%", xl: "16.6666%" },
                }}
              >
                <ParameterCard
                  title={parameter.title}
                  description={parameter.description}
                  icon={parameter.icon}
                  slug={parameter.slug}
                />
              </Grid>
            ))}
          </Grid>
        </BoxedContent>
      </Content>
    </>
  );
};

export default ParamaeterManagementHome;
