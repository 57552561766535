import { isEmpty, debounce } from "lodash";
import { getCurrentUser, getUserPermissions } from "./user-helper";

/**
 *
 * @param {*} array
 * @returns {*} new array
 */
const addNewProps = (array, sportId) => {
  let newArray = array.map((a) => {
    const { id, name } = a;

    return {
      name,
      item_id: id,
      sport_id: sportId,
      fabrics: [],
      linings: [],
      qty: 0,
      price: 0,
      discount: 0,
      discounted_price: 0,
      selected: false,
      fabric_id: null,
      lining_ids: [],
      discount_percentage: 0,
      unit_price: 0,
      original_unit_price: 0,
      total_price: 0,
      original_total_price: 0,
      artwork_charges: 0,
      extra_charges: 0,
      estimated_shipping_charges: 0,
      gst_amount: 0,
      price_id: null,
      extra_charges_list: [],
    };
  });
  return newArray;
};

const compareArrays = (array1, array2) => {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.id === object2.id;
    });
  });
};

const convertToPlaces = (value, x = 100) => {
  // Ensure value is a numeric value before formatting
  if (!isNaN(value)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AUD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / x);
  } else {
    // Handle case where value is not a valid number
    return "";
  }
};

const convertToDecimalPlaces = (value) => {
  // let formated = value.to
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const getDiscountedPrice = (price, discount) => {
  let discounted = discount;
  let newPrice = price - discounted;
  return newPrice < 0 ? price : newPrice;
};

const checkPermission = (permissionName) => {
  const permissions = getUserPermissions();

  return (permissions || []).some(
    (permission) => permission.name === permissionName
  );
};

const checkIsAdmin = () => {
  const isAdmin = getCurrentUser()?.is_admin === 1;
  return isAdmin;
};

const checkIsOnlySalesAgent = () => {
  const roles = getCurrentUser()?.roles.map((role) => role.name);

  if (!roles) {
    return false;
  }

  if (roles.includes("Super Admin")) {
    return false;
  }

  if (roles.includes("Sales Agent")) {
    return true;
  }

  return false;
};

const checkIsOnlySuperAdmin = () => {
  const roles = getCurrentUser()?.roles.map((role) => role.name);

  if (!roles) {
    return false;
  }

  if (roles.includes("Super Admin")) {
    return true;
  }

  return false;
};

const createSlug = (title) => {
  const cleanedTitle = title
    .trim()
    .replace(/[^\w\s-]/g, "")
    .toLowerCase();

  const slug = cleanedTitle.replace(/\s+/g, "-");

  return slug;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export {
  isEmpty,
  addNewProps,
  compareArrays,
  convertToPlaces,
  convertToDecimalPlaces,
  getDiscountedPrice,
  debounce,
  checkPermission,
  checkIsAdmin,
  checkIsOnlySalesAgent,
  checkIsOnlySuperAdmin,
  createSlug,
  capitalizeFirstLetter,
};
