import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { webstoreActions } from "../../../../actions/webstore.actions";
import DataTable from "../../../common/ui/table/DataTable/DataTable.component";

const WebStoreTable = (props) => {
  const { search } = props;
  const dispatch = useDispatch();
  const webstores = useSelector((state) => state.webstores);

  const columns = [
    { field: "id", headerName: "Store ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
    },
    // {
    //   field: "no_if_items",
    //   headerName: "No. of Items",
    //   minWidth: 60,
    //   flex: 1,
    // },
    // {
    //   field: "is_active",
    //   headerName: "Status",
    //   minWidth: 100,
    //   flex: 1,
    // },
    {
      field: "organization_id",
      headerName: "Organization ID",
      minWidth: 100,
      flex: 1,
      hide: true,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "id";

  useEffect(() => {
    dispatch(webstoreActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {webstores && (
        <DataTable
          loading={webstores?.loading}
          rows={webstores?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="webstoreActions"
          hasSeparateView={{
            active: true,
            param: "id",
          }}
          search={search}
        />
      )}
    </Box>
  );
};

export default WebStoreTable;
