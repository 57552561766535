import { FilterAlt } from "@mui/icons-material";
import { Box, Button, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { QuotationsTable } from "./QuotationsTable";

const StyledInputBase = styled(InputBase)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em)`,
    width: "100%",
    height: "38px",
    // width: "100%",
  },
}));

const useStyles = makeStyles({
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
  filterButton: {
    fontSize: 15,
    color: "#fff !important",
    height: "42px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#2AB5BF !important",
      border: "1px solid #2AB5BF",
    },
  },
  dataGrid: {
    "& .MuiTablePagination-displayedRows": {
      fontSize: 12,
    },
    "& .MuiTablePagination-select": {
      fontSize: 12,
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: 12,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

export const QuotationsTableWithFilter = ({
  enableFilters,
  quotationsViewLink,
  getQuotationByOrganization,
  handlePageChange,
  getQuotationByQuotationId,
  searchCustomer,
  setSearchCustomer,
  quotations,
  paginationData,
  setFilterByQuotationId,
  filterByQuotationId,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.salesOrdersFilterBar}>
        {enableFilters && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box className={classes.salesOrderFilterContainer}>
              <Box>
                <StyledInputBase
                  placeholder="Customer Name"
                  inputProps={{ "aria-label": "search", type: "text" }}
                  value={searchCustomer}
                  onChange={(e) => {
                    setSearchCustomer(e.target.value);
                  }}
                  onClick={() => {
                    setFilterByQuotationId("");
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className={classes.filterButton}
                onClick={getQuotationByOrganization}
              >
                <FilterAlt fontSize="12" />
              </Button>
            </Box>
            <Box className={classes.salesOrderFilterContainer}>
              <Box>
                <StyledInputBase
                  placeholder="Quotation ID"
                  inputProps={{ "aria-label": "search", type: "text" }}
                  value={filterByQuotationId}
                  onChange={(e) => {
                    setFilterByQuotationId(e.target.value);
                  }}
                  onClick={() => {
                    setSearchCustomer("");
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className={classes.filterButton}
                onClick={getQuotationByQuotationId}
              >
                Filter
                <FilterAlt fontSize="12" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box style={{ width: "100%" }}>
        <QuotationsTable
          quotations={quotations}
          perPageCount={paginationData?.per_page}
          totalRowsConunt={paginationData?.total}
          handlePageChange={handlePageChange}
          pathToView={quotationsViewLink}
        />
      </Box>
    </>
  );
};
