import { cartConstants } from "../../constants";
import { cartService } from "../../services";
import { alertActions } from "../alert.actions";
import { deleteCartItem, saveCart, updateCart } from "../../helpers";

export const cartActions = {
  // getSingleParameter,
  // getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
// function getSingleParameter(id) {
//   return (dispatch) => {
//     dispatch(request(id));

//     storeItemService.getSingleParameter(id).then(
//       (parameter) => {
//         dispatch(success(parameter));
//       },
//       (error) => {
//         dispatch(failure(error));
//       }
//     );
//   };

//   function request(parameter) {
//     return { type: cartConstants.GET_SINGLE_REQUEST, parameter };
//   }
//   function success(parameter) {
//     return { type: cartConstants.GET_SINGLE_SUCCESS, parameter };
//   }
//   function failure(error) {
//     return { type: cartConstants.GET_SINGLE_FAILURE, error };
//   }
// }

// // get all parameter
// function getAllParameters() {
//   return (dispatch) => {
//     dispatch(request());

//     storeItemService.getAllParameters().then(
//       (items) => dispatch(success(items)),
//       (error) => dispatch(failure(error))
//     );
//   };

//   function request(params) {
//     return { type: cartConstants.GET_ALL_REQUEST, params };
//   }
//   function success(items) {
//     return { type: cartConstants.GET_ALL_SUCCESS, items };
//   }
//   function failure(error) {
//     return { type: cartConstants.GET_ALL_FAILURE, error };
//   }
// }

// add parameter
function addParameter(item) {
  return (dispatch) => {
    dispatch(request(item));

    cartService.addParameter(item).then(
      (parameter) => {
        dispatch(success(parameter));

        // save cart item
        saveCart(parameter);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(parameter) {
    return { type: cartConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: cartConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: cartConstants.ADD_FAILURE, error };
  }
}

// update parameter
function updateParameter(item) {
  return (dispatch) => {
    dispatch(request(item));

    cartService.updateParameter(item).then(
      (parameter) => {
        dispatch(success(parameter));

        // updat cart item
        updateCart(parameter?.item);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(parameter) {
    return { type: cartConstants.UPDATE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: cartConstants.UPDATE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: cartConstants.UPDATE_FAILURE, error };
  }
}

// delete parameter
function deleteParameter(item) {
  return (dispatch) => {
    dispatch(request(item));

    cartService.deleteParameter(item).then(
      (parameter) => {
        dispatch(success(parameter));

        // delete cart item
        deleteCartItem(parameter?.item?.id);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(parameter) {
    return { type: cartConstants.DELETE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: cartConstants.DELETE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: cartConstants.DELETE_FAILURE, error };
  }
}

// clear parameter
function clear() {
  return { type: cartConstants.CLEAR };
}
