import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  styled,
  Table,
  Typography,
} from "@mui/material";
import Intro from "../../../atoms/Intro/Intro.component";
import EditIcon from "@mui/icons-material/Edit";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { convertToPlaces } from "../../../../helpers/helpers";
import { authHeader } from "../../../../helpers";
import { apiConstants } from "../../../../constants";

let breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" to="/admin/factory-invoice">
    All Factory Invoices
  </Link>,
  <Typography key="3">{"View Factory Invoice"}</Typography>,
];

const StyledHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  height: "93px",
  paddingLeft: "10px",
  paddingTop: "10px",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  padding: "20px",
}));

const StyledTableCell = styled(TableCell)({
  width: "200px",
  fontWeight: 700,
});

const StyledTypography = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
});

const StyledItalic = styled("i")({
  fontSize: "12px",
});

const StyledLinked = styled(Typography)({
  textDecoration: "underline",
  textUnderlineOffset: "0.2em",
  cursor: "pointer",
});

const StyledImageContainer = styled("div")({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "100%",
  height: "auto",
});
const FactoryInvoiceDetailView = () => {
  const { id } = useParams();
  const [response, setResponse] = useState("");

  let gst = 0;
  let totalPrice = 0;
  let originalTotal = 0;
  let extraCharges = 0;
  let totalDelivery = 0;

  response?.sales_order_items?.map((item) => {
    totalPrice = totalPrice + item?.total_price;
    originalTotal = originalTotal + item?.original_total_price;
    gst = gst + item?.gst_amount || 0;
    totalDelivery = totalDelivery + item?.estimated_shipping_charges || 0;
    extraCharges = extraCharges + item?.extra_charges || 0;
    return "";
  });
  const totalDiscount = originalTotal - totalPrice || 0;
  const grandTotal =
    gst + totalPrice + extraCharges + totalDelivery - totalDiscount;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/factory-invoices/${id}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setResponse(data?.data);
      });
    });
  }, []);

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ pr: 2 }}>
        <Intro
          pageTitle={"View Factory Invoice"}
          pageTitleShort={"View Factory Invoice"}
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
        />
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        // sx={{
        //     padding: admin ? 0 : "40px 25px 40px 40px;",
        //     backgroundColor: admin ? "#fff" : "#F4F4F4",
        // }}
      >
        <div>
          <Card style={{ minWidth: "500px" }}>
            <Stack direction={"column"} justifyContent="center">
              <StyledHeader>
                <Typography fontSize={"24px"}>Items</Typography>
              </StyledHeader>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: "56px" }}>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell align="right">
                        Unit Price
                      </StyledTableCell>
                      <StyledTableCell align="right">Quantity</StyledTableCell>
                      <StyledTableCell align="right">Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response?.sales_order_items?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item?.price?.item?.name
                            ? item?.price?.item?.name +
                              " - " +
                              item?.price?.fabric?.name
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {convertToPlaces(item?.unit_price)}
                        </TableCell>
                        <TableCell align="right">{item?.qty}</TableCell>
                        <TableCell align="right">
                          {convertToPlaces(item?.total_price)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          </Card>
        </div>
        <Stack direction={"column"} style={{ minWidth: "375px" }} spacing={3}>
          <StyledCard>
            <Stack textAlign={"left"} spacing={3}>
              <Typography fontSize={"20px"}>Invoice</Typography>
              <Stack spacing={1}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledTypography>Add-ons</StyledTypography>
                  <StyledTypography>
                    {convertToPlaces(extraCharges)}
                  </StyledTypography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledTypography>Discounts applied</StyledTypography>
                  <StyledTypography>
                    {convertToPlaces(totalDiscount)}
                  </StyledTypography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledTypography>Total Excluding Tax</StyledTypography>
                  <StyledTypography>
                    {convertToPlaces(totalPrice)}
                  </StyledTypography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledTypography>Delivery</StyledTypography>
                  <StyledTypography>
                    {convertToPlaces(totalDelivery)}
                  </StyledTypography>
                </Stack>
                <Divider style={{ backgroundColor: "#E0E0E0" }} />
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledTypography>GST</StyledTypography>
                  <StyledTypography>{convertToPlaces(gst)}</StyledTypography>
                </Stack>
                <Divider style={{ backgroundColor: "#E0E0E0" }} />
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledTypography>Total</StyledTypography>
                  <StyledTypography>
                    {convertToPlaces(grandTotal)}
                  </StyledTypography>
                </Stack>
              </Stack>
            </Stack>
          </StyledCard>
        </Stack>
      </Stack>
    </>
  );
};

export default FactoryInvoiceDetailView;
