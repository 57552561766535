import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alertActions,
  paymentTermActions,
  bankDetailActions,
  deliveyFeeActions,
  fabricActions,
  gstActions,
  itemActions,
  labelActions,
  patternActions,
  productionOrderActions,
  sportCategoryActions,
  liningActions,
  extraChargeActions,
  artworkChargeActions,
  sizeActions,
  measurementActions,
  coloryActions,
} from "../../../../../../actions";
import { If, Else, Then } from "react-if";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  CircularProgress,
  Box,
  Alert,
} from "@mui/material";
import {
  HelpOutline,
  ErrorOutline,
  CheckCircleOutline,
} from "@mui/icons-material";
import {authHeader} from "../../../../../../helpers";
import {apiConstants} from "../../../../../../constants";
import AlertToast from "../../../../Toast";
import AlertToastSuccess from "../../../../ToastSuccess";

const DeleteDialog = (props) => {
  const {
    record,
    deleteDialogOpen,
    handleDeleteDialog,
    recordIdentifier,
    columns,
    actionIdentifier,
  } = props;

  const dispatch = useDispatch();
  const alertState = useSelector((state) => state.alert);
  const bankDetailsState = useSelector((state) => state.bankDetailsState);
  const deliveryFeesState = useSelector((state) => state.deliveryFees);
  const fabricsState = useSelector((state) => state.fabrics);
  const gstState = useSelector((state) => state.gst);
  const itemState = useSelector((state) => state.items);
  const labelState = useSelector((state) => state.labels);
  const patternsState = useSelector((state) => state.patterns);
  const productionOrderState = useSelector((state) => state.productionOrders);
  const paymentTermsState = useSelector((state) => state.paymentTerms);
  const sportsCategoryState = useSelector((state) => state.sportsCategories);
  const liningState = useSelector((state) => state.linings);
  const extraChargesState = useSelector((state) => state.extraCharges);
  const artworkChargesState = useSelector((state) => state.artworkCharges);
  const sizesState = useSelector((state) => state.sizes);
  const measurementsState = useSelector((state) => state.measurements);
  const colorsState = useSelector((state) => state.colors);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const handleToastClose2 = () => setToastState2({ open: false });
  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastState2, setToastState2] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const paramTypes = [
    {
      name: "bankDetailActions",
      action: bankDetailActions,
      state: bankDetailsState,
    },
    {
      name: "coloryActions",
      action: coloryActions,
      state: colorsState,
    },
    {
      name: "deliveyFeeActions",
      action: deliveyFeeActions,
      state: deliveryFeesState,
    },
    {
      name: "fabricActions",
      action: fabricActions,
      state: fabricsState,
    },
    {
      name: "gstActions",
      action: gstActions,
      state: gstState,
    },
    {
      name: "itemActions",
      action: itemActions,
      state: itemState,
    },
    {
      name: "labelActions",
      action: labelActions,
      state: labelState,
    },
    {
      name: "patternActions",
      action: patternActions,
      state: patternsState,
    },
    {
      name: "productionOrderActions",
      action: productionOrderActions,
      state: productionOrderState,
    },
    {
      name: "paymentTermActions",
      action: paymentTermActions,
      state: paymentTermsState,
    },
    {
      name: "sportCategoryActions",
      action: sportCategoryActions,
      state: sportsCategoryState,
    },
    {
      name: "liningActions",
      action: liningActions,
      state: liningState,
    },
    {
      name: "extraChargeActions",
      action: extraChargeActions,
      state: extraChargesState,
    },
    {
      name: "artworkChargeActions",
      action: artworkChargeActions,
      state: artworkChargesState,
    },
    {
      name: "sizeActions",
      action: sizeActions,
      state: sizesState,
    },
    {
      name: "measurementActions",
      action: measurementActions,
      state: measurementsState,
    },
    {
      name: "coloryActions",
      action: coloryActions,
      state: colorsState,
    },{
      name: "position",
    },
  ];
  const currentParamType = paramTypes.filter(
    (a) => a.name === actionIdentifier
  );

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  useEffect(() => {
    const updateOpen = () => {
      setOpen(deleteDialogOpen);
    };

    updateOpen();
  }, [deleteDialogOpen]);

  useEffect(() => {
    const handleAlert = () => {
      if (alertState?.type === "success") {
        setSuccess(true);
      } else if (alertState?.type === "error") {
        setFail(true);
      } else {
        setSuccess(false);
        setFail(false);
      }
    };

    handleAlert();
  }, [alertState]);

  const handleCancel = () => {
    handleDeleteDialog(false);
  };

  const handleClose = () => {
    handleCancel();
    setTimeout(() => {
      dispatch(alertActions.clear());
      dispatch(currentParamType[0].action.getAllParameters());
    }, 500);
  };

  const handleDelelete = () => {
    if (currentParamType[0].name==='position'){

      const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
      };

      fetch(`${apiConstants.API_URL}/positions/${record["id"]}`, requestOptions).then(
          (response) => {
            response.text().then((text) => {
              const data = text && JSON.parse(text);
              if (!response.ok) {
                if (response.status === 401) {
                  // logout()
                }
                const error = (data && data.error) || response.statusText;
                const errorMessage = Object.values(error).map((errorKey) => {
                  return errorKey[0];
                });
                setToastMessage(errorMessage);
                setToastState({
                  open: true,
                  vertical: "top",
                  horizontal: "right",
                });

              } else {
                setToastMessage("Deleted Successfully");
                setToastState2({
                  open: true,
                  vertical: "top",
                  horizontal: "right",
                });
                // window.history.back()
              }
            });
          }
      );
    }else {
      dispatch(currentParamType[0].action.deleteParameter(record["id"]));
    }

  };

  return (
    <>
      <AlertToast
          toastMessage={toastMessage}
          toastState={toastState}
          handleClose={handleToastClose}
      />
      <AlertToastSuccess
          toastMessage={toastMessage}
          toastState={toastState2}
          handleClose={handleToastClose2}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <If condition={success || fail}>
          <Then>
            {success && (
              <DialogTitle id="alert-dialog-title">
                <CheckCircleOutline
                  color="success"
                  sx={{
                    position: "relative",
                    top: "0.25rem",
                    mr: 1,
                    display: { xs: "block", sm: "inline-block" },
                    margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
                  }}
                />
                {`Success!`}
              </DialogTitle>
            )}
            {fail && (
              <DialogTitle id="alert-dialog-title">
                <ErrorOutline
                  color="error"
                  sx={{
                    position: "relative",
                    top: "0.25rem",
                    mr: 1,
                    display: { xs: "block", sm: "inline-block" },
                    margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
                  }}
                />
                {`Failed!`}
              </DialogTitle>
            )}
          </Then>
          <Else>
            {record && (
              <DialogTitle id="alert-dialog-title">
                <HelpOutline
                  color="error"
                  sx={{
                    position: "relative",
                    top: "0.25rem",
                    mr: 1,
                    display: { xs: "block", sm: "inline-block" },
                    margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
                  }}
                />
                {`Are you sure you want to delete the parameter?`}
              </DialogTitle>
            )}
          </Else>
        </If>

        <DialogContent>
          {record && !success && !fail && (
            <DialogContentText id="alert-dialog-description">
              You are about to delete the parameter{" "}
              <strong>{record[recordIdentifier]}</strong> from the database.
              <br />
              Please confirm your action.
            </DialogContentText>
          )}
          <DialogContentText>
            {/* <List>
              {record &&
                Object.entries(record).map(([key, value], i) => {
                  const r = columns.filter((column) => column.field === key);

                  return !r[0].hide ? (
                    <>
                      <ListItem key={key}>
                        <ListItemText
                          primary={r[0].headerName}
                          secondary={value}
                        />
                      </ListItem>
                    </>
                  ) : null;
                })}
            </List> */}

            {currentParamType[0]?.state?.loading && (
              <Box>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pt: 6, pb: 2 }}
                >
                  <CircularProgress />
                </Stack>
              </Box>
            )}

            {success && (
              <Alert severity="success" icon={false}>
                {alertState?.message?.data}
              </Alert>
            )}

            {fail && (
              <Alert severity="error" icon={false}>
                Error deleting parameter
                {/* {alertState?.message} */}
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            {success || fail ? (
              <Button onClick={handleClose}>Close</Button>
            ) : (
              <>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                  onClick={handleDelelete}
                  autoFocus
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
              </>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
