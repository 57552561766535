import { Box, Button, Modal, Stack, styled, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { convertToPlaces } from "../../helpers/helpers";

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "30%",
  left: "30%",
  width: "700px",
  height: "204px",
  justifyContent: "space-evenly",
  display: "flex",
  flexDirection: "column",
  padding: "10px",
};

const StyledIcon = styled(ErrorTwoToneIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderRadius: "100px",
  fontSize: "20px",
}));

const StyledTheaders = styled(TableCell)({
  fontSize: "14px",
  padding: "10px",
});

const SuccessModal = ({ ...props }) => {
  const { open, handleClose, item } = props;

  return (
    <>
      <StyledIcon onClick={() => handleClose(!open)} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            spacing={2}
            direction={"column"}
            justifyContent="space-between"
          >
            <Typography fontSize={"20px"}>{item?.item?.name}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTheaders>Unit Price</StyledTheaders>
                  <StyledTheaders>Quantity</StyledTheaders>
                  <StyledTheaders>Price</StyledTheaders>
                  <StyledTheaders>Discount(%)</StyledTheaders>
                  <StyledTheaders>Discount Amount</StyledTheaders>
                  <StyledTheaders>Discounted Price</StyledTheaders>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {convertToPlaces(item?.original_unit_price)}
                  </TableCell>
                  <TableCell>{item?.qty}</TableCell>
                  <TableCell>
                    {convertToPlaces(item?.original_total_price)}
                  </TableCell>
                  <TableCell>
                    {Math.round(
                      ((item?.unit_discount * item?.qty) /
                        item?.original_total_price) *
                        100
                    )}
                    %
                  </TableCell>
                  <TableCell>
                    {convertToPlaces(item?.unit_discount * item?.qty)}
                  </TableCell>
                  <TableCell>{convertToPlaces(item?.total_price)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Button onClick={() => handleClose(!open)}>CLOSE</Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

SuccessModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  item: PropTypes.object,
};

SuccessModal.defaultProps = {
  open: false,
  item: {},
};

export default SuccessModal;
