import React, { useEffect, useState } from "react";
import { Stack, CircularProgress, Skeleton, CardMedia } from "@mui/material";
import { authHeader } from "../../../../helpers";
import { apiConstants } from "../../../../constants";

const GarmentImage = (props) => {
  const {
    id,
    organizationId,
    thumbnail,
    setHover = () => {},
    minHeight = 250,
    padding = 0,
    maxWidth,
    shadow = true,
  } = props;

  const [itemImage, setItemImage] = useState(thumbnail);
  const [itemImageLoading, setItemImageLoading] = useState(false);

  // get image url from production order
  useEffect(() => {
    const getItemImage = () => {
      if (thumbnail && thumbnail !== "") {
        setItemImage(thumbnail);
      } else {
        if (itemImage === "" || (itemImage === undefined && organizationId)) {
          setItemImageLoading(true);

          const requestOptions = {
            method: "GET",
            headers: authHeader(),
          };
          fetch(
            `${apiConstants.API_URL}/store-production-orders?organization.id=${organizationId}`,
            requestOptions
          ).then((response) => {
            response.text().then((text) => {
              const data = text && JSON.parse(text);

              const filteredItem = data?.data.filter(
                (storeitem) => storeitem?.id === parseInt(id)
              );

              setItemImage(filteredItem[0]?.items[0]?.image_url || "");
              setItemImageLoading(false);
            });
          });
        }
      }
    };

    getItemImage();
  }, [id, itemImage, organizationId, thumbnail]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: minHeight,
        boxShadow: shadow ? "0 0 7px 0px rgba(0,0,0,0.1)" : "none",
        borderRadius: 2,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {itemImageLoading && (
        <>
          <CircularProgress />
          <Skeleton
            variant="rectangular"
            sx={{
              height: "100%",
              display: "block",
              width: "100%",
              position: "absolute",
              borderRadius: 2,
            }}
          />
        </>
      )}

      {!itemImageLoading && itemImage && (
        <CardMedia
          component="img"
          height=""
          image={itemImage}
          alt="item image"
          sx={{
            minHeight: minHeight,
            padding: padding,
            maxWidth: maxWidth,
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      )}
    </Stack>
  );
};

export default GarmentImage;
