import { storeItemConstants } from "../../constants";

const initialState = {
  loading: false,
  items: [],
  current: null,
  error: null,
};

export function storeItems(state = initialState, action) {
  switch (action.type) {
    // get all
    case storeItemConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case storeItemConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items,
        current: null,
        error: null,
      };
    case storeItemConstants.GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // get single
    case storeItemConstants.GET_SINGLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case storeItemConstants.GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.parameter,
        error: null,
      };
    case storeItemConstants.GET_SINGLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // add
    case storeItemConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case storeItemConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case storeItemConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case storeItemConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case storeItemConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case storeItemConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case storeItemConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case storeItemConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case storeItemConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case storeItemConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
