import React from "react";
import { Grid, Typography } from "@mui/material";
import SpecImageDialog from "../SpecImageDialog/SpecImageDialog,component";
import styles from "./SpecImage.module.scss";

const itemData = [
  {
    img: "https://i.ibb.co/89yhRNP/sizechart-US-men.jpg",
    title: "Breakfast",
  },
  {
    img: "https://i.ibb.co/dWgZ5VM/sizechart-US-women.jpg",
    title: "Burger",
  },
];

const SpecImage = (props) => {
  const images = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Typography>Size Guide</Typography>
      </div>

      <Grid container spacing={2}>
        {images?.images.length !== 0 &&
          images?.images.map((item, i) => (
            <Grid item key={`img${i}`} xs={12} md={6}>
              <SpecImageDialog img={item} />
            </Grid>
          ))}
      </Grid>

      {/* <ImageList cols={2}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList> */}
    </div>
  );
};

export default SpecImage;
