import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, Typography, ButtonGroup, Button } from "@mui/material";
import AddShoppingCartTwoToneIcon from "@mui/icons-material/AddShoppingCartTwoTone";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";

const ItemDetails = ({
  currentItem,
  isInCart,
  handleAddtoCart,
  handleUpdateCart,
  handleRemoveFromCart,
  itemTotal,
  unitprice,
  quantity,
}) => {
  const navigate = useNavigate();

  const formattedTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AUD",
  }).format(itemTotal);

  const formattedUnitPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AUD",
  }).format(unitprice);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Box>
        {currentItem?.name && (
          <Typography variant="h3" sx={{ fontSize: "1.6rem", fontWeight: 600 }}>
            {currentItem?.name}
          </Typography>
        )}
        {currentItem?.style && (
          <Typography variant="caption">Style #{currentItem?.style}</Typography>
        )}
        <Stack direction="row">
          {formattedTotal && (
            <Typography variant="subtitle1" sx={{ fontWeight: 700, mr: 2 }}>
              Price: {formattedTotal}
            </Typography>
          )}
          {formattedUnitPrice && quantity && (
            <Typography variant="subtitle2" sx={{ mt: 0.4 }}>
              ({formattedUnitPrice}/per item * {quantity})
            </Typography>
          )}
        </Stack>
      </Box>

      <Box>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          {isInCart ? (
            <>
              <Button
                color="primary"
                size="large"
                endIcon={<AddShoppingCartTwoToneIcon />}
                onClick={() => {
                  handleUpdateCart();
                }}
                sx={{ py: "0.6rem" }}
              >
                Update Cart
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              size="large"
              endIcon={<AddShoppingCartTwoToneIcon />}
              onClick={() => {
                handleAddtoCart();
              }}
              sx={{ py: "0.6rem" }}
            >
              Add To Cart
            </Button>
          )}

          <Button
            color="secondary"
            size="large"
            onClick={() => {
              navigate("/store/cart");
            }}
            sx={{ py: "0.6rem" }}
          >
            Go to Checkout
          </Button>
        </ButtonGroup>

        {isInCart && (
          <Button
            color="error"
            size="small"
            variant="text"
            endIcon={<RemoveShoppingCartOutlinedIcon />}
            onClick={() => {
              handleRemoveFromCart();
            }}
            sx={{
              py: "0.6rem",
              boxShadow: "none",
              mt: 1,
              mb: 1,
              ml: "auto",
              display: "flex",
            }}
          >
            Remove from Cart
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default ItemDetails;
