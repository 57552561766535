import { Typography } from '@mui/material';
import * as React from 'react';
import styled from '@emotion/styled';

const StyledTypography = styled(Typography)`
   color: ${props => {
        switch (props.status) {
            case 'approved':
            return '#2AB5BF'
            case 'accepted':
            return '#2AB5BF'
            case 'pending':
            return '#000000'
            case 'paid':
            return '#69C464'
            case 'partially-paid':
            return '#2ab5bf'
            case 'rejected':
            return 'red'
            case 'canceled':
            return 'red'
            case 'completed':
            return '#69C464'
            default:
            return 'black'
        }
   }};
   font-size:14px;
`;

const StatusChange = ({...props}) => {
    const {row, component} = props;
    return(
        <>
        {component === 'salesOrder' &&
            <StyledTypography status={row.status.keyword}>{row.status.name}</StyledTypography>
        }
        {row?.quotation_status && 
            <StyledTypography status={row.quotation_status.keyword}>{row.quotation_status.name}</StyledTypography>
        }
        {component === 'invoice' &&
            <StyledTypography status={row.status.keyword} >{row.status.keyword === 'pending' ? 'Invoice Sent' : row.status.name }</StyledTypography>
        }
        {component === 'productionOrders' &&
            (row.status.keyword === 'completed' ? 
            <StyledTypography status={row.status.keyword}>{'Completed'}</StyledTypography>
            :
            <StyledTypography status={row.status.keyword}>{row.status.name}</StyledTypography>
            )
            //<StyledTypography status={row.status.keyword}>{row.status.name}</StyledTypography>
        }

        </>
    );
}

export default StatusChange;