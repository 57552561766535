import * as React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Box, Button } from "@mui/material";
import OrderItemDetailsComponent from "./OrderItemDetails";
import FabricDetailsComponent from "./FabricDetailsComponent";
import SizeAndNamesComponent from "./SizeAndNamesComponent";
import LabelsComponent from "./LabelComponent";
import SpecialInstructionComponent from "./SpecialInstructionComponent";
import SpecificationComponent from "./SpecificationComponent";
import EmproideryComponent from "./EmproideryComponent";
import { ArrowForward } from "@mui/icons-material";
import TrimDetailsComponent from "./TrimDetailsComponent";
import { PreProductionApprovalDetailsComponent } from "./PreProdDetailsComponent";
import { PRE_PRODUCTION_APPROVAL } from "../../../constants/Constants";
import SublimationComponent from "./SublimationComponent";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ButtonStyle = styled(Button)({
  boxShadow: "none",
  border: "1px solid",
  backgroundColor: "#69C464",
  borderColor: "#69C464",
  width: "50%",
  height: "70px",
  fontWeight: "700",
  fontSize: 16,
  color: "#fff",
  "&:hover": {
    borderColor: "#69C464",
    backgroundColor: "#69C464",
    color: "#fff",
  },
  "&:disabled": {
    borderColor: "#5E5E5E",
    backgroundColor: "#5E5E5E",
    color: "#fff",
    opacity: 0.5,
  },
  "&:active": {},
  "&:focus": {},
});

const OrderItemsContainer = (props) => {
  const {
    id,
    productionOrder,
    activeItem,
    colors,
    fabrics,
    trims,
    poFields,
    nameAndSizes,
    patterns,
    setPatternId,
    sizeCounts,
    labels,
    selectedPattern,
    selectedPatternObj,
    patternSizeAdultHeader,
    patternSizeChildHeader,
    selectedPatternImage,
    setSpecialComment,
    specialComment,
    handleSubmit,
    handleStoreStages,
    stages,
    activeStep,
    handleProductionStatus,
    productionStatus,
    buttonText,
    setActiveItem,
    positions,
    preProdApprovals,
    handleTemplateChange,
    templates,
  } = props;
  const passedStageData = activeItem?.sales_order_item_passed_stages;

  const disabledBtn =
    productionStatus === "Approved" &&
    passedStageData[passedStageData?.length - 1]?.stage.id !== 5;
  const isPrototypeApproved =
    preProdApprovals.prototypeApproval?.status ===
    PRE_PRODUCTION_APPROVAL.STATUS.APPROVED;

  const isSkipped =
    preProdApprovals.prototypeApproval?.status ===
    PRE_PRODUCTION_APPROVAL.STATUS.SKIPPED;

  return (
    <Content>
      <OrderItemDetailsComponent
        id={id}
        productionOrder={productionOrder}
        activeItem={activeItem}
        handleStoreStages={handleStoreStages}
        stages={stages}
        activeStep={activeStep}
        productionStatus={productionStatus}
        handleTemplateChange={handleTemplateChange}
        template={templates[activeItem?.id]}
      />
      <Box sx={{ padding: "0 16px 50px", position: "relative" }}>
        {colors?.length && fabrics.length && poFields?.length && (
          <FabricDetailsComponent
            activeItem={activeItem}
            colors={colors}
            fabrics={fabrics}
            poFields={poFields}
            handleSubmit={handleSubmit}
            positions={positions}
            template={templates[activeItem?.id]}
          />
        )}
        {colors?.length && trims.length && poFields?.length && (
          <TrimDetailsComponent
            activeItem={activeItem}
            colors={colors}
            trims={trims}
            poFields={poFields}
            handleSubmit={handleSubmit}
            setActiveItem={setActiveItem}
            positions={positions}
            template={templates[activeItem?.id]}
          />
        )}
        {nameAndSizes?.length && sizeCounts?.length && (
          <SizeAndNamesComponent
            activeItem={activeItem}
            nameAndSizes={nameAndSizes}
            sizeCounts={sizeCounts}
          />
        )}
        {labels?.length && (
          <LabelsComponent
            labels={labels}
            handleSubmit={handleSubmit}
            activeItem={activeItem}
          />
        )}
        <SpecialInstructionComponent
          setSpecialComment={setSpecialComment}
          specialComment={specialComment}
          handleSubmit={handleSubmit}
        />
        {patterns?.length && (
          <SpecificationComponent
            patterns={patterns}
            setPatternId={setPatternId}
            selectedPattern={selectedPattern}
            selectedPatternObj={selectedPatternObj}
            patternSizeAdultHeader={patternSizeAdultHeader}
            patternSizeChildHeader={patternSizeChildHeader}
            selectedPatternImage={selectedPatternImage}
            handleSubmit={handleSubmit}
          />
        )}
        <PreProductionApprovalDetailsComponent
          preProdApprovals={preProdApprovals}
        />
        <EmproideryComponent />
        <SublimationComponent />
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <ButtonStyle
            variant="contained"
            onClick={handleProductionStatus}
            disabled={disabledBtn || !(isPrototypeApproved || isSkipped)}
          >
            {buttonText}
            <ArrowForward sx={{ marginLeft: "12px" }} />
          </ButtonStyle>
        </Box>
      </Box>
    </Content>
  );
};

export default OrderItemsContainer;
