import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { ArrowForwardIos, ArrowForward } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1A2027",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.secondary.light,
  textAlign: "left",
}));

const ButtonStyle = styled(Button)({
  boxShadow: "none",
  border: "1px solid",
  backgroundColor: "#69C464",
  borderColor: "#69C464",
  width: "100%",
  height: "70px",
  fontWeight: "700",
  fontSize: 16,
  color: "#fff",
  "&:hover": {
    borderColor: "#69C464",
    backgroundColor: "#69C464",
    color: "#fff",
  },
  "&:disabled": {
    borderColor: "#5E5E5E",
    backgroundColor: "#5E5E5E",
    color: "#fff",
    opacity: 0.5,
  },
  "&:active": {},
  "&:focus": {},
});

const useStyles = makeStyles({
  productionSidebarContainer: {
    p: 0,
    position: "sticky",
    top: 0,
    height: "100vh",
    overflow: "auto",
  },
  typography: {
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
  sidebarButtonContainer: {
    padding: "12px 10px 18px",
  },
  sidebarList: {
    position: "relative",
    overflow: "auto",
    height: "auto",
  },
  sidebarHeaderContainer: {
    padding: "16px 16px 32px",
  },
  sidebarFooterContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  itemStatus: {
    width: "100px",
    borderRadius: "16px",
    textAlign: "center",
    padding: "3px 0px 3px",
    marginRight: "5px",
  },
});

const ProductionOrderViewSideBar = (props) => {
  const classes = useStyles();
  const {
    productionOrderItems,
    activeItemId,
    setActiveItem,
    buttonText,
    productionStatus,
    activeItem,
    handleShippingDetails,
  } = props;

  const handleListItemClick = (event, id) => {
    setActiveItem(id);
  };

  const allItemsLastStages = productionOrderItems.every(
    (i) => i?.status_id === 5
  );

  return (
    <Item className={classes.productionSidebarContainer}>
      <Box className={classes.sidebarHeaderContainer}>
        <Typography component="span" sx={{ fontSize: "14px" }}>
          Items in this order | 此顺序中的项目
        </Typography>
      </Box>

      <List
        component="nav"
        aria-label="secondary mailbox folder"
        className={classes.sidebarList}
      >
        {productionOrderItems.map((item) => (
          <>
            <ListItemButton
              key={item?.id}
              selected={item.id === activeItemId}
              onClick={(event) => handleListItemClick(event, item.id)}
            >
              <ListItemText
                className={classes.typography}
                sx={{ color: "#fff", textTransform: "capitalize" }}
                primary={item.style}
                secondary={
                  <Typography component="span" variant="body2" color="#fff">
                    {item.chineseName}
                  </Typography>
                }
              />
              <Box
                className={classes.itemStatus}
                sx={{
                  background:
                    item?.status === "pending" ? "#5E5E5E" : "#1B5E20",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    textTransform: "capitalize",
                    fontSize: 12,
                  }}
                >
                  {item?.status === "completed" ? "confirmed" : item?.status}
                </Typography>
              </Box>
              <ArrowForwardIos sx={{ fontSize: "14px" }} />
            </ListItemButton>
            <Divider variant="middle" sx={{ borderColor: "#3d3d3d" }} />
          </>
        ))}
      </List>
      <Box className={classes.sidebarFooterContainer}>
        {/* <Box sx={{ textAlign:'center', margin:'18px 0 18px'}}>
                    <CircularStatic/>
                </Box> */}

        {/* <Box sx={{ textAlign:'center',}}>
                    <Typography sx={{fontSize:11, color:'#fff', opacity:0.7}}>
                        {`0 out of ${productionOrderItems.length} designs complete`} 
                    </Typography>
                    <Typography sx={{fontSize:11, color:'#fff', opacity:0.7}}>
                    {`${productionOrderItems.length} 个设计中有 0 个参与竞争`}
                    </Typography>
                </Box> */}

        <Box className={classes.sidebarButtonContainer}>
          <ButtonStyle
            variant="contained"
            onClick={handleShippingDetails}
            disabled={!allItemsLastStages}
          >
            Shipping
            <ArrowForward sx={{ marginLeft: "12px" }} />
          </ButtonStyle>
        </Box>
      </Box>
    </Item>
  );
};
export default ProductionOrderViewSideBar;
