import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { trimsActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const TrimsList = () => {
  const dispatch = useDispatch();
  const trims = useSelector((state) => state.trims);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "chinese_name",
      headerName: "Chinese Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(trimsActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {trims && (
        <DataTable
          loading={trims?.loading}
          rows={trims?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="trimsActions"
        />
      )}
    </Box>
  );
};

export default TrimsList;
