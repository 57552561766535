import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, CircularProgress, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ViewRecordDialog from "./ViewRecordDialog/ViewRecordDialog.component";
import DeleteDialog from "./DeleteDialog/DeleteDialog.component";
import RowActions from "./RowActions/RowActions.component";

const DataTable = (props) => {
  const {
    loading,
    rows,
    columns,
    recordIdentifier,
    actionIdentifier,
    recordType,
    isEnabledView = true,
    isEnabledEdit = false,
    isEnabledDelete = true,
    paginationData = {},
    handlePageChange,
    hasSeparateView,
    search = "",
  } = props;

  const [columnsFormatted, setColumnsFormatted] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [filteredRows, setFilteredRows] = useState();

  const navigate = useNavigate();

  const handleViewDialog = (response) => {
    setViewDialogOpen(response);
  };

  const handleDeleteDialog = (response) => {
    setDeleteDialogOpen(response);
  };

  useEffect(() => {
    const updateColumns = () => {
      const c = [];

      columns.map((column, i) => c.push(columns[i]));
      c.push({
        field: "row_actions",
        headerName: "Actions",
        width: 120,
        sortable: false,
        disableClickEventBubbling: true,

        renderCell: (params) => {
          const actionView = (e) => {
            setCurrentRecord(params.row);

            if (hasSeparateView && hasSeparateView.active) {
              if (hasSeparateView?.param) {
                console.log(
                  "params",
                  `${params["row"][hasSeparateView?.param]}`
                );
                navigate(
                  `${params["row"][hasSeparateView?.param]}` +
                    (hasSeparateView?.suffix || "")
                );
              } else {
                navigate(`${params?.row?.name}`);
              }
            } else {
              handleViewDialog(true);
            }
          };

          const actionEdit = (e) => {
            setCurrentRecord(params.row);
            navigate(`./${params?.row?.id}/edit`, {
              state: {
                param: params.row,
                mode: "edit",
              },
            });
          };

          const actionDelete = (e) => {
            setCurrentRecord(params.row);
            handleDeleteDialog(true);
          };

          const options = [
            {
              label: "View",
              action: actionView,
              active: isEnabledView,
            },
            {
              label: "Edit",
              action: actionEdit,
              active: isEnabledEdit,
            },
            {
              label: "Delete",
              action: actionDelete,
              active: isEnabledDelete,
            },
          ];

          return <RowActions row={params.row} options={options} />;
        },
      });

      setColumnsFormatted(c);
    };

    updateColumns();
  }, [columns]);

  // search
  useEffect(() => {
    const filterData = () => {
      if (rows?.length > 0 && search !== "") {
        const filtered = rows.filter((row) =>
          row?.name?.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredRows(filtered);
      } else {
        setFilteredRows(rows);
      }
    };

    filterData();
  }, [rows, search]);

  return (
    <Box>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 3, pb: 6 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        rows &&
        columnsFormatted && (
          <DataGrid
            rows={search !== "" ? filteredRows : rows}
            autoHeight={true}
            onCellClick={(e) => e.stopPropagation}
            // rowsPerPageOptions={[5]}
            pageSize={paginationData?.per_page ? paginationData?.per_page : 30}
            pagination
            rowCount={
              paginationData?.total ? paginationData?.total : rows?.length
            }
            page={page}
            onPageChange={(newPage) => {
              if (paginationData?.per_page) {
                handlePageChange(newPage);
              }
              setPage(newPage);
            }}
            columns={columnsFormatted}
            paginationMode={paginationData?.per_page ? "server" : "client"}
          />
        )
      )}

      <ViewRecordDialog
        record={currentRecord}
        viewDialogOpen={viewDialogOpen}
        handleViewDialog={handleViewDialog}
        columns={columns}
        recordIdentifier={recordIdentifier}
        recordType={recordType}
      />

      <DeleteDialog
        record={currentRecord}
        deleteDialogOpen={deleteDialogOpen}
        handleDeleteDialog={handleDeleteDialog}
        recordIdentifier={recordIdentifier}
        columns={columns}
        actionIdentifier={actionIdentifier}
      />
    </Box>
  );
};

export default DataTable;
