import { productionOrderConstants } from "../../constants";
import { productionOrderService } from "../../services";
import { alertActions } from "../alert.actions";

export const productionOrderActions = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter() {}

// get all parameter
function getAllParameters() {
  return (dispatch) => {
    dispatch(request());

    productionOrderService.getAllParameters().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error))
    );
  };

  function request(params) {
    return { type: productionOrderConstants.GET_ALL_REQUEST, params };
  }
  function success(items) {
    return { type: productionOrderConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: productionOrderConstants.GET_ALL_FAILURE, error };
  }
}

// add parameter
function addParameter(name, chinese_name, is_trim, status) {
  return (dispatch) => {
    dispatch(request(name, chinese_name, is_trim, status));

    productionOrderService.addParameter(name, chinese_name, is_trim, status).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: productionOrderConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: productionOrderConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: productionOrderConstants.ADD_FAILURE, error };
  }
}

// update parameter
function updateParameter(id, name, chinese_name, is_trim, status) {
  return (dispatch) => {
    dispatch(request(id, name, chinese_name, is_trim, status));

    productionOrderService.updateParameter(id, name, chinese_name, is_trim, status).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: productionOrderConstants.UPDATE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: productionOrderConstants.UPDATE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: productionOrderConstants.UPDATE_FAILURE, error };
  }
}

// delete parameter
function deleteParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    productionOrderService.deleteParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: productionOrderConstants.DELETE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: productionOrderConstants.DELETE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: productionOrderConstants.DELETE_FAILURE, error };
  }
}

// clear parameter
function clear() {
  return { type: productionOrderConstants.CLEAR };
}
