import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  Autocomplete,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../Theme/Style";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";

const SpecificationComponent = (props) => {
  const {
    patterns,
    setPatternId,
    selectedPattern,
    patternSizeAdultHeader,
    patternSizeChildHeader,
    selectedPatternImage,
    handleSubmit,
    selectedPatternObj,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const defaultProps = {
    options: patterns,
    getOptionLabel: (option) => option.code || "",
  };

  const handleChange = (e, value) => {
    setPatternId(value?.id);
  };

  const sizeHeaders = [
    ...new Set(
      selectedPattern
        .map((p) =>
          p.size_charts.map((s) =>
            Object.keys(s)
              .filter((s) => s.startsWith("s_"))
              .map((s) => s.replace("s_", ""))
          )
        )
        .flat(3)
    ),
  ];

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#2AB5BF" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Specification
          </Grid>
          <Grid item xs={4}>
            规格
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box>Select the Item Specification</Box>

        <Box sx={{ marginTop: "12px" }}>
          <Autocomplete
            {...defaultProps}
            id="controlled-demo"
            value={selectedPatternObj}
            autoComplete
            includeInputInList
            onChange={(event, value) => handleChange(event, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                label="Patterns | 模式"
                variant="standard"
                value={params.value}
              />
            )}
          />
        </Box>
        {selectedPatternImage?.image1 && (
          <Paper className={classes.specificationImageContainer}>
            <Grid container>
              <Grid item xs={6}>
                <img src={selectedPatternImage?.image1} height="300px" alt="" />
              </Grid>
              <Grid item xs={6}>
                {/* <img src="/trouser2.png" height="300px" alt="" /> */}
              </Grid>
            </Grid>
          </Paper>
        )}
        {selectedPattern?.length !== 0 && (
          <TableContainer
            sx={{ mt: 2 }}
            component={Paper}
            className={classes.specificationTableContainer}
          >
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead sx={{ backgroundColor: "#232323" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>Adults</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Code</TableCell>
                  {sizeHeaders.map((s, index) => (
                    <TableCell key={index} align="right" sx={{ color: "#fff" }}>
                      {s}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPattern?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell align="left">{row?.name}</TableCell>
                    <TableCell align="left">{row?.code}</TableCell>
                    {sizeHeaders.map((size, index) => (
                      <TableCell key={index} align="right">
                        {row.size_charts[0]["s_" + size]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {patternSizeChildHeader?.length !== 0 && (
          <TableContainer
            sx={{ mt: 2 }}
            component={Paper}
            className={classes.specificationTableContainer}
          >
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#232323" }}>
                  <TableCell sx={{ color: "#fff" }}>Kid</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{"Code"}</TableCell>
                  {patternSizeChildHeader?.map((s, index) => (
                    <TableCell key={index} align="right" sx={{ color: "#fff" }}>
                      {s}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPattern?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell align="left">{row?.name}</TableCell>
                    <TableCell align="left">{row?.code}</TableCell>
                    {row?.size_charts?.map(
                      (size, index) =>
                        size?.size?.flag_kid_size === 1 && (
                          <TableCell key={index} align="right">
                            {size?.value}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box className={classes.buttonContainer}>
          <Grid container>
            <Grid item xs={10}>
              <Button
                variant="contained"
                onClick={() => navigate("/admin/parameter-management/patterns")}
                size="medium"
                className={classes.submitBtn}
              >
                Go to patterns
              </Button>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            className={classes.submitBtn}
            onClick={() => handleSubmit(null, "pattern")}
          >
            {" "}
            Save{" "}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SpecificationComponent;
