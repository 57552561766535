import React, { useCallback, useEffect, useMemo } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { Add, Delete, Remove } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  fabricActions,
  productionOrderActions,
  trimsActions,
} from "../../../../../../actions";
import {
  useCreateProdOrderTemplateMutation,
  useEditProdOrderTemplateMutation,
  useLazyGetProdTemplateByIdQuery,
} from "../../../../../../services/api/parameters/production.order.template.service";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "../../../../../../Contexts/GlobalNotificationContext";

export const PaymentMethodForm = ({
  title,
  titleSingle,
  mode,
  paramdata,
  handleSuccessDialog,
  handleErrorAlert,
}) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const submitForm = useCallback(
    async ({ id, name, productionOderCategories, trimDetails }) => {
      try {
        if (mode === "add") {
          addNotification({
            message: `Templated Created successfully`,
            type: "success",
          });
        } else if (mode === "edit") {
          addNotification({
            message: `Templated Updated successfully`,
            type: "success",
          });
        }
      } catch (error) {
        addNotification({
          message: `Templated Saving Failed! Please try again`,
          type: "error",
        });
      }
    },
    [mode]
  );

  return (
    <Formik
      initialValues={{
        name: "",
      }}
      validationSchema={validationSchema}
      onSubmit={submitForm}
      enableReinitialize={true}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              maxWidth: 800,
            }}
          >
            <Stack direction="row" spacing={2} flexGrow={"revert"}>
              {mode !== "add" && (
                <Field
                  sx={{ flex: 1 }}
                  disabled
                  name="id"
                  as={TextField}
                  label="ID"
                  variant="outlined"
                  error={touched.id && Boolean(errors.id)}
                  helperText={touched.id && errors.id}
                />
              )}
              <Field
                disabled={mode === "view"}
                sx={{ flex: 1 }}
                name="name"
                as={TextField}
                label="Name"
                variant="outlined"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>

            {mode != "view" && (
              <LoadingButton type="submit" variant="contained" color="primary">
                {mode == "add" ? "Submit" : "Update"}
              </LoadingButton>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
