import React, { useEffect } from "react";
import { Switch, Case, Default } from "react-if";
import {
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide, Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SpecificationTable from "../../SpecificationTable/SpecificationTable.component";
import ImageList from "@mui/material/ImageList";
import SpecImage from "../../SpecificationTable/SpecImage/SpecImage.component";
import styles from "../../SpecificationTable/SpecImage/SpecImage.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewRecordDialog = (props) => {
  const {
    record,
    viewDialogOpen,
    handleViewDialog,
    columns,
    recordIdentifier,
    recordType,
  } = props;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const updateOpen = () => {
      setOpen(viewDialogOpen);
    };

    updateOpen();
  }, [viewDialogOpen]);

  const handleClose = () => {
    // setOpen(false);
    handleViewDialog(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} sx={{ position: "relative" }}>
          <Toolbar sx={{ px: { sm: 2 } }}>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {record && record[recordIdentifier]}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {record &&
            Object.entries(record).map(([key, value], i) => {
              const r = columns.filter((column) => {
                return column.field === key;
              });

              return !r[0]?.hide || r[0]?.showDetail ? (
                <div key={r[0]?.headerName}>
                  <ListItem>
                    <Switch>
                      <Case condition={r[0]?.headerName === "Status"}>
                        <ListItemText
                          primary={r[0]?.headerName}
                          secondary={value === 1 ? "Active" : "Deactive"}
                        />
                      </Case>
                      <Case condition={r[0]?.type === "boolean"}>
                        <ListItemText
                          primary={r[0]?.headerName}
                          secondary={value === 1 ? "Yes" : "No"}
                        />
                      </Case>
                      <Default>
                        <ListItemText
                          primary={r[0]?.headerName}
                          secondary={value}
                        />
                      </Default>
                    </Switch>

                    {/* <If condition={r[0]?.headerName === "Status"}>
                      <Then>
                        <ListItemText
                          primary={r[0]?.headerName}
                          secondary={value === 1 ? "Active" : "Deactive"}
                        />
                      </Then>
                      <Else>
                        <If condition={r[0]?.type === "boolean"}>
                          <Then>
                            <ListItemText
                              primary={r[0]?.headerName}
                              secondary={value === 1 ? "Yes" : "No"}
                            />
                          </Then>
                          <Else>
                            <ListItemText
                              primary={r[0]?.headerName}
                              secondary={value}
                            />
                          </Else>
                        </If>
                      </Else>
                    </If> */}
                  </ListItem>
                  <Divider />
                </div>
              ) : null;
            })}

          <Divider />
        </List>

        {recordType === "label" &&
        <Grid container spacing={2}>
          {record?.image_url ? (
              <Grid item xs={12} md={12} lg={12}>
                <div className={styles.title}>
                  <Typography>Label Image</Typography>
                </div>
                <div className={styles.title}>
                  <img
                      src={`${record?.image_url}`}
                      srcSet={`${record?.image_url}`}
                      alt=""
                      style={{display: "block", maxWidth: "40%", height: "auto"}}
                  />
                </div>
              </Grid>
          ) : null
          }
        </Grid>
        }

        {recordType === "patterns"  && (
          <SpecificationTable specId={record?.id} />
        )}
      </Dialog>
    </div>
  );
};

export default ViewRecordDialog;
