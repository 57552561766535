import { Buffer } from "buffer";

const encodeUser = (user) => {
  return Buffer.from(JSON.stringify(user)).toString("base64");
};

const decodeUser = (user) => {
  if (!user) return null;
  return JSON.parse(Buffer.from(user, "base64"));
};

const storeUser = (user) => {
  localStorage.setItem("user", encodeUser(user));
};

const removeUser = () => {
  localStorage.clear();
};

const getFullUser = () => {
  const user = localStorage.getItem("user");

  if (!user) return null;
  return decodeUser(user);
};

const updateUserLocally = (userDetails) => {
  const user = getFullUser();
  user.data = userDetails;
  storeUser(user);
};

const updatePermissionsLocally = (permissions) => {
  const user = getFullUser();

  if (!user) return null;

  user.data.roles[0].permissions = permissions;
  storeUser(user);
};

const getCurrentUser = () => {
  const user = getFullUser();

  if (!user) return null;

  return user.data;
};

const getUserPermissions = () => {
  const user = getCurrentUser();

  if (!user) return null;

  return user?.roles[0]?.permissions;
};

const getUserToken = () => {
  const user = getFullUser();

  if (!user) return null;

  return user.token;
};

export {
  storeUser,
  removeUser,
  getCurrentUser,
  getUserPermissions,
  getUserToken,
  updateUserLocally,
  updatePermissionsLocally,
};
