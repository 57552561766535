import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, InputBase, Typography} from "@mui/material";
import Intro from "../../atoms/Intro/Intro.component";
import {useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment";
import StatusChange from "../../SalesOrders/StatusChange";
import ActionsButton from "../../SalesOrders/ActionsButton";
import {authHeader} from "../../../helpers";
import {apiConstants} from "../../../constants";
import {convertToPlaces} from "../../../helpers/helpers";

const useStyles = makeStyles({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "5px 0 5px",
    },

    salesOrderFilterTitle: {
        color: 'rgba(0, 0, 0, 0.87) !important',
        fontSize: '14 !important'
    },
    salesOrderFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    salesOrdersFilterBar: {
        margin: '14px 0px 16px',
        backgroundColor: '#EFEFEF',
        padding: '16px'
    },
});

const StyledInputBase = styled(InputBase)(() => ({
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    '& .MuiInputBase-input': {
        paddingLeft: `calc(1em)`,
        width: '100%',
        height: '38px',
    },
}));

const columns = [
    {field: 'id', headerName: 'Factory Invoice ID', resizable: false, width: 150,},
    {
        field: 'created_at',
        headerName: 'Date',
        width: 150,
        renderCell: params => moment(params.row.created_at).format('DD/MM/YYYY')
    },
    {
        field: 'grand_total',
        headerName: 'Grand Total',
        sortable: true,
        width: 300,
        minWidth: 100,
        renderCell: params => convertToPlaces(params.row.grand_total )

    },

    {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        type: 'actions',
        renderCell: (params) => (<ActionsButton {...params} component='factoryInvoiceItem'/>),
        resizable: false
    },
];

const FactoryInvoices = () => {
    const classes = useStyles();
    const [filterCustomerName, setFilterCustomerName] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [response, setResponse] = useState('');
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const breadcrumbs = [
        <Typography key="3">
            Factory Invoices
        </Typography>,
    ];

    useEffect(() => {

        setLoadingComponent(true);

        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };

        fetch(`${apiConstants.API_URL}/factory-invoices`, requestOptions).then((response) => {
            response.text().then((text) => {

                const data = text && JSON.parse(text);
                setFilteredResults(data?.data);
                setResponse(data?.meta);
                setLoadingComponent(false);
            });
        });
    },[]);

    function handlePageChange(page) {
        setCurrentPage(page)
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        let url
        if((filterCustomerName != '' )){
            url = `${apiConstants.API_URL}/factory-invoices?organization.name=${filterCustomerName}&page=${page+1}`
        // }else if((filterItemName == '' ) && (searchCustomer != '' )){
        //     url = `${apiConstants.API_URL}/production-orders?organization_name=${searchCustomer}&page=${page+1}`
        }else {
            url = `${apiConstants.API_URL}/factory-invoices?page=${page+1}`
        }

        fetch(url,requestOptions).then((response)=>{
            response.text().then((text) => {
                const data = text && JSON.parse(text);
                setFilteredResults(data?.data);
                setResponse(data?.meta);

            });
        });
    }

    const handleFilter = () => {
        if(filterCustomerName !== ''){

            const requestOptions = {
                method: "GET",
                headers: authHeader(),
            };

            fetch(`${apiConstants.API_URL}/factory-invoices?organization.name=${filterCustomerName}`, requestOptions).then((response)=>{
                response.text().then((text) => {
                    const data = text && JSON.parse(text);
                    setFilteredResults(data?.data);
                    setResponse(data?.meta);

                });
            });
        }
    }

        return (

            <>
                <Box className={classes.headerContainer}>
                    <Intro
                        pageTitle={"Factory Invoices"}
                        pageTitleShort=""
                        breadcrumbs={breadcrumbs}
                        additionalWidgets=""
                    />
                </Box>

                {/*<Box className={classes.salesOrdersFilterBar}>*/}
                {/*    <Typography component={'span'} className={classes.salesOrderFilterTitle}>*/}
                {/*        Filter Factory Invoices*/}
                {/*    </Typography>*/}
                {/*    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>*/}
                {/*        <Box className={classes.salesOrderFilterContainer}>*/}
                {/*            <Box sx={{mr: 2}}>*/}
                {/*                <StyledInputBase*/}
                {/*                    placeholder="Customer Name"*/}
                {/*                    inputProps={{'aria-label': 'search', type: 'text'}}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        setFilterCustomerName(e.target.value);*/}
                {/*                    }}*/}
                {/*                    value={filterCustomerName}*/}
                {/*                />*/}
                {/*            </Box>*/}
                {/*            <Button variant="contained" className={classes.filterButton} onClick={handleFilter}>*/}
                {/*                filter*/}
                {/*            </Button>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}

                {/*</Box>*/}

                <Box style={{width: '100%'}}>
                    {loadingComponent ?
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={50}/>
                        </Box>
                        :
                        <DataGrid
                            autoHeight={true}
                            className={classes.dataGrid}
                            rows={filteredResults}
                            pageSize={response?.per_page}
                            pagination
                            getRowId={(row) => row.id}
                            // checkboxSelection
                            rowCount={response?.total}
                            onPageChange={handlePageChange}
                            columns={columns}
                            paginationMode="server"
                        />
                    }
                </Box>
            </>

        );
    };

    export default FactoryInvoices;
