import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Box, Button, ButtonGroup, Card, Divider, Grid, Stack, styled, Table, Typography} from "@mui/material";
import Intro from "../../../atoms/Intro/Intro.component";
import {authHeader} from "../../../../helpers";
import {apiConstants} from "../../../../constants";
import {makeStyles} from "@mui/styles";
import PaymentSummaryDetails from "../../../Invoice/PaymentSummaryDetails";
import PaymentDetailsComponent from "../../../Invoice/PaymentDetails";
import InvoiceSteppers from "../../../Invoice/InvoiceStepper";
import {VERTICAL} from "../../../../constants/Constants";
import RecordPaymentModal from "../../../../Common/Modals/RecordPaymentModal";

let breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/reseller-invoices">
        All Reseller Invoices
    </Link>,
    <Typography key="3">{"View Reseller Invoice"}</Typography>,
];

const StyledStepperContainer = styled(Stack)({
    width: "100%",
    minHeight: "500px",
    marginTop: '27px'
});

const useStyles = makeStyles({
    invoiceHeaderButtonGroup: {
        width: '100%',
        margin: '40px 0',
        borderColor: 'transparent',
        "& .MuiButtonGroup-grouped:first-child": {
            color: '#fff !important',
            width: '100%',
            fontSize: 15,
            borderColor: 'transparent !important',
            "&:hover": {
                backgroundColor: '#2ab5bf !important'
            }
        },
        "& .MuiButtonGroup-grouped:nth-child(2)": {
            color: '#fff !important',
            width: '100%',
            fontSize: 15,
            backgroundColor: '#3F91D9',
            borderColor: 'transparent !important'
        },
        "& .MuiButtonGroup-grouped:last-child": {
            width: '100%',
            color: '#fff !important',
            fontSize: 15,
            backgroundColor: '#69C464'
        }
    },
})
const ResellerInvoiceDetailView = () => {

    const {id} = useParams();
    const [response, setResponse] = useState('');
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(1);
    const [addModal, setAddModal] = useState(false);
    const [steps, setSteps] = React.useState([
        {
            label: 'Invoice Created',
            date: '',
            color: 'default'
        },
        {
            label: 'Invoice Sent',
            date: '',
            color: 'default'
        },
    ]);

    let gst = 0;
    let totalPrice = 0;
    let originalTotal = 0;
    let extraCharges = 0;
    let totalDelivery = 0;


    response?.sales_order_items?.map((item) => {
        totalPrice = totalPrice + item?.total_price;
        originalTotal = originalTotal + item?.original_total_price;
        gst = gst + item?.gst_amount || 0;
        totalDelivery = totalDelivery + item?.estimated_shipping_charges || 0;
        extraCharges = extraCharges + item?.extra_charges || 0;
        return "";
    });
    const totalDiscount = originalTotal - totalPrice || 0;
    const grandTotal =
        gst + totalPrice + extraCharges + totalDelivery - totalDiscount;

    useEffect(() => {

        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };

        fetch(`${apiConstants.API_URL}/factory-invoices/${id}`, requestOptions).then((response) => {
            response.text().then((text) => {

                const data = text && JSON.parse(text);
                setResponse(data?.data);
            });
        });

    }, [])

    const amountDecimalFormat = (amount) => {
        if (amount >= 0) {
            return parseFloat(amount).toFixed(2);
        }
    }
    const amountFormat = (amount) => {
        return parseFloat(amount / 100)?.toFixed(2);
    }
    const closeAddModal = () => setAddModal(false);

    const submitRecordPayment = (obj) => {}

    const handleRecordPayment = () => {
        setAddModal(true);
    }
    return (
        <Box sx={{p: 3}}>
            <Grid container >
                <Grid item xs={12} sm={12} md={8}>
                    <Box className={classes.invoiceHeaderTitle}>
                        <Intro
                            pageTitle={"View Reseller Invoice"}
                            pageTitleShort={"View Reseller Invoice"}
                            breadcrumbs={breadcrumbs}
                            additionalWidgets=""
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <ButtonGroup variant="contained" className={classes.invoiceHeaderButtonGroup}
                                 aria-label="outlined primary button group">
                        <Button
                            // onClick={()=>setOpenViewInvoice(true)}
                        >View Invoice</Button>
                        <Button
                            onClick={handleRecordPayment}
                        >Record Payment</Button>
                    </ButtonGroup>
                </Grid>

                <Grid item xs={12} md={8}>
                    <PaymentDetailsComponent invoiceDetails={[]} orderIds={[]}/>
                    <Typography sx={{fontSize: 20, color: 'rgba(0, 0, 0, 0.87)'}}>Payment History</Typography>
                    <StyledStepperContainer direction={"column"} sx={{minWidth: '160px'}}>
                        <InvoiceSteppers
                            activeStep={activeStep}
                            orientation={VERTICAL}
                            steps={steps}
                            alternativeLabel={false}
                        />
                    </StyledStepperContainer>
                </Grid>

                <Grid item xs={12} md={4}>

                    <PaymentSummaryDetails
                        invoiceDetails={""}
                        amountFormat={amountFormat}
                        amountDecimalFormat={amountDecimalFormat}
                    />
                </Grid>

            </Grid>
            <RecordPaymentModal handleClose={closeAddModal} open={addModal} invoiceDetails={[]} submitRecordPayment={submitRecordPayment} />
        </Box>

    );
};

export default ResellerInvoiceDetailView;
