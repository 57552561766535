import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  Divider,
  FormHelperText,
  Collapse,
  Alert,
  AlertTitle,
  Fade,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Close as CloseIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styles from "./Login.module.scss";
import * as yup from "yup";
import {authHeader, getCurrentUser} from "../../../helpers";
import {apiConstants} from "../../../constants";

const CustomerFirstTimeLogin = () => {

  const isAdmin = getCurrentUser()?.is_admin === 1;
  const alert = useSelector((state) => state.alert);
  const authentication = useSelector((state) => state.authentication);
  const navigate = useNavigate();
  const formikRef = React.createRef();

  const debug = false;

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [content, setContent] = useState();

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  });

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  useEffect(() => {
    const updateFormSubmitting = () => {
      if (!authentication.loggingIn && !authentication.loggedIn && alert) {
        formikRef.current.setSubmitting(false);
        // formikRef.current.resetForm();
      }
    };
    updateFormSubmitting();
  }, [authentication, formikRef, alert]);

  useEffect(() => {
    const updateErrorMessage = () => {
      if (alert) {
        setShowErrorMessage(true);
      }
    };
    updateErrorMessage();
  }, [alert]);

  const handleSubmit = (values, props) => {
    handleLogin(values);
  };

  const handleLogin = (values) => {
    if (values.oldPassword && values.newPassword && values.confirmPassword) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          current_password: values.oldPassword,
          password: values.newPassword,
          password_confirmation: values.confirmPassword,
        }),
      };

      fetch(`${apiConstants.API_URL}${isAdmin?'/password/change':'/customer/password/reset'}`, requestOptions)
          .then((response) => {
            response.text().then((text) => {
              const data = text && JSON.parse(text);
              if (response.status === 200) {
                if(isAdmin){
                  navigate("/auth/login", {replace: true});
                }else {
                  navigate( "/login", {replace: true});
                }
              }else {
                setShowErrorMessage(true)
                setContent(data.error)
              }
            })

          })

    }
  };

  useEffect(()=>{

    if (authentication.loggedIn  && authentication?.user?.flag_customer_password ===1) {
      navigate("./../../login");
    }

  },[authentication])



  return (
    <div>
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "1.8rem",
            },
            marginBottom: "0.5rem",
          }}
        >
          Welcome !
        </Typography>
        <Typography variant="body2">
          Please change your password before continue
        </Typography>

        <Collapse in={content && showErrorMessage}>
          <Alert
            severity="error"
            className={`login-alert login-alert-warning`}
            sx={{ mt: 3, mb: -1 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowErrorMessage(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Fade
              in={content && showErrorMessage}
              {...(showErrorMessage ? { timeout: 500 } : {})}
            >
              <AlertTitle className="alert-title" sx={{ fontSize: "0.8rem" }}>
                {content}
              </AlertTitle>
            </Fade>

            {/* <Fade
              in={alert.type && showErrorMessage}
              {...(alert.type && showErrorMessage ? { timeout: 1500 } : {})}
            >
              <small>{alert?.message}</small>
            </Fade> */}
          </Alert>
        </Collapse>
      </Box>

      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={formikRef}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            // resetForm();
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              <Grid
                container
                rowSpacing={2}
                sx={{
                  marginTop: { xs: "0.5rem" },
                  marginBottom: { xs: "1rem" },
                }}
              >
                <Grid item xs={12}>
                  <FormControl
                      fullWidth
                      variant="standard"
                      error={touched.oldPassword && Boolean(errors.oldPassword)}
                      className={styles.textField}
                  >
                    <InputLabel htmlFor="password">Old Password</InputLabel>
                    <Input
                        fullWidth
                        id="oldPassword"
                        name="oldPassword"
                        label="Old Password"
                        value={values.oldPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="standard"
                        required
                        type={showOldPassword ? "text" : "password"}
                        autoComplete="current-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowOldPassword(!showOldPassword);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                            >
                              {showOldPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                    />

                    {touched.oldPassword && errors.oldPassword && (
                        <FormHelperText>{errors.oldPassword}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    className={styles.textField}
                  >
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <Input
                      fullWidth
                      id="newPassword"
                      name="newPassword"
                      label="New Password"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      required
                      type={showNewPassword ? "text" : "password"}
                      autoComplete="current-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowNewPassword(!showNewPassword);
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    {touched.newPassword && errors.newPassword && (
                      <FormHelperText>{errors.newPassword}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    className={styles.textField}
                  >
                    <InputLabel htmlFor="password">Confirm Password</InputLabel>
                    <Input
                      fullWidth
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm Password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete="current-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormHelperText>{errors.confirmPassword}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    size="large"
                    variant="contained"
                    color="secondary"
                    sx={{ marginTop: "1rem" }}
                    loading={authentication.loggingIn}
                  >
                    Change Password
                  </LoadingButton>
                </Grid>

              </Grid>

              {debug && (
                <>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  <pre style={{ textAlign: "left" }}>
                    <strong>Values</strong>
                    <br />
                    {JSON.stringify(values, null, 2)}
                  </pre>
                  <pre style={{ textAlign: "left" }}>
                    <strong>Errors</strong>
                    <br />
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default CustomerFirstTimeLogin;
