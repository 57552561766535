import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import { HORIZONTAL, VERTICAL } from "../constants/Constants";

const Dividers = ({ orientation, ...props }) => {
  return <Divider orientation={orientation} {...props} />;
};

Dividers.propTypes = {
  orientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),
};

Dividers.defaultProps = {
  orientation: HORIZONTAL,
};

export default Dividers;
