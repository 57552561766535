export const CUSTOMER_DETAIL = "customerDetailsScreen";
export const ORDER_DETAIL = "Order Details";
export const CONCEPT_DETAIL = "Concept Designs";
export const PURCHASE_ORDER = "Purchase Order";

export const DETAIL_SCREEN = {
  CUSTOMER_DETAIL,
  ORDER_DETAIL,
  CONCEPT_DETAIL,
};

export const CANCEL = "";
export const UPDATE = "";
export const COMPLETE = "Complete";
export const NEXT = "NEXT";
export const FINISH = "FINISH";

export const countries = ["Sri Lanka", "England", "Australia"];
export const STEPS = ["Customer Details", ORDER_DETAIL, "Concept Details"];

export const SALES_ORDER_STEPS = [
  "Customer Details",
  ORDER_DETAIL,
  "Concept Details",
];

export const SALES_ORDER_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
};

export const INFO = "info";
export const SUCCESS = "success";
export const ERROR = "error";

export const ORDER_STEP_1 = "";
export const ORDER_STEPS = [ORDER_DETAIL, CONCEPT_DETAIL];

export const HORIZONTAL = "horizontal";
export const VERTICAL = "vertical";

export const ADD_REQUEST = "ADD REQUEST";
export const REJECT_REQUEST = "SUBMIT REQUEST";
export const REQUEST_REASON =
  "Please describe your requests. We may contact you if any further information is required";
export const REJECT_REASON =
  "Please describe why you decided to reject the quotation. We may able to use this information to improve our next quotation with you.";

export const LIST_ITEM = {
  name: null,
  item_id: "",
  sport_id: null,
  fabrics: [],
  linings: [],
  qty: 0,
  style: "",
  unit_discount: 0,
  discount_percentage: 0,
  selected: false,
  fabric_id: null,
  lining_ids: [],
  original_total_price: 0,
  artwork_charges: 0,
  unit_price: 0,
  total_price: 0,
  price_id: null,
  original_unit_price: 0,
  gst_amount: 0,
  extra_charges: 0,
  estimated_shipping_charges: 0,
  fabric_name: "",
  lining_name: [],
  extra_charges_list: [],
  id: null,
};

export const PRE_PRODUCTION_APPROVAL = {
  NAMES: {
    DESIGN: "design",
    PROTOTYPE: "prototype",
  },
  STATUS: {
    CREATED: "created",
    SUBMITTED: "submitted",
    APPROVED: "approved",
    REJECTED: "rejected",
    SKIPPED: "skipped",
  },
  MAX_IMAGES: 5,
};
