import React from "react";
import {
  Stack,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
} from "@mui/material";

const DeliveryOptions = ({
  deliveryMethod,
  deliveryMethodError,
  setDeliveryMethod,
}) => {
  // delivery method change
  const handleDeliveryMethodChange = (event) => {
    setDeliveryMethod(event.target.value);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box>
          <Typography variant="h4" sx={{ fontSize: "1.1rem", fontWeight: 600 }}>
            Delivery Method
          </Typography>
          <Typography variant="body2">
            Please select the delivery method you wish to use
          </Typography>
        </Box>

        <FormControl variant="standard" sx={{ minWidth: 225 }}>
          <InputLabel id="">Delivery Method</InputLabel>
          <Select
            labelId=""
            id=""
            value={deliveryMethod}
            error={deliveryMethodError}
            onChange={handleDeliveryMethodChange}
          >
            <MenuItem value={"deliver-to-client"}>
              Deliver to the Client
            </MenuItem>
            <MenuItem value={"deliver-to-s-trend"}>
              Deliver to the S-Trend Office
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      {deliveryMethodError && (
        <Grid item xs={12} sx={{ mb: 0, mt: 2 }}>
          <Alert severity="error">Please select the delivery method</Alert>
        </Grid>
      )}
    </>
  );
};

export default DeliveryOptions;
