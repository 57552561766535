import React from "react";
import { productionDetails } from "../../../../constants";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import DataTable from "./DataTable/DataTable.component";
import TextPanel from "./TextPanel/TextPanel.component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./ProductionData.module.scss";

const ProductionData = (props) => {
  const { type, loading, data } = props;

  // get titles
  const pdData = productionDetails.find((item) => item.type === type);
  const { title, render } = pdData;

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={styles.header}
        >
          <Stack spacing={5} direction="row">
            {title?.en && <Typography>{title?.en}</Typography>}
            {title?.zh && <Typography>{title?.zh}</Typography>}
          </Stack>
        </AccordionSummary>

        <AccordionDetails className={styles.wrapper}>
          {loading ? (
            <Stack sx={{ p: 3 }}>
              <CircularProgress size={24} />
            </Stack>
          ) : (
            <>
              {render?.type === "table" && (
                <DataTable
                  columns={render?.table?.columns}
                  data={data}
                  type={type}
                />
              )}

              {render?.type === "text" && (
                <TextPanel text={render?.text} data={data} />
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProductionData;
