import { patternConstants } from "../../constants";

export function patterns(state = {}, action) {
  switch (action.type) {
    // get all
    case patternConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case patternConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case patternConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case patternConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case patternConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case patternConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case patternConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case patternConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case patternConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case patternConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case patternConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case patternConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case patternConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
