import * as React from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputBase,
  Button,
  InputAdornment,
  Select,
  InputLabel,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useStyles } from "../../../Theme/Style";
import Notification from "../../../Common/NotificationContainer";
import { ArrowBack } from "@mui/icons-material";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddPriceBookEntryComponent = ({ ...props }) => {
  const {
    backToSportCategory,
    itemsRowParams,
    sportCategories,
    items,
    fabrics,
    linings,
    sportDetails,
    submitEntry,
    disabledSubmitButton,
  } = props;
  const classes = useStyles();

  const selectedItemData = itemsRowParams?.data[itemsRowParams?.index];

  const rows = [
    createData(
      "1 - 14",
      itemsRowParams.type === "edit price"
        ? parseFloat(selectedItemData.up_1_to_14 / 100)
        : 0
    ),
    createData(
      "15 - 29",
      itemsRowParams.type === "edit price"
        ? parseFloat(selectedItemData.up_15_to_29 / 100)
        : 0
    ),
    createData(
      "30 - 99",
      itemsRowParams.type === "edit price"
        ? parseFloat(selectedItemData.up_30_to_99 / 100)
        : 0
    ),
    createData(
      "100 - 149",
      itemsRowParams.type === "edit price"
        ? parseFloat(selectedItemData.up_100_to_149 / 100)
        : 0
    ),
    createData(
      "150+",
      itemsRowParams.type === "edit price"
        ? parseFloat(selectedItemData.up_150_plus / 100)
        : 0
    ),
    // createData('Factory Cost', itemsRowParams.type === 'edit price'? parseFloat(selectedItemData.production_cost/100) : 0),
  ];

  // enable facory cost only if permitted
  if (checkPermission(PERMISSIONS.CREATE_AND_EDIT_PRICEBOOK_RECORDS)) {
    rows.push(
      createData(
        "Factory Cost",
        itemsRowParams.type === "edit price"
          ? parseFloat(selectedItemData.production_cost / 100)
          : 0
      )
    );
  }

  let editLinings = [];
  if (itemsRowParams.type === "edit price") {
    editLinings = selectedItemData?.linings.map((item) => {
      return item.id;
    });
  }

  const [sport, setSport] = React.useState(
    itemsRowParams.type !== "new" ? sportDetails : { name: "" }
  );
  const [item, setItem] = React.useState(
    itemsRowParams.type !== "new" ? itemsRowParams?.data[0].item : { name: "" }
  );
  const [fabric, setFabric] = React.useState(
    itemsRowParams.type === "edit price"
      ? selectedItemData.fabric
      : { name: "" }
  );
  const [lining, setLining] = React.useState(
    itemsRowParams.type === "edit price" ? editLinings : []
  );
  const [priceRange, setPriceRange] = React.useState(rows);
  const [title] = React.useState(
    itemsRowParams.type === "edit price"
      ? "Edit Price Book Parameters"
      : "Enter Price Book Parameters"
  );
  const [submitButtonName] = React.useState(
    itemsRowParams.type === "edit price" ? "edit entry" : "save entry"
  );

  const sportProps = {
    options: sportCategories,
    getOptionLabel: (option) => option.name,
  };

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option.name,
  };

  const fabricsProps = {
    options: fabrics,
    getOptionLabel: (option) => option.name,
  };

  const handleChange = (event, index) => {
    const { value } = event.target;
    const rowsInput = [...priceRange];
    rowsInput[index]["pricePerUnit"] = value;
    setPriceRange(rowsInput);
  };

  const handleLining = (event) => {
    const {
      target: { value },
    } = event;

    setLining(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const column = ["Range", "Price Per Unit"];

  function createData(range, pricePerUnit) {
    return { range, pricePerUnit };
  }

  const submitPriceBookEntry = () => {
    const id = selectedItemData?.id;
    const type = itemsRowParams.type === "edit price" ? "Edited" : "Created";
    const successMessage = {
      open: true,
      title: `Price Book Entry ${type} Successfully`,
      body: `The new price book entry is ${type.toLowerCase()} successfully. You can now use this item in your Quotations / Sales orders`,
    };
    const data = {
      sport_id: sport.id,
      item_id: item.id,
      fabric_id: fabric.id,
      lining_ids: lining,
      up_1_to_14: parseFloat(priceRange[0].pricePerUnit) * 100,
      up_15_to_29: parseFloat(priceRange[1].pricePerUnit) * 100,
      up_30_to_99: parseFloat(priceRange[2].pricePerUnit) * 100,
      up_100_to_149: parseFloat(priceRange[3].pricePerUnit) * 100,
      up_150_plus: parseFloat(priceRange[4].pricePerUnit) * 100,
      production_cost: parseFloat(priceRange[5].pricePerUnit) * 100,
      online_wholesale: 0,
      online_retail: 0,
    };

    submitEntry(
      itemsRowParams.type === "edit price" ? "edit" : "new",
      successMessage,
      data,
      id
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      {itemsRowParams.type}price
      <Typography variant="h6" className={classes.priceBookEntryTitle}>
        {title}
      </Typography>
      <Box sx={{ marginTop: "38px", marginBottom: "50px" }}>
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <Autocomplete
                {...sportProps}
                defaultValue={sport}
                id="auto-complete"
                autoComplete
                includeInputInList
                onChange={(e, value) => {
                  setSport(value);
                }}
                disabled={itemsRowParams.type !== "new"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    label="Sports Category"
                    variant="standard"
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <Autocomplete
                {...itemProps}
                defaultValue={item}
                id="auto-complete"
                autoComplete
                includeInputInList
                onChange={(e, value) => {
                  setItem(value);
                }}
                disabled={itemsRowParams.type !== "new"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    label="Item"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <Autocomplete
                {...fabricsProps}
                id="auto-complete"
                defaultValue={fabric}
                autoComplete
                includeInputInList
                onChange={(e, value) => {
                  setFabric(value);
                }}
                disabled={itemsRowParams.type === "edit price"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    label="Fabric"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120, width: "100%" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Lining
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={lining}
                defaultValue={lining}
                onChange={handleLining}
                input={<Input label="Tag" />}
                renderValue={(selected) =>
                  linings
                    .filter((item) => selected.includes(item.id))
                    .map((record) => record.name)
                    .join(", ")
                }
                MenuProps={MenuProps}
              >
                {linings.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    <Checkbox checked={lining.indexOf(data.id) > -1} />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item >
                        <FormControl sx={{ m: 1, minWidth: 120, width:'100%' }}>
                            <TextField className={classes.textField} id="standard-basic" label="Order Limit" variant="standard" 
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120, width:'100%' }}>
                            <TextField className={classes.textField} id="standard-basic" label="Artwork charges" variant="standard" 
                            />
                        </FormControl>
                    </Grid> */}
        </Grid>
      </Box>
      <Grid container sx={{ paddingBottom: "70px" }}>
        <Grid item xs={6}>
          <Typography
            sx={{ color: "rgba(0, 0, 0, 0.87)", marginBottom: "21px" }}
          >
            Unit Pricing
          </Typography>
          <Notification
            description={
              "Please enter the unit price for each quantity range. "
            }
          />
          <TableContainer component={Paper} sx={{ marginTop: "24px" }}>
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#D5F9FC" }}>
                <TableRow>
                  {column.map((i) => (
                    <TableCell className={classes.tableHeaderCell}>
                      {i}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.range}
                    </TableCell>
                    <TableCell align="left">
                      <InputBase
                        sx={{ flex: 1, fontSize: 14 }}
                        placeholder="Enter Unit Price"
                        inputProps={{
                          "aria-label": "search google maps",
                          type: "number",
                        }}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        onChange={(event) => handleChange(event, index)}
                        defaultValue={row?.pricePerUnit}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box className={classes.priceBookActionContainer}>
        <Button onClick={backToSportCategory}>
          <ArrowBack sx={{ paddingRight: "12px" }} />
          back to price book
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={submitPriceBookEntry}
          disabled={disabledSubmitButton}
        >
          {submitButtonName}
        </Button>
      </Box>
    </Box>
  );
};
export default AddPriceBookEntryComponent;
