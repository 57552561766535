export const labelConstants = {
  GET_SINGLE_REQUEST: "LABEL_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "LABEL_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "LABEL_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "LABEL_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "LABEL_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "LABEL_GET_ALL_FAILURE",

  ADD_REQUEST: "LABEL_ADD_REQUEST",
  ADD_SUCCESS: "LABEL_ADD_SUCCESS",
  ADD_FAILURE: "LABEL_ADD_FAILURE",

  UPDATE_REQUEST: "LABEL_UPDATE_REQUEST",
  UPDATE_SUCCESS: "LABEL_UPDATE_SUCCESS",
  UPDATE_FAILURE: "LABEL_UPDATE_FAILURE",

  DELETE_REQUEST: "LABEL_DELETE_REQUEST",
  DELETE_SUCCESS: "LABEL_DELETE_SUCCESS",
  DELETE_FAILURE: "LABEL_DELETE_FAILURE",

  CLEAR: "LABEL_CLEAR",
};
