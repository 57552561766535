import { apiConstants } from "../constants";
import { authHeader, getUserToken } from "../helpers";

export const fileUploaderService = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  addProfileImage,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter() {}

// get all parameter
function getAllParameters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/payment-terms`, requestOptions).then(
    handleResponse
  );
}

// add parameter
function addParameter(file) {
  // const requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   body: JSON.stringify({
  //     image: file,
  //   }),
  // };

  const formData = new FormData();
  formData.append("image", file);

  const token = getUserToken();

  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    body: formData,
  };

  return fetch(`${apiConstants.API_URL}/images/quotation`, requestOptions).then(
    handleResponse
  );
}

// add profile image parameter
function addProfileImage(file) {

  const formData = new FormData();
  formData.append("image", file);

  const token = getUserToken();

  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    body: formData,
  };

  return fetch(`${apiConstants.API_URL}/images/user-image`, requestOptions).then(
    handleResponse
  );
}

// update parameter
function updateParameter(id, name, code) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      name: name,
      code: code,
    }),
  };

  return fetch(
    `${apiConstants.API_URL}/payment-terms/${id}`,
    requestOptions
  ).then(handleResponse);
}

// delete parameter
function deleteParameter(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/payment-terms/${id}`,
    requestOptions
  ).then(handleResponse);
}

// clear parameter
function clear() {}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
