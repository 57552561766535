import React from 'react';
import {Link} from "react-router-dom";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Stack,
    Step,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";

import {makeStyles} from "@mui/styles";
import Intro from "../../../atoms/Intro/Intro.component";


let breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/factory-invoice">
        All Pre Production Approvals
    </Link>,
    <Typography key="3">{"View Pre Production Approval"}</Typography>,
];


const useStyles = makeStyles({
    orderItemDetailsBar: {
        backgroundColor: '#e3e3e3',
        padding: '9px 0px 9px 28px'
    },
    label: {
        fontSize: '12px',
        color: '#000',
        opacity: 0.85,
        textAlign: 'left',
    },
    detail: {
        color: '#000',
        opacity: 0.6,
        textAlign: 'left',
    },
    imageContainer: {
        padding: '0px 16px',
        textAlign: 'center',
        marginTop: '20px',
    }

})

const steps = [{
    id: 1,
    name: "Start",
    keyword: "start",
}, {
    id: 2,
    name: "Pre-Production Artwork Approval",
    keyword: "Pre-Production Artwork Approval",
}, {
    id: 3,
    name: "Prototype Approval",
    keyword: "Prototype Approval",
}]
const activeStep = "approve"
const alternativeLabel = "approve"

const PreProdAprDetailedViewClient = () => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const openProductOrderStatusModal = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{pr: 2}}>
                <Intro
                    pageTitle={"View Pre Production Approval"}
                    pageTitleShort={"View Pre Production Approval"}
                    breadcrumbs={breadcrumbs}
                    additionalWidgets=""
                />
            </Stack>

            <Stepper
                sx={{marginTop: "0 !important"}}
                activeStep={1}
                orientation={"horizontal"}
                alternativeLabel={alternativeLabel}
                style={{paddingBottom: "50px"}}
            >
                {steps.map((label, index) => {
                    return (
                        <Step key={index}>
                            <StepLabel>{label.name}</StepLabel>
                        </Step>

                    );
                })}
            </Stepper>

            <Typography
                variant="h5"
                style={{paddingBottom: "10px"}}
            >
                Pre-Production Artwork
            </Typography>
            <Grid container spacing={2}>

                <Grid item>
                    <Box>
                        <img
                            src={'https://s3.ap-southeast-2.amazonaws.com/assets-staging.s-trend.com.au/images/SalesOrder/1698761491.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWFHQXZG5Z4ATGUMA%2F20231122%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T145223Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=a67b727bf562d80e08f71ee0cfe03b99f06bc4f7ebba1f60f4c27fc3914cf686'}
                            width={'100%'} alt=''/>
                    </Box>
                </Grid>

                <Grid item>
                    <Box>
                        <img
                            src={'https://s3.ap-southeast-2.amazonaws.com/assets-staging.s-trend.com.au/images/SalesOrder/1698761491.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWFHQXZG5Z4ATGUMA%2F20231122%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T145223Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=a67b727bf562d80e08f71ee0cfe03b99f06bc4f7ebba1f60f4c27fc3914cf686'}
                            width={'100%'} alt=''/>
                    </Box>
                </Grid>

                <Grid item>
                    <Box>
                        <img
                            src={'https://s3.ap-southeast-2.amazonaws.com/assets-staging.s-trend.com.au/images/SalesOrder/1698761491.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWFHQXZG5Z4ATGUMA%2F20231122%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T145223Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=a67b727bf562d80e08f71ee0cfe03b99f06bc4f7ebba1f60f4c27fc3914cf686'}
                            width={'100%'} alt=''/>
                    </Box>
                </Grid>

                <Grid item>
                    <Box>
                        <img
                            src={'https://s3.ap-southeast-2.amazonaws.com/assets-staging.s-trend.com.au/images/SalesOrder/1698761491.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWFHQXZG5Z4ATGUMA%2F20231122%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T145223Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=a67b727bf562d80e08f71ee0cfe03b99f06bc4f7ebba1f60f4c27fc3914cf686'}
                            width={'100%'} alt=''/>
                    </Box>
                </Grid>

                <Grid item>
                    <Box>
                        <img
                            src={'https://s3.ap-southeast-2.amazonaws.com/assets-staging.s-trend.com.au/images/SalesOrder/1698761491.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWFHQXZG5Z4ATGUMA%2F20231122%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T145223Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=a67b727bf562d80e08f71ee0cfe03b99f06bc4f7ebba1f60f4c27fc3914cf686'}
                            width={'100%'} alt=''/>
                    </Box>
                </Grid>

            </Grid>


            <Grid container spacing={2} style={{paddingTop: '30px'}}>
                <Grid item>
                    <Button variant="contained" onClick={openProductOrderStatusModal}
                            sx={{fontWeight: 500, fontSize: 12, color: '#fff', background: '#69C464'}}>
                       Accept Pre-Production Artwork
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={openProductOrderStatusModal}
                            sx={{fontWeight: 500, fontSize: 12, color: '#', background: '#ea2626'}}>
                       Reject Pre-Production Artwork
                    </Button>
                </Grid>
            </Grid>


        </div>
    );
};

export default PreProdAprDetailedViewClient;
