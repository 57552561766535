import React from "react";
import { Snackbar, Alert } from "@mui/material";

const AlertToast = ({ ...props }) => {
  const { toastMessage, toastState, handleClose } = props;
  const [state] = React.useState({
    open: toastState.open,
    vertical: toastState.vertical,
    horizontal: toastState.horizontal,
  });
  const { vertical, horizontal } = state;
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={toastState.open}
        onClose={handleClose}
        autoHideDuration={6000}
        key={toastState.vertical + toastState.horizontal}
      >
        <Alert onClose={handleClose} severity="error">
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AlertToast;
