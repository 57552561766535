import React from "react";
import { Container } from "@mui/system";
import { Button, Stack, styled, Typography, Box } from "@mui/material";
import drawer from "../images/drawer.png";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";

const StyledContainer = styled(Stack)({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "406px",
  height: "406px",
});

const StyledImageContainer = styled(Stack)({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "406px",
  height: "auto",
});

const EditConceptDesign = ({ ...props }) => {
  const { file, setFile } = props;
  const files = file;
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    files.push({
      image: formData,
      image_url: URL.createObjectURL(file),
      name: file?.name,
      newUpload: true,
    });
    setFile([...files]);
  };

  // const clearSelectedFile = () => {
  //   setFile({});
  // }

  const removeFile = (e, index) => {
    const uploadFiles = file.filter((f, i) => i !== index);
    setFile(uploadFiles);
  };

  return (
    <div>
      <Container>
        <Stack spacing={1} textAlign="left">
          <Typography fontSize={"24px"}>Concept Details</Typography>
          <Typography fontSize={"16px"} fontWeight={400}>
            Upload concept designs if available.
            {/* Multiple designs files allowed */}
          </Typography>
        </Stack>
      </Container>
      <Container
        sx={{
          textAlign: "left",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Stack direction={"row"} spacing={3}>
          <StyledContainer
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "end",
            }}
            spacing={3}
          >
            <img src={drawer} width={"81px"} height={"81px"} alt={""} />
            <Typography fontWeight={400}>
              Click or drag files to this area to upload your concept designs
            </Typography>
            <Typography fontSize={"12px"}>
              (Accepted file types: SVG, PDF, CSV, JPG, PNG)
            </Typography>
            <Typography fontSize={"13px"}>Maximum file size is 3MB</Typography>
            <Stack direction={"row"} sx={{ padding: "20px" }} spacing={2}>
              {/* <Button variant="outlined" color="error" onClick={clearSelectedFile}>
                CLEAR
              </Button> */}
              <label htmlFor="image">
                <Button
                  variant="outlined"
                  sx={{ width: "250px", pointerEvents: "none" }}
                >
                  Upload
                </Button>
              </label>
              <input
                type="file"
                id={`image`}
                multiple
                name={`image`}
                accept="*"
                onChange={handleImageUpload}
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  visibility: "hidden",
                }}
              />
            </Stack>
          </StyledContainer>
          <StyledImageContainer>
            {file?.map((i, index) => (
              <Box
                sx={{
                  display: "flex",
                  padding: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <InsertDriveFileIcon
                    color="primary"
                    sx={{ marginRight: "5px" }}
                  />
                  <Typography variant="span">{i?.name}</Typography>
                </Box>
                <Button>
                  <CloseIcon
                    id={index}
                    color="red"
                    fontSize="15"
                    sx={{ marginTop: "5px", fontWeight: "800" }}
                    onClick={(e) => removeFile(e, index)}
                  />
                </Button>
              </Box>
            ))}
          </StyledImageContainer>
        </Stack>
      </Container>
    </div>
  );
};

export default EditConceptDesign;
