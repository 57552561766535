import { Box, Stack, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AlertToast from "../components/common/Toast";
import { alertActions } from "../actions";

const StyedBox = styled(Box)({
  background:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1;",
  padding: "10px",
  borderRadius: "5px",
  minHeight: "108px",
});

const StyledText = styled(Typography)({
  fontSize: "15px",
});

export const GlobalNotification = () => {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(alertActions.clear());
  };

  return (
    <AlertToast
      toastMessage={alert.message}
      toastState={{
        open: !!alert.type,
        vertical: "bottom",
        horizontal: "right",
      }}
      handleClose={handleClose}
    />
  );
};
