import * as React from "react";
import {
  Typography,
  Box,
  Paper,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { InfoOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  customerInformationContainer: {
    backgroundColor: "#E6F3FA !important",
    borderRadius: "8px",
    padding: "14px 17px",
    textAlign: "left",
    marginBottom: "18px",
  },
  notificationOfCustomerInformation: {
    display: "flex",
    justifyContent: "flex-start",
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiSvgIcon-root": {
      color: "#0288D1",
      marginRight: "9px",
    },
  },
  inputContainer: {
    marginTop: "40px",
    "& .MuiFormControl-root": {
      width: "100%",
      marginBottom: "16px !important",
    },
    "& .MuiInputLabel-root": {
      fontSize: 14,
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      fontSize: 14,
    },
  },
});

const CustomerInformationComponent = ({ ...props }) => {
  const classes = useStyles();
  const {
    handleCustomerInformation,
    paymentFacilities,
    paymentFacility,
    discountStatus,
    sponsorPercentage,
    discountAmount,
    customerEmail,
    comment,
    error,
  } = props;

  return (
    <Paper className={classes.customerInformationContainer}>
      <Box className={classes.notificationOfCustomerInformation}>
        <InfoOutlined />
        <Typography>
          Please complete this information before send the invoice to the
          customer.
          <br></br>
          <br></br>
          This information will not displayed on the customer invoice
        </Typography>
      </Box>
      <Box className={classes.inputContainer}>
        <FormControl>
          <TextField
            id="standard-basic"
            label="Customer Email Address"
            variant="standard"
            onChange={(e) => handleCustomerInformation(e)}
            InputProps={{
              readOnly: true,
            }}
            value={customerEmail}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">
            Payment Facility
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) =>
              handleCustomerInformation(e, "payment_facility_id")
            }
            label="Payment Facility"
            value={paymentFacility}
          >
            {paymentFacilities.map((data) => (
              <MenuItem key={data.id} value={data.id}>
                {data.facility}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {paymentFacility === 2 && (
          <FormControl>
            <TextField
              id="standard-basic"
              label="Sponsorship Percentage"
              variant="standard"
              InputProps={{ inputProps: { min: 0, max: 10 } }}
              onChange={(e) =>
                handleCustomerInformation(e, "sponsered_percentage")
              }
              type="number"
              defaultValue={sponsorPercentage}
            />
            {error.value && error.name === "sponsered_percentage" && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {/* <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">
            Discount?
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => handleCustomerInformation(e, "discountStatus")}
            label="Discount?"
            value={discountStatus}
          >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </Select>
        </FormControl>

        {discountStatus === 1 && (
          <FormControl sx={{ width: "100%" }}>
            <TextField
              id="standard-basic"
              label="Discount Amount"
              variant="standard"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e) => handleCustomerInformation(e, "discount_amount")}
              type="number"
              defaultValue={discountAmount}
            />
            {error.value && error.name === "discount_amount" && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        )} */}

        <FormControl sx={{ width: "100%" }}>
          <TextField
            id="standard-basic"
            label="Comments"
            variant="standard"
            onChange={(e) => handleCustomerInformation(e, "comment")}
            defaultValue={comment}
          />
        </FormControl>
      </Box>
    </Paper>
  );
};

export default CustomerInformationComponent;
