import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import OrganizationForm from "../../../components/admin/OrganizationManagement/OrganizationForm/OrganizationForm.component";

const OrganizationAdd = () => {
  const currentParameter = {
    id: 0,
    slug: "organization-management",
    title: "Organizations",
    titleSingular: "Organization",
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/organization-management"
    >
      Organization Management
    </Link>,

    <Typography key="3">Add New Organization</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Add New Organization`}
        pageTitleShort={`Add New Organization`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent
            title={`Enter New Organization`}
            subtitle=""
            description=""
          >
            <Grid container spacing="2">
              <Grid item xs={12}>
                <OrganizationForm parameter={currentParameter} mode="add" />
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default OrganizationAdd;
