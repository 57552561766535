import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PropTypes from "prop-types";

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "30%",
  left: "30%",
  transform: "translate(-50%, -50%)",
  width: "444px",
  height: "154px",
  padding: "10px",
};

const WarningModal = ({ ...props }) => {
  const { title, open, handleClose, body, index, updateItems } = props;

  const removeItem = () => {
    updateItems(index);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"}>
            <ErrorOutlineIcon
              color="warning"
              fontSize="small"
              style={{ margin: "5px 5px 0 0" }}
            />
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              {title}
            </Typography>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} s>
            {body}
          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button onClick={() => handleClose()}>CLOSE</Button>
            <Button
              onClick={() => removeItem()}
              color="error"
              variant="contained"
            >
              REMOVE
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

WarningModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
};

WarningModal.defaultProps = {
  open: "",
  title: "",
  body: "",
};

export default WarningModal;
