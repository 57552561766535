import styled from "@emotion/styled";
import { Box, Button, Input, InputLabel, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "600px",
  height: "300px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const AddCommentModal = ({ ...props }) => {
  const { open, handleClose, type, request, buttonType, buttonText, headerText, bodyText, reason, label } = props;

  //buttonType - "success" : "error"

  const [reasons, setReasons] = useState("");
  const onChange = (e) => {
    setReasons(() => e.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction={"column"} spacing={2} sx={{ padding: "0 5px 0 5px" }}>
          <Typography fontSize={"24px"}>{headerText}</Typography>
          <Typography fontSize={"14px"}>{bodyText}</Typography>
          <Typography fontSize={"16px"} style={{ color: "#00000099" }}>
            {reason}
          </Typography>
        </Stack>
        <InputLabel>{label}</InputLabel>
        <Input style={{ outline: "none" }} onChange={onChange}/>
        <Stack direction={"row"} justifyContent="space-between">
          <Button color="success" onClick={() => handleClose(!open)}>
            CLOSE
          </Button>
          <StyledButton
            color={buttonType}
            variant="contained"
            onClick={() => request(reasons)}
          >
            {buttonText}
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};

AddCommentModal.propTypes = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,

  request: PropTypes.func.isRequired,
};
AddCommentModal.defaultProps = {
  open: false,
  type: '',
};

export default AddCommentModal;
