import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Container,
  Box,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import WebstoreItemForm from "../../../components/admin/WebStoreManagement/WebStoreItemForm/WebStoreItemForm.component";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { organizationActions } from "../../../actions";

const PageWidgets = (props) => {
  const { productionOrderId } = props;
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Button
          variant="contained"
          // startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() =>
            navigate(`/admin/production-orders_item/${productionOrderId}/view`)
          }
          size="large"
        >
          View production order
        </Button>
      </Stack>
    </Box>
  );
};

const WebstoreSingleEdit = () => {
  const { id, item } = useParams();
  const dispatch = useDispatch();

  const organization = useSelector(
    (state) => state.organizations?.parameter?.data
  );

  const [isLoading, setIsLoading] = useState(true);
  const [storeItem, setStoreItem] = useState({});
  const [fetchCount, setFetchCount] = useState(0);

  // get organization
  useEffect(() => {
    dispatch(organizationActions.getSingleParameter(id));
  }, [dispatch, id]);

  // get current item
  useEffect(() => {
    const getStoreItem = () => {
      setIsLoading(true);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      fetch(
        `${apiConstants.API_URL}/store-production-orders?organization.id=${id}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          const filteredItem = data?.data.find(
            (storeitem) => storeitem?.id === parseInt(item)
          );
          console.log(
            "🚀 ~ file: index.js:83 ~ response.text ~ filteredItem:",
            filteredItem
          );

          const isMigrated = filteredItem.is_old_po === 1 ? true : false;

          const getItemImage = (item) => {
            if (item?.image_url !== null) {
              return item?.image_url;
            }

            if (item?.thumbnail_url !== null) {
              return item?.thumbnail_url;
            }

            if (item?.items[0]?.image_url !== null) {
              return item?.items[0]?.image_url;
            }

            return "";
          };

          const formattedItem = {
            itemId: item,
            isMigrated: isMigrated,
            item: filteredItem,
            patternNo: filteredItem?.pattern_id || "",
            itemName: isMigrated
              ? filteredItem?.item?.name
              : filteredItem?.price?.item?.name || "",
            sportCategory: isMigrated
              ? filteredItem?.sport?.name
              : filteredItem?.price?.sport?.name || "",
            description: filteredItem?.store_item?.description || "",
            itemPrice: filteredItem?.store_item?.unit_price
              ? filteredItem?.store_item?.unit_price
              : filteredItem?.price?.online_retail || "",
            itemImage: getItemImage(filteredItem),
            productionOrderId: filteredItem?.id,
            activeStoreItem: filteredItem?.store_item?.id ? true : false,
            style: filteredItem?.style || "",
            storeItem: {
              id: filteredItem?.store_item?.id || "",
              is_active: filteredItem?.store_item?.is_active || 0,
              is_official: filteredItem?.store_item?.is_official || 0,
              description: filteredItem?.store_item?.description || "",
              gender: filteredItem?.store_item?.gender || "male",
              production_order_id:
                filteredItem?.store_item?.production_order_id || "",
              organization_id: filteredItem?.store_item?.organization_id || "",
              unit_price: filteredItem?.store_item?.unit_price || "",
              adult_price: filteredItem?.store_item?.adult_price || "",
              kids_price: filteredItem?.store_item?.kids_price || "",
              factory_price: filteredItem?.store_item?.factory_price || "",
              shipping_price: filteredItem?.store_item?.shipping_price || "",
            },
          };

          setStoreItem(formattedItem);

          setIsLoading(false);
        });
      });
    };

    getStoreItem();
  }, [id, item, fetchCount]);

  // breadcrumb
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/webstore-management/"
    >
      Store Management
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/webstore-management/"
    >
      Web Stores
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/webstore-management/${id}`}
    >
      Store - {organization?.name}
    </Link>,
    <Typography key="3">{storeItem?.itemName}</Typography>,
  ];

  // refetch
  const handleRefetch = () => {
    setFetchCount(fetchCount + 1);
  };

  return (
    <>
      <Intro
        pageTitle={storeItem?.itemName}
        pageTitleShort={storeItem?.itemName}
        breadcrumbs={breadcrumbs}
        additionalWidgets={
          <PageWidgets productionOrderId={storeItem?.productionOrderId} />
        }
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent
            title={`View/Edit item details`}
            subtitle=""
            description=""
          >
            <Grid container spacing="2">
              <Grid item xs={12}>
                {isLoading ? (
                  <Stack>
                    <CircularProgress />
                  </Stack>
                ) : (
                  <WebstoreItemForm
                    parameter={storeItem}
                    mode="edit"
                    handleRefetch={handleRefetch}
                    // productionOrderId={productionOrderId}
                  />
                )}
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default WebstoreSingleEdit;
