import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const InvoicesActionButton = ({ pathToView, invoiceId }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      onClick={() => navigate(pathToView.replace("$id$", invoiceId))}
    >
      View
    </Button>
  );
};
