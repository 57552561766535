import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, CircularProgress } from "@mui/material";
import { patternService } from "../../../../../services";
import SpecImage from "./SpecImage/SpecImage.component";
import SpecTable from "./SpecTable/SpecTable.component";
import styles from "./SpecificationTable.module.scss";

const SpecificationTable = (props) => {
  const { specId } = props;
  const [specDetails, setSpecDetails] = useState({});
  const sizes = useSelector((state) => state.sizes);

  const kidsSizes = sizes?.items?.data?.filter((s) => s.flag_kid_size === 1);
  const adultSizes = sizes?.items?.data?.filter((s) => s.flag_kid_size === 0);

  useEffect(() => {
    const getPattern = async (id) => {
      const res = await patternService.getSingleParameter(id);
      setSpecDetails(res?.data);
    };

    getPattern(specId);
  }, [specId]);

  return (
    <Grid container spacing={2}>
      {specDetails?.image_url ? (
        <Grid item xs={12} md={12} lg={12}>
          <SpecImage images={[specDetails?.image_url]} />
        </Grid>
      ) : null}

      <Grid item xs={12} md={12} lg={6} className={styles.tableWrap}>
        {specDetails?.measurements && kidsSizes ? (
          <Paper
            elevation={0}
            sx={{ px: 2, pb: 2, boxShadow: "none", border: "none" }}
          >
            {specDetails?.measurements && (
              <SpecTable
                measurements={specDetails?.measurements}
                sizes={kidsSizes}
                mode="kids"
              />
            )}
          </Paper>
        ) : (
          <Paper
            sx={{
              p: 6,
              textAlign: "center",
              boxShadow: "none",
              border: "none",
            }}
          >
            <CircularProgress />
          </Paper>
        )}
      </Grid>

      <Grid item xs={12} md={12} lg={6} className={styles.tableWrap}>
        {specDetails?.measurements && kidsSizes ? (
          <Paper elevation={0} sx={{ px: 2, pb: 2 }}>
            {specDetails?.measurements && (
              <SpecTable
                measurements={specDetails?.measurements}
                sizes={adultSizes}
                mode="adults"
              />
            )}
          </Paper>
        ) : (
          <Paper
            sx={{
              p: 6,
              textAlign: "center",
              boxShadow: "none",
              border: "none",
            }}
          >
            <CircularProgress />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default SpecificationTable;
