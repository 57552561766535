import React, { useState } from "react";
import GlobalLoadingContext from "../Contexts/GlobalLoadingContext";

const LoadingProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  return (
    <GlobalLoadingContext.Provider value={{ isLoading, showLoader, hideLoader }}>
      {props.children}
    </GlobalLoadingContext.Provider>
  );
};

export default LoadingProvider;