import { organizationConstants } from "../constants";

export function organizations(state = {}, action) {
  switch (action.type) {
    // get all
    case organizationConstants.GET_SINGLE_REQUEST:
      return {
        loading: true,
      };
    case organizationConstants.GET_SINGLE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case organizationConstants.GET_SINGLE_FAILURE:
      return {
        error: action.error,
      };

    // get all
    case organizationConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case organizationConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case organizationConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case organizationConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case organizationConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case organizationConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case organizationConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case organizationConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case organizationConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case organizationConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case organizationConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case organizationConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case organizationConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
