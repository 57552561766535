import React from "react";
import Notification from "../Common/Notification";
import { Button, CardContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import { apiConstants } from "../constants";
import { getUserToken } from "../helpers";

const StyledBoxContent = styled(Stack)({
  height: "auto",
  minHeight: "142px",
  padding: "19px 30px",
  borderRadius: "8px",
  justifyContent: "space-around",
  textAlign: "center",
  border: "1px dashed #9E9E9E",
});

const StyledBox = styled(StyledBoxContent)(({ theme }) => ({
  backgroundColor: "#efefef !important",
  border: "1px dashed #9E9E9E",
  height: "auto",
  // width: "501px",
}));

const CustomerConceptDetails = ({ items, updateItems, setLoading }) => {
  const handleImageUpload = (e, item, index, place) => {
    setLoading(true);
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);
    let token = getUserToken();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    };
    console.log(file);
    fetch(`${apiConstants.API_URL}/images/sales-order`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);

          console.log({ place });

          if (place === 1) {
            let newData = {
              ...item,
              image: data?.data?.path,
              image_url: URL.createObjectURL(file),
            };
            updateItems(index, newData);
            setLoading(false);
          }

          if (place === 2) {
            let newData = {
              ...item,
              image_2: data?.data?.path,
              image_2_url: URL.createObjectURL(file),
            };
            updateItems(index, newData);
            setLoading(false);
          }

          if (place === 3) {
            let newData = {
              ...item,
              image_3: data?.data?.path,
              image_3_url: URL.createObjectURL(file),
            };
            updateItems(index, newData);
            setLoading(false);
          }

          // if (item?.image_url && item?.image) {
          //   let newData = {
          //     ...item,
          //     image_2: data?.data?.path,
          //     image_2_url: URL.createObjectURL(file),
          //   };
          //   updateItems(index, newData);
          //   setLoading(false);
          // } else {
          //   let newData = {
          //     ...item,
          //     image: data?.data?.path,
          //     image_url: URL.createObjectURL(file),
          //   };
          //   updateItems(index, newData);
          //   setLoading(false);
          // }
        });
      }
    );
  };

  const clearSelectedFile1 = (e, item, index) => {
    let newData = {
      ...item,
      image: "",
      image_url: "",
    };
    updateItems(index, newData);
  };
  const clearSelectedFile2 = (e, item, index) => {
    let newData = {
      ...item,
      image_2_url: "",
      image_2: "",
    };
    updateItems(index, newData);
  };

  const clearSelectedFile3 = (e, item, index) => {
    let newData = {
      ...item,
      image_3_url: "",
      image_3: "",
    };
    updateItems(index, newData);
  };

  return (
    <CardContent
      sx={{ textAlign: "left", minWidth: "500px", minHeight: "576px" }}
    >
      <Stack spacing={3}>
        <Typography fontSize={"24px"}>Concept Details</Typography>
        <Notification
          body="Please upload and assign concept designs for each item. If you have already uploaded the designs while creating the quotation, you can use existing designs as well."
          extra="Please Note"
        />
        <Stack spacing={2}>
          {items?.map((item, index) => (
            <div key={item?.id}>
              <Stack direction={"row"} justifyContent="space-between">
                <Typography>{item?.price?.item?.name || item?.name}</Typography>
              </Stack>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                  sx={{ width: "100%" }}
                >
                  <StyledBox sx={{ cursor: "pointer" }}>
                    <Typography fontSize={"14px"}>
                      Click files to this area to upload your concept designs
                    </Typography>
                    <Typography fontSize={"11px"}>
                      (Accepted file types: SVG, EPS, PDF, JPG, PNG)
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent="space-evenly"
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => clearSelectedFile1(e, item, index)}
                      >
                        CLEAR
                      </Button>
                      <label htmlFor={`image${item?.id}_1`}>
                        <Button
                          variant="outlined"
                          style={{ width: "246px", pointerEvents: "none" }}
                          disabled={!!item?.image}
                        >
                          UPLOAD {`image`}
                        </Button>
                      </label>
                    </Stack>
                  </StyledBox>
                  <input
                    required
                    type="file"
                    id={`image${item?.id}_1`}
                    name={`image${item?.id}_1`}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      // setFieldValue("image", e.currentTarget.files[0]);
                      // showImgPreview(e);
                      handleImageUpload(e, item, index, 1);
                    }}
                    //onBlur={handleBlur}
                    // error={touched.image && Boolean(errors.image)}
                    // helperText={touched.image && errors.image}
                    style={{
                      position: "absolute",
                      pointerEvents: "none",
                      visibility: "hidden",
                    }}
                  />
                  <Stack sx={{ width: "100%" }}>
                    <StyledBox>
                      {!item?.image_url || !item?.image ? (
                        <Typography fontSize={"14px"}>
                          Click or drag files to this area to upload your
                          concept designs
                        </Typography>
                      ) : (
                        <img
                          id={`image${item?.id}`}
                          alt="uploaded img"
                          src={item?.image_url || item?.image}
                          style={{
                            height: "auto",
                            objectFit: "cover",
                            width: "50%",
                          }}
                        />
                      )}
                    </StyledBox>
                  </Stack>
                </Stack>
              </Stack>
              <br />
              <Stack direction={"row"} justifyContent="space-between">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                  sx={{ width: "100%" }}
                >
                  <StyledBox sx={{ cursor: "pointer" }}>
                    <Typography fontSize={"14px"}>
                      Click files to this area to upload your concept designs
                    </Typography>
                    <Typography fontSize={"11px"}>
                      (Accepted file types: SVG, EPS, PDF, JPG, PNG)
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent="space-evenly"
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => clearSelectedFile2(e, item, index)}
                      >
                        CLEAR
                      </Button>
                      <label htmlFor={`image${item?.id}_2`}>
                        <Button
                          variant="outlined"
                          style={{ width: "246px", pointerEvents: "none" }}
                          disabled={item?.image_2}
                        >
                          UPLOAD {`image`}
                        </Button>
                      </label>
                    </Stack>
                  </StyledBox>
                  <input
                    type="file"
                    id={`image${item?.id}_2`}
                    name={`image${item?.id}_2`}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      // setFieldValue("image", e.currentTarget.files[0]);
                      // showImgPreview(e);
                      handleImageUpload(e, item, index, 2);
                    }}
                    //onBlur={handleBlur}
                    // error={touched.image && Boolean(errors.image)}
                    // helperText={touched.image && errors.image}
                    style={{
                      position: "absolute",
                      pointerEvents: "none",
                      visibility: "hidden",
                    }}
                  />
                  <Stack sx={{ width: "100%" }}>
                    <StyledBox>
                      {!item?.image_2_url || !item?.image_2 ? (
                        <Typography fontSize={"14px"}>
                          Click or drag files to this area to upload your
                          concept designs
                        </Typography>
                      ) : (
                        <img
                          id={`image${item?.id}`}
                          alt="uploaded img"
                          src={item?.image_2_url || item?.image_2}
                          style={{
                            height: "auto",
                            objectFit: "cover",
                            width: "50%",
                          }}
                        />
                      )}
                    </StyledBox>
                  </Stack>
                </Stack>
              </Stack>

              <br />
              <Stack direction={"row"} justifyContent="space-between">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                  sx={{ width: "100%" }}
                >
                  <StyledBox sx={{ cursor: "pointer" }}>
                    <Typography fontSize={"14px"}>
                      Click files to this area to upload your concept designs
                    </Typography>
                    <Typography fontSize={"11px"}>
                      (Accepted file types: SVG, EPS, PDF, JPG, PNG)
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent="space-evenly"
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => clearSelectedFile3(e, item, index)}
                      >
                        CLEAR
                      </Button>
                      <label htmlFor={`image${item?.id}_3`}>
                        <Button
                          variant="outlined"
                          style={{ width: "246px", pointerEvents: "none" }}
                          disabled={item?.image_3}
                        >
                          UPLOAD {`image`}
                        </Button>
                      </label>
                    </Stack>
                  </StyledBox>
                  <input
                    type="file"
                    id={`image${item?.id}_3`}
                    name={`image${item?.id}_3`}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      // setFieldValue("image", e.currentTarget.files[0]);
                      // showImgPreview(e);
                      handleImageUpload(e, item, index, 3);
                    }}
                    //onBlur={handleBlur}
                    // error={touched.image && Boolean(errors.image)}
                    // helperText={touched.image && errors.image}
                    style={{
                      position: "absolute",
                      pointerEvents: "none",
                      visibility: "hidden",
                    }}
                  />
                  <Stack sx={{ width: "100%" }}>
                    <StyledBox>
                      {!item?.image_3_url || !item?.image_3 ? (
                        <Typography fontSize={"14px"}>
                          Click or drag files to this area to upload your
                          concept designs
                        </Typography>
                      ) : (
                        <img
                          id={`image${item?.id}`}
                          alt="uploaded img"
                          src={item?.image_3_url || item?.image_3}
                          style={{
                            height: "auto",
                            objectFit: "cover",
                            width: "50%",
                          }}
                        />
                      )}
                    </StyledBox>
                  </Stack>
                </Stack>
              </Stack>
            </div>
          ))}
        </Stack>
      </Stack>
    </CardContent>
  );
};

CustomerConceptDetails.propTypes = {
  items: PropTypes.object,
};
CustomerConceptDetails.defaultProps = {
  items: {
    items: [],
  },
};
export default CustomerConceptDetails;
