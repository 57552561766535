import { Today } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import AddTrackingDetailsModal from "../../Common/Modals/AddTrackingDetailsModal";
import ProgressCircularWithBackdrop from "../../Common/ProgressCircular/ProgressCircularWithBackdrop";
import {
  couriersActions,
  patternActions,
  productionOrderActions,
} from "../../actions";
import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";
import AlertToast from "../common/Toast";
import Intro from "../atoms/Intro/Intro.component";
import OrderInformation from "./OrderInformation";
import OrderItemsContainer from "./OrderItems/OrderItemsContainer";
import ProductionOrderViewSideBar from "./ProductionOrderViewSideBar";
import { useEffect, useState } from "react";
import StickyBox from "react-sticky-box";
import Sticky from "react-stickynode";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import Select from "@mui/material/Select";
import styles from "../admin/parameterManagement/ParameterCard/ParameterForm/ParameterForm.module.scss";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SvgIcon from "@mui/material/SvgIcon";
import CardActions from "@mui/material/CardActions";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 35px",
  },
  headerContainerMainTitle: {
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "40.8px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  headerContainerSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  headerContainerSubTitleIcon: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 14.9px",
  },
  headerButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  printButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#F5F5F5 !important",
    borderColor: "#d2d0d0 !important",
  },
  downloadButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#E0E0E0 !important",
  },
  tabs: {
    background: "#fff",
    border: "1px solid #2AB5BF",
    color: "#2AB5BF",
    borderRadius: "4px",
    "& .MuiTab-root.Mui-selected": {
      background: "#2AB5BF",
      color: "#fff",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.125 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function removeNullUndefinedWithReduce(obj) {
  console.log("remove null undefined ########");
  console.log({ obj });
  return Object.entries(obj).reduce((acc, [key, value]) => {
    console.log("########");
    console.dir({ acc });
    if (value !== null && value !== undefined && !isNaN(value)) {
      acc[key] =
        typeof value === "object"
          ? removeNullUndefinedWithReduce(value)
          : value;
    }
    return acc;
  }, {});
}

const ProductionOrderView = () => {
  const dispatch = useDispatch();
  const paramState = useSelector((state) => state.patterns);
  const poFiledState = useSelector((state) => state.productionOrders);
  const couriersParam = useSelector((state) => state.couriers);
  const theme = useTheme();
  const classes = useStyles();
  const { id } = useParams();

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const [productionOrder, setProductionOrder] = React.useState({});
  const [productionOrderItems, setProductionOrderItems] = React.useState([]);
  const [activeItemId, setActiveItemId] = React.useState("");
  const [activeItem, setActiveItem] = React.useState({});
  const [colors, setColors] = React.useState([]);
  const [fabrics, setFabrics] = React.useState([]);
  const [trims, setTrims] = React.useState([]);
  const [nameAndSizes, setNameAndSizes] = React.useState([]);
  const [patterns, setPatterns] = React.useState([]);
  const [poFields, setPoFields] = React.useState([]);
  const [positions, setPositions] = React.useState([]);
  const [selectedPattern, setSelectedPattern] = React.useState([]);
  const [selectedPatternImage, setSelectedPatternImage] = React.useState({});
  const [selectedPatternId, setSelectedPatternId] = React.useState(null);
  const [selectedPatternObj, setSelectedPatternObj] = React.useState({});
  const [patternSizeAdultHeader, setPatternSizeAdultHeader] = React.useState(
    []
  );
  const [patternSizeChildHeader, setPatternSizeChildHeader] = React.useState(
    []
  );
  const [preProdApprovals, setPreProdApprovals] = React.useState({});

  const [sizeCounts, setSizeCount] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [specialComment, setSpecialComment] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmTrackingDetails, setOpenConfirmTrackingDetails] =
    React.useState(false);
  const [productionOrderButtonText, setProductionOrderButtonText] =
    React.useState("Level 1 Approved");
  const [productionStatus, setProductionStatus] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(
    "Saved Successfully!"
  );
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const stageData = [
    {
      id: 1,
      name: "Cutting Completed",
      keyword: "cutting_completed",
    },
    {
      id: 2,
      name: "Printed",
      keyword: "printed",
    },
    {
      id: 4,
      name: "Embroidered",
      keyword: "embroidered",
    },
    {
      id: 3,
      name: "Sewing Completed",
      keyword: "sewing_completed",
    },
    {
      id: 5,
      name: "QC Completed",
      keyword: "qc_completed",
    },
    // {
    //     id:6,
    //     name:'Released for Production',
    //     keyword:'released_for_production'
    // },
  ];

  const [stages, setStages] = React.useState(stageData);
  const [activeStep, setActiveStep] = React.useState(1);

  const [openTrackingDetailsModal, setOpenTrackingDetailsModal] =
    React.useState(false);
  const [couriers, setCouriers] = React.useState([]);
  const [isShipToCLientAddress, setShipToCLientAddress] = React.useState(true);
  const [itemIdsInOrders, setItemIdsInOrders] = React.useState([]);
  const [trackingDetails, setTrackingDetails] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const openUploadImageDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmTrackingDetailsClose = () => {
    setOpenConfirmTrackingDetails(false);
  };

  React.useEffect(() => {
    dispatch(patternActions.getAllParameters());
    dispatch(productionOrderActions.getAllParameters());
  }, [dispatch]);

  React.useEffect(() => {
    setPatterns(paramState?.items?.data);
    const po = poFiledState?.items?.data.map((data) => {
      return {
        ...data,
        combinedName: `${data.name} - ${data.chinese_name}!`,
      };
    });
    setPoFields(po);
  }, [paramState?.items?.data, poFiledState?.items?.data]);

  React.useEffect(() => {
    setCouriers(couriersParam?.items?.data);
  }, [couriersParam?.items?.data]);

  const getProductOrderById = (item = null) => {
    setOpenProgressCircular(true);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/invoices/${id}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // logout()
            }
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setOpenProgressCircular(false);
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setProductionOrder(data.data);
            const items = data?.data?.sales_order_items.map((item) => {
              const passedStagesLastIndex =
                item?.sales_order_item_passed_stages?.length - 1 || 0;
              const currentStage =
                item?.sales_order_item_passed_stages[passedStagesLastIndex]
                  ?.stage?.name || "Pending";
              const currentStageId =
                item?.sales_order_item_passed_stages[passedStagesLastIndex]
                  ?.stage?.id || 0;
              return {
                id: item?.id,
                itemId: item?.price?.item?.id,
                name: item?.price?.item?.name,
                chineseName: "",
                status: currentStage,
                status_id: currentStageId,
                style: item.style,
              };
            });
            setProductionOrderItems(items);
            setActiveItemId(item === null ? items[0]?.id : item?.id);
            const activeItemObject =
              item === null
                ? data?.data?.sales_order_items[0]
                : data?.data?.sales_order_items.filter(
                    (i) => i?.id === item?.id
                  )[0];
            const customLabels = activeItemObject?.production_order?.labels.map(
              (l) => {
                return {
                  id: l?.id,
                  url: l?.image_url || l?.url,
                  label: { id: l?.id, name: l?.name || l?.label?.name },
                };
              }
            );

            const {
              design_approval: designApproval,
              prototype_approval: prototypeApproval,
            } = activeItemObject?.production_order;

            setPreProdApprovals({ designApproval, prototypeApproval });

            activeItemObject.production_order["labels"] = customLabels;
            setActiveItem(activeItemObject);
            const patternId = activeItemObject?.production_order?.pattern_id;
            patternId && handlePatternId(patternId);
            const comment = activeItemObject?.production_order?.comment;
            comment && setSpecialComment(comment);

            const salesOrderItemPassedData =
              activeItemObject?.sales_order_item_passed_stages;
            setActiveStep(
              activeItemObject?.sales_order_item_passed_stages?.length
            );
            if (salesOrderItemPassedData.length >= 2) {
              const filterStages = stageData.filter((stage) => {
                return !salesOrderItemPassedData.some((passedData) => {
                  return stage.id === passedData.stage.id;
                });
              });
              setStages(filterStages);
            } else {
              setStages(stageData);
            }

            const status = activeItemObject?.production_order?.status?.keyword;
            if (status === "pending") {
              setProductionOrderButtonText("Level 1 Approval");
              setProductionStatus("Pending");
            } else if (status === "completed") {
              setProductionOrderButtonText("Level 2 Approval");
              setProductionStatus("Completed");
            } else if (status === "approved") {
              if (
                salesOrderItemPassedData[salesOrderItemPassedData?.length - 1]
                  ?.stage.id === 5
              ) {
                setProductionOrderButtonText("Shipping");
              } else {
                if (
                  salesOrderItemPassedData[salesOrderItemPassedData?.length - 1]
                    ?.stage.id === 7
                ) {
                  setProductionOrderButtonText("Shipped");
                } else {
                  setProductionOrderButtonText(
                    "Level 2 Approved & Production Start"
                  );
                }
              }
              setProductionStatus("Approved");
            }

            const sizes = activeItemObject.item_details.map((item) => {
              return {
                id: item.id,
                size_id: item?.size?.id,
                sizeCode: item.size?.code,
                number: item?.number || "-",
                name: item?.name || "-",
              };
            });
            setNameAndSizes(sizes);
            const sizeWithQuantity = sizes?.map((s) => {
              return {
                size_id: s?.size_id,
                sizeCode: s?.sizeCode,
                quantity: 1,
              };
            });
            let hash = Object.create(null);
            let sizesCounts = [];

            sizeWithQuantity.forEach(function (o) {
              if (!hash[o.size_id]) {
                hash[o.size_id] = {
                  size_id: o.size_id,
                  quantity: 0,
                  sizeCode: o.sizeCode,
                };
                sizesCounts.push(hash[o.size_id]);
              }
              hash[o.size_id].quantity += +o.quantity;
            });
            setSizeCount(sizesCounts);
            setOpenProgressCircular(false);
            if (data?.data?.invoice_courier_details?.length !== 0) {
              const isClientAddress = data?.data?.invoice_courier_details?.some(
                (i) => i?.shipping_type?.id === 2
              );
              setShipToCLientAddress(isClientAddress);
            }
          }
        });
      }
    );

    fetch(`${apiConstants.API_URL}/colors`, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setColors(data?.data);
        }
      });
    });

    fetch(`${apiConstants.API_URL}/fabrics`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // logout()
            }
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setFabrics(data?.data);
          }
        });
      }
    );
    fetch(`${apiConstants.API_URL}/positions?status=true`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // logout()
            }
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setPositions(data?.data);
          }
        });
      }
    );

    fetch(`${apiConstants.API_URL}/labels`, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setLabels(data?.data);
        }
      });
    });

    fetch(`${apiConstants.API_URL}/trims`, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setTrims(data?.data);
        }
      });
    });
  };

  React.useEffect(() => {
    getProductOrderById(null);
  }, []);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/production-orders/proceededProductionOrder"
    >
      All Invoices In Production
    </Link>,
    <Typography key="3">{id}</Typography>,
  ];

  const handleActiveItem = (itemId) => {
    setActiveItemId(itemId);
    let filterObj = productionOrder?.sales_order_items.filter(
      (item) => item?.id === itemId
    );
    const activeItemCustom = filterObj[0];

    const {
      design_approval: designApproval,
      prototype_approval: prototypeApproval,
    } = activeItemCustom?.production_order;

    setPreProdApprovals({ designApproval, prototypeApproval });

    const customLabels = filterObj[0]?.production_order?.labels.map((l) => {
      return {
        id: l?.id,
        url: l?.image_url || l?.url,
        label: { id: l?.id, name: l?.name || l?.label?.name },
      };
    });

    activeItemCustom.production_order["labels"] = customLabels;
    setActiveItem(activeItemCustom);
    const sizes = filterObj[0].item_details.map((item) => {
      return {
        id: item.id,
        size_id: item?.size?.id,
        sizeCode: item.size?.code,
        number: item?.number || "-",
        name: item?.name || "-",
      };
    });
    setNameAndSizes(sizes);
    const sizeWithQuantity = sizes?.map((s) => {
      return {
        size_id: s.size_id,
        sizeCode: s.sizeCode,
        quantity: 1,
      };
    });

    let hash = Object.create(null);
    let sizesCounts = [];

    sizeWithQuantity.forEach(function (o) {
      if (!hash[o.size_id]) {
        hash[o.size_id] = {
          size_id: o.size_id,
          quantity: 0,
          sizeCode: o.sizeCode,
        };
        sizesCounts.push(hash[o.size_id]);
      }
      hash[o.size_id].quantity += +o.quantity;
    });
    setSizeCount(sizesCounts);

    const patternId = filterObj[0]?.production_order?.pattern_id;
    if (patternId !== null) {
      handlePatternId(patternId);
    } else {
      setSelectedPatternId(null);
      setSelectedPattern([]);
      setPatternSizeAdultHeader([]);
      setPatternSizeChildHeader([]);
      setSelectedPatternObj({});
      setSelectedPatternImage({});
    }

    const comment = filterObj[0]?.production_order?.comment;
    if (comment) {
      setSpecialComment(comment);
    } else {
      setSpecialComment("");
    }

    const salesOrderItemPassedData =
      filterObj[0]?.sales_order_item_passed_stages;

    setActiveStep(salesOrderItemPassedData?.length);
    if (salesOrderItemPassedData.length > 1) {
      const filterStages = stageData.filter((stage) => {
        return !salesOrderItemPassedData.some((passedData) => {
          return stage.id === passedData.stage.id;
        });
      });
      setStages(filterStages);
    } else {
      setStages(stageData);
    }

    const status = filterObj[0]?.production_order?.status?.keyword;
    if (status === "pending") {
      setProductionOrderButtonText("Level 1 Approval");
      setProductionStatus("Pending");
    } else if (status === "approved") {
      if (
        salesOrderItemPassedData[salesOrderItemPassedData?.length - 1]?.stage
          .id === 5
      ) {
        setProductionOrderButtonText("Shipping");
      } else {
        if (
          salesOrderItemPassedData[salesOrderItemPassedData?.length - 1]?.stage
            .id === 7
        ) {
          setProductionOrderButtonText("Shipped");
        } else {
          setProductionOrderButtonText("Level 2 Approved & Production Start");
        }
      }
      setProductionStatus("Approved");
    } else if (status === "completed") {
      setProductionOrderButtonText("Level 2 Approval");
      setProductionStatus("Completed");
    }
  };

  const handlePatternId = (patternId) => {
    setSelectedPatternId(patternId);

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/patterns/${patternId}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // logout()
            }
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            console.log({
              data: data?.data?.measurements,
            });
            const customizePattern = data?.data?.measurements;

            setSelectedPattern(customizePattern);
            setSelectedPatternImage({
              image1: data?.data?.image_url,
              image2: data?.data?.image_2_url,
            });
            setSelectedPatternObj({
              id: data?.data?.id,
              name: data?.data?.name,
              code: data?.data?.code,
            });
          }
        });
      }
    );
  };

  const handleProductionStatus = () => {
    const passedStageData = activeItem?.sales_order_item_passed_stages;
    const productionStatusChange =
      passedStageData[passedStageData?.length - 1]?.stage.id !== 5;
    const status =
      activeItem?.production_order?.status?.keyword === "pending"
        ? "complete"
        : "approve";
    if (productionStatusChange) {
      const requestOptions = {
        method: "PUT",
        headers: authHeader(),
      };
      fetch(
        `${apiConstants.API_URL}/production-orders/${String(
          activeItem?.production_order?.id
        )}/${status}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setSuccessMessage(
              `${
                status === "complete" ? "Level 1 Approved" : "Level 2 Approved"
              } Successfully`
            );
            if (status === "approve") {
              handleStoreStages({
                id: 6,
                name: "Released for Production",
                keyword: "released_for_production",
              });
            } else if (status === "complete") {
              const item = activeItem;
              getProductOrderById(item);
            }
            setOpenDialog(true);
          }
        });
      });
    } else {
      handleStoreStages({
        id: 7,
        name: "Shipped",
        keyword: "shipped",
      });
    }
  };

  const handleSubmit = (value, type) => {
    let statusType = "";
    switch (type) {
      case "fabric":
        statusType = "Fabric Details";
        break;
      case "comment":
        statusType = "Comment";
        break;
      case "label":
        statusType = "Labels";
        break;
      case "pattern":
        statusType = "Specification";
        break;
      default:
        statusType = "";
    }

    const reqData = {
      production_order_no: String(activeItem?.production_order?.id),
      organization_id: productionOrder?.organization?.id,
      pattern_id: parseInt(selectedPatternId),
      price_id: activeItem?.price?.id,
      field_values: [],
      label_ids: [],
      comment: specialComment,
      embroideries: [] || null,
    };

    if (type === "label") {
      const labels = value.map((l) => {
        return l?.label?.id;
      });
      reqData["label_ids"] = labels;
      delete reqData["field_values"];
      delete reqData["embroideries"];
    }

    if (type === "fabric") {
      console.log(
        activeItem?.production_order?.po_fields,
        "start part in fabric"
      );
      const trimsPoFields =
        activeItem?.production_order?.po_fields.filter(
          (p) => p?.is_trim === 1
        ) || [];
      console.log(trimsPoFields, "filter trims fields");
      const trims = trimsPoFields?.map((t) => {
        return {
          po_field_id: t?.id,
          value: t?.po_field_values?.value,
          chinese_value: t?.po_field_values?.chinese_name
            ? t?.po_field_values?.chinese_name
            : t?.po_field_values?.value,
          color_id: t?.po_field_values?.color?.id,
        };
      });
      const fabrics = value.map((f) => {
        return {
          po_field_id: f?.item?.id,
          value: f?.fabricType?.name,
          chinese_value: f?.fabricType?.chinese_name
            ? f?.fabricType?.chinese_name
            : f?.fabricType?.name,
          color_id: f?.color?.id,
        };
      });
      const mergeResult = [...fabrics, ...trims];
      console.log(mergeResult, "fabric merge result");
      reqData["field_values"] = mergeResult;
      delete reqData["embroideries"];
      delete reqData["label_ids"];
    }

    if (type === "trims") {
      console.log(
        activeItem?.production_order?.po_fields,
        "start part in trim"
      );
      const activePoFields =
        activeItem?.production_order?.po_fields.length == 0
          ? []
          : activeItem?.production_order?.po_fields.filter(
              (p) => p?.is_trim === 0
            );
      console.log(activePoFields, "filter no trim fields");
      const poFieldItems = poFields.filter((p) => p?.is_trim === 0) || [];
      if (activePoFields?.length == 0) {
        const poField = {
          id: poFieldItems[0]?.id,
          name: poFieldItems[0]?.name,
          is_trim: poFieldItems[0]?.is_trim,
          po_field_values: {
            value: activeItem?.price?.fabric?.name,
            color: activeItem?.color,
          },
        };
        activePoFields.push(poField);
      }
      const fields = activePoFields.map((f) => {
        return {
          po_field_id: f?.id,
          value: f?.po_field_values?.value,
          chinese_value: f?.po_field_values?.chinese_name
            ? f?.po_field_values?.chinese_name
            : f?.po_field_values?.value,
          color_id: f?.po_field_values?.color?.id,
        };
      });
      const trims = value.map((t) => {
        return {
          po_field_id: t?.item?.id,
          value: t?.fabricType?.name,
          chinese_value: t?.fabricType?.chinese_name
            ? t?.fabricType?.chinese_name
            : t?.fabricType?.name,
          color_id: t?.color?.id,
        };
      });
      const mergeResult = [...fields, ...trims];
      console.log(mergeResult, "trim merge result");
      reqData["field_values"] = mergeResult;
      delete reqData["embroideries"];
      delete reqData["label_ids"];
    }

    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify(removeNullUndefinedWithReduce(reqData)),
    };

    fetch(
      `${apiConstants.API_URL}/production-orders/${String(
        activeItem?.production_order?.id
      )}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          console.log(errorMessage[0]);
          if (errorMessage[0] === "The pattern id must be an integer.") {
            setToastMessage("Pattern is Required");
          } else {
            setToastMessage(errorMessage);
          }

          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setSuccessMessage(`${statusType} Saved Successfully!`);
          setOpenDialog(true);
          getProductOrderById(activeItem);
        }
      });
    });
  };

  const handleStoreStages = (stageType, ids = []) => {
    const reqData = {
      sales_order_item_stage_id: stageType?.id,
      start_date: moment(Today).format("YYYY-MM-DD"),
      end_date: moment(Today).format("YYYY-MM-DD"),
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };
    if (ids?.length === 0) {
      fetch(
        `${apiConstants.API_URL}/sales-order-items/${activeItem?.id}/store-stage`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setSuccessMessage(`Update Status Successfully!`);
            setOpenDialog(true);
            const item = activeItem;
            item?.sales_order_item_passed_stages.push({ stage: stageType });
            setActiveItem(item);
            setActiveStep(item?.sales_order_item_passed_stages?.length);
            getProductOrderById(item);
          }
        });
      });
    } else {
      console.log(ids, "changing stages for all items");
      ids.forEach((item, index) => {
        fetch(
          `${apiConstants.API_URL}/sales-order-items/${item?.id}/store-stage`,
          requestOptions
        ).then((response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setToastMessage(errorMessage);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              if (ids?.length === index + 1) {
                setSuccessMessage(`Update Status Successfully!`);
                setOpenDialog(true);
              }
              const updateItem = productionOrder?.sales_order_items.filter(
                (i) => i?.id === item?.id
              );
              updateItem[0]?.sales_order_item_passed_stages.push({
                stage: stageType,
              });
              setActiveItem(updateItem[0]);
              setActiveStep(
                updateItem[0]?.sales_order_item_passed_stages?.length
              );
              getProductOrderById(updateItem[0]);
            }
          });
        });
      });
    }
  };

  const handleShippingDetails = () => {
    setOpenTrackingDetailsModal(true);
    dispatch(couriersActions.getAllParameters());
  };

  const handleTrackingDetailsModalClose = () => {
    setOpenTrackingDetailsModal(false);
  };

  const submitTrackingId = (values) => {
    setTrackingDetails(values);
    setOpenConfirmTrackingDetails(true);
  };

  const handleConfirmTracking = () => {
    console.log(trackingDetails);
    const shippingTypeId = trackingDetails?.is_client_Address ? 2 : 1;
    const reqData = {
      shipping_type_id: shippingTypeId,
      courier_id: trackingDetails?.courier_id,
      tracking_code: trackingDetails?.tracking_number,
      // "shipped_date": "2023-09-09 12:23:09"
    };
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };
    fetch(
      `${apiConstants.API_URL}/invoices/${id}/set-tracking-code`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setSuccessMessage(
            `Handed over courier and to be ship to ${
              isShipToCLientAddress ? "Client Address" : "Head Office"
            } Successfully!`
          );
          setOpenDialog(true);
          handleTrackingDetailsModalClose();
          handleConfirmTrackingDetailsClose();
          getProductOrderById(null);
          handleStoreStages(
            {
              id: 7,
              name: "Shipped",
              keyword: "shipped",
            },
            productionOrderItems
          );
        }
      });
    });
  };

  const [templates, setTemplates] = React.useState({});

  const handleTemplateChange = (template) => {
    console.log("template:", templates);

    setTemplates((prev) => {
      prev[activeItem?.id] = template;
      return { ...prev };
    });
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "20px 20px" }}>
      <Box className={classes.headerContainer}>
        <Intro
          pageTitle={`All Invoices In Production`}
          pageTitleShort=""
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
        />
        <Box className={classes.headerButtonContainer}>
          <ButtonGroup variant="contained">
            <Button className={classes.printButtonStyle}> Print 打印 </Button>
            <Button className={classes.downloadButtonStyle}>
              download 下载
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      {Object.keys(activeItem).length !== 0 && (
        <OrderInformation
          id={id}
          productionOrder={productionOrder}
          activeItem={activeItem}
        />
      )}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        {Object.keys(activeItem).length !== 0 && (
          <Box gridColumn="span 4">
            <ProductionOrderViewSideBar
              productionOrderItems={productionOrderItems}
              activeItemId={activeItemId}
              setActiveItem={handleActiveItem}
              buttonText={productionOrderButtonText}
              productionStatus={productionStatus}
              activeItem={activeItem}
              handleShippingDetails={handleShippingDetails}
            />
            <div style={{ paddingTop: "80px" }}>
              <Sticky enabled={true} top={10}>
                <img src={`${activeItem?.image_url}`} width={"100%"} alt="" />
              </Sticky>
            </div>
          </Box>
        )}
        <Box gridColumn="span 8">
          {Object.keys(activeItem).length !== 0 && (
            <OrderItemsContainer
              id={id}
              productionOrder={productionOrder}
              activeItem={activeItem}
              colors={colors}
              fabrics={fabrics}
              trims={trims}
              poFields={poFields}
              nameAndSizes={nameAndSizes}
              sizeCounts={sizeCounts}
              labels={labels}
              patterns={patterns}
              setPatternId={handlePatternId}
              selectedPattern={selectedPattern}
              selectedPatternObj={selectedPatternObj}
              patternSizeAdultHeader={patternSizeAdultHeader}
              patternSizeChildHeader={patternSizeChildHeader}
              selectedPatternImage={selectedPatternImage}
              setSpecialComment={setSpecialComment}
              specialComment={specialComment}
              handleSubmit={handleSubmit}
              handleStoreStages={handleStoreStages}
              stages={stages}
              activeStep={activeStep}
              handleProductionStatus={handleProductionStatus}
              productionStatus={productionStatus}
              buttonText={productionOrderButtonText}
              setActiveItem={setActiveItem}
              positions={positions}
              preProdApprovals={preProdApprovals}
              handleTemplateChange={handleTemplateChange}
              templates={templates}
            />
          )}
        </Box>
      </Box>

      <AlertToast
        toastMessage={toastMessage}
        toastState={toastState}
        handleClose={handleToastClose}
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {successMessage}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmTrackingDetails}
        onClose={handleConfirmTrackingDetailsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Shipping Delivery Address?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure,{" "}
            {trackingDetails?.is_client_Address
              ? `ship to this client address: ${productionOrder?.ship_to}`
              : "ship to Head Office"}{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmTrackingDetailsClose}>Disagree</Button>
          <Button onClick={handleConfirmTracking} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <ProgressCircularWithBackdrop open={openProgressCircular} />
      <AddTrackingDetailsModal
        open={openTrackingDetailsModal}
        type={"new"}
        handleClose={handleTrackingDetailsModalClose}
        couriers={couriers}
        submitTrackingId={submitTrackingId}
        data={productionOrder}
      />

      <Dialog open={open} onClose={handleCloseDialog} fullScreen>
        <DialogContent>
          <AppBar elevation={0} sx={{ position: "relative" }}>
            <Toolbar sx={{ px: { sm: 2 } }}>
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
                {/*{record && record[recordIdentifier]}*/}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Formik
            initialValues={""}
            validationSchema={""}
            // innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
              // handleSubmit(values);
              resetForm();
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              setFieldValue,
            }) => (
              <Form noValidate autoComplete="off">
                <Grid
                  container
                  spacing={{ xs: 2, sm: 2 }}
                  sx={{ pt: 0, pb: 0 }}
                >
                  <Grid item xs={6} style={{ paddingTop: "35px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Oredr Item
                    </InputLabel>
                    <Select
                      fullWidth
                      label="Item"
                      id="item"
                      name="item"
                      value={values.item}
                      // label="Age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      // className={styles.textField}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      <MenuItem
                        // key={name?.id}
                        value={1}
                        // style={getStyles(name, personName, theme)}
                      >
                        Item 1
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      spacing={{ xs: 2, sm: 2 }}
                      sx={{ pt: 0, pb: 0, mt: 2 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <label htmlFor="image">
                          <br />
                          <Card
                            elevation={0}
                            sx={{
                              border: "1px dashed #bbb",
                              cursor: "pointer",
                              mt: 2,
                              minHeight: "15rem",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <CardContent>
                              <Box>
                                <SvgIcon
                                  sx={{
                                    width: "3rem",
                                    height: "3rem",
                                    margin: "1rem auto 1.5rem auto",
                                    opacity: "0.8",
                                    display: "block",
                                  }}
                                >
                                  <svg
                                    xmlSpace="preserve"
                                    viewBox="0 0 409.794 409.794"
                                  >
                                    <path d="M387.367 176.731c3.861-2.284 5.154-7.267 2.869-11.128L329.955 63.46a8.133 8.133 0 0 0-6.999-3.999H96.876a8.117 8.117 0 0 0-7.096 4.17L32.848 165.774a8.111 8.111 0 0 0 3.138 11.055 8.08 8.08 0 0 0 3.95 1.032 8.106 8.106 0 0 0 7.104-4.17l54.616-97.982h216.651l57.924 98.144a8.138 8.138 0 0 0 11.136 2.878zM401.665 196.768H301.196c-4.495 0-8.129 3.633-8.129 8.129s3.633 8.129 8.129 8.129h92.341v121.059H16.257v-114.37h83.139c4.495 0 8.129-3.633 8.129-8.129s-3.633-8.129-8.129-8.129H8.129A8.121 8.121 0 0 0 0 211.586v130.618a8.121 8.121 0 0 0 8.129 8.129h393.537a8.121 8.121 0 0 0 8.129-8.129V204.897a8.129 8.129 0 0 0-8.13-8.129z" />
                                    <path d="m258.277 202.41-12.42 38.741h-76.254l-21.151-40.049c-2.089-3.967-7.015-5.511-10.982-3.39-3.967 2.097-5.495 7.007-3.39 10.982l23.443 44.382a8.12 8.12 0 0 0 7.186 4.333h87.081a8.12 8.12 0 0 0 7.738-5.641l14.233-44.382c1.374-4.276-.984-8.852-5.251-10.226-4.299-1.367-8.867.99-10.233 5.25zM333.011 168.652a8.107 8.107 0 0 0 10.949 3.503 8.128 8.128 0 0 0 3.503-10.949l-11.51-22.354a8.126 8.126 0 0 0-7.226-4.406H98.421a8.14 8.14 0 0 0-7.486 4.95L81.44 161.75c-1.756 4.129.171 8.901 4.308 10.665a8.102 8.102 0 0 0 3.178.642 8.141 8.141 0 0 0 7.486-4.95l7.389-17.403H323.76l9.251 17.948zM107.216 126.79a8.141 8.141 0 0 0 7.486-4.95l6.706-15.834h178l8.568 16.428a8.125 8.125 0 0 0 10.974 3.447 8.144 8.144 0 0 0 3.447-10.974l-10.852-20.801a8.138 8.138 0 0 0-7.21-4.365H116.027a8.14 8.14 0 0 0-7.486 4.95l-8.811 20.793c-1.756 4.137.187 8.909 4.308 10.665a8.313 8.313 0 0 0 3.178.641z" />
                                  </svg>
                                </SvgIcon>
                              </Box>
                              <Typography
                                gutterBottom
                                variant="body"
                                component="div"
                                sx={{
                                  textAlign: "center",
                                  fontSize: "0.875rem",
                                  lineHeight: "normal",
                                }}
                              >
                                Click or drag files to upload Label
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  textAlign: "center",
                                  fontSize: "0.65rem",
                                }}
                              >
                                (Accepted file types: JPEG, PNG, GIF, BMP)
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ pointerEvents: "none", mb: 1 }}>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                fullWidth
                              >
                                Upload
                              </Button>
                            </CardActions>
                          </Card>
                        </label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          // onChange={(e) => {
                          //
                          //     handleImageUpload(e);
                          // }}
                          // onBlur={handleBlur}

                          style={{
                            position: "absolute",
                            pointerEvents: "none",
                            visibility: "hidden",
                          }}
                        />
                        <br />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ paddingTop: "150px" }}>
                  <Button variant="contained">Save</Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ProductionOrderView;
