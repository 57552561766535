export const liningConstants = {
  GET_SINGLE_REQUEST: "LINING_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "LINING_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "LINING_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "LINING_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "LINING_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "LINING_GET_ALL_FAILURE",

  ADD_REQUEST: "LINING_ADD_REQUEST",
  ADD_SUCCESS: "LINING_ADD_SUCCESS",
  ADD_FAILURE: "LINING_ADD_FAILURE",

  UPDATE_REQUEST: "LINING_UPDATE_REQUEST",
  UPDATE_SUCCESS: "LINING_UPDATE_SUCCESS",
  UPDATE_FAILURE: "LINING_UPDATE_FAILURE",

  DELETE_REQUEST: "LINING_DELETE_REQUEST",
  DELETE_SUCCESS: "LINING_DELETE_SUCCESS",
  DELETE_FAILURE: "LINING_DELETE_FAILURE",

  CLEAR: "LINING_CLEAR",
};
