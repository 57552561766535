import * as React from "react";
import PropTypes from "prop-types";
import {Stack, Stepper, Step, StepLabel, Typography  } from "@mui/material";
import { HORIZONTAL, VERTICAL } from "../constants/Constants";
import moment from "moment";

const QuotationActivityLogsStepper = ({
  steps,
  activeStep,
  orientation,
}) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
        activeStep={activeStep}
        orientation={orientation}
      >
        {steps.map((row, index) => (
            <Step key={index}>    
              <StepLabel>
                <Typography sx={{fontWeight:700, fontSize:13}}>{moment(row.created_at).format('DD/MM/YYYY')}</Typography>
                <Typography sx={{color:row.color === 'default' ? '#2ab5bf' :'#69c464' , fontWeight:600, width:'100%', textTransform:'capitalize'}}>{row.action}</Typography>
              </StepLabel>
              <Typography sx={{marginLeft: '12px', paddingLeft: '20px', paddingRight: '8px', borderLeft: '1px solid #bdbdbd'}}>{row.description}</Typography>
            </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

QuotationActivityLogsStepper.propTypes = {
  /**
   * Stpes to be displayed
   */
  steps: PropTypes.array.isRequired,

  /**
   *  Active stage
   */
  activeStep: PropTypes.number,

  /**
   *
   */
  orientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),
};

QuotationActivityLogsStepper.defaultProps = {
  activeStep: 1,
  orientation: HORIZONTAL,
  steps: [],
};

export default QuotationActivityLogsStepper;
