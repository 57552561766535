import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import QuantityForm from "../QuantitiesInput/QuantityForm.component";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import styles from "./QuantitiesTable.module.scss";

const QuantitiesTable = (props) => {
  const {
    sizeQuantity,
    setSizeQuantity,
    updateSizeQunatity,
    sizes,
    totalQuantity,
  } = props;

  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  // handle delete row click
  const handleRemoveRow = (index) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  // handle edit row click
  const handleEditRow = (index) => {
    setEditIndex(index);
    setEditDialogOpen(true);
  };

  // handle delete confirmed
  const handleDeleteConfirmed = () => {
    if (deleteIndex !== null) {
      const updatedRows = [...sizeQuantity];
      updatedRows.splice(deleteIndex, 1);
      setSizeQuantity(updatedRows);
      setDeleteIndex(null);
    }
    setDeleteDialogOpen(false);
  };

  // handle edit confirmed
  // const handleEditConfirmed = () => {
  //   console.log("edit saved");
  // };

  // close delete dialog
  const handleDeleteDialogClose = () => {
    setDeleteIndex(null);
    setDeleteDialogOpen(false);
  };

  // close edit dialog
  const handleEditDialogClose = () => {
    setEditIndex(null);
    setEditDialogOpen(false);
  };

  // close edit dialog upon success
  useEffect(() => {
    handleEditDialogClose();
  }, [sizeQuantity]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={styles.thead}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Size</TableCell>
              <TableCell width={100}>Quantity</TableCell>
              <TableCell width={115}>Customize?</TableCell>
              <TableCell>Names/Numbers</TableCell>
              <TableCell align="right" width={100}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sizeQuantity.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.size.toUpperCase()}</TableCell>
                <TableCell>{row?.quantity}</TableCell>
                <TableCell>{row?.customize ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <div className={styles.nameWrap}>
                    {row?.customizations?.length > 0 &&
                      row?.customizations.map((item, index) => (
                        // <Typography variant="body2" key={item?.name}>
                        //   {item?.name}/{item?.number}
                        // </Typography>
                        <Chip
                          key={item?.name}
                          label={`${item?.name}/${item?.number}`}
                          className={styles.chip}
                        />
                      ))}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEditRow(index)}>
                    <ModeEditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveRow(index)}
                    sx={{ mr: -1.5 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter className={styles.tFoot}>
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="button">Total Quantity</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="button">{totalQuantity}</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this row?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Update Details</DialogTitle>
        <DialogContent className={styles.editDialogContent}>
          <QuantityForm
            sizeOptions={sizes}
            mode="edit"
            size={sizeQuantity[editIndex]?.size}
            quantity={sizeQuantity[editIndex]?.quantity}
            customize={sizeQuantity[editIndex]?.customize}
            customizations={sizeQuantity[editIndex]?.customizations}
            updateSizeQunatity={updateSizeQunatity}
            editIndex={editIndex}
          />
        </DialogContent>
        <DialogActions
          className={styles.editDialogActions}
          sx={{ justifyContent: "flex-start" }}
        >
          <Button
            onClick={handleEditDialogClose}
            color="primary"
            sx={{ ml: 1, position: "relative", top: -1 }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuantitiesTable;
