import { deliveryFeeConstants } from "../../constants";

export function deliveryFees(state = {}, action) {
  switch (action.type) {
    // get all
    case deliveryFeeConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case deliveryFeeConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case deliveryFeeConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case deliveryFeeConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case deliveryFeeConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case deliveryFeeConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case deliveryFeeConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case deliveryFeeConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case deliveryFeeConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case deliveryFeeConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case deliveryFeeConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case deliveryFeeConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case deliveryFeeConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
