import { getAxiosInstance } from "../axios";
import { getValue, hasPersisted, persistKeys, storeValue } from "../persist";

export class UtilService {
  async getColors() {
    try {
      if (hasPersisted(persistKeys.colors)) {
        return getValue(persistKeys.colors);
      }

      const response = await getAxiosInstance().get("/colors");
      storeValue(persistKeys.colors, response.data);
      return response.data;
    } catch (error) {}
  }

  async getFabrics() {
    try {
      if (hasPersisted(persistKeys.fabrics)) {
        return getValue(persistKeys.fabrics);
      }

      const response = await getAxiosInstance().get("/fabrics");
      storeValue(persistKeys.fabrics, response.data);
      return response.data;
    } catch (error) {}
  }

  async getLabels() {
    try {
      if (hasPersisted(persistKeys.labels)) {
        return getValue(persistKeys.labels);
      }

      const response = await getAxiosInstance().get("/labels");
      storeValue(persistKeys.labels, response.data);
      return response.data;
    } catch (error) {}
  }

  async getTrims() {
    try {
      if (hasPersisted(persistKeys.trims)) {
        return getValue(persistKeys.trims);
      }

      const response = await getAxiosInstance().get("/trims");
      storeValue(persistKeys.trims, response.data);
      return response.data;
    } catch (error) {}
  }

  async getCountries() {
    try {
      if (hasPersisted(persistKeys.countries)) {
        return getValue(persistKeys.countries);
      }

      const response = await getAxiosInstance().get("/countries");
      storeValue(persistKeys.countries, response.data);
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
}
