import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { patternActions, sizeActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const Patterns = (props) => {
  const { paramSlug } = props;
  const dispatch = useDispatch();
  const patterns = useSelector((state) => state.patterns);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Pattern Name",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "code",
      headerName: "Pattern Code",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "image",
      headerName: "Pattern Image",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      hide: true,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(patternActions.getAllParameters());
    dispatch(sizeActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {patterns && (
        <DataTable
          loading={patterns?.loading}
          rows={patterns?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="patternActions"
          recordType={paramSlug}
        />
      )}
    </Box>
  );
};

export default Patterns;
