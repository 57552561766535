import { apiConstants } from "../constants";
import { authHeader } from "../helpers";

export const roleService = {
    getAllExternalRole,
    getAllInternalRole,
    getSingle,
    addRole,
    updateRole,
    updatePermissions,
};

function getAllInternalRole() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
  
    return fetch(`${apiConstants.API_URL}/auth/get-admin-roles`, requestOptions).then(
      handleResponse
    );
}

function getAllExternalRole() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
  
    return fetch(`${apiConstants.API_URL}/auth/get-customer-roles`, requestOptions).then(
      handleResponse
    );
}

// get single parameter
function getSingle(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/auth/roles/${id}`, requestOptions).then(
    handleResponse
  );
}

function addRole(
  role,
  description,
  roleType,
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      name : role,
      // description: description,
      guard_name: roleType,
    }),
  };

  return fetch(`${apiConstants.API_URL}/auth/roles`, requestOptions).then(
    handleResponse
  );
}

function updateRole(
  id,
  role,
  description,
  roleType,
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      role : role,
      description: description,
      guard_name: roleType,
    }),
  };

  return fetch(`${apiConstants.API_URL}/update/${id}`, requestOptions).then(
    handleResponse
  );
}

function updatePermissions(
  roleId,
  permissions
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({"permissions":permissions}),
  };

  return fetch(`${apiConstants.API_URL}/auth/role/${roleId}/assign-permissions`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          //logout();
          // window.location.reload(true);
        }
  
        const error = (data && data.error) || response.statusText;
        const errorMessage = Object?.values(error).map((errorKey) => {
          return errorKey[0];
        })
        return Promise.reject(error);
      }
      return data;
    });
}