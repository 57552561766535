import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import rootReducer from "./../reducers";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { baseApi } from "../services/api";

const loggerMiddleware = createLogger();
const middleware = [thunkMiddleware, loggerMiddleware, baseApi.middleware];

// export const store = createStore(
//   rootReducer,
//   // applyMiddleware(thunkMiddleware, loggerMiddleware),
//   composeWithDevTools(applyMiddleware(...middleware))
// );

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
