import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  order: [],
  listedItems: [],
  activeSport: null,
  activeSports: [],
  allActiveSport: [],
};

const orderSlice = createSlice({
  name: "orders",
  initialState: initialValue,
  reducers: {
    addOrder: (state, action) => {
      let array = [...state.order];
      array.push(action.payload);
      state.order = array;
    },
    removeOrder: (state, action) => {
      const { newItem } = action?.payload;
      let array = [...state.order];
      array = array.filter((f) => f.id !== newItem.id);
      return {
        ...state,
        order: [...array],
      };
    },
    addtoList: (state, action) => {
      state.listedItems = [...state.listedItems, ...action.payload];
    },
    updateOrder: (state, action) => {
      const { newItem } = action?.payload;
      let newArry = [...state.order];
      const index = newArry.findIndex((f) => f.id === newItem.id);
      newArry[index] = newItem;
      return {
        ...state,
        order: [...newArry],
      };
    },
    updateList: (state, action) => {
      const { newItem } = action?.payload;
      let newArry = [...state.listedItems];
      const index = newArry.findIndex((f) => f.id === newItem.id);
      newArry[index] = newItem;
      return {
        ...state,
        listedItems: [...newArry],
      };
    },
    setAtiveSport: (state, action) => {
      return {
        ...state,
        activeSport: action.payload,
      };
    },
    addSingleItem: (state, action) => {
      state.listedItems.push(action.payload);
    },
    getFlteredItems: (state, action) => {
      return "";
    },
    addActiveSports: (state, action) => {
      state.activeSports.push(action.payload);
    },
    addAllOrder: (state, action) => {
      let listed = [...action.payload];
      listed = listed.map((l) => {
        let newOb = {
          ...l,
          selected: true,
        };
        return newOb;
      });
      return {
        ...state,
        order: listed,
        listedItems: listed,
      };
    },
    removeAll: (state, action) => {
      return {
        ...state,
        order: [],
        listedItems: [],
      };
    },
    removeList: (state, action) => {
      const { newItem } = action?.payload;
      let array = [...state.listedItems];
      array = array.filter((f) => f.id !== newItem.id);
      return {
        ...state,
        listedItems: [...array],
      };
    },
    addActiveAll: (state, action) => {
      const { allActiveSport, listedItems, order } = state;
      let newState = [...allActiveSport, action.payload.activeSport];
      let newActiveted = listedItems.map((l) => {
        if (l.sport_id === action.payload.activeSport) {
          return { ...l, selected: true };
        } else {
          return {
            ...l,
          };
        }
      });
      let tobeOrdered = newActiveted.filter(
        (l) => l.sport_id === action.payload.activeSport
      );
      let newOrder = [...order, ...tobeOrdered];
      return {
        ...state,
        allActiveSport: newState,
        listedItems: newActiveted,
        order: newOrder,
      };
    },
    removeActiveAll: (state, action) => {
      const { allActiveSport, listedItems, order } = state;
      const { activeSport } = action.payload;

      let newState = allActiveSport.filter((f) => f !== activeSport);
      let newActiveted = listedItems.map((l) => {
        if (l.sport_id === activeSport) {
          return {
            ...l,
            selected: false,
          };
        } else {
          return { ...l };
        }
      });

      let newOrder = order.filter((o) => o.sport_id !== activeSport);
      return {
        ...state,
        allActiveSport: newState,
        listedItems: newActiveted,
        order: newOrder,
      };
    },
  },
});
export const {
  addOrder,
  removeOrder,
  updateOrder,
  updateList,
  addtoList,
  addSingleItem,
  setAtiveSport,
  getFlteredItems,
  addAllOrder,
  removeList,
  addActiveAll,
  removeActiveAll,
  removeAll,
} = orderSlice.actions;

export default orderSlice.reducer;
