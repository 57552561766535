import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./Intro.module.scss";

const Intro = ({ pageTitle, pageSubTitle, breadcrumbs, additionalWidgets }) => {
  return (
    <Box
      component="section"
      sx={{
        mb: {
          xs: 3,
          md: 2,
        },
      }}
    >
      <Grid container>
        <Grid item>
          {pageTitle && (
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "1.2rem",
                  md: "1.4rem",
                  lg: "1.6rem",
                },
                fontWeight: 400,
              }}
            >
              {pageTitle}
            </Typography>
          )}
          {pageSubTitle && (
            <Typography variant="subtitle">{pageSubTitle}</Typography>
          )}

          {breadcrumbs && (
            <Breadcrumbs
              separator={<NavigateNextTwoToneIcon fontSize="small" />}
              aria-label="breadcrumb"
              className={styles.breadcrumbs}
            >
              {breadcrumbs}
            </Breadcrumbs>
          )}
        </Grid>

        <Grid sx={{ flex: 1 }}>{additionalWidgets}</Grid>
      </Grid>
    </Box>
  );
};

export default Intro;
