import { organizationConstants } from "../constants";
import { organizationService } from "../services";
import { alertActions } from "../actions";

export const organizationActions = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    organizationService.getSingleParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
        // dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: organizationConstants.GET_SINGLE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: organizationConstants.GET_SINGLE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: organizationConstants.GET_SINGLE_FAILURE, error };
  }
}

// get all parameter
function getAllParameters() {
  return (dispatch) => {
    dispatch(request());

    organizationService.getAllParameters().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error))
    );
  };

  function request(params) {
    return { type: organizationConstants.GET_ALL_REQUEST, params };
  }
  function success(items) {
    return { type: organizationConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: organizationConstants.GET_ALL_FAILURE, error };
  }
}

// add parameter
function addParameter(
  name,
  email,
  contact_no,
  contact_person_name,
  sales_rep_id,
  address_line_1,
  address_line_2,
  city,
  state,
  postal_code,
  use_same_billing_address,
  purchase_order_required,
  country_id,
  payment_term_id,
  status,
  delivery_name,
  delivery_email,
  delivery_contact_no,
  delivery_contact_person_name,
  delivery_address_line_1,
  delivery_address_line_2,
  delivery_postal_code,
  delivery_city,
  delivery_state,
  delivery_country_id,
  gst_enabled,
  user_first_name,
  user_last_name,
  user_code,
  user_contact_no,
  user_email,
  user_address_line_1,
  user_address_line_2,
  user_postal_code,
  user_country_id,
  store_name,
  store_enabled
) {
  return (dispatch) => {
    dispatch(
      request(
        name,
        email,
        contact_no,
        contact_person_name,
        sales_rep_id,
        address_line_1,
        address_line_2,
        city,
        state,
        postal_code,
        use_same_billing_address,
        purchase_order_required,
        country_id,
        payment_term_id,
        status,
        delivery_name,
        delivery_email,
        delivery_contact_no,
        delivery_contact_person_name,
        delivery_address_line_1,
        delivery_address_line_2,
        delivery_postal_code,
        delivery_city,
        delivery_state,
        delivery_country_id,
        gst_enabled,
        user_first_name,
        user_last_name,
        user_code,
        user_contact_no,
        user_email,
        user_address_line_1,
        user_address_line_2,
        user_postal_code,
        user_country_id,
        store_name,
        store_enabled
      )
    );

    organizationService
      .addParameter(
        name,
        email,
        contact_no,
        contact_person_name,
        sales_rep_id,
        address_line_1,
        address_line_2,
        city,
        state,
        postal_code,
        use_same_billing_address,
        purchase_order_required,
        country_id,
        payment_term_id,
        status,
        delivery_name,
        delivery_email,
        delivery_contact_no,
        delivery_contact_person_name,
        delivery_address_line_1,
        delivery_address_line_2,
        delivery_postal_code,
        delivery_city,
        delivery_state,
        delivery_country_id,
        gst_enabled,
        user_first_name,
        user_last_name,
        user_code,
        user_contact_no,
        user_email,
        user_address_line_1,
        user_address_line_2,
        user_postal_code,
        user_country_id,
        store_name,
        store_enabled
      )
      .then(
        (parameter) => {
          dispatch(success(parameter));
          dispatch(alertActions.success(parameter));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(parameter) {
    return { type: organizationConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: organizationConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: organizationConstants.ADD_FAILURE, error };
  }
}

// update parameter
function updateParameter(
  id,
  name,
  email,
  contact_no,
  contact_person_name,
  sales_rep_id,
  address_line_1,
  address_line_2,
  city,
  state,
  postal_code,
  use_same_billing_address,
  purchase_order_required,
  country_id,
  payment_term_id,
  status,
  delivery_name,
  delivery_email,
  delivery_contact_no,
  delivery_contact_person_name,
  delivery_address_line_1,
  delivery_address_line_2,
  delivery_postal_code,
  delivery_city,
  delivery_state,
  delivery_country_id,
  store_enabled,
  gst_enabled
) {
  return (dispatch) => {
    dispatch(
      request(
        id,
        name,
        email,
        contact_no,
        contact_person_name,
        sales_rep_id,
        address_line_1,
        address_line_2,
        city,
        state,
        postal_code,
        use_same_billing_address,
        purchase_order_required,
        country_id,
        payment_term_id,
        status,
        delivery_name,
        delivery_email,
        delivery_contact_no,
        delivery_contact_person_name,
        delivery_address_line_1,
        delivery_address_line_2,
        delivery_postal_code,
        delivery_city,
        delivery_state,
        delivery_country_id,
        store_enabled,
        gst_enabled
      )
    );

    organizationService
      .updateParameter(
        id,
        name,
        email,
        contact_no,
        contact_person_name,
        sales_rep_id,
        address_line_1,
        address_line_2,
        city,
        state,
        postal_code,
        use_same_billing_address,
        purchase_order_required,
        country_id,
        payment_term_id,
        status,
        delivery_name,
        delivery_email,
        delivery_contact_no,
        delivery_contact_person_name,
        delivery_address_line_1,
        delivery_address_line_2,
        delivery_postal_code,
        delivery_city,
        delivery_state,
        delivery_country_id,
        store_enabled,
        gst_enabled
      )
      .then(
        (parameter) => {
          dispatch(success(parameter));
          dispatch(alertActions.success(parameter));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(parameter) {
    return { type: organizationConstants.UPDATE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: organizationConstants.UPDATE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: organizationConstants.UPDATE_FAILURE, error };
  }
}

// delete parameter
function deleteParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    organizationService.deleteParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: organizationConstants.DELETE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: organizationConstants.DELETE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: organizationConstants.DELETE_FAILURE, error };
  }
}

// clear parameter
function clear() {
  return { type: organizationConstants.CLEAR };
}
