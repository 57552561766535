export const paymentTermConstants = {
  GET_SINGLE_REQUEST: "PAYMENT_TERM_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "PAYMENT_TERM_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "PAYMENT_TERM_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "PAYMENT_TERM_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "PAYMENT_TERM_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "PAYMENT_TERM_GET_ALL_FAILURE",

  ADD_REQUEST: "PAYMENT_TERM_ADD_REQUEST",
  ADD_SUCCESS: "PAYMENT_TERM_ADD_SUCCESS",
  ADD_FAILURE: "PAYMENT_TERM_ADD_FAILURE",

  UPDATE_REQUEST: "PAYMENT_TERM_UPDATE_REQUEST",
  UPDATE_SUCCESS: "PAYMENT_TERM_UPDATE_SUCCESS",
  UPDATE_FAILURE: "PAYMENT_TERM_UPDATE_FAILURE",

  DELETE_REQUEST: "PAYMENT_TERM_DELETE_REQUEST",
  DELETE_SUCCESS: "PAYMENT_TERM_DELETE_SUCCESS",
  DELETE_FAILURE: "PAYMENT_TERM_DELETE_FAILURE",

  CLEAR: "PAYMENT_TERM_CLEAR",
};
