import {
  Box,
  Button,
  Modal,
  Stack,
  styled,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import axios from "../../API/axios";
import { convertToPlaces } from "../../helpers/helpers";
import { authHeader } from "../../helpers";
import { apiConstants } from "../../constants";

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  innerWidth: "1000px",
  height: "auto",
  justifyContent: "space-evenly",
  display: "flex",
  flexDirection: "column",
  padding: "10px",
};

const StyledTheaders = styled(TableCell)({
  fontSize: "14px",
  padding: "10px",
});

const SuccessModal = ({ ...props }) => {
  const { open, handleClose, item, updateItems, index, status, colors } = props;
  const [changeItem, updateItem] = useState({ ...item });

  const {
    original_total_price,
    total_price,
    original_unit_price,
    qty,
    unit_discount = 0,
    lining_ids = [],
    price,
    color = "",
    note = "",
    extra_charges_list,
  } = item;

  const [totalPrice, setTotalPrice] = useState(total_price);

  const [priceWithQuantity, setPriceWithQuantity] =
    useState(original_total_price);

  const [unitDiscount, setUnitDiscount] = useState(unit_discount);

  const [discountAmount, setDiscountAmount] = useState(
    original_total_price - total_price
  );

  const [quantity, setQuantity] = useState(qty);

  const [extra, setExtra] = useState([]);
  const [extraCharges, setExtraCharges] = useState([]);
  const [selected, setSelected] = useState({ name: "" });

  //const [enableEdit, setEdit] = useState(false);

  let showExtra = extraCharges?.length > 0;

  useEffect(() => {
    getExtra();
    setExtraCharges(extra_charges_list);
  }, [extra_charges_list]);

  const getExtra = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(`${apiConstants.API_URL}/extra-charges`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const res = text && JSON.parse(text);
          setExtra(res?.data);
        });
      }
    );
  };

  const colorsProps = {
    options: colors,
    getOptionLabel: (option) => option?.english_name || "",
  };

  const updateAction = async () => {
    let requestData = {
      sport_id: price?.sport?.id,
      item_id: price?.item?.id,
      fabric_id: price?.fabric?.id,
      lining_ids: lining_ids,
      qty: qty,
    };

    let newPrice = changeItem?.original_unit_price;

    if (item.qty !== changeItem.qty) {
      const res = await axios.post("/price", requestData);
      newPrice = res?.data?.data?.unit_price;
    }

    let newData = {
      ...changeItem,
      original_unit_price: newPrice,
      //unit_price: (newPrice * (100 - changeItem?.discount_percentage)) / 100,
      unit_price: newPrice,
      unit_discount: unitDiscount,
      total_price: (newPrice - unitDiscount) * changeItem.qty,
    };

    updateItems(index, newData);
    //setEdit(false);
    handleClose();
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    updateItem({
      ...changeItem,
      [id]: value,
    });
    if (id === "qty") {
      setQuantity(value);
      setPriceWithQuantity(changeItem?.original_unit_price * value);
      const amount = (changeItem?.original_unit_price - unitDiscount) * value;
      setDiscountAmount(unitDiscount * value);
      setTotalPrice(amount);
    } else if (id === "discount") {
      let discount = Number(value);
      discount = isNaN(discount) ? 0 : Math.abs(discount * 100);
      setUnitDiscount(discount);
      const amount = (changeItem?.original_unit_price - discount) * quantity;
      setDiscountAmount(qty * discount);
      setTotalPrice(amount);
    }
  };

  const addExtraTotal = () => {
    if (selected.code) {
      let newArra = [...extraCharges];
      newArra.push(selected);
      setExtraCharges(() => [...newArra]);

      setSelected({});
      let totalExtra = 0;
      newArra.map((e) => {
        totalExtra = totalExtra + e.cost;
        return "";
      });
      updateItem((state) => ({
        ...state,
        extra_charges: totalExtra,
        extra_charges_list: [...newArra],
      }));
    }
  };

  const removeExtra = (index) => {
    let newArray = [];
    if (index) {
      newArray = extraCharges.slice(index);
    }

    setExtraCharges([...newArray]);
    let totalExtra = 0;
    newArray.map((e) => {
      totalExtra = totalExtra + e.cost;
      return "";
    });
    setSelected({ name: "" });
    updateItem({
      ...changeItem,
      extra_charges: totalExtra,
      extra_charges_list: [...newArray],
    });
  };

  const onChangeExtra = (e) => {
    const { value } = e.target;
    setSelected(() => value);
  };

  return (
    <>
      <Modal
        open={!!open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            spacing={2}
            direction={"column"}
            justifyContent="space-between"
          >
            <Typography fontSize={"20px"}>{item?.item?.name}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTheaders>Color</StyledTheaders>
                  <StyledTheaders>Unit Price</StyledTheaders>
                  <StyledTheaders>Quantity</StyledTheaders>
                  <StyledTheaders>Price</StyledTheaders>
                  <StyledTheaders>Unit Discount</StyledTheaders>
                  <StyledTheaders>Discount Amount</StyledTheaders>
                  <StyledTheaders>Discounted Price</StyledTheaders>
                  <StyledTheaders>Notes</StyledTheaders>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {status === "edit" ? (
                      <Autocomplete
                        id="auto-complete"
                        {...colorsProps}
                        defaultValue={color}
                        autoComplete
                        includeInputInList
                        value={item?.colorObj}
                        onChange={(e, value) => {
                          updateItem({
                            ...changeItem,
                            color_id: value?.id,
                            color: value?.english_name,
                            colorObj: {
                              id: value?.id,
                              english_name: value?.english_name,
                            },
                          });
                        }}
                        sx={{ minWidth: "150px" }}
                        renderInput={(params) => (
                          <TextField
                            sx={{ padding: 0 }}
                            {...params}
                            placeholder="Colors"
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        id="auto-complete"
                        {...colorsProps}
                        defaultValue={color}
                        autoComplete
                        disabled
                        includeInputInList
                        value={item?.colorObj}
                        sx={{ minWidth: "150px" }}
                        renderInput={(params) => (
                          <TextField
                            sx={{ padding: 0 }}
                            {...params}
                            placeholder="Colors"
                          />
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {convertToPlaces(original_unit_price)}
                  </TableCell>
                  <TableCell>
                    {status === "edit" ? (
                      <TextField
                        size="small"
                        type="number"
                        id="qty"
                        defaultValue={qty}
                        onChange={onChange}
                        sx={{ minWidth: "100px" }}
                      />
                    ) : (
                      <TextField
                        value={qty}
                        size="small"
                        //onClick={() => setEdit(true)}
                        disabled={true}
                      />
                    )}
                  </TableCell>
                  <TableCell>{convertToPlaces(priceWithQuantity)}</TableCell>
                  <TableCell>
                    {status === "edit" ? (
                      <TextField
                        size="medium"
                        type="number"
                        defaultValue={unitDiscount / 100}
                        onChange={onChange}
                        id="discount"
                        sx={{ minWidth: "100px" }}
                      />
                    ) : (
                      <TextField
                        value={unitDiscount / 100}
                        size="small"
                        type="number"
                        disabled={true}
                        //onClick={() => setEdit(true)}
                      />
                    )}
                  </TableCell>
                  <TableCell>{convertToPlaces(discountAmount)}</TableCell>
                  <TableCell>{convertToPlaces(totalPrice)}</TableCell>
                  <TableCell>
                    {status === "edit" ? (
                      <TextField
                        size="small"
                        type="text"
                        id="note"
                        defaultValue={note}
                        onChange={onChange}
                        sx={{ minWidth: "100px" }}
                      />
                    ) : (
                      <TextField
                        value={note}
                        size="small"
                        disabled={true}
                        sx={{ minWidth: "100px" }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
          <Stack
            direction={"row"}
            sx={{ margin: "20px 0 10px", padding: "0 16px" }}
          >
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Select Extra Charges
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="item"
                size="small"
                value={selected}
                onChange={onChangeExtra}
                disabled={status !== "edit"}
              >
                {extra?.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {status === "edit" && (
              <Button
                variant="outlined"
                onClick={() => addExtraTotal()}
                sx={{ minWidth: "160px" }}
              >
                ADD CHARGES
              </Button>
            )}
          </Stack>
          {showExtra && (
            <Stack sx={{ marginBottom: "10px", padding: "0 16px" }}>
              <Divider>
                <Typography fontWeight={500} fontSize={"20px"}>
                  EXTRA CHARGES
                </Typography>
              </Divider>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Cost</TableCell>
                    {status === "edit" && <TableCell>Action</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraCharges.map((e, index) => (
                    <TableRow key={index}>
                      <TableCell>{e.code}</TableCell>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{convertToPlaces(e.cost)}</TableCell>
                      {status === "edit" && (
                        <TableCell>
                          <DeleteIcon
                            color="warning"
                            onClick={() => removeExtra(index)}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      Add-ons {convertToPlaces(changeItem.extra_charges)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          )}
          <Stack
            spacing={2}
            direction={"column"}
            justifyContent="space-between"
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Button onClick={() => handleClose(!open)}>CLOSE</Button>
              <Button
                onClick={() => updateAction()}
                color="success"
                variant="contained"
                style={{ color: "white" }}
              >
                UPDATE
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

SuccessModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  updateItems: PropTypes.func.isRequired,
  index: PropTypes.number,
};

SuccessModal.defaultProps = {
  open: "",
  title: "",
  body: "",
  index: 0,
};

export default SuccessModal;
