import styled from "@emotion/styled";
import {
  Box,
  Button,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { convertToPlaces } from "../../helpers/helpers";

const StyledHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: "8px",
  borderTopLeftRadius: "8px",
  height: "93px",
  display: "flex",
  alignItems: "center",
  paddingLeft: "10px",
  color: theme.palette.neutral.light,
}));

const StyledTableContainer = styled("div")({
  overflowX: "auto",
  overflowY: "auto",
});

const StyledHeaderText = styled(Typography)({
  fontSize: "24px",
});

const StyledHeaderSubText = styled(Typography)({
  fontSize: "14px",
});

const StyledTableRow = styled(TableRow)({
  height: "56px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
}));

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "8px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  paddingBottom: "10px",
};

const QuotationPreviewModal = ({ ...props }) => {
  const { open, handleClose, successAction } = props;
  const { order } = useSelector((state) => state.orders);

  const totalPrice = (row) => {
    const { total_price, extra_charges, unit_price, qty } = row;
    return convertToPlaces(unit_price * qty + extra_charges);
  };

  console.log(order);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <StyledHeader>
          <Stack>
            <StyledHeaderText>Your Products</StyledHeaderText>
            <StyledHeaderSubText>
              Please note all prices are exclude GST
            </StyledHeaderSubText>
          </Stack>
        </StyledHeader>
        <Box>
          <StyledTableContainer>
            <TableContainer>
              <Table
                sx={{ minWidth: 650, overflow: "true" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <StyledTableRow>
                    <TableCell align="right">Style Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Discount </TableCell>
                    <TableCell align="right">Discounted Price</TableCell>
                    <TableCell align="right">Qty</TableCell>
                    <TableCell align="right">Extra Charges</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {order.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">{row?.style}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {convertToPlaces(row?.original_unit_price)}
                      </TableCell>
                      <TableCell align="right">
                        {convertToPlaces(row?.unit_discount)}
                      </TableCell>
                      <TableCell align="right">
                        {convertToPlaces(row?.unit_price)}
                      </TableCell>
                      <TableCell align="right">{row?.qty}</TableCell>
                      <TableCell align="right">
                        {convertToPlaces(row?.extra_charges)}
                      </TableCell>
                      <TableCell align="right">{totalPrice(row)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledTableContainer>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          padding={"10px"}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => handleClose()}
          >
            CANCEL
          </Button>
          <StyledButton
            color="success"
            variant="contained"
            onClick={() => successAction()}
          >
            SEND QUOTATION
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};

QuotationPreviewModal.prototype = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,

  data: PropTypes.array,

  successAction: PropTypes.func.isRequired,
};
QuotationPreviewModal.defaultProps = {
  open: false,

  data: [],
};

export default QuotationPreviewModal;
