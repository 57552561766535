import moment from "moment";
import * as React from "react";

import { checkIsAdmin } from "../../../helpers/helpers";
import { SalesOrdersActionButton } from "../../atoms/ActionButton/SalesOrdersActionButton";
import CustomGrid from "../../atoms/CustomGrid/CustomGrid";
import SalesOrderStatusLable from "../../atoms/StatusLable/SalesOrderStatusLable";
import { userService } from "../../../services/user.service";
import { Stack } from "@mui/material";

export const SalesOrdersTable = ({
  salesOrders,
  perPageCount,
  totalRowsConunt,
  handlePageChange,
  pathToView,
}) => {
  const columns = [
    { field: 'id', headerName: 'ID', resizable: false, minWidth: 50 },
    { field: 'created_at', headerName: 'Date', width: 150, renderCell: params => moment(params.row.created_at).format('DD/MM/YYYY') },
    {
      field: 'customer',
      headerName: 'Organization Name',
      sortable: true,
      width: 300,
      minWidth: 100,
      valueGetter: (params) =>
        `${params.row.organization?.name || ''}`,
    },
    {
      field: "orag_id",
      headerName: "Organization ID",
      valueGetter: (params) =>
        `${params.row.organization?.id || ''}`,
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => <SalesOrderStatusLable {...params} />,
      width: 200,
    },
    {
      field: "approvedBy",
      headerName: "Approved By",
      minWidth: 200,
      renderCell: (params) => {
        if (params?.row?.accepted.length > 0) {
          return (
            <Stack direction="column">
              <div style={{ fontSize: '12px', color: '#666', marginRight: '10px' }}>Approved By</div>
              <span>{params.row?.accepted[0]?.user?.first_name} {params.row?.accepted[0]?.user?.last_name}</span>
            </Stack>);
        }
        else if (params?.row?.rejected.length > 0) {
          return (
            <Stack direction="column">
              <div style={{ fontSize: '12px', color: '#666', marginRight: '10px' }}>Rejected By</div>
              <span>{params.row?.rejected[0]?.user?.first_name} {params.row?.rejected[0]?.user?.last_name}</span>
            </Stack>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      type: "actions",
      renderCell: (params) => (
        <SalesOrdersActionButton
          isViewOnly={!checkIsAdmin()}
          pathToView={pathToView}
          salesOrderId={params.row.id}
          status={params.row.status.keyword}
        />
      ),
    },
  ];

  return (
    <CustomGrid
      rows={salesOrders}
      columns={columns}
      perPageCount={perPageCount}
      totalRowCount={totalRowsConunt}
      handlePageChange={handlePageChange}
    />
  );
};
