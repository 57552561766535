import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "ordertype", headerName: "Order Type", width: 150 },
  { field: "deliverytype", headerName: "Delivery Type", width: 150 },
  { field: "deliverytime", headerName: "Delivery Time", width: 150 },
  {
    field: "extrachargefordelivery",
    headerName: "Extra Charge for Delivery",
    width: 150,
  },
  {
    field: "seconddeliverytime",
    headerName: "Second Delivery Time",
    width: 150,
  },
  {
    field: "surchargesecondoption",
    headerName: "Surcharge Second Option",
    width: 150,
  },
  { field: "thirddeliverytime", headerName: "Third Delivery Time", width: 150 },
  {
    field: "surchargethirdoption",
    headerName: "Surcharge Third Option",
    width: 150,
  },
  { field: "weighttype", headerName: "Weight Type", width: 150 },
  { field: "minitems", headerName: "Min Items", width: 150 },
  { field: "currencytype", headerName: "Currency Type", width: 150 },
  { field: "priceforminitem", headerName: "Price for Min Item", width: 150 },
  { field: "numadditionlitem", headerName: "Num Additionl Item", width: 150 },
  {
    field: "priceadditionalitem",
    headerName: "Price Additional Item",
    width: 150,
  },
  { field: "status", headerName: "Status", width: 150 },
];

const rows = [
  {
    id: 1,
    ordertype: "Order Type 1",
    deliverytype: "Delivery Type 1",
    deliverytime: "12:00",
    extrachargefordelivery: "$10.00",
    seconddeliverytime: "14:00",
    surchargesecondoption: "Type 1",
    thirddeliverytime: "18:00",
    surchargethirdoption: "Type 3",
    weighttype: "Heavy",
    minitems: "1",
    currencytype: "$",
    priceforminitem: "200",
    numadditionlitem: "2",
    priceadditionalitem: "50",
    status: "Active",
  },
  {
    id: 2,
    ordertype: "Order Type 1",
    deliverytype: "Delivery Type 1",
    deliverytime: "12:00",
    extrachargefordelivery: "$10.00",
    seconddeliverytime: "14:00",
    surchargesecondoption: "Type 1",
    thirddeliverytime: "18:00",
    surchargethirdoption: "Type 3",
    weighttype: "Heavy",
    minitems: "1",
    currencytype: "$",
    priceforminitem: "200",
    numadditionlitem: "2",
    priceadditionalitem: "50",
    status: "Active",
  },
  {
    id: 3,
    ordertype: "Order Type 1",
    deliverytype: "Delivery Type 1",
    deliverytime: "12:00",
    extrachargefordelivery: "$10.00",
    seconddeliverytime: "14:00",
    surchargesecondoption: "Type 1",
    thirddeliverytime: "18:00",
    surchargethirdoption: "Type 3",
    weighttype: "Heavy",
    minitems: "1",
    currencytype: "$",
    priceforminitem: "200",
    numadditionlitem: "2",
    priceadditionalitem: "50",
    status: "Active",
  },
  {
    id: 4,
    ordertype: "Order Type 1",
    deliverytype: "Delivery Type 1",
    deliverytime: "12:00",
    extrachargefordelivery: "$10.00",
    seconddeliverytime: "14:00",
    surchargesecondoption: "Type 1",
    thirddeliverytime: "18:00",
    surchargethirdoption: "Type 3",
    weighttype: "Heavy",
    minitems: "1",
    currencytype: "$",
    priceforminitem: "200",
    numadditionlitem: "2",
    priceadditionalitem: "50",
    status: "Active",
  },
  {
    id: 5,
    ordertype: "Order Type 1",
    deliverytype: "Delivery Type 1",
    deliverytime: "12:00",
    extrachargefordelivery: "$10.00",
    seconddeliverytime: "14:00",
    surchargesecondoption: "Type 1",
    thirddeliverytime: "18:00",
    surchargethirdoption: "Type 3",
    weighttype: "Heavy",
    minitems: "1",
    currencytype: "$",
    priceforminitem: "200",
    numadditionlitem: "2",
    priceadditionalitem: "50",
    status: "Active",
  },
];

const PaymentTerms = () => {
  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        rowsPerPageOptions={[5]}
        // checkboxSelection
      />
    </Box>
  );
};

export default PaymentTerms;
