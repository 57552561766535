import React from "react";
import { Box } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      component="div"
      sx={{ flex: 1 }}
    >
      {value === index && (
        <Box component="div" sx={{ px: 3 }}>
          <>{children}</>
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
