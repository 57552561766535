import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Stack,
  FormHelperText,
  Alert,
  Chip,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styles from "./QuantityInput.module.scss";

const NamesForm = ({
  quantity,
  namesNumbers,
  setNamesNumbers,
  mode,
  fomSubmitted,
}) => {
  const [formData, setFormData] = useState({
    sets: [{ name: "", number: "" }],
  });
  const [validationErrors, setValidationErrors] = useState([]);

  // validation schema
  const validationSchema = Yup.object().shape({
    sets: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          number: Yup.string().nullable(),
        })
      )
      .min(1, "You need at least one set")
      .max(quantity, `You can add up to ${quantity} sets`),
  });

  // add new name/number set
  const handleAddSet = () => {
    if (formData.sets.length < quantity) {
      setFormData({
        ...formData,
        sets: [...formData.sets, { name: "", number: "" }],
      });
    }
  };

  // remove name/number set
  const handleRemoveSet = (index) => {
    const updatedSets = [...formData.sets];
    updatedSets.splice(index, 1);
    setFormData({ ...formData, sets: updatedSets });
  };

  // clear formdata on main form submit

  useEffect(() => {
    const clearForm = () => {
      fomSubmitted && alert("works");
      // setFormData({
      //   sets: [{ name: "", number: "" }],
      // });
    };

    clearForm();
  }, [fomSubmitted]);

  // handle submit
  const handleFormSubmit = () => {
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        // Filter out sets where both "name" and "number" are empty
        const filteredSets = formData.sets.filter(
          (set) => set.name || set.number
        );

        if (filteredSets.length === 0) {
          // Handle the case when all sets have both "name" and "number" empty
          console.log("No valid sets to submit");
        } else {
          // Handle form submission with the filtered sets
          console.log(filteredSets);
          setNamesNumbers(filteredSets);
        }
      })
      .catch((errors) => {
        const errorMessages = errors.inner.map((error) => error.message);
        setValidationErrors(errorMessages);
      });
  };

  // prefill names in edit mode
  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && namesNumbers.length > 0) {
        setFormData({ sets: namesNumbers });
      }
    };

    prefillData();
  }, [mode, namesNumbers]);

  return (
    <Paper elevation={0}>
      <form>
        {validationErrors.length > 0 && (
          <Alert severity="error" title="Error!" sx={{ my: 2 }}>
            <ul className={styles.errorList}>
              {validationErrors.map((error, index) => (
                <li>
                  <FormHelperText key={index} style={{ color: "red" }}>
                    {error}
                  </FormHelperText>
                </li>
              ))}
            </ul>
          </Alert>
        )}

        {formData.sets.map((set, index) => (
          <div key={index}>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={7}>
                <TextField
                  name={`sets[${index}].name`}
                  label="Name"
                  type="text"
                  value={set.name}
                  size="small"
                  onChange={(e) => {
                    const updatedSets = [...formData.sets];
                    updatedSets[index].name = e.target.value;
                    setFormData({ ...formData, sets: updatedSets });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={`sets[${index}].number`}
                  label="Number"
                  type="number"
                  value={set.number}
                  size="small"
                  onChange={(e) => {
                    const updatedSets = [...formData.sets];
                    updatedSets[index].number = e.target.value;
                    setFormData({ ...formData, sets: updatedSets });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveSet(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        ))}

        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          {formData.sets.length < quantity ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddSet}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Another Set
            </Button>
          ) : (
            <Chip
              icon={<ErrorOutlineIcon />}
              label={`Please increase the item quantity to add more items`}
            />
          )}

          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            {mode === "edit" ? "Save Names" : "Save Names and Numbers"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default NamesForm;
