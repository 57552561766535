import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import SuccessModal from "../Common/Modals/SuccessModal";
import QuotationPreviewModal from "../Common/Modals/QuotationPreviewModal";
import { useSelector, useDispatch } from "react-redux";
import { convertToPlaces, convertToDecimalPlaces } from "../helpers/helpers";
import { apiConstants } from "../constants";
import { authHeader, getUserToken } from "../helpers";
import AlertToast from "./common/Toast";
import { useNavigate } from "react-router-dom";
import { removeAll } from "../store/Store/orderSlice";
import { removeCustomerData } from "../store/Store/Slice";
import AdditionalEmailBody from "../Common/Modals/AdditionalEmailBody";

const StyledButton = styled(Button)({
  color: "white",
});

const StyledHeaders = styled(Typography)({
  fontSize: "16px",
});
const StyledContents = styled(Typography)({
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.6)",
});

const EditQuotationSummery = ({ ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer } = useSelector((state) => state.customer);
  const { order } = useSelector((state) => state.orders);
  const {
    enableSubmit,
    id,
    file,
    additionalBody,
    setAdditionalBody,
    setTotalDelivery,
    totalDelivery,
    setUpdateTotalDelivery,
    updateTotalDelivery,
    setLoading,
  } = props;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [preview, updatePreview] = useState(true);
  const [openPreview, setPreview] = useState(false);
  const [openSucces, setSuccess] = useState(false);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const [enableDelivery, setEnableDelivery] = useState(true);
  const [quotationId, setQuotationId] = useState(id);
  const handleToastClose = () => setToastState({ open: false });
  const [openAdditional, updateAdditional] = useState(false);

  const handleGetDelivery = useCallback(() => {
    const items = order.map((o) => {
      const delivery = {
        item_id: o?.item_id,
        qty: o.qty,
      };
      return delivery;
    });

    const reqData = {
      items: items,
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };

    fetch(
      `${apiConstants.API_URL}/prices/shipping-charges`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
          setPreview(false);
        } else {
          setTotalDelivery(data?.data?.total_shipping_charges / 100);
        }
      });
    });
  }, [order]);

  // useEffect(() => {
  //   if(order.length !==0 ){
  //     handleGetDelivery();
  //   }
  // }, [order, handleGetDelivery]);

  const submitQuotation = () => {
    //saveRequest();
  };

  const submitPreview = () => {
    setPreview(!openPreview);
  };

  const submitAction = preview ? submitPreview : submitQuotation;

  const submitBtnText = preview
    ? " PREVIEW AND RESEND QUOTATION "
    : "RESEND QUOTATION";

  const visibleTotals = order.length > 0;

  const handleSuccess = () => {
    dispatch(removeAll({}));
    dispatch(removeCustomerData({}));
    navigate("/admin/quotations");
  };

  const calculateTotal = () => {
    let totalPrice = 0;
    order.map((o) => {
      let calTotal = o.unit_price * o.qty + o.extra_charges;
      return (totalPrice = totalPrice + calTotal);
    });
    return totalPrice + updateTotalDelivery * 100;
  };

  const saveRequest = () => {
    setLoading(true);
    updateAdditional(false);
    let fileArray = [];
    // if(file?.image_url){
    //   fileArray = file?.file;
    // }
    const newFiles = file.filter((f) => f.newUpload);
    const uploadedFile = file.filter((f) => !f.newUpload);
    console.log(newFiles);
    const token = getUserToken();
    function formateFile(requestImageOptions, index) {
      fetch(`${apiConstants.API_URL}/file/quotation`, requestImageOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setToastMessage(errorMessage[0]);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              newFiles[index]["file"] = data?.data?.path;
              console.log(newFiles, "newFiles");
              const combinedArray = [...uploadedFile, ...newFiles];
              console.log(combinedArray);
              if (index === newFiles.length - 1) {
                const files = combinedArray.map((o) => {
                  return o?.file;
                });
                console.log(files, "files");
                fileArray = files;
                sentQuotation(fileArray);
              }
            }
          });
        }
      );
    }
    if (newFiles.length !== 0) {
      newFiles?.map((f, index) => {
        console.log(index);
        const requestImageOptions = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: f?.image,
        };
        formateFile(requestImageOptions, index);
      });
    } else if (newFiles.length === 0 && uploadedFile.length !== 0) {
      const files = uploadedFile.map((o) => {
        return o?.file;
      });
      console.log(files, "files");
      fileArray = files;
      sentQuotation(fileArray);
    } else {
      sentQuotation(fileArray);
    }
  };

  const sentQuotation = (fileArray) => {
    const {
      name,
      phone,
      email,
      address_line_1,
      address_line_2,
      postal_code,
      country_id,
      organization_id,
      sales_rep_id,
      gst_enabled,
    } = customer;

    console.log(customer);

    let custom_items = order.filter((f) => f.price_id === null);
    let items = order.filter((f) => f.price_id ?? false);

    let newItems = order.map((o) => {
      const {
        discount_percentage,
        qty,
        unit_price,
        original_unit_price,
        total_price,
        artwork_charges,
        extra_charges,
        gst_amount,
        price_id,
        original_total_price,
        existing,
        id,
        extra_charges_list,
        estimated_shipping_charges,
      } = o;

      const estimatedShippingCharges =
        updateTotalDelivery !== totalDelivery
          ? (updateTotalDelivery / items?.length) * 100
          : estimated_shipping_charges;

      const extraChargesList = extra_charges_list.map((x) => {
        return {
          extra_charge_id: x.id,
          cost: x.cost,
        };
      });

      if (existing) {
        return {
          discount_percentage,
          qty,
          unit_price,
          original_unit_price,
          total_price,
          artwork_charges,
          extra_charges,
          gst_amount,
          price_id,
          original_total_price,
          id,
          quotation_item_extra_charges: extraChargesList,
          estimated_shipping_charges: estimatedShippingCharges,
        };
      } else {
        return {
          discount_percentage,
          qty,
          unit_price,
          original_unit_price,
          total_price,
          artwork_charges,
          extra_charges,
          // estimated_shipping_charges,
          gst_amount,
          price_id,
          original_total_price,
          quotation_item_extra_charges: extraChargesList,
          estimated_shipping_charges: estimatedShippingCharges,
        };
      }
    });

    let newCustoms = custom_items.map((n) => {
      const { name, discount_percentage, qty, unit_price, unit_discount } = n;
      return {
        name,
        unit_discount,
        qty,
        price: unit_price,
      };
    });
    let reqData;
    if (fileArray.length !== 0) {
      reqData = {
        organization_id: organization_id,
        organization_name: name,
        organization_email: email,
        organization_address_line_1: address_line_1,
        organization_address_line_2: address_line_2,
        postal_code: postal_code,
        country_id: country_id,
        contact_no: phone,
        sales_agent_id: sales_rep_id,
        items: newItems,
        custom_items: newCustoms,
        //file:imagePath,
        quotation_status_id: 1,
        quotation_files: fileArray,
        additional_email_body: additionalBody,
        estimated_shipping_charges: updateTotalDelivery * 100,
        gst_enabled: gst_enabled,
      };
    } else {
      reqData = {
        organization_id: organization_id,
        organization_name: name,
        organization_email: email,
        organization_address_line_1: address_line_1,
        organization_address_line_2: address_line_2,
        postal_code: postal_code,
        country_id: country_id,
        contact_no: phone,
        sales_agent_id: sales_rep_id,
        items: newItems,
        custom_items: newCustoms,
        quotation_status_id: 1,
        additional_email_body: additionalBody,
        estimated_shipping_charges: updateTotalDelivery * 100,
        gst_enabled: gst_enabled,
      };
    }

    if (additionalBody === "") {
      delete reqData["additional_email_body"];
    }

    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };

    fetch(`${apiConstants.API_URL}/quotations/${id}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
            }
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage[0]);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
            setPreview(false);
            setLoading(false);
          } else {
            setQuotationId(data?.data.id);
            setPreview(false);
            setSuccess(!openSucces);
            setLoading(false);
          }
        });
      }
    );
  };

  const getExtra = () => {
    let extra = 0;
    order.map((o) => {
      extra = extra + o.extra_charges;
      return extra;
    });
    return extra;
  };

  const closeAdditional = () => {
    updateAdditional(false);
  };

  const confirmQuotation = () => {
    updateAdditional(true);
    setPreview(false);
  };

  const handleEnableDelivery = () => {
    setEnableDelivery(!enableDelivery);
    if (enableDelivery) {
      handleGetDelivery();
    }
  };

  const onchangeDelivery = (e) => {
    setUpdateTotalDelivery(e.target.value);
  };

  return (
    <>
      <Stack spacing={3}>
        <Stack>
          <Typography fontWeight={400}>Quotation Summery</Typography>
          <StyledContents>
            This is a Summary of the generated quotation
          </StyledContents>
        </Stack>
        <Stack style={{ height: "120px", overflow: "scroll" }} spacing>
          <StyledHeaders>Customer</StyledHeaders>
          <Box>
            <StyledContents>{customer?.name}</StyledContents>
            <StyledContents>{customer?.address_line_1}</StyledContents>
            <StyledContents>
              {customer?.address_line_2},{customer?.postal_code}
            </StyledContents>
            <StyledContents>{customer?.country}</StyledContents>
          </Box>
        </Stack>
        <Divider style={{ marginTop: "10px" }} />
        <Stack>
          <StyledHeaders position={"sticky"}>Include in Order</StyledHeaders>
          <div
            style={{ overflow: "scroll", minHeight: "85px", height: "100%" }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                {order.map((o, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "!left" }}>
                      <StyledContents>{o?.name}</StyledContents>
                    </td>
                    <td>
                      <StyledContents
                        style={{ minWidth: "50px", textAlign: "right" }}
                      >
                        * {o?.qty}
                      </StyledContents>
                    </td>
                    <td>
                      <StyledContents
                        style={{ textAlign: "right", minWidth: "100px" }}
                      >
                        {convertToPlaces(o?.unit_price * o?.qty)}
                      </StyledContents>
                    </td>
                  </tr>
                ))}
                {visibleTotals && (
                  <tr style={{ backgroundColor: "#E5E5E5" }}>
                    <td>
                      <StyledContents style={{ textAlign: "left" }}>
                        Extra Charges
                      </StyledContents>
                    </td>
                    <td></td>
                    <td>
                      <StyledContents style={{ textAlign: "right" }}>
                        {convertToPlaces(getExtra())}
                      </StyledContents>
                    </td>
                  </tr>
                )}
                {visibleTotals && updateTotalDelivery && (
                  <tr style={{ backgroundColor: "#E5E5E5" }}>
                    <td>
                      <StyledContents style={{ textAlign: "left" }}>
                        Delivery
                      </StyledContents>
                    </td>
                    <td></td>
                    <td>
                      <StyledContents style={{ textAlign: "right" }}>
                        {convertToDecimalPlaces(updateTotalDelivery)}
                      </StyledContents>
                    </td>
                  </tr>
                )}
                {visibleTotals && (
                  <tr style={{ backgroundColor: "#E5E5E5" }}>
                    <td>
                      <StyledContents style={{ textAlign: "left" }}>
                        Total
                      </StyledContents>
                    </td>
                    <td></td>
                    <td>
                      <StyledContents style={{ textAlign: "right" }}>
                        {convertToPlaces(calculateTotal())}
                      </StyledContents>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Divider style={{ marginTop: "10px" }} />
        </Stack>
        <Stack spacing={1}>
          <FormControlLabel
            control={
              <Checkbox
                value={enableDelivery}
                checked={enableDelivery}
                onChange={handleEnableDelivery}
              />
            }
            label="Total Delivery Charge Update"
          />
          {enableDelivery && (
            <>
              <StyledHeaders>Delivery / Shipping</StyledHeaders>
              <Stack spacing={2} direction={"row"}>
                <FormControl ariant="standard" fullWidth>
                  <TextField
                    id="outlined-controlled"
                    size="small"
                    value={updateTotalDelivery}
                    onChange={onchangeDelivery}
                    disabled={!enableDelivery}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
            </>
          )}
        </Stack>
        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                value={preview}
                checked={preview}
                onChange={() => updatePreview(!preview)}
              />
            }
            label="Preview quotation before send"
          />

          <StyledButton
            sx={{ marginTop: "10px" }}
            variant="contained"
            color="success"
            endIcon={<ArrowForwardIcon />}
            onClick={() => submitAction()}
            disabled={!enableSubmit}
          >
            {submitBtnText}
          </StyledButton>
          <SuccessModal
            open={openSucces}
            handleClose={handleSuccess}
            title={"Quotation Resent Successfully"}
            body={`Quotation #${quotationId} is updated successfully and sent to the customer email ${customer?.email}. Thank you.`}
          />
          <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
          />
          <QuotationPreviewModal
            open={openPreview}
            handleClose={submitPreview}
            successAction={confirmQuotation}
          />
          <AdditionalEmailBody
            open={openAdditional}
            handleClose={closeAdditional}
            type={"success"}
            label={"Quotation Additional Email body"}
            setAdditionalBody={setAdditionalBody}
            note={
              "You can mention additional note here. This will be added in email body as additionally"
            }
            saveRequest={saveRequest}
            buttonText={"Submit"}
          />
        </Stack>
      </Stack>
    </>
  );
};

EditQuotationSummery.propTypes = {
  enableSubmit: PropTypes.bool,
  customerDetails: PropTypes.object,
};

EditQuotationSummery.defaultProps = {
  enableSubmit: false,
  customerDetails: {},
};

export default EditQuotationSummery;
