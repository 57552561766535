import React, { Suspense } from 'react'
import {
    Box,
    Stack,
    Typography,
    Skeleton,
} from "@mui/material";
import topBannerBg from "../../../../assets/imges/backgrounds/bg_top_banner_accept.jpeg";
import strendLogo from "../../../../assets/imges/logos/strend_logo_default_black.svg"

function AcceptanceTopbanner({ view, data, organizationEmail, organizationName, salesAgent }) {

    const _view = view;
    const _data = data;

    return (
        <Box
            sx={{
                backgroundImage: `url(${topBannerBg})`,
                backgroundColor: '#000',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
                minHeight: '400px',
                padding: "60px 0",
            }}
        >
            <Box sx={{ padding: "40px 0", maxWidth: '1280px', marginLeft: 'auto', marginRight: 'auto' }}>

                <Stack direction="column">

                    <Typography sx={{ color: '#fff', textTransform: "uppercase", fontSize: "12px", fontWeight: "900" }}>
                        {_data?.quotation_id ? 'Sales quotation #' + _data?.quotation_id : <Skeleton variant="text" sx={{ fontSize: '12px', background: '#bbb', maxWidth: "200px" }} />}
                    </Typography>




                    <Typography sx={{ color: "#69C464", fontSize: "32px", marginBottom: "30px" }}>
                        {organizationName ? organizationName : <Skeleton variant="text" sx={{ fontSize: '32px', background: '#86e780', maxWidth: "200px" }} />}
                    </Typography>

                    <Stack direction="row" columnGap={10} sx={{ marginBottom: "30px" }}>

                        {_view === "sales-order" && (
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                <Typography sx={{ textTransform: 'uppercase', fontSize: "10px", color: "#fff" }}>Client Number:</Typography>
                                <Typography sx={{ color: "#fff", fontSize: "14px" }}>398478</Typography>
                            </Box>
                        )}

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <Typography sx={{ textTransform: 'uppercase', fontSize: "10px", color: "#fff" }}>prepared by:</Typography>
                            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {
                                salesAgent.first_name && salesAgent.last_name ?
                                    salesAgent.first_name + " " + salesAgent.last_name + " (" + salesAgent.email + ")" :
                                    <Skeleton variant="text" sx={{ fontSize: '14px', background: '#bbb', width: "200px" }} />

                            }
                                {/* {(salesAgent.first_name && salesAgent.last_name) ?
                                    { salesAgent.first_name + " " + salesAgent.last_name ({salesAgent.email})  : <Skeleton variant="text" sx={{ fontSize: '14px', background: '#bbb', maxWidth: "200px" }} />
                                    } */}
                            </Typography>
                        </Box>

                    </Stack>

                    <Box sx={{ height: "1px", backgroundColor: "#444", width: "500px", marginBottom: "30px" }}></Box>

                    <Stack direction="row">
                        <Box>
                            <img src={strendLogo} width={135} style={{ filter: "Invert(1)", marginTop: "10px" }} />
                        </Box>
                        <Box direction="column" sx={{ marginLeft: "30px" }}>
                            <Typography sx={{ color: "#fff", fontSize: "12px" }}>Unit 6, 7 Pambalong Drive, Mayfield West NSW 2304</Typography>
                            <Typography sx={{ color: "#fff", fontSize: "12px", marginBottom: "10px" }}>+61 2 4040 9757</Typography>

                            <Typography sx={{ color: "#fff", fontSize: "12px" }}>Suite 6, Level 3,  24 Albert Road, South Melb VIC 3205</Typography>
                            <Typography sx={{ color: "#fff", fontSize: "12px", marginBottom: "10px" }}>+61 3 9077 2638</Typography>

                            <Typography sx={{ color: "#fff", fontSize: "12px", marginBottom: "10px" }}>ABN: 3568991</Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default AcceptanceTopbanner