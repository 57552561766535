import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  orderItemDetailsBar: {
    backgroundColor: "#e3e3e3",
    padding: "9px 0px 9px 28px",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  label: {
    fontSize: "12px",
    color: "#000",
    opacity: 0.85,
    textAlign: "left",
  },
  marginTop: {
    marginTop: "10px",
  },
  detail: {
    color: "#000",
    opacity: 0.6,
    textAlign: "left",
  },
  orderInformationLabel: {
    marginTop: "12px !important",
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.89)",
  },
  orderInformationValue: {
    marginBottom: "30px !important",
    fontSize: 14,
    textTransform: "capitalize",
  },
});

const OrderInformation = (props) => {
  const classes = useStyles();
  const { id, productionOrder, activeItem } = props;

  const quotationFiles = productionOrder?.quotation?.quotation_files;

  const downloadUsingAnchorElement = async (index) => {
    const anchor = document.createElement("a");
    anchor.href = quotationFiles[index].file_url;
    anchor.download = "*";
    document.body.appendChild(anchor);
    anchor.click();
  };

  return (
    <Box className={classes.orderItemDetailsBar}>
      <Grid container>
        <Grid item xs={3}>
          <Box className={classes.label}>Order Number | 订单号</Box>
          <Box className={classes.detail}>{activeItem?.sales_order_id}</Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.label}>Organization Type | 组织类型</Box>
          <Box className={classes.detail}>Default</Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.label}>Client | 客户</Box>
          <Box className={classes.detail}>
            {productionOrder?.organization?.name}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.label}>Invoice To | 开发票</Box>
          <Box className={classes.detail}>{productionOrder?.invoice_to}</Box>
        </Grid>
        <Grid item xs={3} className={classes.marginTop}>
          <Box className={classes.label}>Ship To | 运送到</Box>
          <Box className={classes.detail}>{productionOrder?.ship_to}</Box>
        </Grid>
        <Grid item xs={3} className={classes.marginTop}>
          <Box className={classes.label}>Country | 国家</Box>
          <Box className={classes.detail}>
            {productionOrder?.organization?.country?.name}
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.marginTop}>
          <Box className={classes.label}>Account No | 户口号码</Box>
          <Box className={classes.detail}>-</Box>
        </Grid>
      </Grid>
    </Box>
    // <Content sx={{ flexGrow: 1, paddingLeft:'38px'}}>
    //     <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    //         <Grid item xs={12} sx={{marginTop: '27px'}}>
    //             <Typography className={classes.orderInformationLabel}>Order Number | 订单号:</Typography>
    //             <Typography className={classes.orderInformationValue}>
    //             {activeItem?.sales_order_id}
    //             </Typography>

    //             <Divider></Divider>

    //             <Typography className={classes.orderInformationLabel}>Production Order Number | 生产订单号:</Typography>
    //             <Typography className={classes.orderInformationValue}>{activeItem?.production_order?.id}</Typography>

    //             <Divider></Divider>

    //             {/* <Typography className={classes.orderInformationLabel}>Order Method | 订购方式:</Typography>
    //             <Typography className={classes.orderInformationValue}>{`${productionOrder?.order_method?.name} Order`}</Typography>

    //             <Divider></Divider> */}

    //             <Typography className={classes.orderInformationLabel}>Organization Type | 组织类型</Typography>
    //             <Typography className={classes.orderInformationValue}>Default</Typography>

    //             <Divider></Divider>

    //             <Typography className={classes.orderInformationLabel}>Client | 客户:</Typography>
    //             <Typography className={classes.orderInformationValue}>{productionOrder?.organization?.name}</Typography>

    //             <Divider></Divider>

    //             <Typography className={classes.orderInformationLabel}>Country | 国家:</Typography>
    //             <Typography className={classes.orderInformationValue}>{productionOrder?.organization?.country?.name }</Typography>

    //             <Divider></Divider>

    //             <Typography className={classes.orderInformationLabel}>Account No | 户口号码:</Typography>
    //             <Typography className={classes.orderInformationValue}>-</Typography>
    //             {quotationFiles === undefined || quotationFiles?.length !== 0 &&
    //                 <>
    //                     <Typography className={classes.orderInformationLabel}>Quotation Concept files</Typography>
    //                     <Box>
    //                         {quotationFiles?.map((i, index) => (
    //                             <Box key={i?.id} sx={{display:'flex', padding:'5px', justifyContent:'space-between', alignItems:'center'}}>
    //                             <Box sx={{display:'flex'}}>
    //                                 <InsertDriveFileIcon color="primary" sx={{marginRight:'5px'}}/>
    //                                 <Typography variant="span">{(i?.file.split('/')[2]).split('_')[1]}</Typography>
    //                             </Box>
    //                             <Button onClick={()=>downloadUsingAnchorElement(index)}>Download File</Button>
    //                             </Box>
    //                         ))}
    //                     </Box>
    //                 </>
    //             }
    //         </Grid>
    //     </Grid>
    // </Content>
  );
};

export default OrderInformation;
