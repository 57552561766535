import { userConstants } from "../constants";

export function profile(state = {}, action) {
  switch (action.type) {
     // get sinngle
     case userConstants.GET_USER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_USER_DETAILS_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case userConstants.GET_USER_DETAILS_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
