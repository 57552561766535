import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../../actions/alert.actions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormDetail from "./FormDetail.component";

const ParameterForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alertState = useSelector((state) => state.alert);
  const organizationState = useSelector((state) => state.organizations);
  const { parameter, mode = "add", paramdata } = props;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogMode, setDialogMode] = useState(null);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const handleSuccessDialog = (msg, mode) => {
    setDialogContent(msg);
    setDialogMode(mode);
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(alertActions.clear());
    setOpen(false);
    if (mode === "edit") {
      navigate(-1);
    }
  };

  const handleErrorAlert = () => {
    setError(true);
    setTimeout(() => {
      handleErrorClose();
    }, 6000);
  };

  const handleErrorClose = () => {
    setError(false);
    dispatch(alertActions.clear());
  };

  return (
    <div>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={handleErrorClose}>
          <AlertTitle>Error adding {parameter?.titleSingular}</AlertTitle>
          {organizationState?.error &&
          <span>
            {organizationState?.error} <strong>. Please try again</strong>
          </span>
          }
          {typeof alertState?.message === "string" && (
            <span>
              {alertState?.message} <strong>. Please try again</strong>
            </span>
          )}
        </Alert>
      )}

      <FormDetail
        title={parameter?.title}
        titleSingle={parameter?.titleSingular}
        mode={mode}
        paramdata={paramdata}
        handleSuccessDialog={handleSuccessDialog}
        handleErrorAlert={handleErrorAlert}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {`${organizationState?.parameter?.data?.name} ${parameter?.titleSingular} ${
            mode === "add" ? "Created" : mode === "edit" ? "Updated" : ""
          } Successfully!`}
        </DialogTitle>

        {dialogContent && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {typeof dialogContent === "string" && dialogContent}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ParameterForm;
