import React, { useState } from "react";
import { Box } from "@mui/material";
import QuantityForm from "./QuantityForm.component";

const QuantitiesInput = (props) => {
  const { sizes, updateSizeQunatity } = props;

  return (
    <Box>
      <QuantityForm
        sizeOptions={sizes}
        updateSizeQunatity={updateSizeQunatity}
        mode="create"
      />
    </Box>
  );
};

export default QuantitiesInput;
