import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";
import { profile } from "./profile.reducer";
import { roles } from "./roles.reducer";
import { organizations } from "./organization.reducer";
import { webstores } from "./webstore.reducer";
import { alert } from "./alert.reducer";
import { fileUploads } from "./fileUpoader.reducer";
import { paymentTerms } from "./parameters/paymentTerms.reducer";
import { sportCategories } from "./parameters/sportCategories.reducer";
import { items } from "./parameters/items.reducer";
import { fabrics } from "./parameters/fabrics.reducer";
import { colors } from "./parameters/colors.reducer";
import { patterns } from "./parameters/patterns.reducer";
import { labels } from "./parameters/labels.reducer";
import { productionOrders } from "./parameters/productionOrder.reducer";
import { gst } from "./parameters/gst.reducer";
import { deliveryFees } from "./parameters/deliveryFees.reducer";
import { bankDetails } from "./parameters/bankDetails.reducer";
import { linings } from "./parameters/lining.reducer";
import { extraCharges } from "./parameters/extraCharges.reducer";
import { artworkCharges } from "./parameters/artworkCharges.reducer";
import { sizes } from "./parameters/sizes.reducer";
import { measurements } from "./parameters/measurements.reducer";
import { sizeCharts } from "./parameters/sizeCharts.reducer";
import { weight } from "./parameters/weight.reducer";
import { countries } from "./parameters/countries.reducer";
import { trims } from "./parameters/trims.reducer";
import { couriers } from "./parameters/couriers.reducer";
import { storeItems } from "./store/storeitem.reducer";
import { cartItems } from "./store/cart.reducer";

// ashnaz
import cartReducer from "./../store/Store/Slice";
import orderSlice from "./../store/Store/orderSlice";
import messagesSlice from "./../store/Store/messagesSlice";
import { prodApprovalsApi } from "../services/api/prod-approval";
import { baseApi } from "../services/api";

const customer = cartReducer;
const orders = orderSlice;
const message = messagesSlice;

const rootReducer = combineReducers({
  authentication,
  users,
  profile,
  roles,
  alert,
  fileUploads,
  paymentTerms,
  sportCategories,
  items,
  fabrics,
  colors,
  patterns,
  labels,
  productionOrders,
  gst,
  deliveryFees,
  bankDetails,
  linings,
  artworkCharges,
  extraCharges,
  sizes,
  measurements,
  sizeCharts,
  weight,
  customer,
  orders,
  message,
  organizations,
  countries,
  webstores,
  trims,
  couriers,
  storeItems,
  cartItems,
  [baseApi.reducerPath]: baseApi.reducer,
});

export default rootReducer;
