import { itemConstants } from "../../constants";

export function items(state = {}, action) {
  switch (action.type) {
    // get all
    case itemConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case itemConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case itemConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case itemConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case itemConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case itemConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case itemConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case itemConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case itemConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case itemConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case itemConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case itemConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case itemConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
