import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useStyles } from "../../../Theme/Style";
import styles from "../../admin/UserManagement/UserForm/FormDetail.module.scss";
import { useEffect } from "react";

const TrimDetailsComponent = (props) => {
  const classes = useStyles();
  const {
    activeItem,
    colors,
    trims,
    poFields,
    handleSubmit,
    positions,
    template,
  } = props;

  // const poFieldsItems = poFields.filter((p=> p?.is_trim === 1)) || [];
  // console.log(poFieldsItems)

  useEffect(() => {
    if (template?.trimDetails) {
      const trimsFromTemplates = template?.trimDetails?.map((t) => {
        return {
          item: {
            ...t,
          },
          fabricType: {},
          color: {},
        };
      });
      setFabricItems(trimsFromTemplates);
    }
  }, [template]);

  const defaultProps = {
    options: poFields,
    getOptionLabel: (option) =>
      option?.name ? option?.name | (+"-" + option?.chinese_name) : "",
  };
  const defaultProps1 = {
    options: trims,
    getOptionLabel: (option) =>
      option?.name ? option?.name + "-" + option?.chinese_name : "",
  };
  const defaultProps2 = {
    options: colors,
    getOptionLabel: (option) =>
      option?.english_name
        ? option?.english_name + "-" + option?.chinese_name
        : "",
  };
  const defaultProps3 = {
    options: positions,
    getOptionLabel: (options) => (options?.name ? options?.name : ""),
  };

  const [fabricsItems, setFabricItems] = React.useState([]);

  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  console.log("props", defaultProps1);
  React.useEffect(() => {
    const nonTrims = activeItem?.production_order?.po_fields.filter(
      (f) => f?.is_trim !== 0
    );
    const items = nonTrims.map((n) => {
      return {
        item: { id: n?.id, name: n?.combinedName },
        fabricType: { name: n?.po_field_values?.value },
        color: n?.po_field_values?.color,
      };
    });
    setFabricItems(items);
  }, [activeItem]);

  const addNewRow = () => {
    const fabricDetails = {
      item: {},
      fabricType: {},
      color: {},
    };
    setFabricItems([...fabricsItems, fabricDetails]);
  };

  const handleChange = (event, name, index, val) => {
    setError({ status: false });
    const { value } = event.target;
    const rowsInput = [...fabricsItems];
    rowsInput[index][name] = val;
    setFabricItems(rowsInput);
  };

  const deleteRows = (index) => {
    const rows = [...fabricsItems];
    rows.splice(index, 1);
    setFabricItems(rows);
  };

  const handleTrimSubmit = () => {
    if (
      fabricsItems?.every((el) => el?.item?.id && el.fabricType && el.color?.id)
    ) {
      handleSubmit(fabricsItems, "trims");
    } else {
      setError({ status: true, message: "Please fill the values" });
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Trim Details
          </Grid>
          <Grid item xs={4}>
            面料细节
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Box className={classes.tableHeader}>
          <Grid container>
            <Grid item xs={3} className={classes.cell}>
              <Box>Item</Box>
              <Box>物品</Box>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Box>Trim Type</Box>
              <Box>面料类型</Box>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Box>Color</Box>
              <Box>颜色</Box>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Box>Position</Box>
              <Box>颜色</Box>
            </Grid>
          </Grid>
        </Box>
        {fabricsItems?.map((subItems, index) => {
          return (
            <Box className={classes.row} key={index}>
              <Grid container>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps1}
                      value={subItems?.item}
                      //defaultValue={subItems.item || ''}
                      id="controlled-demo"
                      autoComplete
                      includeInputInList
                      //freeSolo={true}
                      // onSelect={(e)=>handleChange(e, 'item', index)}
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "item", index, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    {/*<Autocomplete*/}
                    {/*    className={classes.select}*/}
                    {/*    {...defaultProps1}*/}
                    {/*    value={subItems?.fabricType}*/}
                    {/*    // defaultValue={subItems.fabricType}*/}
                    {/*    id="controlled-demo"*/}
                    {/*    autoComplete*/}
                    {/*    includeInputInList*/}
                    {/*    freeSolo={true}*/}
                    {/*    onChange={(e, value) => {*/}
                    {/*        //setSizes(value);*/}
                    {/*        handleChange(e, 'fabricType', index, value)*/}
                    {/*    }}*/}
                    {/*    renderInput={(params) => (*/}
                    {/*    <TextField {...params} placeholder="Select" variant="standard" />*/}
                    {/*    )}*/}
                    {/*/>*/}
                    <TextField
                      style={{ paddingTop: "2px" }}
                      fullWidth
                      id="trim_type"
                      name="trim_type"
                      // label="Trim Type"
                      // value={values.first_name}
                      onChange={(e, value) => {
                        //setSizes(value);*/}
                        handleChange(e, "fabricType", index, e.target.value);
                        console.log(e);
                      }}
                      // onBlur={handleBlur}
                      // error={touched.first_name && Boolean(errors.first_name)}
                      // helperText={touched.first_name && errors.first_name}
                      variant="standard"
                      className={classes.textField}
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps2}
                      value={subItems?.color}
                      // defaultValue={subItems.color}
                      id="controlled-demo"
                      autoComplete
                      includeInputInList
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "color", index, value);
                      }}
                      // onSelect={(e)=>handleChange(e, 'color', index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps3}
                      value={subItems?.position}
                      // defaultValue={subItems.color}
                      id="controlled-demo"
                      autoComplete
                      includeInputInList
                      onChange={(e, value) => {
                        //setSizes(value);
                        handleChange(e, "color", index, value);
                      }}
                      // onSelect={(e)=>handleChange(e, 'color', index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Button className={classes.itemRemoveIcon}>
                  <RemoveCircleOutline onClick={() => deleteRows(index)} />
                </Button>
              </Grid>
            </Box>
          );
        })}

        {error?.status && (
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ color: "red", fontSize: 12 }}>
              {error?.message}
            </Typography>
          </Box>
        )}
        <Box className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            className={classes.addNewRowBtn}
            onClick={addNewRow}
          >
            {" "}
            <AddCircleOutline sx={{ paddingRight: "10px" }} /> Add New Row{" "}
          </Button>
          <Button
            variant="contained"
            className={classes.submitBtn}
            onClick={handleTrimSubmit}
          >
            {" "}
            Save{" "}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TrimDetailsComponent;
