export const fileUploadConstants = {
  GET_SINGLE_REQUEST: "FILE_UPLOAD_GET_SINGLE_REQUEST",
  GET_SINGLE_SUCCESS: "FILE_UPLOAD_GET_SINGLE_SUCCESS",
  GET_SINGLE_FAILURE: "FILE_UPLOAD_GET_SINGLE_FAILURE",

  GET_ALL_REQUEST: "FILE_UPLOAD_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "FILE_UPLOAD_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "FILE_UPLOAD_GET_ALL_FAILURE",

  ADD_REQUEST: "FILE_UPLOAD_ADD_REQUEST",
  ADD_SUCCESS: "FILE_UPLOAD_ADD_SUCCESS",
  ADD_FAILURE: "FILE_UPLOAD_ADD_FAILURE",

  UPDATE_REQUEST: "FILE_UPLOAD_UPDATE_REQUEST",
  UPDATE_SUCCESS: "FILE_UPLOAD_UPDATE_SUCCESS",
  UPDATE_FAILURE: "FILE_UPLOAD_UPDATE_FAILURE",

  DELETE_REQUEST: "FILE_UPLOAD_DELETE_REQUEST",
  DELETE_SUCCESS: "FILE_UPLOAD_DELETE_SUCCESS",
  DELETE_FAILURE: "FILE_UPLOAD_DELETE_FAILURE",

  CLEAR: "FILE_UPLOAD_CLEAR",
};
