import { useTheme } from "@emotion/react";
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import AllProductionOrders from "./AllProductionOrders";
import AllSalesOrderInProduction from "./AllSalesOrderInProduction";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 35px",
  },
  headerContainerMainTitle: {
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "40.8px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  headerContainerSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  headerContainerSubTitleIcon: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 14.9px",
  },
  headerButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  printButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#F5F5F5 !important",
    borderColor: "#d2d0d0 !important",
  },
  downloadButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#E0E0E0 !important",
  },
  tabs: {
    background: "#fff",
    color: "rgba(0, 0, 0, 0.6)",
    borderRadius: "4px",
    fontWeight: 700,
    "& .MuiTab-root.Mui-selected": {
      borderBottom: "1px solid #2AB5BF",
      color: "#2AB5BF",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.125 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ProductionOrderDetails = ({ ...props }) => {
  const {
    productionOrders,
    paginationData,
    handlePageChange,
    status,
    allInvoiceInProduction,
    paginationSalesOrderData,
    pendingProductionOrders,
    completeProductionOrders,
    approveProductionOrders,
    setStatus,
    handleProductionOrdersTab,
    handleInvoiceInProductionsPageChange,
    loadingTabComponent,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const switchResult = (status) => {
      switch (status) {
        case 0:
          return "all";
        case 1:
          return "pending";
        case 2:
          return "completed";
        case 3:
          return "approved";
        default:
          return "all";
      }
    };
    const result = switchResult(newValue);
    handleProductionOrdersTab(result);
    setStatus(result);
  };

  return (
    <>
      {status !== "proceededProductionOrder" && (
        <AppBar
          position="static"
          sx={{ boxShadow: "none", borderRadius: "4px" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            className={classes.tabs}
          >
            <Tab
              label="All Production Orderb"
              {...a11yProps(0)}
              sx={{ opacity: 1 }}
            />
            <Tab
              label="Production Orders - pending"
              {...a11yProps(1)}
              sx={{ opacity: 1 }}
            />
            <Tab
              label="Production Orders - Level 1 Approved"
              {...a11yProps(2)}
              sx={{ opacity: 1 }}
            />
            <Tab
              label="Production Orders - Level 2 Approved"
              {...a11yProps(3)}
              sx={{ opacity: 1 }}
            />
          </Tabs>
        </AppBar>
      )}

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
      >
        {[0, 1, 2, 3].map((item, index) => (
          <TabPanel
            key={index}
            value={value}
            index={item}
            dir={theme.direction}
          >
            {status !== "proceededProductionOrder" ? (
              <AllProductionOrders
                productionOrders={productionOrders}
                pendingProductionOrders={pendingProductionOrders}
                completeProductionOrders={completeProductionOrders}
                approveProductionOrders={approveProductionOrders}
                status={value}
                paginationData={paginationData}
                handlePageChange={handlePageChange}
                loadingTabComponent={loadingTabComponent}
              />
            ) : (
              <AllSalesOrderInProduction
                productionOrders={allInvoiceInProduction}
                status={value}
                paginationData={paginationSalesOrderData}
                handlePageChange={handleInvoiceInProductionsPageChange}
                loadingTabComponent={loadingTabComponent}
              />
            )}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};
export default ProductionOrderDetails;
