import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogTitle,
  InputBase,
  Stack,
  Table,
  Typography,
} from "@mui/material";
import Intro from "../../atoms/Intro/Intro.component";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import StatusChange from "../../SalesOrders/StatusChange";
import ActionsButton from "../../SalesOrders/ActionsButton";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";
import FactoryInvoiceDetailView from "../FactoryInvoice/FactoryInvoiceDetailView/FactoryinvoiceDetailView";
import Modal from "@mui/material/Modal";

// import Button from '@mui/material/Button';/
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { convertToPlaces } from "../../../helpers/helpers";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import Typography from '@mui/material/Typography';
// import CloseIcon from '@mui/icons-material;

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 5px",
  },

  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
});

const StyledHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  height: "93px",
  paddingLeft: "10px",
  paddingTop: "10px",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  padding: "20px",
}));

const StyledTableCell = styled(TableCell)({
  width: "200px",
  fontWeight: 700,
});

const StyledTypography = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
});

const data = [
  {
    id: 65,
    created_at: "2023-10-31 03:11:34",
    ItemDescription: "Sublimed Polo",
    organization_name: "customer online 01",
    estimated_shipping_charges: 2500,
    extra_charges: 0,
    gst_amount: 2300,
    unit_price: 4600,
    qty: 5,
    grand_total: 23000,
  },
  {
    id: 66,
    created_at: "2023-10-20 03:11:34",
    ItemDescription: "hoodie",
    organization_name: "customer online 01",
    estimated_shipping_charges: 2600,
    extra_charges: 0,
    gst_amount: 2400,
    unit_price: 5000,
    qty: 15,
    grand_total: 75000,
  },
];

const columns = [
  { field: "id", headerName: "Production ID", resizable: false },
  {
    field: "created_at",
    headerName: "Date",
    width: 150,
    renderCell: (params) => moment(params.row.created_at).format("DD/MM/YYYY"),
  },
  {
    field: "ItemDescription",
    headerName: "Item Description",
    sortable: true,
    width: 300,
    minWidth: 100,
    valueGetter: (params) => `${params.row.ItemDescription || ""}`,
  },
  {
    field: "organization_name",
    headerName: "Organization Name",
    sortable: true,
    width: 200,
    minWidth: 100,
    valueGetter: (params) => `${params.row.organization_name || ""}`,
  },
  {
    field: "qty",
    headerName: "Quantity",
    sortable: true,
    // renderCell: params => convertToPlaces(params.row.qty )
  },
  {
    field: "unit_price",
    headerName: "Unit Price",
    sortable: true,
    renderCell: (params) => convertToPlaces(params.row.unit_price),
  },
  {
    field: "grand_total",
    headerName: "Grand Total",
    sortable: true,
    renderCell: (params) => convertToPlaces(params.row.grand_total),
  },
];

const GenerateResellerInvoices = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { proceededProductionOrder } = useParams();
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [response, setResponse] = useState("");
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [tot, setTot] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [openDialogErr, setOpenDialogErr] = useState(false);
  const [dialogMessageErr, setDialogMessageErr] = useState("");
  const [totalExtra, setTotalExtra] = useState();
  const [totDiscount, setTotDiscount] = useState();
  const [totDelivery, setTotDelivery] = useState();
  const [totGST, setTotGST] = useState();
  const [grndTotal, setGrndTotal] = useState();

  let gst = 0;
  let totalPrice = 0;
  let originalTotal = 0;
  let extraCharges = 0;
  let totalDelivery = 0;
  let grandTotal = 0;
  let totalDiscount = 0;

  const breadcrumbs = [
    <Typography key="3">Generate Factory Invoices</Typography>,
  ];

  useEffect(() => {
    const selectedRowsData = selectedIds.map((id) =>
      filteredResults.find((row) => row.id === id)
    );
    setSelectedRow(selectedRowsData);
    console.log(selectedRowsData);

    selectedRowsData.map((item) => {
      totalPrice = totalPrice + item?.unit_price * item?.qty;
      originalTotal = originalTotal + item?.original_total_price;
      gst = gst + item?.gst_amount || 0;
      totalDelivery = totalDelivery + item?.estimated_shipping_charges || 0;
      extraCharges = extraCharges + item?.extra_charges || 0;
      return "";
    });
    setTot(totalPrice);
    setTotalExtra(extraCharges);
    setTotDelivery(totalDelivery);
    setTotGST(gst);

    totalDiscount = originalTotal - totalPrice || 0;
    setTotDiscount(totalDiscount);
    grandTotal =
      gst + totalPrice + extraCharges + totalDelivery - totalDiscount;
    setGrndTotal(grandTotal);
    console.log(grandTotal);
  }, [selectedIds]);

  useEffect(() => {
    setLoadingComponent(true);

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/sales-order-items?have_invoice=0`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
        setResponse(data?.meta);
        setLoadingComponent(false);
      });
    });
  }, []);

  function handlePageChange(page) {
    setCurrentPage(page);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    let url;
    url = `${apiConstants.API_URL}/sales-order-items?have_invoice=0&page=${
      page + 1
    }`;

    fetch(url, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
        setResponse(data?.meta);
      });
    });
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handelSubmit = () => {
    setOpen(false);
    let reqData = {
      sales_order_item_ids: selectedIds,
      organization_id: 12105,
      comment: "Sample Comment",
    };
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };

    fetch(`${apiConstants.API_URL}/factory-invoices`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          if (response.status === 200) {
            setDialogMessage("Factory Invoice Created Successfully");
            setOpenDialog(true);
          } else {
            setDialogMessageErr("Factory Invoice Create Unsuccessful");
            setOpenDialogErr(true);
          }
        });
      }
    );
  };
  const handleClose = () => setOpen(false);
  const handleDialogClose = () => setOpenDialog(false);

  function handleDialogCloseErr() {
    setOpenDialogErr(false);
  }

  return (
    <>
      <Box className={classes.headerContainer}>
        <Intro
          pageTitle={"Generate Resell Invoices"}
          pageTitleShort=""
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
        />
      </Box>

      <Box className={classes.salesOrdersFilterBar}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={selectedIds.length === 0}
            variant="contained"
            className={classes.filterButton}
            onClick={handleClick}
          >
            Generate Resell Invoice
          </Button>
        </Box>
      </Box>

      <Box style={{ width: "100%" }}>
        {loadingComponent ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <DataGrid
            autoHeight={true}
            className={classes.dataGrid}
            rows={filteredResults}
            pageSize={response.per_page}
            pagination
            getRowId={(row) => row.id}
            checkboxSelection
            rowCount={response.total}
            onPageChange={handlePageChange}
            columns={columns}
            paginationMode="server"
            onSelectionModelChange={(ids) => {
              console.log(ids);
              setSelectedIds(ids);
            }}
          />
        )}
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <>
          <AppBar elevation={0} sx={{ position: "relative" }}>
            <Toolbar sx={{ px: { sm: 2 } }}>
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
                {/*{record && record[recordIdentifier]}*/}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              padding: "40px 25px 40px 40px;",
            }}
            style={{ justifyContent: "center" }}
          >
            <div>
              <Card style={{ minWidth: "500px" }}>
                <Stack direction={"column"} justifyContent="center">
                  <StyledHeader>
                    <Typography fontSize={"24px"}>Items</Typography>
                  </StyledHeader>
                  <Box>
                    <Table>
                      <TableHead>
                        <TableRow style={{ height: "56px" }}>
                          <StyledTableCell>Description</StyledTableCell>
                          <StyledTableCell align="right">
                            Unit Price
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Quantity
                          </StyledTableCell>
                          <StyledTableCell align="right">Price</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRow?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {item?.ItemDescription
                                ? item?.ItemDescription
                                : ""}
                            </TableCell>
                            <TableCell align="right">
                              {convertToPlaces(item?.unit_price)}
                            </TableCell>
                            <TableCell align="right">{item?.qty}</TableCell>
                            <TableCell align="right">
                              {convertToPlaces(item?.unit_price * item?.qty)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Stack>
              </Card>
            </div>
            <Stack
              direction={"column"}
              style={{ minWidth: "375px" }}
              spacing={3}
            >
              <StyledCard>
                <Stack textAlign={"left"} spacing={3}>
                  <Typography fontSize={"20px"}>Invoice</Typography>
                  <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Add-ons</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(totalExtra)}
                      </StyledTypography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Discounts applied</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(totDiscount)}
                      </StyledTypography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Total Excluding Tax</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(tot)}
                      </StyledTypography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Delivery</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(totDelivery)}
                      </StyledTypography>
                    </Stack>
                    <Divider style={{ backgroundColor: "#E0E0E0" }} />
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>GST</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(totGST)}
                      </StyledTypography>
                    </Stack>
                    <Divider style={{ backgroundColor: "#E0E0E0" }} />
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Total</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(grndTotal)}
                      </StyledTypography>
                    </Stack>
                  </Stack>
                </Stack>
              </StyledCard>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handelSubmit}>
                  generate Invoice
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {dialogMessage}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => {
              handleDialogClose();
              navigate("/admin/factory-invoice");
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogErr}
        onClose={handleDialogCloseErr}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="error"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {dialogMessageErr}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleDialogCloseErr} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateResellerInvoices;
