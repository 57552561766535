import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Button,
  Typography,
  Stack,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import Intro from "../../atoms/Intro/Intro.component";
import { Link, useNavigate } from "react-router-dom";
import {
  CUSTOMER_DETAIL,
  ORDER_DETAIL,
  CONCEPT_DETAIL,
  SALES_ORDER_STEPS,
  NEXT,
  FINISH,
} from "../../../constants/Constants";
import Stepper from "../../../Common/Stepper";
import Customer from "../../Customer";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import validationSchema from "../../../Schemas/customerSchema";
import { create, removeCustomerData } from "../../../store/Store/Slice";
import { sportCategoryActions } from "../../../actions";
import { coloryActions } from "../../../actions";
import { removeAll } from "../../../store/Store/orderSlice";
import SalesOrderSummery from "./SalesOrderSummery";
import SalesOrderDetails from "./SalesOrderDetails";
import AlertToast from "../../common/Toast";
import SalesOrderConceptDetails from "./SalesOrderConceptDetails";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowBack } from "@mui/icons-material";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";
import SuccessModal from "../../../Common/Modals/SuccessModal";
import ProgressCircularWithBackdrop from "../../../Common/ProgressCircular/ProgressCircularWithBackdrop";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)({
  color: "white",
});

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
  backgroundColor: theme.palette.success.main,
  width: "100%",
  marginTop: "20px",
}));

const CreateNewSalesOrderContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stage, updateStage] = useState(CUSTOMER_DETAIL);
  const [finished, updateFinish] = useState(false);
  const [sports, updateSports] = useState([]);
  const [colors, setColors] = useState([]);
  const sportsState = useSelector((state) => state.sportCategories);
  const colorState = useSelector((state) => state.colors);
  const { customer } = useSelector((state) => state.customer);
  const formikRef = React.createRef();
  const [items, setItems] = useState([]);
  const [orderId, setOrderId] = useState(null);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });

  const [openSuccess, setSuccess] = useState(false);
  const [totalDelivery, setTotalDelivery] = React.useState(0);
  const [updateTotalDelivery, setUpdateTotalDelivery] = React.useState(0);
  const handleSuccessClose = () => {
    setSuccess(false);
    navigate("/admin/sales-orders");
  };
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const [newCustomer, setNewCustomer] = useState(false);

  useEffect(() => {
    dispatch(removeAll({}));
    dispatch(removeCustomerData({}));
    dispatch(sportCategoryActions.getAllParameters());
    dispatch(coloryActions.getAllParameters());
    formik.resetForm();
  }, [dispatch]);

  useEffect(() => {
    updateSports(sportsState?.items?.data);
  }, [sportsState]);

  useEffect(() => {
    setColors(colorState?.items?.data);
  }, [colorState]);

  const formik = useFormik({
    initialValues: customer,
    validationSchema: validationSchema,
    enableReinitialize: true,
    innerRef: formikRef,
    onSubmit: (e) => {
      dispatch(create(e));
      switch (stage) {
        case CUSTOMER_DETAIL:
          if (formik?.isValid) {
            updateStage(ORDER_DETAIL);
            //break;
          }
          break;
        case ORDER_DETAIL:
          if (items.length > 0) {
            if (items.every((el) => el.color_id)) {
              updateStage(CONCEPT_DETAIL);
              break;
            } else {
              setToastMessage("Color of every item should be selected");
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            }
          }
          break;
        default:
          break;
      }
    },
  });

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/sales-orders">
      All Sales Order
    </Link>,
    <Typography key="3">{"New Sales Order"}</Typography>,
  ];

  /**
   *
   */
  const nextAction = () => {
    formik.submitForm();
  };

  const finishAction = () => {
    updateFinish(true);
  };

  const nextBtnText = stage === CONCEPT_DETAIL ? FINISH : NEXT;

  const btnAction = nextBtnText === FINISH ? finishAction : nextAction;

  const activeStep =
    stage === CUSTOMER_DETAIL
      ? 0
      : stage === ORDER_DETAIL
      ? 1
      : stage === CONCEPT_DETAIL && finished
      ? 3
      : 2;

  const enableSubmitButton =
    items.length > 0 &&
    items.every((el) => el.color_id) &&
    items.every((el) => el.image);

  /**
   *
   */
  const preAction = () => {
    switch (stage) {
      case ORDER_DETAIL:
        updateStage(CUSTOMER_DETAIL);
        break;
      case CONCEPT_DETAIL:
        updateStage(ORDER_DETAIL);
        break;
      default:
        break;
    }
  };

  /**
   *
   * @param {*} screen
   * @returns
   */
  const renderPage = (screen) => {
    switch (screen) {
      case CUSTOMER_DETAIL:
        return (
          <Customer
            formik={formik}
            enableNewCustomer={false}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
          />
        );
      case ORDER_DETAIL:
        return (
          <SalesOrderDetails
            items={items}
            updateItems={updateItems}
            colors={colors}
            view={false}
            sports={sports}
            addNewItems={addNewItems}
          />
        );
      case CONCEPT_DETAIL:
        return (
          <SalesOrderConceptDetails
            items={items}
            updateItems={updateItems}
            setOpenProgressCircular={setOpenProgressCircular}
          />
        );
      default:
        return (
          <Customer
            enableNewCustomer={false}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
          />
        );
    }
  };

  const updateItems = (index, newItem) => {
    let newArra = [...items];
    if (newItem) {
      newArra[index] = newItem;
    } else {
      newArra = newArra.filter((x) => x.id !== newArra[index]?.id);
      handleGetDelivery(newArra);
    }
    setItems(newArra);
  };

  const addNewItems = (newItem) => {
    const itemArray = [...items];
    itemArray.push(newItem);
    setItems(itemArray);
  };

  const backToOrderDetails = () => {
    updateStage(ORDER_DETAIL);
  };

  const handleGetDelivery = useCallback(
    (newItem) => {
      console.log(newItem);
      const updateItems = newItem || items;
      const itemArray = updateItems.map((o) => {
        const delivery = {
          item_id: o.item_id,
          qty: o.qty,
        };
        return delivery;
      });

      const reqData = {
        items: itemArray,
      };

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(reqData),
      };

      if (updateItems.length === 0) {
        setTotalDelivery(0);
        setUpdateTotalDelivery(0);
      } else {
        fetch(
          `${apiConstants.API_URL}/prices/shipping-charges`,
          requestOptions
        ).then((response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setToastMessage(errorMessage);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              setTotalDelivery(data?.data?.total_shipping_charges / 100);
              setUpdateTotalDelivery(data?.data?.total_shipping_charges / 100);
            }
          });
        });
      }
    },
    [items]
  );

  const submit = () => {
    setOpenProgressCircular(true);
    const oldItems = items?.map((item) => {
      const extraChargesList = item?.extra_charges_list?.map((x) => {
        return {
          extra_charge_id: x.id,
          cost: x.cost,
        };
      });

      delete item["colorObj"];
      if (item.selected) {
        delete item["id"];
      }
      const estimatedShippingCharges =
        totalDelivery !== updateTotalDelivery
          ? (updateTotalDelivery / items?.length) * 100
          : item?.estimated_shipping_charges;

      let newObt = {
        ...item,
        price_id: item.selected ? item?.price_id : item?.price?.id,
        sales_order_item_extra_charges: extraChargesList,
        item_details: [],
        estimated_shipping_charges: estimatedShippingCharges,
      };
      return newObt;
    });

    let data = {
      order_method_id: 1,
      invoice_id: null,
      organization_id: customer?.organization_id,
      status_id: 1,
      items: oldItems,
      estimated_shipping_charges: updateTotalDelivery * 100,
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(data),
    };

    if (items.every((el) => el.image)) {
      fetch(`${apiConstants.API_URL}/sales-orders`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              if (response.status === 401) {
                // logout()
              }
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });

              setToastMessage(errorMessage);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
              setOpenProgressCircular(false);
            } else {
              setOrderId(data?.data?.id);
              setOpenProgressCircular(false);
              setSuccess(!openSuccess);
            }
          });
        }
      );
    } else {
      setToastMessage("Design is required for every items");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    }
  };

  return (
    <Box>
      <Intro
        pageTitle={`New Sales Order`}
        pageTitleShort="New Sales Order"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />
      <Box sx={{ width: "50%", margin: "10px 0" }}>
        <Stepper steps={SALES_ORDER_STEPS} activeStep={activeStep} />
      </Box>
      <Stack direction={"row"} sx={{ direction: "column" }}>
        <Card
          sx={{
            width: "934px",
            display: "flex",
            flexDirection: "column",
            paddingRight: "10px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            {renderPage(stage)}

            {/* footer */}
            <Container>
              <Stack
                direction={"row"}
                sx={{ justifyContent: "space-between", marginTop: "30px" }}
              >
                {stage !== "customerDetailsScreen" ? (
                  <Button variant="text" onClick={() => preAction()}>
                    BACK
                  </Button>
                ) : (
                  <Box></Box>
                )}
                {nextBtnText !== "FINISH" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "10px",
                    }}
                  >
                    <StyledButton
                      variant="contained"
                      onClick={() => {
                        btnAction();
                      }}
                    >
                      {nextBtnText}
                    </StyledButton>
                  </Box>
                )}
              </Stack>
            </Container>
          </CardContent>
        </Card>
        {stage !== "customerDetailsScreen" && (
          <Card
            title="Quotation Summery"
            sx={{ width: "350px", backgroundColor: "#EFEFEF" }}
          >
            <CardContent>
              <SalesOrderSummery
                items={items}
                file={{}}
                view={false}
                totalDelivery={totalDelivery}
                setTotalDelivery={setTotalDelivery}
                updateTotalDelivery={updateTotalDelivery}
                setUpdateTotalDelivery={setUpdateTotalDelivery}
                handleGetDelivery={handleGetDelivery}
              />

              <StyledSubmitButton
                style={{ height: "68px" }}
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                onClick={() => submit()}
                disabled={!enableSubmitButton}
              >
                {"submit and send"}
              </StyledSubmitButton>

              {stage === CONCEPT_DETAIL && (
                <Button
                  style={{ height: "68px", width: "100%" }}
                  startIcon={
                    <ArrowBack sx={{ paddingRight: "12px", width: "32px" }} />
                  }
                  variant="text"
                  onClick={() => backToOrderDetails()}
                >
                  Back Order Details
                </Button>
              )}
            </CardContent>
          </Card>
        )}
      </Stack>
      <SuccessModal
        open={openSuccess}
        handleClose={handleSuccessClose}
        title="Sales Order Submitted Successfully"
        body={`Sales order #${orderId} is submitted successfully and sent to the customer email ${customer?.email}. Thank you`}
      />
      <ProgressCircularWithBackdrop open={openProgressCircular} />

      <AlertToast
        toastMessage={toastMessage}
        toastState={toastState}
        handleClose={handleToastClose}
      />
    </Box>
  );
};

export default CreateNewSalesOrderContainer;
