import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Box } from "@mui/material";
import { labelActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const PaymentTerms = () => {
  const dispatch = useDispatch();
  const  labels  = useSelector((state) => state.labels);
  console.log(labels)
  // const labels = {
  //   items: {
  //     data: [
  //       {
  //         id: 1,
  //         labelname: "Main Label",
  //         image: "",
  //       },
  //       {
  //         id: 2,
  //         labelname: "Care Label",
  //         image: "",
  //       },
  //       {
  //         id: 3,
  //         labelname: "Side Pip Label",
  //         image: "",
  //       },
  //       {
  //         id: 4,
  //         labelname: "Swing Tickt",
  //         image: "",
  //       },
  //       {
  //         id: 5,
  //         labelname: "Special Swing ticket",
  //         image: "",
  //       },
  //       {
  //         id: 6,
  //         labelname: "Barcode",
  //         image: "",
  //       },
  //       {
  //         id: 7,
  //         labelname: "Polybag",
  //         image: "",
  //       },
  //     ],
  //   },
  // };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Label Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "image",
      headerName: "Image",
      minWidth: 200,
      flex: 1,
      hide: true,
    },
    {
      field: "image_url",
      headerName: "image_url",
      minWidth: 200,
      flex: 1,
      hide: true,
    },
    {
      field: "label_type_id",
      headerName: "Label Type",
      width: 150,
      hide: true,
    },
    // {
    //   field: "updated_at",
    //   headerName: "Updated At",
    //   width: 150,
    //   hide: true,
    // },
  ];

  const recordIdentifier = "labelname";

  useEffect(() => {
    dispatch(labelActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>

        <DataTable
          loading={labels?.loading}
          rows={labels?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="labelActions"
          recordType={"label"}
        />

    </Box>
  );
};

export default PaymentTerms;
