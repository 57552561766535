import { artworkChargeConstants } from "../../constants";
import { artworkChargeService } from "../../services";
import { alertActions } from "../alert.actions";

export const artworkChargeActions = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter() {}

// get all parameter
function getAllParameters() {
  return (dispatch) => {
    dispatch(request());

    artworkChargeService.getAllParameters().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error))
    );
  };

  function request(params) {
    return { type: artworkChargeConstants.GET_ALL_REQUEST, params };
  }
  function success(items) {
    return { type: artworkChargeConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: artworkChargeConstants.GET_ALL_FAILURE, error };
  }
}

// add parameter
function addParameter(count, max_limit, cost) {
  return (dispatch) => {
    dispatch(request(count, max_limit, cost));

    artworkChargeService.addParameter(count, max_limit, cost).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: artworkChargeConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: artworkChargeConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: artworkChargeConstants.ADD_FAILURE, error };
  }
}

// update parameter
function updateParameter(id, count, max_limit, cost) {
  return (dispatch) => {
    dispatch(request(id, count, max_limit, cost));

    artworkChargeService.updateParameter(id, count, max_limit, cost).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: artworkChargeConstants.UPDATE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: artworkChargeConstants.UPDATE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: artworkChargeConstants.UPDATE_FAILURE, error };
  }
}

// delete parameter
function deleteParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    artworkChargeService.deleteParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: artworkChargeConstants.DELETE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: artworkChargeConstants.DELETE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: artworkChargeConstants.DELETE_FAILURE, error };
  }
}

// clear parameter
function clear() {
  return { type: artworkChargeConstants.CLEAR };
}
