import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { itemActions, weightActions } from "../../../../../../actions";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";

const ItemsStyles = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;

  const dispatch = useDispatch();
  const paramState = useSelector((state) => state.items);
  const alertState = useSelector((state) => state.alert);
  const weightState = useSelector((state) => state.weight);
  const formikRef = React.createRef();

  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    nick_name: Yup.string().required("Nick Name is required"),
    weight_id: Yup.string().required("Weight is required"),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    name: "",
    nick_name: "",
    weight_id: "",
    status: true,
  };

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata) {
        const fields = ["name", "nick_name", "status", "weight_id"];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, paramdata[field], false)
        );
      }
    };

    prefillData();
  }, []);

  useEffect(() => {
    const handleSuccess = () => {
      if (paramState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (paramState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [paramState, handleSuccessDialog, alertState, handleErrorAlert]);

  useEffect(() => {
    dispatch(weightActions.getAllParameters());
  }, [dispatch]);

  const handleSubmit = (values) => {
    if (values && mode === "add") {
      dispatch(
        itemActions.addParameter(
          values.name,
          values.nick_name,
          values.status,
          values.weight_id
        )
      );
    }
    if (values && mode === "edit") {
      dispatch(
        itemActions.updateParameter(
          paramdata.id,
          values.name,
          values.nick_name,
          values.status,
          values.weight_id
        )
      );
    }
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Item Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="nick_name"
                  name="nick_name"
                  label="Item Nick Name"
                  value={values.nick_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nick_name && Boolean(errors.nick_name)}
                  helperText={touched.nick_name && errors.nick_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.weight_id && Boolean(errors.weight_id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Item Weight</InputLabel>
                  {weightState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="weight_id"
                    name="weight_id"
                    label="Item Weight"
                    value={values.weight_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {weightState?.items?.data.map((item) => (
                      <MenuItem key={item?.id} value={item.id}>
                        {item?.name} ({item?.code})
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.weight_id && Boolean(errors.weight_id) && (
                    <FormHelperText>
                      {touched.weight_id && errors.weight_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={paramState?.loading}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ItemsStyles;
