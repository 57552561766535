import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const useStyles = makeStyles({
  productTableContainer: {
    marginBottom: "55px",
  },
  productTableTitleContainer: {
    backgroundColor: "#000",
    height: "55px",
    textAlign: "left",
    padding: "19px 16px",
    borderRadius: "8px 8px 0 0",
    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: 24,
      color: "#fff",
    },
  },
  tableHeaderCell: {
    fontWeight: "700 !important",
  },
});

const ProductsDetailsTable = ({ ...props }) => {
  const classes = useStyles();
  const {
    products,
    salesOrderById,
    subCharge,
    amountFormat,
    customer,
    shippingCharge,
    view,
  } = props;

  React.useEffect(() => {
    console.log("products", products);
  }, [products]);

  return (
    <Paper className={classes.productTableContainer}>
      <Box className={classes.productTableTitleContainer}>
        <Typography>Your Products</Typography>
      </Box>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>
              Style Name
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              Description
            </TableCell>
            <TableCell align="right" className={classes.tableHeaderCell}>
              Item
            </TableCell>
            <TableCell align="right" className={classes.tableHeaderCell}>
              Quantity
            </TableCell>
            <TableCell align="right" className={classes.tableHeaderCell}>
              Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: "#D5F9FC" }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 700, fontSize: 12 }}>
              {customer || view ? "Invoice" : "Sales Order"} #
              {salesOrderById.id}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          {products.map((item) => (
            <TableRow
              key={item.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ fontSize: 14 }}>
                {item?.style || "N/A"}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ fontSize: 14 }}>
                {item.price?.item?.name ? `${item.price?.item?.name} - ` : ""}{" "}
                {item.price?.fabric?.name}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 14 }}>
                A${(item.unit_price / 100).toFixed(2)}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 14 }}>
                {item.qty}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 14 }}>
                A${(item.total_price / 100).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              height: "52px",
              backgroundColor: "#E2E2E2",
            }}
          >
            <TableCell component="th" scope="row">
              {customer || view ? "Invoice" : "Sales Order"} #
              {salesOrderById.id} Total
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">
              A$
              {amountFormat(
                salesOrderById.sub_total - (subCharge + shippingCharge)
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ProductsDetailsTable;
