import React, { useState } from "react";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";

import { countries } from "../../../../../../constants";

const GstTax = (props) => {
  const { title, titleSingle, mode, handleSuccessDialog } = props;
  const debug = false;
  const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    percentage: Yup.string().required("GST percentage is required"),
    country: Yup.string().required("Country is required"),
    orgid: Yup.string(),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    percentage: "",
    country: "",
    orgid: "",
    status: true,
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  const handleSubmit = (values) => {
    console.log(values);
    handleSuccessDialog(successMsg);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="percentage"
                  name="percentage"
                  label="GST Percentage (%)"
                  value={values.percentage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.percentage && Boolean(errors.percentage)}
                  helperText={touched.percentage && errors.percentage}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="country"
                  name="country"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  size="small"
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.orgid && Boolean(errors.orgid)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Organization ID(s)
                  </InputLabel>
                  <Select
                    id="orgid"
                    name="orgid"
                    value={values.orgid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"Organization ID 1"}>
                      Organization ID 1
                    </MenuItem>
                    <MenuItem value={"Organization ID 2"}>
                      Organization ID 2
                    </MenuItem>
                  </Select>
                  {touched.orgid && Boolean(errors.orgid) && (
                    <FormHelperText>{errors.orgid}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={submitting}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GstTax;
