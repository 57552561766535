import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { liningActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const Linings = () => {
  const dispatch = useDispatch();
  const linings = useSelector((state) => state.linings);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Lining Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      hide: true,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(liningActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {linings && (
        <DataTable
          loading={linings?.loading}
          rows={linings?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="liningActions"
        />
      )}
    </Box>
  );
};

export default Linings;
