import { apiConstants } from "../../constants";
import { authHeader, getUserToken } from "../../helpers";

export const patternService = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/patterns/${id}`, requestOptions).then(
    handleResponse
  );
}

// get all parameter
function getAllParameters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/patterns`, requestOptions).then(
    handleResponse
  );
}

// add parameter
function addParameter(name, code, image, status, measurements, method, csv) {
  if (method === 1) {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("code", code);
    formData.append("image", image);
    formData.append("status", status === true ? 1 : 0);
    formData.append("file", csv);

    const token = getUserToken();

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    return fetch(`${apiConstants.API_URL}/upload-pattern`, requestOptions).then(
      handleResponse
    );
  } else {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        name: name,
        code: code,
        image: image,
        status: status,
        measurements: measurements,
      }),
    };

    return fetch(`${apiConstants.API_URL}/patterns`, requestOptions).then(
      handleResponse
    );
  }
}

// update parameter
function updateParameter(id, name, code, image, status, measurements) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      name: name,
      code: code,
      image: image,
      status: status,
      measurements: measurements,
    }),
  };

  return fetch(`${apiConstants.API_URL}/patterns/${id}`, requestOptions).then(
    handleResponse
  );
}

// delete parameter
function deleteParameter(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/patterns/${id}`, requestOptions).then(
    handleResponse
  );
}

// clear parameter
function clear() {}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
