import React, { useState } from "react";
import * as DOMpurify from "dompurify";
import { Stack, Box, Typography, Grid, TextField } from "@mui/material";

const ItemComments = (props) => {
  const { comments, setComments } = props;

  const handleCommentChange = (event) => {
    const clean = DOMpurify.sanitize(event.target.value);
    setComments(clean);
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box>
          <Typography variant="h4" sx={{ fontSize: "1.1rem", fontWeight: 600 }}>
            Comments
          </Typography>
          <Typography variant="body2">
            Please specify if you have any additional comments, special
            instructions, changes etc.
          </Typography>
        </Box>
      </Stack>

      <Grid container>
        <Grid item xs={12}>
          <TextField
            id="standard-multiline-static"
            label="Your Comments"
            multiline
            rows={4}
            maxRows={4}
            variant="standard"
            fullWidth
            value={comments}
            onChange={handleCommentChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ItemComments;
