import { gstConstants } from "../../constants";

export function gst(state = {}, action) {
  switch (action.type) {
    // get all
    case gstConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case gstConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case gstConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case gstConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case gstConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case gstConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case gstConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case gstConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case gstConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case gstConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case gstConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case gstConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case gstConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
