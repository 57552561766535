import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../../../actions";
import { Switch, Case, Default } from "react-if";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PaymentTerms from "./PaymentTerms/PaymentTerms.component";
import SportCategories from "./SportsCategories/SportCategories.component";
import Fabrics from "./Fabrics/Fabrics.component";
import QuantityRanges from "./QuantityRanges/QuantityRanges.component";
import ItemsStyles from "./ItemsStyles/ItemsStyles.component";
import ProductionOrder from "./ProductionOrders/ProductionOrders.component";
import Pattern from "./Patterns/Patterns.component";
import SizingCharts from "./Patterns/Patterns.old.with-sizing-charts.component";
import Labels from "./Labels/Labels.component";
import GstTax from "./GstTax/GstTax.component";
import Colors from "./Colors/Colors.component";
import DeliveryFees from "./DeliveryFees/DeliveryFees.component";
import BankDetails from "./BankDetails/BankDetails.component";
import Linings from "./Linings/Linings.component";
import ExtraCharges from "./ExtraCharges/ExtraCharges.component";
import ArtworkCharges from "./ArtworkCharges/ArtworkCharges.component";
import Sizes from "./Sizes/Sizes.component";
import Measurements from "./Measurement/Measurement.component";
import TrimsComponent from "./Trims/Trims.component";
import CouriersComponent from "./Couriers/Couriers.component";
import Position from "./Position/Position.component";
import { ProductionOrderTemplateForm } from "./ProductionOrderTemplate";
import { PaymentMethodForm } from "./PaymentMethods";

const ParameterForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alertState = useSelector((state) => state.alert);
  const { parameter, mode = "add", paramdata } = props;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogMode, setDialogMode] = useState(null);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const handleSuccessDialog = (msg, mode) => {
    setDialogContent(msg);
    setDialogMode(mode);
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(alertActions.clear());
    setOpen(false);
    navigate(-1);
  };

  const handleErrorAlert = () => {
    setError(true);
    setTimeout(() => {
      handleErrorClose();
    }, 6000);
  };

  const handleErrorClose = () => {
    setError(false);
    dispatch(alertActions.clear());
  };

  return (
    <div>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={handleErrorClose}>
          <AlertTitle>Error adding {parameter?.titleSingular}</AlertTitle>
          {typeof alertState?.message === "string" && (
            <span>
              {alertState?.message} <strong>. Please try again</strong>
            </span>
          )}
        </Alert>
      )}

      <Switch>
        <Case condition={parameter?.slug === "payment-terms"}>
          <PaymentTerms
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "sports-categories"}>
          <SportCategories
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "fabrics"}>
          <Fabrics
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "quantity-ranges"}>
          <QuantityRanges
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "items-styles"}>
          <ItemsStyles
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "production-order-items"}>
          <ProductionOrder
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "patterns"}>
          <Pattern
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "sizing-charts"}>
          <SizingCharts
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "labels"}>
          <Labels
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "gst-tax"}>
          <GstTax
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "colors"}>
          <Colors
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "delivery-fees"}>
          <DeliveryFees
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "bank-details"}>
          <BankDetails
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "linings"}>
          <Linings
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "extra-charges"}>
          <ExtraCharges
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "artwork-charges"}>
          <ArtworkCharges
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "sizes"}>
          <Sizes
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "measurements"}>
          <Measurements
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "trims"}>
          <TrimsComponent
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "couriers"}>
          <CouriersComponent
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "position"}>
          <Position
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "production-order-templates"}>
          <ProductionOrderTemplateForm
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Case condition={parameter?.slug === "payment-methods"}>
          <PaymentMethodForm
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            mode={mode}
            paramdata={paramdata}
            handleSuccessDialog={handleSuccessDialog}
            handleErrorAlert={handleErrorAlert}
          />
        </Case>
        <Default>No form found</Default>
      </Switch>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {`${parameter?.titleSingular} ${
            mode === "add" ? "Created" : mode === "edit" ? "Updated" : ""
          } Successfully!`}
        </DialogTitle>

        {dialogContent && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {typeof dialogContent === "string" && dialogContent}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ParameterForm;
