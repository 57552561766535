import React from "react";
import { useLoading } from "../../../Contexts/GlobalLoadingContext";
import { Box, LinearProgress, styled } from "@mui/material";

const Loader = () => {
  const { isLoading } = useLoading();

  return isLoading ? (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  ) : null;
};

const BarLoaderStyle = styled(Box)({
  height: 4,
  margin: "2px 0px",
});

const BarLoader = ({ loading }) => {
  return <BarLoaderStyle>{loading && <LinearProgress />}</BarLoaderStyle>;
};

export { Loader, BarLoader };
