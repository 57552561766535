import { apiConstants } from "../constants";
import { authHeader, removeUser, storeUser } from "../helpers";

export const userService = {
  login,
  customerLogin,
  logout,
  getAll,
  getSingle,
  addUser,
  updateUser,
  assignRolesToUser,
  getUserDetails,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      // remote IDM server
      // userName: username,
      email: username,
      password: password,
      // local JWT API
      // username: username,
      // password: password,
    }),
  };

  return (
    // fetch(`${apiConstants.API_URL}/users/authenticate`, requestOptions) // local JWT test server
    fetch(`${apiConstants.API_URL}/login`, requestOptions) // remote IDM server
      .then(handleResponse)
      .then((user) => {
        user.alias = username;
        storeUser(user);
        return user;
      })
  );
}

function customerLogin(username, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      // remote IDM server
      // userName: username,
      email: username,
      password: password,
      // local JWT API
      // username: username,
      // password: password,
    }),
  };

  return (
    // fetch(`${apiConstants.API_URL}/users/authenticate`, requestOptions) // local JWT test server
    fetch(`${apiConstants.API_URL}/customer-login`, requestOptions) // remote IDM server
      .then(handleResponse)
      .then((user) => {
        user.alias = username;
        console.log("🚀 ~ file: user.service.js:37 ~ .then ~ user", user);
        storeUser(user);
        return user;
      })
  );
}

function logout() {
  removeUser();
}

// get single parameter
function getSingle(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function getAll(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/users?page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function addUser(
  first_name,
  last_name,
  code,
  contact_no,
  address_line_1,
  address_line_2,
  postal_code,
  country_id,
  manager_id,
  is_admin,
  status,
  email,
  organization_id,
  sport_id
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      code: code,
      contact_no: contact_no,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      postal_code: postal_code,
      country_id: country_id,
      manager_id: null,
      is_admin: is_admin,
      status: status,
      email: email,
      organization_id: is_admin ? null : organization_id,
      sport_id: null,
    }),
  };

  return fetch(`${apiConstants.API_URL}/register`, requestOptions).then(
    handleResponse
  );
}

function updateUser(
  id,
  first_name,
  last_name,
  code,
  contact_no,
  address_line_1,
  address_line_2,
  postal_code,
  country_id,
  manager_id,
  is_admin,
  status,
  email,
  organization_id,
  sport_id,
  image
) {
  const request = {
    first_name: first_name,
    last_name: last_name,
    code: code,
    contact_no: contact_no,
    address_line_1: address_line_1,
    address_line_2: address_line_2,
    postal_code: postal_code,
    country_id: country_id,
    manager_id: null,
    is_admin: is_admin,
    status: status,
    email: email,
    organization_id: is_admin ? null : organization_id,
    sport_id: null,
    image: image ? image : "",
  };

  if (request?.image === "") {
    delete request["image"];
  }
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(request),
  };
  return fetch(`${apiConstants.API_URL}/update/${id}`, requestOptions) // remote IDM server
    .then(handleResponse);
}
//Assign roles to user
function assignRolesToUser(userId, role) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ roles: [role] }),
  };

  return fetch(
    `${apiConstants.API_URL}/auth/users/${userId}/assign-roles`,
    requestOptions
  ).then(handleResponse);
}

function getUserDetails(userId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiConstants.API_URL}/users/${userId}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // window.location.reload(true);
      }

      const error =
        (data && data.error) || (data && data.message) || response.statusText;
      let errorMessage = "";
      if (data?.code === 500) {
        errorMessage = (data && data.message) || response.statusText;
      } else {
        errorMessage = Object.values(error).map((errorKey) => {
          return errorKey[0];
        });
      }
      return Promise.reject(errorMessage);
    }

    return data;
  });
}
