export const productionDetails = [
  {
    type: "fabric",
    title: {
      en: "Fabric Details",
      zh: "面料细节",
    },
    render: {
      type: "table",
      table: {
        columns: [
          {
            en: "Item",
            zh: "物品",
          },
          {
            en: "Fabric Type",
            zh: "面料类型",
          },
          {
            en: "Color",
            zh: "颜色",
          },
        ],
      },
    },
  },
  {
    type: "trim",
    title: {
      en: "Trim Details",
      zh: "面料细节",
    },
    render: {
      type: "table",
      table: {
        columns: [
          {
            en: "Item",
            zh: "物品",
          },
          {
            en: "Trim Type",
            zh: "面料类型",
          },
          {
            en: "Color",
            zh: "颜色",
          },
        ],
      },
    },
  },
  {
    type: "size",
    title: {
      en: "Sizes and Names",
      zh: "尺寸和名称",
    },
    render: {
      type: "table",
      table: {
        columns: [
          {
            en: "Total units",
            zh: "总计 单位",
          },
          {
            en: "Total units",
            zh: "总计 单位",
          },
          {
            en: "Total units",
            zh: "总计 单位",
          },
        ],
      },
    },
  },
  {
    type: "label",
    title: {
      en: "Labels",
      zh: "标签",
    },
    render: {
      type: "table",
      table: {
        columns: [
          {
            en: "Label",
            zh: "标签",
          },
          {
            en: "Preview",
            zh: "预习",
          },
        ],
      },
    },
  },
  {
    type: "instructions",
    title: {
      en: "Special Instructions",
      zh: "特别说明",
    },
    render: {
      type: "text",
      text: {
        title: {
          en: "",
          zh: "",
        },
        subtitle: {
          en: "Please specify if you have any additional requests, special instructions, change etc.",
          zh: "请说明您是否有任何其他要求、特殊说明、更改等。",
        },
        body: {
          en: "",
          zh: "",
        },
        fields: [
          {
            name: "comments",
            type: "textarea",
            en: "Your Comments",
            zh: "你的评论",
          },
        ],
      },
    },
  },
];
